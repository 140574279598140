<template>
<div>
          
  <div class="row">
            <div v-if="!layoutOnly" class="col-5">
              
              <slot></slot>
              <input type="color" v-model="backgroundColor" />

              <input type="color" v-model="color" />              
              
            </div>
            
            <div class="col text-start">
              <div class="accordion" id="accordionExample">
                
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Margin
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="box">
                        <box-layout-input v-model="marginTop" side="top"></box-layout-input>
                        <box-layout-input v-model="marginRight" side="right"></box-layout-input>
                        <box-layout-input v-model="marginBottom" side="bottom"></box-layout-input>
                        <box-layout-input v-model="marginLeft" side="left"></box-layout-input>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Padding
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="box">
                        <box-layout-input side="top" v-model="paddingTop"></box-layout-input>
                        <box-layout-input side="right" v-model="paddingRight"></box-layout-input>
                        <box-layout-input side="bottom" v-model="paddingBottom"></box-layout-input>
                        <box-layout-input side="left" v-model="paddingLeft"></box-layout-input>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Border Radius
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="box">
                        <box-layout-input v-model="borderTopLeftRadius" side="top-left"></box-layout-input>
                        <box-layout-input v-model="borderTopRightRadius" side="top-right"></box-layout-input>
                        <box-layout-input v-model="borderBottomLeftRadius" side="bottom-left"></box-layout-input>
                        <box-layout-input v-model="borderBottomRightRadius" side="bottom-right"></box-layout-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      Width
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div class="accordion-body" style="flex-direction: column">
                      <label>Width
                        <box-layout-input v-model="width"></box-layout-input>
                      </label>

                      <label>Min width
                        <box-layout-input v-model="minWidth"></box-layout-input>
                      </label>

                      <label>Max width
                        <box-layout-input v-model="maxWidth"></box-layout-input>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      Height
                    </button>
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div class="accordion-body" style="flex-direction: column">
                      <label>Height
                        <box-layout-input v-model="height"></box-layout-input>
                      </label>

                      <label>Min height
                        <box-layout-input v-model="minHeight"></box-layout-input>
                      </label>

                      <label>Max height
                        <box-layout-input v-model="maxHeight"></box-layout-input>
                      </label>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
  </div>

</div>
</template>

<script>
import { rgbToHex, parseRgbString } from '../utils.js';

import BoxLayoutInput from './inputs/BoxLayoutInput.vue';

export default {

  components: {
    BoxLayoutInput,
  },
  
  name: 'BoxLayout',
  data () {

    const bg = this.value.backgroundColor || '';
    let bgHex = null;
    if (bg.substring(0, 4) === 'rgb(') {
      bgHex = rgbToHex(
        ...Object.values(parseRgbString(this.value.backgroundColor))
      );
    } else if (bg.substring(0, 1) === '#') {
      bgHex = bg;
    } else {
      bgHex = '';
    }

    const fg = this.value.color || '';
    let fgHex = null;
    if (fg.substring(0, 4) === 'rgb(') {
      fgHex = rgbToHex(
        ...Object.values(parseRgbString(this.value.color))
      );
    } else if (fg.substring(0, 1) === '#') {
      fgHex = fg;
    } else {
      fgHex = '';
    }
    
    return {
      backgroundColor: bgHex,
      color: fgHex,      

      borderTopLeftRadius: this.value.borderTopLeftRadius,
      borderTopRightRadius: this.value.borderTopRightRadius,
      borderBottomRightRadius: this.value.borderBottomRightRadius,
      borderBottomLeftRadius: this.value.borderBottomLeftRadius,
      
      marginTop: this.value.marginTop,
      marginRight: this.value.marginRight,
      marginBottom: this.value.marginBottom,
      marginLeft: this.value.marginLeft,
      
      paddingTop: this.value.paddingTop,
      paddingRight: this.value.paddingRight,
      paddingBottom: this.value.paddingBottom,
      paddingLeft: this.value.paddingLeft,

      width: this.value.width,
      maxWidth: this.value.maxWidth,
      minWidth: this.value.minWidth,

      height: this.value.height,
      maxHeight: this.value.maxHeight,
      minHeight: this.value.minHeight,
    };
  },
  
  props: ['value', 'layoutOnly'],

  computed: {
    borderRadius () {
      return {
        borderTopLeftRadius: this.borderTopLeftRadius,
        borderTopRightRadius: this.borderTopRightRadius,
        borderBottomRightRadius: this.borderBottomRightRadius,
        borderBottomLeftRadius: this.borderBottomLeftRadius,
      };
    },
    margin () {
      return {
        marginTop: this.marginTop,
        marginRight: this.marginRight,
        marginBottom: this.marginBottom,
        marginLeft: this.marginLeft,
      };
    },
    padding () {
      return {
        paddingTop: this.paddingTop,
        paddingRight: this.paddingRight,
        paddingBottom: this.paddingBottom,
        paddingLeft: this.paddingLeft,
      };
    },
    widths () {
      return {
        width: this.width,
        maxWidth: this.maxWidth,
        minWidth: this.minWidth,
      };
    },
    heights () {
      return {
        height: this.height,
        maxHeight: this.maxHeight,
        minHeight: this.minHeight,
      };
    },
  },

  watch: {
    borderRadius () {
      this.save();
    },
    margin () {
      this.save();
    },
    padding () {
      this.save();
    },
    widths () {
      this.save();
    },
    heights () {
      this.save();
    },
    backgroundColor () {
      this.save();
    },
    color () {
      this.save();
    },
  },

  methods: {

    save () {
      this.$emit('input', {
        ...this.borderRadius,
        ...this.margin,
        ...this.padding,
        ...this.widths,
        ...this.heights,
        backgroundColor: this.backgroundColor,
        color: this.color,
      });
    },

  },
}
</script>

<style scoped>
.margin, .padding, .content {
  border: 1px solid lightgray;
  padding: 3rem;
  position: relative;
  
  min-height: 300px;
  width: 100%;
    
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-body {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  position: relative;
  border: 1px solid #eee;
  --input-width: 14rem;
  --input-height: 2rem;

  width: calc(100% - var(--input-width));
  height: calc(400px - var(--input-height) * 2);
  margin: auto;
  
}

>>> .box-layout-input input,
.box >>> input {
  height: var(--input-height);
  border: 1px solid #aaa;
  border-right: none;
}
>>> .box-layout-input select,
.box >>> select {
  padding: 0;
  height: var(--input-height);
  border: 1px solid #aaa;
  border-left: none;  
}
.box >>> .top {
  position: absolute;
  top: calc(-1 * var(--input-height) / 2);
  left: calc(50% - var(--input-width) / 2);
  height: var(--input-height);
  width: var(--input-width);
}
.box >>> .right {
  position: absolute;
  right: calc(-1 * var(--input-width) / 2);
  top: calc(50% - var(--input-height) / 2);
  height: var(--input-height);
  width: var(--input-width);
}
.box >>> .top-right {
  position: absolute;
  top: calc(-1 * var(--input-height) / 2);
  right: calc(-1 * var(--input-width) / 2);
  height: var(--input-height);
  width: var(--input-width);
}
.box >>> .top-left {
  position: absolute;
  top: calc(-1 * var(--input-height) / 2);
  left: calc(-1 * var(--input-width) / 2);
  height: var(--input-height);
  width: var(--input-width);
}
.box >>> .bottom-left {
  position: absolute;
  bottom: calc(-1 * var(--input-height) / 2);
  left: calc(-1 * var(--input-width) / 2);
  height: var(--input-height);
  width: var(--input-width);
}
.box >>> .bottom-right {
  position: absolute;
  bottom: calc(-1 * var(--input-height) / 2);
  right: calc(-1 * var(--input-width) / 2);
  height: var(--input-height);
  width: var(--input-width);
}
.box >>> .left {
  position: absolute;
  left: calc(-1 * var(--input-width) / 2);
  top: calc(50% - var(--input-height) / 2);
  height: var(--input-height);
  width: var(--input-width);
}
.box >>> .bottom {
  position: absolute;
  bottom: calc(-1 * var(--input-height) / 2);
  left: calc(50% - var(--input-width) / 2);
  height: var(--input-height);
  width: var(--input-width);
}
</style>
