<template>
<vue-draggable-resizable
  drag-handle=".dialog-drag-bar" class="dialog-content"
  :w="w" :h="h" :x="x" :y="y"
  :enable-native-drag="true" :draggable="draggable"
  :style="style">
  <div v-if="draggable" class="dialog-drag-bar top" @dblclick="toggleMinimized($event, 'top')">
    <div class="dialog-drag-bar-toolbar">
      <button @click="toggleMinimized"
              @mouseover="transparent = true" @mouseout="transparent = false">
        <ri v-if="!minimized" n="eye-off-line" />
        <ri v-if="minimized" n="eye-off-line" />
      </button>
    </div>
  </div>

  <dialog-preview-button v-if="previewable"
                         @unmask="transparent = true; $emit('unmask')"
                         @remask="transparent = false; $emit('remask')" />

  <slot></slot>
  <div v-if="draggable" class="dialog-drag-bar bottom"  @dblclick="toggleMinimized($event, 'bottom')">
    <div class="dialog-drag-bar-toolbar">
      <button @click="toggleMinimized"
              @mouseover="transparent = true" @mouseout="transparent = false">
        <ri v-if="!minimized" n="eye-off-line" />
        <ri v-if="minimized" n="eye-off-line" />
      </button>
    </div>
  </div>
</vue-draggable-resizable>
</template>

<script>
export default {
  props: ['draggable', 'previewable', 'width', 'height', 'isRoot'],

  methods: {
    toggleMinimized (e, direction) {
      this.minimized = !this.minimized;
      if (e && direction === 'top') {
        this.$nextTick(() => {
          this.offsetY = e.clientY - this.$el.offsetTop;
          this.$el.scrollTop = 0;
        });
      }
      if (e && direction === 'bottom') {
        this.$nextTick(() => {
          this.offsetY = e.clientY - this.$el.offsetTop - this.$el.offsetHeight;
          this.$el.scrollTop = this.$el.scrollHeight;
        });
      }
    },
    maskHovered () {
      this.minimized = true;
    },
    emitMaskChange () {
      if (this.minimized || this.transparent) {
        this.$emit('unmask');
      } else {
        this.$emit('remask');
      }
    },
  },

  watch: {
    minimized () {
      this.emitMaskChange();
    },
    transparent () {
      this.emitMaskChange();
    },
  },
  computed: {

    style () {
      const height = this.minimized ? {
        height: '20px',
      } : {};
      const opacity = this.transparent ? {
        opacity: 0.1,
      } : {};
      let rootStyles = {};
      if (this.isRoot) {
        rootStyles = {
          "margin-left": "auto",
          "margin-right": "auto",
          "position": "unset",
          "height": "80vh",
          "width": "80vw",
          "box-shadow": "0 1rem 3rem rgb(0 0 0 / 18%)",
        };
      }
      return {...height, ...opacity, ...rootStyles};
    },
    
    w () {
      return window.innerWidth * (this.width || 0.8);
    },
    
    h () {
      return this.height ? (window.innerHeight * this.height) : 'auto';
    },

    x () {
      return 0;
    },
    y () {
      return this.offsetY;
    },
    
  },
  data () {
    return {
      minimized: false,
      transparent: false,
      offsetY: 0,
    };
  },
}
</script>

<style scoped>
.dialog-content {
  border: none;
  width: 80%;
  position: fixed;
  margin: 100px auto;
  padding: 20px;
  background-color: #fff;
  z-index: 99999;
  box-shadow: 0 1rem 3rem rgba(0,0,0,.875);
  max-height: 80vh;
  overflow: scroll;
}
.dialog-content.dragging {
  transition: 0.01s;
  transform: translateX(-9999px);
}
.dialog-content .dialog-drag-bar {
  cursor: move;
  height: 1rem;
  width: calc(100% + 40px);
  background: #f2e4f2;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}
.dialog-content .dialog-drag-bar-toolbar {
  z-index: 99;
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
.dialog-content .dialog-drag-bar-toolbar button {
  background: none;
  border: none;
}
.dialog-content .dialog-drag-bar.top {
  margin-bottom: 20px;
}
.dialog-content .dialog-drag-bar.bottom {
  margin-top: 20px;
}
</style>
