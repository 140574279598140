var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"bounce"}},[(_vm.isMounted)?_c('md-card',{staticClass:"md-card-transparent-unless-hovered"},[_c('md-card-actions',{staticStyle:{"justify-content":"center"}},[_c('md-button',{on:{"click":function($event){return _vm.$emit('save', { uuid: _vm.uuid, type: _vm.blockType, args: _vm.value, code: _vm.code, rendered: _vm.rendered })}}},[_vm._v(" Save ")]),_c('md-button',{on:{"click":function($event){return _vm.$emit('discard', { uuid: _vm.uuid })}}},[_vm._v(" Cancel ")])],1),_c('md-card-content',_vm._l((_vm.form),function(ref,key){
var type = ref.type;
var subtype = ref.subtype;
var options = ref.options;
return _c('div',{key:key,style:(type === 'html' ? 'flex:100%;max-height:30vh;overflow:scroll': '')},[_c('label',[_vm._v(" "+_vm._s(key)+" "),(type === 'text')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value[key]),expression:"value[key]"}],attrs:{"type":"text"},domProps:{"value":(_vm.value[key])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, key, $event.target.value)}}}):(type === 'number')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value[key]),expression:"value[key]"}],attrs:{"type":"number"},domProps:{"value":(_vm.value[key])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, key, $event.target.value)}}}):(type === 'html')?_c('tiptap',{model:{value:(_vm.value[key]),callback:function ($$v) {_vm.$set(_vm.value, key, $$v)},expression:"value[key]"}}):(type === 'image')?_c('image-input',{model:{value:(_vm.value[key]),callback:function ($$v) {_vm.$set(_vm.value, key, $$v)},expression:"value[key]"}}):(type === 'select')?_c('md-field',[_c('label',{attrs:{"for":("field-" + key)}},[_vm._v(" "+_vm._s(key)+" ")]),_c('md-select',{attrs:{"name":("field-" + key),"id":("field-" + key)},model:{value:(_vm.value[key]),callback:function ($$v) {_vm.$set(_vm.value, key, $$v)},expression:"value[key]"}},_vm._l((options),function(ref){
var label = ref.label;
var value = ref.value;
return _c('md-option',{key:label,attrs:{"value":value}},[_vm._v(" "+_vm._s(label)+" ")])}),1)],1):_vm._e()],1)])}),0)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }