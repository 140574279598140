<template>
  <div>
    <div class="media-unit--content-wrapper" v-for="slot in numSlots" :key="slot">
      <img style="width:100%"
           :src="`https://res.cloudinary.com/pies/image/upload/ar_${aspectRatio}${images[slot].transforms}/${images[slot].public_id}`">
    </div>
  </div>
</template>

<script>
export default {
  name: 'image-gallery-columns',
  props: ['numSlots', 'aspectRatio', 'images'],
}
</script>
