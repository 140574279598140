export const banner_centered = {
  block: 'banner_header',
  args: {
    contentHeight: '6',
  },
};

export const banner_centered_no_image = {
  block: 'banner_header',
  args: {
    image: '',
    contentHeight: '6',
  },
};

export const small_banner_centered_no_image = {
  block: 'banner_header',
  args: {
    image: '',
    contentHeight: '.5',
    styles: {
      'max-width': '1200px',
      '--theme--font__size__heading': '1.3rem',
    },
  },
};

export const banner_centered_no_image_no_color = {
  block: 'banner_header',
  args: {
    image: '',
    contentHeight: '6',
    primaryColor: '#ffffff',
  },
};

export const banner_text_left = {
  block: 'banner_header',
  args: {
    media_side: 'right',
    contentMaxWidth: '800px',
  },
};

export const header_squares = {
  block: 'header_squares',
};
