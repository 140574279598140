<template>
<dialog-layout style="min-height: min(80vh, 800px)" :previewable="true"
               @unmask="$emit('unmask')"
               @remask="$emit('remask')" >
  
  <dialog-close-button @close="$emit('close')" />
  
  <md-app style="min-height: min(80vh, 800px)">
    <md-app-toolbar :class="dirty ? 'md-large md-dense' : ''">
      <div class="md-toolbar-row">      
        <app-tabs :tabs="tabs" @tab-changed="activeTab = $event; activeSubtab = '';" />
      </div>
      
      <div class="md-toolbar-row" v-if="dirty">
        <div class="md-toolbar-section-start">
          <md-button class="md-accent" @click="discardChanges">
            Revert Changes
          </md-button>
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-primary" @click="saveChanges">
            Save Changes
          </md-button>
        </div>
        
      </div>
      
    </md-app-toolbar>
    
    <md-app-drawer md-permanent="clipped">
      
      <md-list>
        <template v-if="subtabs[activeTab]">
          <md-list-item v-for="tab in subtabs[activeTab]"
                        :key="tab.id"
                        @click="activeSubtab = tab.id"
                        :class="activeSubtab === tab.id ? 'md-active' : ''">
            <md-icon><ri :n="tab.icon" /></md-icon>
            <span class="md-list-item-text">{{ tab.label }}</span>
          </md-list-item>
        </template>
        
      </md-list>
      
    </md-app-drawer>
    
    <md-app-content style="background:whitesmoke">
      
      <template v-if="activeTab === 'footer'">
        <md-empty-state v-if="!activeSubtab">
        </md-empty-state>
        <template v-else-if="activeSubtab === 'footer-nav'">

          <draggable :list="footer" @end="updateFooter" :disabled="sending">
            <form-card v-for="entry, index in footer"
                       :key="`footer-link-${index}`"
                       :no-layout="true"
                       inner-style="border-left: 10px solid whitesmoke; cursor: move;"
                       @submit="updateFooter"
                       @delete="removeFooterNavEntry(index)"
                       :sending="sending"
                       :style="`z-index: ${footer.length - index + 1}; position: relative`">
              <div class="md-layout">
                <div class="md-layout-item md-size-45 pe-3">
                  <md-field v-if="entry.href">
                    <label>URL</label>
                    <md-input v-model="entry.href" type="url" @blur="validateFooterLinkHref" />
                  </md-field>
                  <md-field v-else>
                    <label>Page</label>
                    <md-select v-model="entry.to" @md-selected="entry.title = computedPage(pageFromTo($event)).title">
                      <md-option value=""></md-option>
                      <md-option v-for="page, key in $store.state.repo.pages"
                                 :key="key"
                                 :value="toFromPage(key)">
                        {{ computedPage(key).title }}: /{{ computedPage(key).key }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-45 px-1">
                  <md-field>
                    <label>Label</label>
                    <md-input v-model="entry.title" type="text" />
                  </md-field>
                </div>
                <div class="md-layout-item md-size-10 ps-1">
                  <md-field class="md-has-value md-borderless">
                    <label>Buttonize?</label>
                    <md-checkbox v-model="entry.button" :value="true" style="margin: 10px 0 0" />
                  </md-field>
                </div>                
              </div>
              <div v-if="entry.button" class="md-layout">
                <div class="md-layout-item md-size-10 pe-3">
                  <md-field class="md-has-value md-borderless">
                    <label>Background</label>
                    <color-picker expand-to="center-right" v-model="entry.background" class="mt-1" size="25" />
                  </md-field>
                </div>
                <div class="md-layout-item md-size-10 pe-3">
                  <md-field class="md-has-value md-borderless">
                    <label>Text</label>
                    <color-picker to="to-right" v-model="entry.color" class="mt-1" size="25" />
                  </md-field>
                </div>
              </div>
            </form-card>
          </draggable>
          
          <form-card key="add-footer-link-form-card"
                     style="z-index: 0; position: relative"
                     :title="footer.length ? 'Add another link' : 'Add a link'"
                     :sending="sending"
                     :submit-enabled="addingFooterLinkValid"
                     @submit="addFooterLink">
            <md-switch v-model="addingFooterLink.external" class="md-switch-left-right">
              <span v-if="addingFooterLink.external">Link to URL</span>
              <span v-else>Link to page</span>
            </md-switch>
            <md-field v-if="addingFooterLink.external">
              <label>URL</label>
              <md-input v-model="addingFooterLink.href" type="url" @blur="validateFooterLinkHref" />
            </md-field>
            <md-field v-else>
              <label>Page</label>
              <md-select v-model="addingFooterLink.page">
                <md-option value=""></md-option>
                <md-option v-for="page, key in $store.state.repo.pages" :key="key" :value="key">
                  {{ computedPage(key).title }}: /{{ computedPage(key).key }}
                </md-option>
              </md-select>
            </md-field>
            <md-field>
              <label>Label</label>
              <md-input v-model="addingFooterLink.title" type="text" />
            </md-field>
          </form-card>
        </template>        
        <form-card v-else-if="activeSubtab === 'footer-colors'">
          <div>
            <label>
              Background color
              <color-picker
                expand-to="bottom-right"
                key="--theme--color__heavy" v-model="footerStyles['--theme--color__heavy']" fallback="rgba(0,0,0,0)" />
            </label>
          </div>
          <div>
            <label>
              Text color
              <color-picker
                expand-to="bottom-right"
                key="--theme--color__text" v-model="footerStyles['--theme--color__text']" fallback="rgba(0,0,0,1)" />
            </label>
          </div>
          <div>
            <label>
              Disclaimer color
              <color-picker
                expand-to="bottom-right"
                key="--disclaimer-color" v-model="footerStyles['--disclaimer-color']" fallback="rgba(0,0,0,1)" />
            </label>
          </div>                    
          <div>
            <label>
              Button colors
              <div>
                <color-picker
                  expand-to="bottom-right"
                  key="--link-color__hover" v-model="footerStyles['--button-color']" fallback="rgba(0,0,0,0)" />
                &nbsp;
                <color-picker
                  expand-to="bottom-right"
                  key="button-text-color" v-model="footerStyles['--button-text-color']" fallback="rgba(0,0,0,0)" />
                &nbsp;
                <color-picker
                  expand-to="bottom-right"
                  key="button-border-color" v-model="footerStyles['--button-border-color']" fallback="rgba(0,0,0,0)" />
              </div>
            </label>
          </div>
          <div>
            <label>
              Hovered-over button colors
              <div>
                <color-picker
                  expand-to="top-right"
                  key="--link-color__hover" v-model="footerStyles['--button-color__hover']" fallback="rgba(0,0,0,0)" />
                &nbsp;
                <color-picker
                  expand-to="top-right"
                  key="button-text-color" v-model="footerStyles['--button-text-color__hover']" fallback="rgba(0,0,0,0)" />
                &nbsp;
                <color-picker
                  expand-to="top-right"
                  key="button-border-color" v-model="footerStyles['--button-border-color__hover']" fallback="rgba(0,0,0,0)" />
              </div>
            </label>
          </div>
          <div>
            <label>
              Social icon color
              <color-picker
                expand-to="top-right"
                :key="`--social-button-color--${footerStyles['--button-color']}`"
                v-model="footerStyles['--social-button-color']"
                :fallback="footerStyles['--button-color']" />
            </label>
          </div>
          <div>
            <label>
              Hovered-over social icon color
              <color-picker
                expand-to="top-right"
                :key="`--social-button-color__hover--${footerStyles['--button-color__hover']}`"
                v-model="footerStyles['--social-button-color__hover']"
                :fallback="footerStyles['--button-color__hover']" />
            </label>
          </div>
          
        </form-card>
        
        <form-card v-else-if="activeSubtab === 'footer-disclaimer'"
                   @submit="updateDisclaimer" :sending="sending"
                   title="Legal Compliance"
                   help="Make sure to include any language required by the FEC, your state's Board of Elections, and/or local regulations. This will be displayed in your site's footer.">
          <md-field>
            <label>Disclaimer</label>
            <md-textarea placeholder="Insert your 'Paid For By' and other legally required text here"
                         v-model="disclaimer"></md-textarea>
          </md-field>
          
          <template v-slot:append>
            <div class="md-layout">
              <pro-tip class="md-size-100 md-layout-item">
                A legal disclaimer is a simple phrase you'll put on all your paid communications.
                Most federal campaigns use something like: "Paid for and authorized by John Smith For Congress"
                for print and web materials, but the requirements can vary based on what you are running for
                and where you are located.
                <br><br>
                If you don't know what your disclaimer needs to say, try calling your local Democratic party
                or the state Board of Elections to ask about the rules for your race. Most states post a rules
                book online, which will have lots more helpful information to prepare you for the race ahead.
              </pro-tip>
            </div>
          </template>
          
        </form-card>
        <template v-else-if="activeSubtab === 'footer-donate'">
          <form-card
            key="footer-donate-form"            
            title="Donate Button"
            help="Your donate button will appear in the site header and footer."
            @submit="updateDonate">
            <md-field key="footer-donate-href">
              <label>URL</label>
              <md-input v-model="donateHref" />
            </md-field>
            <md-field>
              <label>Label</label>
              <md-input v-model="donateTitle" />
            </md-field>
          </form-card>
          <pro-tip>
            Don't have a donate page? You can get one set up with ActBlue pretty quickly in most jurisdictions.
            <br><br>
            If you want (or are legally required) to remove the donate button altogether, just leave the URL field blank
            and it will go away.
          </pro-tip>
        </template>
        
        
      </template>
      <template v-else-if="activeTab === 'header'">
        <md-empty-state v-if="!activeSubtab">
        </md-empty-state>
        <form-card v-else-if="activeSubtab === 'header-design'"
                   :key="`${activeTab}-${activeSubtab}`"
                   title="Site header"
                   help="Changes will be applied immediately.">
          
          <div class="md-horizontal-radio-field">
            <label>
              Style
            </label>
            <md-field class="md-has-value md-borderless">
              <md-radio v-model="headerType" v-for="type in options.headerType" :key="type" :value="type">
                {{capitalize(type.replace('-header', '').replace(/-/g, ' '))}}
              </md-radio>
            </md-field>
          </div>
          
          <div class="md-horizontal-radio-field">
            <label>
              Behavior
            </label>
            <md-field class="md-has-value md-borderless">
              <md-radio v-model="headerStyles['position']"
                        v-for="type, val in options.header.position" :key="type"
                        :value="val">{{type}}</md-radio>
            </md-field>
          </div>
          
          <div>
            <label>
              Background color
              <color-picker
                expand-to="bottom-right"
                key="header-background-color"
                v-model="headerStyles['background-color']"
                fallback="#ffffff" />
            </label>
          </div>

          <div>
            <label>
              Text color
              <color-picker
                expand-to="bottom-right"
                key="header-text-color"
                v-model="headerStyles['--theme--color__text']"
                fallback="$store.getters.styles['--theme--color__text'] || '#fff'" />
            </label>
          </div>

          <div>
            <label>
              Button colors
              <color-picker
                expand-to="top-right"
                key="header-button-color"
                v-model="headerStyles['--button-color']"
                fallback="$store.getters.styles['--button-color'] || '#fff'" />
              <color-picker
                expand-to="top-right"
                key="header-button-text-color"
                v-model="headerStyles['--button-text-color']"
                fallback="$store.getters.styles['--button-text-color'] || '#fff'" />
            </label>
          </div>

          <div>
            <label>
              Mobile menu background color
              <color-picker
                expand-to="top-right"
                key="mobile__menu-background-color"
                v-model="headerStyles['--mobile__menu-background-color']"
                fallback="headerStyles['--menu-background-color'] || '#fff'" />
            </label>
          </div>
          <div>
            <label>
              Mobile menu text color
              <color-picker
                expand-to="top-right"
                key="mobile__theme--color__text"
                v-model="headerStyles['--mobile__theme--color__text']"
                fallback="$store.getters.styles['--mobile__theme--color__text'] || headerStyles['--theme--color__text'] || $store.getters.styles['--theme--color__text'] || '#fff'" />
            </label>
          </div>
          <div>
            <label>
              Mobile hamburger menu color
              <color-picker
                expand-to="top-right"
                key="mobile__hamburger-color"
                v-model="headerStyles['--mobile__hamburger-color']"
                />
            </label>
          </div>          

          <md-field class="md-has-value md-borderless">
            <label>Vertical</label>
            <vue-material-slider :vertical="true" :min="0" :max="250"
                                 :thumb-label="true"
                                 v-model="headerHeight.value" :invert="true" />
          </md-field>
          
        </form-card>

        <template v-else-if="activeSubtab === 'header-donate'">
          <form-card
            key="header-donate-form"
            title="Donate Button"
            help="Your donate button will appear in the site header and footer."
            @submit="updateDonate">
            <md-field key="header-donate-href">
              <label>URL</label>
              <md-input v-model="donateHref" />
            </md-field>
            <md-field>
              <label>Label</label>
              <md-input v-model="donateTitle" />
            </md-field>
          </form-card>
          <pro-tip>
            Don't have a donate page? You can get one set up with ActBlue pretty quickly in most jurisdictions.
            <br><br>
            If you want (or are legally required) to remove the donate button altogether, just leave the URL field blank
            and it will go away.
          </pro-tip>
        </template>
      
        
        <form-card v-else-if="activeSubtab === 'header-logo'"
                   title="Logo"
                   help="A small version (80 pixels tall) with a transparent background works best.">
          <image-input upload-preset="uth5vfjo" placeholder="Logo" :allow-delete="true"
                       v-model="logo" @deleteImage="logo = null" />
        </form-card>
        
        <template v-else-if="activeSubtab === 'header-nav'">

          <draggable :list="header" @end="updateHeader" :disabled="sending">
            <form-card v-for="entry, index in header"
                       :key="`header-link-${index}`"
                       :no-layout="true"
                       ref="header-entry"
                       inner-style="border-left: 10px solid whitesmoke; cursor: move;"
                       @submit="updateHeader"
                       @delete="removeHeaderNavEntry(index)"
                       :sending="sending"
                       :style="`z-index: ${header.length - index + 1}; position: relative`">
              <div class="md-layout">
                <div class="md-layout-item md-size-45 pe-3">
                  <md-field v-if="entry.href">
                    <label>URL</label>
                    <md-input v-model="entry.href" type="url" @blur="validateHeaderLinkHref" />
                  </md-field>
                  <md-field v-else>
                    <label>Page</label>
                    <md-select v-model="entry.to"
                               @md-opened="entry.title = ''"
                               @md-closed="entry.title = entry.title || computedPage(pageFromTo(entry.to)).title">
                      <md-option value=""></md-option>
                      <md-option v-for="page, key in $store.state.repo.pages"
                                 :key="key"
                                 :value="toFromPage(key)">
                        {{ computedPage(key).title }}: /{{ computedPage(key).key }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-45 px-1">
                  <md-field>
                    <label>Label</label>
                    <md-input v-model="entry.title" type="text" />
                  </md-field>
                </div>
                <div class="md-layout-item md-size-10 ps-1">
                  <md-field class="md-has-value md-borderless">
                    <label>Buttonize?</label>
                    <md-checkbox v-model="entry.button" :value="true" style="margin: 10px 0 0" />
                  </md-field>
                </div>
              </div>
              <div v-if="entry.button" class="md-layout">
                <div class="md-layout-item md-size-10 pe-3">
                  <md-field class="md-has-value md-borderless">
                    <label>Background</label>
                    <color-picker expand-to="center-right" v-model="entry.background" class="mt-1" size="25" />
                  </md-field>
                </div>
                <div class="md-layout-item md-size-10 pe-3">
                  <md-field class="md-has-value md-borderless">
                    <label>Text</label>
                    <color-picker to="to-right" v-model="entry.color" class="mt-1" size="25" />
                  </md-field>
                </div>
              </div>
            </form-card>
          </draggable>
          
          <form-card key="add-header-link-form-card"
                     style="z-index: 0; position: relative"
                     :title="header.length ? 'Add another link' : 'Add a link'"
                     :sending="sending"
                     :submit-enabled="addingHeaderLinkValid"
                     @submit="addHeaderLink">
            <md-switch v-model="addingHeaderLink.external" class="md-switch-left-right">
              <span v-if="addingHeaderLink.external">Link to URL</span>
              <span v-else>Link to page</span>
            </md-switch>
            <md-field v-if="addingHeaderLink.external">
              <label>URL</label>
              <md-input v-model="addingHeaderLink.href" type="url" @blur="validateHeaderLinkHref" />
            </md-field>
            <md-field v-else>
              <label>Page</label>
              <md-select v-model="addingHeaderLink.page">
                <md-option value=""></md-option>
                <md-option v-for="page, key in $store.state.repo.pages" :key="key" :value="key">
                  {{ computedPage(key).title }}: /{{ computedPage(key).key }}
                </md-option>
              </md-select>
            </md-field>
            <md-field>
              <label>Label</label>
              <md-input v-model="addingHeaderLink.title" type="text" />
            </md-field>
          </form-card>
        </template>
      </template>
      <template v-else-if="activeTab === 'social'">
        <md-empty-state v-if="!activeSubtab" style="max-width: 700px"
          md-label="Link to your social media"
          md-description="Social media links will appear in your site's header and footer after you add them.">
          <md-subheader>
            Choose a social media platform on the left to get started.
            (Have an account on a platform that's not listed here? Get in touch and we'll be glad to help!)

            <pro-tip class="mt-4">
              Something about which accounts we think are important, and why, and tips like cleaning your accounts
              before launching and why you should use separate campaign accounts instead of personal accounts.
            </pro-tip>
          </md-subheader>
        </md-empty-state>
        <form-card v-else-if="editingSocial !== -1"
                   :key="activeSubtab" :sending="sending" @submit="updateSocial">
          <md-field>
            <label>
              {{ subtabs.social.find(o => o.id === activeSubtab).label }} URL 
            </label>
            <md-input v-model="social[editingSocial].href" />
          </md-field>
        </form-card>
        <form-card v-else @submit="saveSocial">
          <md-field>
            <label>
              {{ subtabs.social.find(o => o.id === activeSubtab).label }} URL 
            </label>
            <md-input class="form-control" type="url" v-model="addingSocial.href" />
          </md-field>
        </form-card>
      </template>
  
    </md-app-content>
    
  </md-app>
  
</dialog-layout>
</template>

<script>
import { startCase } from 'lodash';
import { computedPage } from '../page-utils.js';
import { writeStyleString } from '../utils.js';
import { parseCssUnitValue } from '../css-utils.js';
import draggable from 'vuedraggable'
import ColorPicker from './ColorPicker.vue';

import $ from 'cash-dom';

export default {
  name: 'footer-manager',
  
  components: {
    draggable,
    ColorPicker,
  },
  
  methods: {

    async discardChanges () {
      $('footer').attr('style', writeStyleString(this.$store.getters.footerStyles, false, true));
      $('#masthead').attr('style', writeStyleString(this.$store.getters.headerStyles, false, true));
      
      this.footerStyles = {...this.$store.getters.footerStyles};
      this.headerStyles = {...this.$store.getters.headerStyles};      

      this.dirty = false;
    },

    async saveChanges () {
      const footerStyle = writeStyleString(this.footerStyles, false, true),
            headerStyle = writeStyleString(this.headerStyles, false, true),
            config = {
              ...this.$store.state.repo.config.layout,
              footerStyle,
              headerStyle,
            };
      this.saving = true;
      await this.$store.dispatch('writeLayoutConfig', { config });

      this.saving = false;
      this.dirty = false;
    },
    compileFooterPreview () {
      $('footer').attr('style', writeStyleString(this.footerStyles, false, true));
    },
    compileHeaderPreview () {
      $('#masthead.site-header').attr('style', writeStyleString(this.headerStyles, false, true));
    },
    
    pageFromTo (to) {
      let page = to.replace(/^\//, '');
      if (page === '') {
        page = 'index';
      }
      return page;
    },
    toFromPage (page) {
      if (!page) return '/';
      return page === 'index' ? '/' : `/${page}`;
    },
    
    computedPage (o) {
      if (!o) return { title: '' };
      return computedPage(o, this.$store.state);
    },
    capitalize: o => startCase(o),
    
    writeStyles: o => writeStyleString(o),
    
    validateURL: href => {
      if (href.startsWith('#') || href.startsWith('/') || href.startsWith('?') || href.startsWith('.')) {
        return href;
      }
      if (href.indexOf('://') === -1) {
        href = `https://${href}`;
      }
      return href;
    },
    
    validateSocial (o) {
      if (o.href.startsWith('@')) {
        o = {...o, href: o.href.replace(/^@/, `https://${o.platform}.com/`)};
      }
      if (o.href.indexOf('/') === -1) {
        o = {...o, href: `https://${o.platform}.com/${o.href}`};
      }
      if (o.href.startsWith('http://')) {
        o = {...o, href: o.href.replace('http://', 'https://')};
      }
      o.href = this.validateURL(o.href);
      return o;
    },
    
    validateHeaderLinkHref () {
      if (this.addingHeaderLink.href) {
        this.addingHeaderLink.href = this.validateURL(this.addingHeaderLink.href);
      }
    },
    
    removeHeaderNavEntry (index) {
      this.header = this.header.slice(0, index).concat(this.header.slice(index + 1, this.header.length));
    },

    validateFooterLinkHref () {
      if (this.addingFooterLink.href) {
        this.addingFooterLink.href = this.validateURL(this.addingFooterLink.href);
      }
    },
    
    removeFooterNavEntry (index) {
      this.footer = this.footer.slice(0, index).concat(this.footer.slice(index + 1, this.footer.length));
    },
    
    updateHeader () {
      this.header = [...this.header];
    },
    
    async addHeaderLink () {
      let newLink = {...this.addingHeaderLink};
      delete newLink.external;
      delete newLink.page;
      
      this.header = [...this.header, newLink];
      
      this.addingHeaderLink = {
        ...this.addingHeaderLink,
        
        href: '',
        to: '',
        title: '',
        page: '',
      };
    },

    
    updateFooter () {
      this.footer = [...this.footer];
    },
    
    async addFooterLink () {
      let newLink = {...this.addingFooterLink};
      delete newLink.external;
      delete newLink.page;
      
      this.footer = [...this.footer, newLink];
      
      this.addingFooterLink = {
        ...this.addingFooterLink,
        
        href: '',
        to: '',
        title: '',
        page: '',
      };
    },
    
    async updateDonate () {
      this.sending = true;
      const donate = {
        ...this.$store.state.repo.config.layout.donate,
        href: this.donateHref,
        title: this.donateTitle,
      };
      const config = {...this.$store.state.repo.config.layout, donate };
      await this.$store.dispatch('writeLayoutConfig', { config, immediately: true });
      this.sending = false;
    },
    
    async updateSocial () {
      this.sending = true;
      const social = this.social.filter(o => o.href).map(this.validateSocial);
      const config = {...this.$store.state.repo.config.layout, social };
      await this.$store.dispatch('writeLayoutConfig', { config, immediately: true });
      this.social = this.$store.state.repo.config.layout.social;
      this.sending = false;
    },
    
    async updateDisclaimer () {
      this.sending = true;
      const config = {
        ...this.$store.state.repo.config.layout,
        disclaimer: this.disclaimer,
        disclaimerStyles: this.disclaimerStyles,
      };
      await this.$store.dispatch('writeLayoutConfig', { config, immediately: true });
      this.sending = false;
    },
    async saveSocial () {
      this.sending = true;
      let social = this.social = [...this.social, {
        ...this.addingSocial,
        platform: this.activeSubtab.replace('social-', ''),
      }].filter(o => o.href).map(this.validateSocial);
      
      const config = {...this.$store.state.repo.config.layout, social};
      await this.$store.dispatch('writeLayoutConfig', { config, immediately: true });
      this.addingSocial = { href: '', };
      this.sending = false;
    },
  },
  
  watch: {

    'headerStyles.position' (val) {
      this.headerStyles['z-index'] = 9;
      this.headerStyles.left = '0';
      this.headerStyles.right = '0';
      this.headerStyles.width = '100%';
      if (val === 'sticky') {
        this.headerStyles.top = '-30px';
      } else {
        this.headerStyles.top = 0;
      }
    },
    
    footerStyles: {
      deep: true,
      handler () {
        this.compileFooterPreview();
        this.dirty = true;
      }
    },

    headerStyles: {
      deep: true,
      handler () {
        this.compileHeaderPreview();
        this.dirty = true;
      }
    },

    headerHeight: {
      deep: true,
      handler () {
        this.headerStyles['height'] = `${this.headerHeight.value}${this.headerHeight.unit}`;
      }
    },
    
    activeTab (val) {
      if (this.tabSubtabDefaults[val]) {
        this.activeSubtab = this.tabSubtabDefaults[val];
      }
    },
    
    'addingHeaderLink.page' (val) {
      this.addingHeaderLink.title = this.computedPage(val).title;
      this.addingHeaderLink.to = this.toFromPage(val);
    },
    'addingHeaderLink.external' (val) {
      if (val) {
        this.addingHeaderLink.page = '';
      } else {
        this.addingHeaderLink.href = '';
      }
    },
    
    'addingFooterLink.page' (val) {
      this.addingFooterLink.title = this.computedPage(val).title;
      this.addingFooterLink.to = this.toFromPage(val);
    },
    'addingFooterLink.external' (val) {
      if (val) {
        this.addingFooterLink.page = '';
      } else {
        this.addingFooterLink.href = '';
      }
    },
    
    async headerType (val) {
      const config = {...this.$store.state.repo.config.layout, headerType: val};
      await this.$store.dispatch('updateConfigs', { layout: config });
    },
    async logo (val) {
      const config = {...this.$store.state.repo.config.layout, logo: val};
      await this.$store.dispatch('updateConfigs', { layout: config });
    },
    async headerStyle (val) {
      const config = {...this.$store.state.repo.config.layout, headerStyle: val};
      await this.$store.dispatch('updateConfigs', { layout: config });
    },
  },
  
  computed: {
    addingHeaderLinkValid () {
      return this.addingHeaderLink.title && (
        this.addingHeaderLink.to || this.addingHeaderLink.href
      ) ? true : false;
    },

    addingFooterLinkValid () {
      return this.addingFooterLink.title && (
        this.addingFooterLink.to || this.addingFooterLink.href
      ) ? true : false;
    },
    
    editingSocial () {
      return this.social.findIndex(o => `social-${o.platform}` === this.activeSubtab);
    },
    
    header: {
      get () {
        return [...this.$store.state.repo.config.layout.header];
      },
      async set (val) {
        this.sending = true;
        const config = {...this.$store.state.repo.config.layout, header: val };
        await this.$store.dispatch('writeLayoutConfig', { config, immediately: true });
        this.sending = false;
      },
    },
    
    footer: {
      get () {
        return [...this.$store.state.repo.config.layout.footer];
      },
      async set (val) {
        this.sending = true;
        const config = {...this.$store.state.repo.config.layout, footer: val };
        await this.$store.dispatch('writeLayoutConfig', { config, immediately: true });
        this.sending = false;
      },
    },
  },
  
  data () {
    return {

      dirty: false,
      
      donateHref: this.$store.state.repo.config.layout.donate.href,
      donateTitle: this.$store.state.repo.config.layout.donate.title || 'Donate',
      
      addingHeaderLink: {
        href: '',
        to: '',
        title: '',
        
        external: false,
        page: '',
      },
      
      addingFooterLink: {
        href: '',
        to: '',
        title: '',
        
        external: false,
        page: '',
      },
      
      sending: false,

      tabSubtabDefaults: {
        'header': 'header-nav',
        'footer': 'footer-nav',
      },
      
      tabs: [
        { id: 'header', label: "Header", icon: 'layout-top-line' },
        { id: 'footer', label: "Footer", icon: 'layout-bottom-line' },        
        { id: 'social', label: "Social Media", icon: 'link' },
      ],
      
      subtabs: {
        header: [
          { id: 'header-logo', label: "Logo", icon: 'image-line' },
          { id: 'header-design', label: "Design", icon: 'palette-line' },
          { id: 'header-donate', label: "Donate Button", icon: 'money-dollar-circle-line' },
          { id: 'header-nav', label: "Navigation", icon: 'node-tree' },
        ],
        footer: [
          { id: 'footer-disclaimer', label: "Legal Compliance", icon: 'government-line' },
          { id: 'footer-colors', label: "Design", icon: 'palette-line' },
          { id: 'footer-donate', label: "Donate Button", icon: 'money-dollar-circle-line' },
          { id: 'footer-nav', label: "Navigation", icon: 'node-tree' },
        ],
        social: ['facebook', 'twitter', 'instagram', 'linkedin', 'pinterest', 'youtube'].map(o => ({
          id: `social-${o}`,
          label: o[0].toUpperCase() + o.slice(1),
          icon: o === 'facebook' ? 'facebook-circle-line' : `${o}-line`,
        })),
      },
      activeTab: 'header',
      activeSubtab: '',
      
      logo: this.$store.state.repo.config.layout.logo,

      footerStyles: { ...this.$store.getters.footerStyles },
      headerStyles: { ...this.$store.getters.headerStyles },

      headerHeight: parseCssUnitValue(this.$store.getters.headerStyles.height),
      
      headerStyle: this.$store.state.repo.config.layout.headerStyle,
      options: {
        header: {
          position: {
            'sticky': 'Floating',
            'absolute': 'Overlaid',
            'fixed': 'Fixed',
            'unset': 'Before content',
          },
        },
        headerStyle: {
          'Always visible': {
            'position': 'sticky',
            'background': 'white',
            'top': '0',
            'left': '0',
            'right': '0',
            'z-index': 999,
          },
          'Overlaid': {
            'position': 'absolute',
            'top': 0,
            'left': 0,
            'right': 0,
            'z-index': 999,
          },
          'Before content': {
            'position': 'unset',
            'top': 0,
            'left': 0,
            'right': 0,
            'z-index': 999,
          },
          'Floating': {
            'position': 'sticky',
            'background': 'rgba(255,255,255,0.6)',
            'top': '-30px',
            'left': 0,
            'right': 0,
            'z-index': 999,
          },
        },
        headerType: ['right-aligned-header', 'two-row-header', 'center-anchored-header'],
      },
      headerType: this.$store.state.repo.config.layout.headerType,
      social: this.$store.state.repo.config.layout.social,
      disclaimer: this.$store.state.repo.config.layout.disclaimer,

      addingSocial: { href: '', },
    };
  }
}
</script>

<style scoped>
.form-card label {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.md-horizontal-radio-field > label {
  margin-bottom: -1rem;
}
.md-horizontal-radio-field .md-field {
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
}
.md-horizontal-radio-field .md-field >>> .md-radio label.md-radio-label {
  padding-left: 3px;
}

</style>
