<template>
  <dialog-layout>
    <dialog-close-button @close="$emit('close')" />

    <form-card @submit="save" :sending="sending">
      <md-field v-show="false">
        <label>Name your feed</label>
        <md-input v-model="uuid" />
      </md-field>
      <md-field>
        <label>Content Type</label>
        <md-select v-model="config.dir">
          <md-option v-for="type in $store.state.repo.contentTypes" :key="type" :value="type">
            {{ type }}
          </md-option>
        </md-select>
      </md-field>
      <md-field>
        <label>Limit</label>
        <md-input v-model="config.limit" type="number" />
      </md-field>
    </form-card>
  </dialog-layout>
</template>

<script>
import v4 from 'uuid/v4';

export default {
  name: 'feed-builder',

  props: ['initial', 'page'],
  
  methods: {
    async save () {
      this.sending = true;
      let feeds = {...this.$store.state.repo.config.feeds};
      feeds[this.uuid] = {
        ...this.config,
        page: this.page,
      };
      await this.$store.dispatch('updateConfigs', { feeds });
      this.sending = false;
      this.$emit('close', this.$data);
    },
  },
  
  data () {
    return {
      sending: false,
      uuid: this.initial?.uuid || v4(),
      config: this.initial?.config || {
        dir: '',
        limit: null,
        sortBy: null,
        where: {},
      },
    };
  },
};
</script>
