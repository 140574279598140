<template>
<div class="card">
  <div class="card-header">
    <div class="card-title">
      <span v-html="title"></span>
      <ri :n="icon"></ri>
    </div>
    <div v-if="subtitle" class="card-subhead" v-html="subtitle"></div>
  </div>
  <div class="card-body">
    <slot></slot>
  </div>
  <div class="card-actions">
    <slot name="actions">
      <button class="btn" v-if="step > 0" @click.prevent="$emit('step', step - 1)">Back</button>
      <slot name="extra-actions">
      </slot>
      <button class="btn btn-primary" @click.prevent="$emit('step', step + 1)">Next</button>
    </slot>
  </div>
</div>
</template>

<script>
export default {
  name: 'wizard-card',
  props: ['title', 'subtitle', 'step', 'icon'],
}
</script>

<style>
.card {
  width: 460px;
  min-width: 460px !important;  
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  text-align: center;
  align-self: flex-start;
}
.card-title {
  font-size: 1.5rem;
  line-height: 1.1;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
}
.card-title span {
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.card-subhead {
  color: #777;
  font-size: .95rem;
}
.card-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem .5rem .5rem .5rem;
  margin-top: -2rem;
  margin-bottom: -1rem;  
}
.card-actions .btn {
  margin: 1rem 0;
}
.card-actions .btn-primary {
  margin-right: 0;
  margin-left: auto;
}
.card-actions .btn-primary:first-child:last-child {
  width: 100%;
}
</style>
