<template>
<transition name="bounce">
  <md-card class="md-card-transparent-unless-hovered" v-if="isMounted">
    
    <md-toolbar>
      <app-tabs :tabs="tabs"
                @tab-changed="activeTab = $event"
                @tab-mouseover="mouseover"
                @tab-mouseout="mouseout" />
    </md-toolbar>
    
    <md-card-content v-if="typeof activeContentCell === 'number'">
      <div>

        <md-field class="md-has-value md-borderless">
          <label>Content alignment</label>
          <md-radio v-model="contentCells[activeContentCell].contentAlignment" value="auto">
            Auto
          </md-radio>
          <md-radio v-model="contentCells[activeContentCell].contentAlignment" value="centered">
            Centered
          </md-radio>
          <!--<md-radio v-model="contentCells[activeContentCell].contentAlignment" value="top">
            Top
          </md-radio>-->
          <md-radio v-model="contentCells[activeContentCell].contentAlignment" value="bottom">
            Bottom
          </md-radio>
          <md-radio v-model="contentCells[activeContentCell].contentAlignment" value="top-and-bottom">
            Apart
          </md-radio>
        </md-field>

        <css-slider @input="pushHistory" label="Rounding" :slider-props="{ min: 0 }"
                    :no-space-for-auto-checkbox="true"
                    v-model="contentCells[activeContentCell].borderRadius" />

        <md-field class="md-has-value md-borderless">
          <label>Shadow</label>
          <md-select v-model="contentCells[activeContentCell].boxShadow"
                     @md-selected="pushHistory">
            <md-option v-for="{ value, label } in elevations" :key="value" :value="value">{{ label }}</md-option>
          </md-select>
        </md-field>

        <div style="display:flex">
          <md-field class="md-has-value md-borderless">
            <label style="margin-right:.5em">Background</label>
            <color-picker  :key="`contentCells-${activeContentCell}-background-color`"
                           size="25" expand-to="top-right" v-model="contentCells[activeContentCell].backgroundColor"
                           :clearable="true"
                           fallback="#ffffff" />
          </md-field>
          <md-field class="md-has-value md-borderless">
            <label style="margin-right:.5em;text-align:right">Text color</label>
            <color-picker :key="`contentCells-${activeContentCell}-text-color`"
                          size="25" expand-to="top-right" v-model="contentCells[activeContentCell].color"
                          :clearable="true"
                          fallback="#000000" />
          </md-field>
        </div>

      </div>
      <div>
        <css-slider @input="pushHistory" label="Space above"
                    :key="`contentCells-${activeContentCell}-marginTop`"
                    v-model="contentCells[activeContentCell].marginTop" />
        <css-slider @input="pushHistory" label="Space below"
                    :key="`contentCells-${activeContentCell}-marginBottom`"
                    v-model="contentCells[activeContentCell].marginBottom" />
        <css-slider @input="pushHistory" label="Space left"
                    :key="`contentCells-${activeContentCell}-marginLeft`"
                    v-model="contentCells[activeContentCell].marginLeft"
                    :allow-auto="true"/>
        <css-slider @input="pushHistory" label="Space right"
                    :key="`contentCells-${activeContentCell}-marginRight`"
                    v-model="contentCells[activeContentCell].marginRight"
                    :allow-auto="true" />
      </div>
      
      <div>
        <css-slider @input="pushHistory" label="Padding above"
                    :key="`contentCells-${activeContentCell}-paddingTop`"
                    v-model="contentCells[activeContentCell].paddingTop"
                    :slider-props="{ min: 0 }"
                    :no-space-for-auto-checkbox="true" />
        <css-slider @input="pushHistory" label="Padding below"
                    :key="`contentCells-${activeContentCell}-paddingBottom`"
                    v-model="contentCells[activeContentCell].paddingBottom"
                    :slider-props="{ min: 0 }"
                    :no-space-for-auto-checkbox="true" />
        <css-slider @input="pushHistory" label="Padding left"
                    :key="`contentCells-${activeContentCell}-paddingLeft`"
                    v-model="contentCells[activeContentCell].paddingLeft"
                    :slider-props="{ min: 0 }"
                    :no-space-for-auto-checkbox="true" />
        <css-slider @input="pushHistory" label="Padding right"
                    :key="`contentCells-${activeContentCell}-paddingRight`"
                    v-model="contentCells[activeContentCell].paddingRight"
                    :slider-props="{ min: 0 }"
                    :no-space-for-auto-checkbox="true" />
      </div>
      
      <div>
        <css-slider @input="pushHistory" label="Size"
                    :slider-props="{ min: 0 }"
                    :no-space-for-auto-checkbox="true"
                    v-model="contentCells[activeContentCell].flexBasis" />
        
        <md-field class="md-has-value md-borderless">
          <label>Expand to fill available space?</label>
          <md-radio v-model="contentCells[activeContentCell].flexGrow" value="0">
            No
          </md-radio>
          <md-radio v-model="contentCells[activeContentCell].flexGrow" value="1">
            Yes
          </md-radio>
        </md-field>

        
        <md-field v-if="activeContentCell === 0"
                  class="md-has-value md-borderless">
          <md-switch @change="pushHistory" :value="true" v-model="contentCellsLocked">
            {{ contentCellsLocked ? 'Adjust all cells together' : 'Adjust each cell separately' }}
          </md-switch>
        </md-field>

        <md-field :key="`contentCells-${activeContentCell}-overflow`"
                  v-if="$store.getters.userIsPCCCStaff" class="md-has-value md-borderless">
          <label>Overflow behavior</label>
          <md-radio @change="pushHistory" v-model="contentCells[activeContentCell].overflow" value="hidden">
            Hidden
          </md-radio>
          <md-radio @change="pushHistory" v-model="contentCells[activeContentCell].overflow" value="visible">
            Visible
          </md-radio>
        </md-field>
                
      </div>
      
    </md-card-content>
    <md-card-content v-if="activeTab === 'media'">

      <div>
        <css-slider @input="pushHistory"
                    :no-space-for-auto-checkbox="true" 
                    label="Frame size" v-model="mediaWidth" :invert="imageOnRight" />

        <md-field class="md-has-value"
                  v-if="hasMediaSideContent">
          <label>Picture behavior</label>
          <md-select @md-selected="pushHistory" v-model="imagePinned">
            <md-option value="cover">Stretch</md-option>
            <md-option value="contain">Pin</md-option>
            <md-option value="none">Use natural dimensions</md-option>
          </md-select>
        </md-field>

        <css-slider @input="pushHistory"
                    v-if="hasMediaSideContent"
                    label="Position left/right"
                    v-model="imageObjectPositionX"
                    :no-space-for-auto-checkbox="true" />

        <css-slider @input="pushHistory"
                    v-if="hasMediaSideContent"
                    label="Position up/down"
                    v-model="imageObjectPositionY"
                    :no-space-for-auto-checkbox="true" />
        
      </div>
      
      <div>
        <md-field class="md-has-value md-borderless">
          <label>Shadow</label>
          <md-select @md-selected="pushHistory" v-model="mediaBoxShadow">
            <md-option v-for="{ value, label } in elevations" :key="value" :value="value">{{ label }}</md-option>
          </md-select>
        </md-field>
        <md-field class="md-has-value md-borderless">
          <label>Background</label>
          <color-picker @input="pushHistory" size="25"
                        key="mediaBackgroundColor"
                        :clearable="true"
                        expand-to="top-right"
                        v-model="mediaBackgroundColor" fallback="#ffffff" />
        </md-field>
        <md-field class="md-has-value md-borderless">
          <label>Image depth</label>
          <md-switch @change="pushHistory" class="md-switch-left-right" v-model="mediaZIndex" :value="4">
            {{ mediaZIndex ? 'front' : 'back' }}
          </md-switch>
        </md-field>
      </div>
      
      <div>
        <css-slider key="mediaMarginTop" @input="pushHistory" label="Space above"
                    v-model="mediaMarginTop" />
        <css-slider key="mediaMarginBottom" @input="pushHistory" label="Space below"
                    v-model="mediaMarginBottom" />
        <css-slider key="mediaMarginLeft" @input="pushHistory" label="Space left"
                    v-model="mediaMarginLeft" :allow-auto="true" />
        <css-slider key="mediaMarginRight" @input="pushHistory" label="Space right"
                    v-model="mediaMarginRight" :allow-auto="true" :invert="true" />
      </div>
    </md-card-content>

    <md-card-content v-if="activeTab === 'content'">
      <div>
        <css-slider @input="pushHistory" label="Padding"
                    :slider-props="{ min: 0 }"
                    v-model="contentPaddingX" />
        <css-slider @input="pushHistory" label="Height"
                    :slider-props="{ min: 0 }"
                    v-model="contentPaddingY" />
        <md-field class="md-has-value md-borderless">
          <label>Background</label>
          <color-picker :clearable="true" key="contentBackgroundColor"
                        size="25" expand-to="top-right" v-model="contentBackgroundColor" fallback="#ffffff" />
          
        </md-field>
        <md-field class="md-has-value md-borderless">
          <label>Text color</label>
          <color-picker :clearable="true" key="contentColor"
                        size="25" expand-to="top-right" v-model="contentColor" fallback="#000000" />
        </md-field>
      </div>

      <div>
        <md-field class="md-has-value md-borderless">
          <label>Shadow</label>
          <md-select v-model="contentBoxShadow">
            <md-option v-for="{ value, label } in elevations" :key="value" :value="value">{{ label }}</md-option>
          </md-select>
        </md-field>        
      </div>

      <div>
        <css-slider key="contentMarginTop" @input="pushHistory" label="Space above"
                    v-model="contentMarginTop" />
        <css-slider key="contentMarginBottom" @input="pushHistory" label="Space below"
                    v-model="contentMarginBottom" />
        <css-slider key="contentMarginLeft" @input="pushHistory" label="Space left"
                    v-model="contentMarginLeft" :allow-auto="true" />
        <css-slider key="contentMarginRight" @input="pushHistory" label="Space right"
                    v-model="contentMarginRight" :allow-auto="true" :invert="true" />
      </div>
    </md-card-content>

    <md-card-content v-if="activeTab === 'background'">
      <div>
        <css-slider @input="pushHistory" label="Position left/right"
                    v-model="backgroundImagePositionX"
                    :no-space-for-auto-checkbox="true" />
        <css-slider @input="pushHistory" label="Position up/down"
                    v-model="backgroundImagePositionY"
                    :no-space-for-auto-checkbox="true" />
      </div>
      <div>
        <md-field class="md-has-value md-borderless">
          <label>Scroll?</label>
          <md-radio class="wide-label" @change="pushHistory" v-model="backgroundImageAttachment" value="unset">
            With page
          </md-radio>
          <md-radio @change="pushHistory" v-model="backgroundImageAttachment" value="fixed">
            Independent
          </md-radio>
        </md-field>
        <md-field class="md-has-value md-borderless">
          <label>Size</label>
          <md-radio class="wide-label" @change="pushHistory" v-model="backgroundImageSize" value="cover">
            Cover
          </md-radio>
          <md-radio @change="pushHistory" v-model="backgroundImageSize" value="contain">
            Contain
          </md-radio>
        </md-field>
        <md-field class="md-has-value md-borderless">
          <label>Repeat?</label>
          <md-radio class="wide-label" @change="pushHistory" v-model="backgroundImageRepeat" value="no-repeat">
            No
          </md-radio>
          <md-radio @change="pushHistory" v-model="backgroundImageRepeat" value="repeat">
            Yes
          </md-radio>
        </md-field>
      </div>
    </md-card-content>
    
    <md-card-content v-if="activeTab === 'block'">      
      <div>
        <md-field class="md-has-value">
          <label>Block width</label>
          <md-select @md-selected="pushHistory" v-model="cappedWidth.amount">
            <md-option value="">Fullscreen</md-option>
            <md-option value="1600">Wide</md-option>
            <md-option value="1400">Medium</md-option>
            <md-option value="1200">Narrow</md-option>            
          </md-select>
        </md-field>
        
        <md-field class="md-has-value md-borderless">
          <label>Picture?</label>
          <md-radio @change="pushHistory" v-model="mediaSideOrNone" value="left">Left</md-radio>
          <md-radio @change="pushHistory" v-model="mediaSideOrNone" value="right">Right</md-radio>
          <md-radio @change="pushHistory" v-model="mediaSideOrNone" :value="null">None</md-radio>
        </md-field>
        
        <md-field class="md-has-value md-borderless">
          <label>Background</label>
          <color-picker @input="pushHistory" size="25" :clearable="true"
                        expand-to="top-right" v-model="backgroundColor" fallback="#ffffff" />
        </md-field>
      </div>
      <div>
        <md-field class="md-has-value md-borderless">
          <label>Border color</label>
          <color-picker @input="pushHistory" size="25" :clearable="true"
                        expand-to="top-right" v-model="borderColor" fallback="#ffffff" />
        </md-field>
        
        <css-slider @input="pushHistory" label="Border size" v-model="borderWidth"
                    label-size="6"
                    :slider-props="{ min: 0 }"
                    :no-space-for-auto-checkbox="true" />
        
        <css-slider @input="pushHistory" label="Rounding" v-model="borderRadius"
                    label-size="6"
                    :slider-props="{ min: 0 }"
                    :no-space-for-auto-checkbox="true" />
        
        <md-field class="md-has-value md-borderless">
          <label>Shadow</label>
          <md-select @md-selected="pushHistory" v-model="boxShadow">
            <md-option v-for="{ value, label } in elevations" :key="value" :value="value">{{ label }}</md-option>
          </md-select>
        </md-field>
      </div>
      
      <div>
        <css-slider @input="pushHistory" label="Space above" v-model="marginTop" />
        <css-slider @input="pushHistory" label="Space below" v-model="marginBottom" />
        <css-slider @input="pushHistory" label="Space left" v-model="marginLeft" :allow-auto="true" />
        <css-slider @input="pushHistory" label="Space right" v-model="marginRight" :allow-auto="true"
                    :invert="true" />
      </div>     
      
      <div>
        <css-slider @input="pushHistory" label="Height" v-model="contentPaddingY"
                    :slider-props="{ min: 0 }"
                    :no-space-for-auto-checkbox="true" />
        <css-slider v-if="hasMediaSide" @input="pushHistory"
                    label="Picture frame size" v-model="mediaWidth" :invert="imageOnRight"
                    :no-space-for-auto-checkbox="true" />
        <css-slider @input="pushHistory" label="Padding" v-model="paddingX"
                    :slider-props="{ min: 0 }"
                    :no-space-for-auto-checkbox="true" />
        <md-field key="media-unit-overflow" v-if="$store.getters.userIsPCCCStaff" class="md-has-value md-borderless">
          <label>Overflow behavior</label>
          <md-radio @change="pushHistory" v-model="overflow" value="hidden">
            Hidden
          </md-radio>
          <md-radio @change="pushHistory" v-model="overflow" value="visible">
            Visible
          </md-radio>
        </md-field>
      </div>
    </md-card-content>
    
    <md-card-actions style="justify-content: center;">
      <md-button style="color: green; font-size: 2rem;" @click="save">
          <ri n="checkbox-circle-line" />
      </md-button>
      <md-button style="color: darkred; font-size: 2rem;" @click="discard">
        <ri n="close-circle-line" />
      </md-button>
      <md-button style="font-size: 2rem;" @click="undo" v-if="history.length">
        <ri n="arrow-go-back-line" />
      </md-button>      
    </md-card-actions>
  </md-card>
</transition>
</template>

<script>
import { debounce, cloneDeep } from 'lodash';
import $ from "cash-dom";

import ColorPicker from './ColorPicker.vue'
import CssSlider from './inputs/CssSlider.vue';
import { parseCssUnitValue } from '../css-utils.js';

export default {
  name: 'media-unit-live-preview-editor',
  props: ['uuid'],

  components: {
    ColorPicker,
    CssSlider,
  },

  methods: {

    mouseout () {
      $('.live-preview-editor-overlay').remove();
    },
    
    mouseover ({ id }) {
      let els = [];
      if (id === 'block') {
        els = [this.el];
      } else if (id === 'media') {
        els = [this.mediaEl];
      } else if (id === 'content') {
        els = [this.contentEl];
      } else if (id === 'content-wrappers') {
        els = this.contentCellEls;
      } else {
        const index = parseInt(id.replace('content-wrapper-', ''));
        els = [ this.contentCellEls[index] ];
      }

      $('.live-preview-editor-overlay').remove();
      els.forEach(el => {
        const component = $(el),
              offset = component.offset(),
              height = component.outerHeight(),
              width = component.outerWidth(),
              innerHeight = component.height(),
              innerWidth = component.width(),
              innerOffset = {
                top: (height - innerHeight) / 2,
                left: (width - innerWidth) / 2,
              };
        
        const overlay = $("<div class='live-preview-editor-overlay'>")
          .css('background', 'purple')
          .css('opacity', .2)
          .css('position', 'absolute')
          .css('top', `${offset.top}px`)
          .css('left', `${offset.left}px`)
          .css('width', `${width}px`)
          .css('height', `${height}px`)
          .appendTo('body');
        $("<div>")
          .css('background', 'green')
          .css('opacity', 1)
          .css('position', 'relative')
          .css('top', `${innerOffset.top}px`)
          .css('left', `${innerOffset.left}px`)
          .css('width', `${innerWidth}px`)
          .css('height', `${innerHeight}px`)
          .appendTo(overlay);
        
      });
    },
    
    undo () {
      const entry = this.history.pop();
      Object.keys(entry).forEach(key => {
        this[key] = entry[key];
      });
    },

    immediatelyPushHistory () {
      const doNotMirror = 'history,activeTab,isMounted,contentCellEls,elevations'.split(',');
      let entry = {};
      Object.keys(this.$data)
        .filter(key => doNotMirror.indexOf(key) === -1)
        .forEach(key => {
          const val = this[key];
          entry[key] = cloneDeep(val);
        });
      this.history.push(entry);
    },
    
    pushHistory: debounce(function() {
      this.immediatelyPushHistory();
    }, 300),
    
    save () {
      let contentWrapperStyles = [];
      if (this.contentCellEls) {
        this.contentCellEls.forEach(el => {
          contentWrapperStyles.push({
            'box-shadow': el.style.boxShadow,
            'flex-basis': el.style.flexBasis,
            'flex-grow': el.style.flexGrow,
            'flex-shrink': el.style.flexShrink,
            'overflow': el.style.overflow,
            'border-radius': el.style.borderRadius,
            'margin-left': el.style.marginLeft,
            'margin-right': el.style.marginRight,
            'margin-top': el.style.marginTop,
            'margin-bottom': el.style.marginBottom,            
            'padding-left': el.style.paddingLeft,
            'padding-right': el.style.paddingRight,
            'padding-top': el.style.paddingTop,
            'padding-bottom': el.style.paddingBottom,
            'background-color': el.style.backgroundColor,
            'color': el.style.color,
            'display': el.style.display,
            'flex-direction': el.style.flexDirection,
            'align-items': el.style.alignItems,
            'justify-content': el.style.justifyContent,
          });
        });
      } else {
        contentWrapperStyles = null;
      }
      
      this.$emit('save', { uuid: this.uuid,
                           style: this.emittable.style,
                           mediaStyle: this.emittable.mediaStyle,
                           contentStyle: this.emittable.contentStyle,
                           backgroundImageStyle: this.backgroundImageStyle,
                           contentWrapperStyles,
                           classList: [...this.el.classList] });
    },
    discard () {
      this.$emit('discard', { uuid: this.uuid });
    },
    
    sliderPropsForUnit (val) {
      if (val === 'px') {
        return {
          min: -600,
          max: 600,
          stepSize: 1,
        }
      }
      if (val === '%') {
        return {
          min: -100,
          max: 200,
          stepSize: .1,
        }
      }
      if (val === 'rem') {
        return {
          min: -20,
          max: 20,
          stepSize: .01,
        }
      }
      if (val === 'em') {
        return {
          min: -20,
          max: 20,
          stepSize: .01,
        }
      }
      if (val === 'vh') {
        return {
          min: 0,
          max: 100,
          stepSize: .01,
        }
      }
      if (val === 'vw') {
        return {
          min: 0,
          max: 100,
          stepSize: .01,
        }
      }
      return {
        min: -10,
        max: 10,
        stepSize: 1,
      }
    },
  },
  
  computed: {

    mediaSideOrNone: {
      get () {
        if (!this.hasMediaSide) {
          return null;
        } else if (this.imageOnRight) {
          return 'right';
        } else {
          return 'left';
        }
      },
      set (val) {
        if (val === null) {
          this.hasMediaSide = false;
        } else if (val === 'right') {
          this.hasMediaSide = true;
          this.imageOnRight = true;
        } else {
          this.hasMediaSide = true;
          this.imageOnRight = false;
        }
      },
    },
    
    marginLeftAuto: {
      get () {
        return this.marginLeft.amount === 'auto';
      },
      set (val) {
        if (val) {
          this.marginLeft = {amount: 'auto', unit: ''};
        } else {
          this.marginLeft = {amount: 0, unit: 'rem'};
        }
      },
    },

    marginRightAuto: {
      get () {
        return this.marginRight.amount === 'auto';
      },
      set (val) {
        if (val) {
          this.marginRight = {amount: 'auto', unit: ''};
        } else {
          this.marginRight = {amount: 0, unit: 'rem'};
        }
      },
    },
    
    activeContentCellStyle () {
      if (this.activeContentCell !== null) {
        return this.contentCells[this.activeContentCell];
      } else {
        return {};
      }
    },
    
    activeContentCell () {
      if (this.activeTab === 'content-wrappers') {
        return 0;
      }
      
      if (!this.activeTab || !this.activeTab.startsWith('content-wrapper-')) {
        return null;
      }
      return parseInt(this.activeTab.replace('content-wrapper-', ''));
    },
    
    tabs () {
      let tabs = [
        { id: 'block', icon: 'shopping-bag-line', label: 'Block' },
      ];

      if (this.backgroundImageEl) {
        tabs.push(
          { id: 'background', icon: 'settings-5-line', label: 'Background' }
        );
      }
      
      if (this.hasMediaSide) {
        tabs.push(
          { id: 'media', icon: 'settings-5-line', label: 'Image' }
        );
      }

      tabs.push(
          { id: 'content', icon: 'eye-line', label: 'Content' },
      );

      if (!this.contentCells.length) {
        return tabs;
      }
      
      if (this.contentCellsLocked) {
        tabs.push({
          id: 'content-wrappers', icon: 'eye-line', label: 'Content Cells',
        });
      } else {
        this.contentCells.forEach((o, i) => {
          tabs.push(
            { id: `content-wrapper-${i}`, icon: 'eye-line', label: `Content Cell ${i}` },
          );
        });
      }
      return tabs;
    },
    
    emittable () {
      let composed = {};
      let obj, source;

      obj = {};
      source = this.style;
      Object.keys(source).forEach(key => {
        if (source[key]) {
          obj[key] = source[key];
        }
      });
      composed.style = source;
      
      obj = {};
      source = this.mediaStyle;
      Object.keys(source).forEach(key => {
        if (source[key]) {
          obj[key] = source[key];
        }
      });
      composed.mediaStyle = source;

      obj = {};
      source = this.contentStyle;
      Object.keys(source).forEach(key => {
        if (source[key]) {
          obj[key] = source[key];
        }
      });
      composed.contentStyle = source;

      return composed;
    },
    
    mediaStyle () {
      return {
        'display': this.mediaEl.style.display,
        'padding-left': this.mediaEl.style.paddingLeft,
        'padding-top': this.mediaEl.style.paddingTop,
        'padding-right': this.mediaEl.style.paddingRight,
        'padding-bottom': this.mediaEl.style.paddingBottom,
        'margin-left': this.mediaEl.style.marginLeft,
        'margin-top': this.mediaEl.style.marginTop,
        'margin-right': this.mediaEl.style.marginRight,
        'margin-bottom': this.mediaEl.style.marginBottom,
        'box-shadow': this.mediaEl.style.boxShadow,
        'z-index': this.mediaEl.style.zIndex,
        'overflow': this.mediaEl.style.overflow,
        'background-color': this.mediaEl.style.backgroundColor,
      };
    },
    backgroundImageStyle () {
      if (this.backgroundImageEl) {
        return {
          position: `${this.backgroundImagePositionX.amount}${this.backgroundImagePositionX.unit} ${this.backgroundImagePositionY.amount}${this.backgroundImagePositionY.unit}`,
          attachment: this.backgroundImageAttachment,
          size: this.backgroundImageSize,
          repeat: this.backgroundImageRepeat,
        };
      } else {
        return null;
      }
    },
    contentStyle () {
      return {
        'padding-left': this.contentEl.style.paddingLeft,
        'padding-top': this.contentEl.style.paddingTop,
        'padding-right': this.contentEl.style.paddingRight,
        'padding-bottom': this.contentEl.style.paddingBottom,
        'margin-left': this.contentEl.style.marginLeft,
        'margin-top': this.contentEl.style.marginTop,
        'margin-right': this.contentEl.style.marginRight,
        'margin-bottom': this.contentEl.style.marginBottom,
        'box-shadow': this.contentEl.style.boxShadow,
        'overflow': this.contentEl.style.overflow,
        'background-color': this.contentEl.style.backgroundColor,
        'color': this.contentEl.style.color,
      };
    },
    style () {
      return {
        'margin-top': this.el.style.marginTop,
        'margin-bottom': this.el.style.marginBottom,
        '--media-unit--media__width': this.el.style.getPropertyValue('--media-unit--media__width'),
        '--image-object-position': this.el.style.getPropertyValue('--image-object-position'),
        '--image-object-fit': this.el.style.getPropertyValue('--image-object-fit'),
        'max-width': this.el.style.maxWidth,
        'flex-direction': this.el.style.flexDirection,
        'height': this.el.style.height,
        'padding-left': this.el.style.paddingLeft,
        'padding-top': this.el.style.paddingTop,
        'padding-right': this.el.style.paddingRight,
        'padding-bottom': this.el.style.paddingBottom,
        'box-shadow': this.el.style.boxShadow,
        'overflow': this.el.style.overflow,
        'background-color': this.el.style.backgroundColor,
        'border-color': this.el.style.borderColor,
        'border-width': this.el.style.borderWidth,
        'border-radius': this.el.style.borderRadius,
        'border-style': this.el.style.borderStyle,
      };
    },
    
    el () {
      return document.getElementById(this.uuid);
    },
    mediaEl () {
      return this.el.querySelector('.media-side');
    },
    backgroundImageEl () {
      return [...this.el.querySelectorAll('.media-unit--overlay')].filter(o => {
        return window.getComputedStyle(o)['background-image'].startsWith('url');
      })[0];
    },
    contentEl () {
      return [...this.el.childNodes]
        .filter(o => o.tagName && o.tagName.toUpperCase() === 'DIV')
        .find(o => !o.classList.contains('media-side'));
    },
  },
  watch: {
    borderColor (val) {
      this.el.style.setProperty('border-color', val);
    },
    borderStyle (val) {
      this.el.style.setProperty('border-style', val);
    },
    borderWidth: {
      deep: true,
      handler (val) {
        if (val.amount) {
          this.borderStyle = 'solid';
        } else {
          this.borderStyle = 'none';
        }
        this.el.style.setProperty('border-width', `${val.amount}${val.unit}`);
      },
    },
    borderRadius: {
      deep: true,
      handler (val) {
        if (val.amount) {
          this.borderStyle = 'solid';
        } else {
          this.borderStyle = 'none';
        }
        this.el.style.setProperty('border-radius', `${val.amount}${val.unit}`);
      },
    },    
    
    backgroundColor (val) {
      this.el.style.setProperty('background-color', val);
    },
    mediaBackgroundColor (val) {
      this.mediaEl.style.setProperty('background-color', val);
    },
    contentBackgroundColor (val) {
      this.contentEl.style.setProperty('background-color', val);
    },
    contentColor (val) {
      this.contentEl.style.setProperty('color', val);
    },
    
    backgroundImageAttachment (val) {
      this.backgroundImageEl.style.setProperty('background-attachment', val);
    },

    backgroundImageSize (val) {
      this.backgroundImageEl.style.setProperty('background-size', val);
    },

    backgroundImageRepeat (val) {
      this.backgroundImageEl.style.setProperty('background-repeat', val);
    },

    backgroundImagePositionY: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.backgroundImageEl.style.setProperty('background-position-y', `${val.amount}${val.unit}`);
      },
    },
    backgroundImagePositionX: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.backgroundImageEl.style.setProperty('background-position-x', `${val.amount}${val.unit}`);
      },
    },
    marginTop: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.setProperty('margin-top', `${val.amount}${val.unit}`);
      },
    },
    marginBottom: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;        
        this.el.style.setProperty('margin-bottom', `${val.amount}${val.unit}`);
      },
    },
    marginLeft: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.setProperty('margin-left', `${val.amount}${val.unit}`);
      },
    },
    marginRight: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;        
        this.el.style.setProperty('margin-right', `${val.amount}${val.unit}`);
      },
    },
    mediaWidth: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.setProperty('--media-unit--media__width', `${val.amount}${val.unit || '%'}`);
      },
    },
    imageObjectPositionX: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.setProperty('--image-object-position', `${val.amount}${val.unit} ${this.imageObjectPositionY.amount}${this.imageObjectPositionY.unit}`);
      },
    },
    imageObjectPositionY: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.setProperty('--image-object-position', `${this.imageObjectPositionX.amount}${this.imageObjectPositionX.unit} ${val.amount}${val.unit}`);
      },
    },
    
    boxShadow (val) {
      if (!this.isMounted) return;
      this.el.style.boxShadow = val;
    },
    
    mediaBoxShadow (val) {
      if (!this.isMounted) return;
      this.mediaEl.style.boxShadow = val;
    },

    contentBoxShadow (val) {
      if (!this.isMounted) return;
      this.contentEl.style.boxShadow = val;
    },

    overflow (val) {
      this.el.style.overflow = val;
    },
    
    cappedWidth: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.maxWidth = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },

    paddingX: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.paddingLeft = this.el.style.paddingRight = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },
    paddingY: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.paddingTop = this.el.style.paddingBottom = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },
    contentPaddingY: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.contentEl.style.paddingTop = this.contentEl.style.paddingBottom = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },
    contentPaddingX: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.contentEl.style.paddingLeft = this.contentEl.style.paddingRight = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },
    contentMarginTop: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.contentEl.style.marginTop = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },
    contentMarginLeft: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.contentEl.style.marginLeft = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },
    contentMarginRight: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.contentEl.style.marginRight = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },
    contentMarginBottom: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.contentEl.style.marginBottom = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },

    mediaMarginTop: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.mediaEl.style.marginTop = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },

    mediaMarginLeft: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.mediaEl.style.marginLeft = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },
    mediaMarginRight: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.mediaEl.style.marginRight = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },
    mediaMarginBottom: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.mediaEl.style.marginBottom = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },

    mediaZIndex (val) {
      this.mediaEl.style.zIndex = val;
    },

    contentCells: {
      deep: true,
      handler () {
        if (!this.isMounted) return;
        for (let i = 0; i < this.contentCells.length; ++i) {
          let idx = i;
          if (this.contentCellsLocked) {
            idx = 0;
          }
          this.contentCellEls[i].style.boxShadow = this.contentCells[idx].boxShadow;
          this.contentCellEls[i].style.overflow = this.contentCells[idx].overflow;

          this.contentCellEls[i].style.paddingTop = `${this.contentCells[idx].paddingTop.amount}${this.contentCells[idx].paddingTop.unit}`;
          this.contentCellEls[i].style.paddingBottom = `${this.contentCells[idx].paddingBottom.amount}${this.contentCells[idx].paddingBottom.unit}`;          
          this.contentCellEls[i].style.paddingLeft = `${this.contentCells[idx].paddingLeft.amount}${this.contentCells[idx].paddingLeft.unit}`;
          this.contentCellEls[i].style.paddingRight = `${this.contentCells[idx].paddingRight.amount}${this.contentCells[idx].paddingRight.unit}`;          
          this.contentCellEls[i].style.flexBasis = `${this.contentCells[idx].flexBasis.amount}${this.contentCells[idx].flexBasis.unit}`;
          this.contentCellEls[i].style.borderRadius = `${this.contentCells[idx].borderRadius.amount}${this.contentCells[idx].borderRadius.unit}`;
          this.contentCellEls[i].style.marginLeft = `${this.contentCells[idx].marginLeft.amount}${this.contentCells[idx].marginLeft.unit}`;
          this.contentCellEls[i].style.marginRight = `${this.contentCells[idx].marginRight.amount}${this.contentCells[idx].marginRight.unit}`;
          this.contentCellEls[i].style.marginTop = `${this.contentCells[idx].marginTop.amount}${this.contentCells[idx].marginTop.unit}`;
          this.contentCellEls[i].style.marginBottom = `${this.contentCells[idx].marginBottom.amount}${this.contentCells[idx].marginBottom.unit}`;
          this.contentCellEls[i].style.backgroundColor = this.contentCells[idx].backgroundColor;
          this.contentCellEls[i].style.color = this.contentCells[idx].color;
          this.contentCellEls[i].style.flexGrow = this.contentCells[idx].flexGrow;
          this.contentCellEls[i].style.flexShrink = this.contentCells[idx].flexShrink;

          const alignments = {
            'auto': { display: 'block' },
            'centered': { display: 'flex', 'flex-direction': 'column', 'align-items': 'center', 'justify-content': 'center' },
            'top': { display: 'flex', 'flex-direction': 'column', 'align-items': 'center', 'justify-content': 'flex-start' },
            'bottom': { display: 'flex', 'flex-direction': 'column', 'align-items': 'center', 'justify-content': 'flex-end' },
            'top-and-bottom': { display: 'flex', 'flex-direction': 'column', 'align-items': 'center', 'justify-content': 'space-between' },
          };
          const align = alignments[ this.contentCells[idx].contentAlignment ]  || {};
          Object.keys(align).forEach(key => {
            this.contentCellEls[i].style[key] = align[key];
          });
        }
      },
    },
    
    fixedHeight (val) {
      if (!this.isMounted) return;
      this.el.style.height = val ? `${val}px` : null;
    },
    
    hasMediaSide (val) {
      if (!this.isMounted) return;
      if (val) {
        this.mediaEl.style.display = null;
        this.hasMediaSideContent = this.hasMediaSide && this.el.querySelectorAll('.media-side > *').length;
      } else {
        this.mediaEl.style.display = 'none';
        this.hasMediaSideContent = false;
      }
    },
    
    imagePinned (val) {
      this.el.style.setProperty('--image-object-fit', val);
    },

    imageOnRight (val) {
      if (!this.isMounted) return;
      if (val) {
        this.el.style.flexDirection = 'row-reverse';
      } else {
        this.el.style.flexDirection = null;
      }
    }
  },
  mounted () {

    if (this.backgroundImageEl) {
      this.backgroundImagePositionX = parseCssUnitValue(
        window.getComputedStyle(this.backgroundImageEl)['background-position-x'] || '0px'
      );
      if (this.backgroundImagePositionX.amount === '0' && this.backgroundImagePositionX.unit === 'px') {
        this.backgroundImagePositionX.unit = '%';
      }
        
      this.backgroundImagePositionY = parseCssUnitValue(
        window.getComputedStyle(this.backgroundImageEl)['background-position-y'] || '0px'
      );
      if (this.backgroundImagePositionY.amount === '0' && this.backgroundImagePositionY.unit === 'px') {
        this.backgroundImagePositionY.unit = '%';
      }
      this.backgroundImageAttachment = window.getComputedStyle(this.backgroundImageEl)['background-attachment'];
      this.backgroundImageSize = window.getComputedStyle(this.backgroundImageEl)['background-size'];
      this.backgroundImageRepeat = window.getComputedStyle(this.backgroundImageEl)['background-repeat'];
    }
    
    this.hasMediaSide = this.el.querySelector('.media-side').style.display !== 'none';
    this.hasMediaSideContent = this.hasMediaSide && this.el.querySelectorAll('.media-side > *').length;

    this.boxShadow = this.el.style.boxShadow;
    this.mediaBoxShadow = this.mediaEl.style.boxShadow;
    this.contentBoxShadow = this.contentEl.style.boxShadow;    

    this.borderColor = this.el.style.borderColor;
    this.borderStyle = this.el.style.borderStyle;
    this.borderWidth = parseCssUnitValue(this.el.style.borderWidth || '0px');
    this.borderRadius = parseCssUnitValue(this.el.style.borderRadius || '0px');    
    this.backgroundColor = this.el.style.backgroundColor;
    this.mediaBackgroundColor = this.mediaEl.style.backgroundColor;
    this.contentBackgroundColor = this.contentEl.style.backgroundColor;
    this.contentColor = this.contentEl.style.color;    
    
    this.mediaZIndex = this.mediaEl.style.zIndex ? 4 : null;
    
    this.contentCellEls = [
      ...this.contentEl.childNodes
    ].filter(o => o.tagName && o.tagName.toUpperCase() === 'DIV' && o.classList.contains("media-unit--content-wrapper"));
    this.contentCells = this.contentCellEls.map(o => {

      let obj = {
        backgroundColor: o.style.backgroundColor || '',
        color: o.style.color || '',
        boxShadow: o.style.boxShadow || '',
        flexBasis: parseCssUnitValue(o.style.flexBasis || '100%'),
        flexGrow: o.style.flexGrow,
        flexShrink: o.style.flexShrink,

        contentAlignment: '',
        
        overflow: o.style.overflow || '',
        borderRadius: parseCssUnitValue(o.style.borderRadius || '0px'),
        marginLeft: parseCssUnitValue(o.style.marginLeft || '0rem'),
        marginRight: parseCssUnitValue(o.style.marginRight || '0rem'),
        marginTop: parseCssUnitValue(o.style.marginTop || '0rem'),
        marginBottom: parseCssUnitValue(o.style.marginBottom || '0rem'),
        paddingLeft: parseCssUnitValue(o.style.paddingLeft || '0%'),
        paddingRight: parseCssUnitValue(o.style.paddingRight || '0%'),
        paddingTop: parseCssUnitValue(o.style.paddingTop || '0%'),
        paddingBottom: parseCssUnitValue(o.style.paddingBottom || '0%'),

        locks: {
          paddingX: true,
          paddingY: true,
          marginX: true,
          marginY: true,
        },
      };

      if (o.style.display === 'flex' && o.style.flexDirection === 'column' && o.style.justifyContent === 'center' && o.style.alignItems === 'center') {
        obj.contentAlignment = 'centered';
      } else if (o.style.display === 'flex' && o.style.flexDirection === 'column' && o.style.justifyContent === 'flex-start' && o.style.alignItems === 'center') {
        obj.contentAlignment = 'top';
      } else if (o.style.display === 'flex' && o.style.flexDirection === 'column' && o.style.justifyContent === 'flex-end' && o.style.alignItems === 'center') {
        obj.contentAlignment = 'bottom';
      } else if (o.style.display === 'flex' && o.style.flexDirection === 'column' && o.style.justifyContent === 'space-between' && o.style.alignItems === 'center') {
        obj.contentAlignment = 'top-and-bottom';
      } else if (o.style.display === 'block') {
        obj.contentAlignment = 'auto';
      } 

      return obj;
    });

    let contentCellsLocked = true;
    if (this.contentCells.length > 1) {
      for (let i = 1; i < this.contentCells.length - 1; ++i) {
        if (JSON.stringify(this.contentCells[i]) !== JSON.stringify(this.contentCells[0])) {
          contentCellsLocked = false;
          break;
        }
      }
    }
    this.contentCellsLocked = contentCellsLocked;

    this.overflow = this.el.style.overflow || '';
    
    this.cappedWidth = this.el.style.maxWidth ? parseCssUnitValue(this.el.style.maxWidth) : {
      amount: "",
      unit: 'px',
    };

    this.fixedHeight = this.el.style.height ? parseCssUnitValue(this.el.style.height) : null;

    this.imagePinned = this.el.style.getPropertyValue('--image-object-fit');
    if (!this.imagePinned) {
      if (this.el.classList.contains('media-untethered')) {
        this.imagePinned = 'contain';
      }
    }
    
    this.marginTop = parseCssUnitValue(this.el.style.marginTop || '0px');
    this.marginBottom = parseCssUnitValue(this.el.style.marginBottom || '0px');
    this.marginLeft = parseCssUnitValue(this.el.style.marginLeft || 'auto');
    this.marginRight = parseCssUnitValue(this.el.style.marginRight || 'auto');

    let width = this.el.style.getPropertyValue('--media-unit--media__width');
    this.mediaWidth = width ? parseCssUnitValue(width) : {};

    this.imageOnRight = this.el.style.flexDirection === 'row-reverse';

    let position = this.el.style.getPropertyValue('--image-object-position') || '';
    position = position.split(' ');
    
    if (position[0] === 'left') {
      position[0] = '0%';
    } else if (position[0] === 'right') {
      position[0] = '100%';
    } else if (position[0] === 'center') {
      position[0] = '50%';
    }
    this.imageObjectPositionX = parseCssUnitValue(position[0] || '50%');

    this.paddingX = parseCssUnitValue(this.el.style.paddingLeft || '0rem');
    this.paddingY = parseCssUnitValue(this.el.style.paddingTop || '0rem');

    this.contentPaddingY = parseCssUnitValue(this.contentEl.style.paddingTop || '0rem');
    this.contentPaddingX = parseCssUnitValue(this.contentEl.style.paddingLeft || '0rem');    

    this.contentMarginLeft = parseCssUnitValue(this.contentEl.style.marginLeft || '0rem');
    this.contentMarginRight = parseCssUnitValue(this.contentEl.style.marginRight || '0rem');    
    this.contentMarginTop = parseCssUnitValue(this.contentEl.style.marginTop || '0rem');    
    this.contentMarginBottom = parseCssUnitValue(this.contentEl.style.marginBottom || '0rem');

    this.mediaMarginLeft = parseCssUnitValue(this.mediaEl.style.marginLeft || '0rem');
    this.mediaMarginRight = parseCssUnitValue(this.mediaEl.style.marginRight || '0rem');    
    this.mediaMarginTop = parseCssUnitValue(this.mediaEl.style.marginTop || '0rem');    
    this.mediaMarginBottom = parseCssUnitValue(this.mediaEl.style.marginBottom || '0rem');
    
    if (position[1] === 'top') {
      position[1] = '0%';
    } else if (position[1] === 'bottom') {
      position[1] = '100%';
    } else if (position[1] === 'center') {
      position[1] = '50%';
    }
    this.imageObjectPositionY = parseCssUnitValue(position[1] || '50%');

    this.$nextTick(() => {
      this.isMounted = true;
      this.immediatelyPushHistory();
    });
  },
  data () {
    return {

      history: [],

      activeTab: 'block',
      
      isMounted: false,

      backgroundImagePositionX: {},
      backgroundImagePositionY: {},
      backgroundImageAttachment: null,
      backgroundImageSize: null,
      backgroundImageRepeat: null,
      
      hasMediaSide: null,
      hasMediaSideContent: null,

      contentCellsLocked: false,
      
      contentCellEls: null,
      contentCells: null,

      elevations: [
        {label: "", value: ''},        
        {label: "None", value: 'none'},
        {label: "Bootstrap - Small", value: '0 .125rem .25rem rgba(0,0,0,.075)'},
        {label: "Bootstrap - Medium", value: '0 0.5rem 1rem rgb(0 0 0 / 15%)'},
        {label: "Bootstrap - Large", value: '0 1rem 3rem rgb(0 0 0 / 18%)'},
        {label: "Shallow", value: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'},
        {label: "Medium", value: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'},
        {label: "Raised", value: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)'},
        {label: "Extra raised", value: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)'},
        {label: 'From bottom right', value: 'black 5px 5px 5px 0px'},
      ],

      borderColor: "",
      borderStyle: "",
      borderWidth: {},
      borderRadius: {},      
      backgroundColor: "",
      mediaBackgroundColor: "",
      contentBackgroundColor: "",
      contentColor: "",
      
      boxShadow: "",
      mediaBoxShadow: "",
      contentBoxShadow: "",

      mediaZIndex: '',

      overflow: '',
      cappedWidth: "",
      fixedHeight: null,
      imagePinned: '',

      marginTop: {},
      marginBottom: {},
      marginLeft: {},
      marginRight: {},
      
      mediaWidth: {},

      imageOnRight: null,

      imageObjectPositionX: {},
      imageObjectPositionY: {},

      paddingX: {},
      paddingY: {},

      contentPaddingY: {},
      contentPaddingX: {},      
      
      contentMarginLeft: {},
      contentMarginRight: {},
      contentMarginTop: {},
      contentMarginBottom: {},

      mediaMarginLeft: {},
      mediaMarginRight: {},
      mediaMarginTop: {},
      mediaMarginBottom: {},
    };
  }
}
</script>

<style>
body .md-menu-content.md-select-menu {
  z-index: 999999;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(.9);
    background-color: rgba(255,255,255,1);
  }
  50% {
    transform: scale(1.1);
    background-color: rgba(255,255,255,.6);
  }
  100% {
    transform: scale(1);
    background-color: rgba(255,255,255,.1);    
  }
}
</style>
<style scoped>
.md-card .slider-horizontal {
  min-width: 100px;
  margin-top: 0;
}
.md-has-value {
  align-items: center;
}
.md-has-value label {
  position: unset;
  margin-right: 1em;
  text-align: left;
  line-height: 1.2;
  max-width: 6em;
  min-width: 6em;
}
.md-has-value .wide-label >>> label {
  min-width: 6em;
  max-width: 6em;
  text-align: left;
}
.md-field {
  margin-bottom: 4px;
  padding-top: 0;
}
.md-field:after {
  display: none;
}
.md-radio {
  align-items: center;
}
.md-radio >>> .md-radio-container {
  width: 15px;
  min-width: 15px;
  height: 15px;
}
.md-menu.md-select {
  border: 1px solid #eee;
  padding-left: 5px;
}
.md-card .md-radio >>> .md-radio-label,
.md-card .md-checkbox >>> .md-checkbox-label,
.md-card .md-switch >>> .md-switch-label {
  padding-left: 4px;
}
body .md-card.md-card-transparent-unless-hovered {
  background-color: rgba(255,255,255,0.1);
  transition: background-color .3s ease;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  z-index: 99999;
}
body .md-card.md-card-transparent-unless-hovered .md-toolbar {
  opacity: 0;
  min-height: 0;
  max-height: 0;
}
body .md-card.md-card-transparent-unless-hovered:hover {
  background-color: rgba(255,255,255,1);
}
body .md-card.md-card-transparent-unless-hovered:hover .md-toolbar {
  opacity: 1;
  min-height: 64px;
  max-height: 64px;
}
body .md-card.md-card-transparent-unless-hovered .md-card-actions {
  opacity: 0;
  max-height: 0;
  transition: all .3s ease;
}
body .md-card.md-card-transparent-unless-hovered:hover .md-card-actions {
  opacity: 1;
  max-height: 100px;
  transition: opacity 1.2s ease-in, max-height .3s ease-in;
}
body .md-card.md-card-transparent-unless-hovered .md-card-actions .md-button {
  padding-top: .5rem;
  height: auto;
}
body .md-card.md-card-transparent-unless-hovered .md-card-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  margin-left: -.5rem;
  margin-right: -.5rem;  
}
body .md-card.md-card-transparent-unless-hovered .md-card-content > * {
  flex: 1;
  max-width: 200px;
  margin-left: 1rem;
  margin-right: 1rem;  
}
body .md-card.md-card-transparent-unless-hovered .md-divider {
  align-self: center;
  transform: rotate(90deg);
  width: 850px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.md-borderless:after {
  display: none;
}
>>> .md-switch.md-switch-left-right .md-switch-container {
  background-color: #cfa9c8;
  --md-theme-default-accent-on-: #cfa9c8;
  --md-theme-default-switchvariant: #800080;
  --md-theme-default-accent: #800080;
}
>>> .md-switch.md-switch-left-right .md-ripple {
  display: none;
}
</style>
