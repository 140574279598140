import { camelCase, kebabCase } from 'lodash';

const kebabCaseWithVars = key => {
  if (key.indexOf('-') !== -1) {
    return key;
  }
  let kebabed = kebabCase(key);
  if (key.startsWith('--')) {
    kebabed = `--${kebabed}`;
  }
  return kebabed;
};

const writeStyleString = (styles, kebabize, filterEmpty, separator) => {
  return Object.keys(styles).filter(key => {
    if (!filterEmpty) {
      return true;
    }
    if (!(typeof styles[key] !== 'undefined' && styles[key])) {
      return false;
    }
    if (typeof styles[key].trim === 'function' && !styles[key].trim()) {
      return false;
    }
    return true;
  }).map(key => `${kebabize ? kebabCaseWithVars(key) : key}: ${styles[key]}`).join(separator || '; ');
};

const constructObject = arr => {
  return arr.reduce((acc, val) => {
    const [key, value] = val;
    acc[key] = value;
    return acc;
  }, {});
};

const splitOnce = function(s, on) {
  let first, rest;
  [first, ...rest] = s.split(on)
  return [first, rest.length > 0? rest.join(on) : null]
}

const parseStyles = (styleString, camelize) => {
  if (JSON.stringify(styleString) === '{}') {
    styleString = '';
  }
  return constructObject(
    (styleString || '')
    .split(/;/g)
    .filter(o => o)
    .map(o => o.trim())
    .map(o => splitOnce(o, ':'))
    .filter(o => o[0])
    .map(o => [camelize ? camelCase(o[0].trim()) : o[0].trim(), (o[1] || '').trim()])
  );
};

const units = [
  '', 'px', 'rem', 'em', '%', 'vh', 'vw', 'vmin', 'vmax',
];
const parseCssUnitValue = (value) => {
  const match = units.map(unit => (value || '').match(new RegExp(`(?<sign>[+-]?)(?<amount>\\d*)(?<dot>[.]?)(?<decimal>\\d+)(?<unit>${unit})$`)))
                    .filter(o => o)[0];
  if (!match) {
    return {
      amount: value || '',
      unit: '',
    };
  }
  return {
    amount: `${match.groups.sign}${match.groups.amount}${match.groups.dot}${match.groups.decimal}`,
    unit: match.groups.unit,
  }
};

export {
  writeStyleString,
  parseStyles,
  parseCssUnitValue,
}
