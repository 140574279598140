<template>
<dialog-layout style="min-height: min(80vh, 800px)" :is-root="isRoot">
  
  <dialog-close-button @close="$emit('close')" />
  
  <md-app>
    <md-app-toolbar>
      
      <md-tabs
        md-alignment="fixed"
        :md-active-tab="activeTab"
        class="controls md-transparent" @md-changed="activeTab = $event">
        
        <template slot="md-tab" slot-scope="{ tab }">
          <div class="mb-1">
            <ri v-if="tab.data.icon" class="ri-xl mb-2 d-block" :n="tab.data.icon" />
            <b>{{ tab.label }}</b>
          </div>
        </template>
        
        <md-tab id="tab-library" md-label="Library"
                :md-template-data="{ icon: 'brush-line' }"></md-tab>
        <md-tab  id="tab-image-details" v-if="activeTab === 'tab-image-details'"
                 md-label="Details" ref="tab-image-details"
                 :md-template-data="{ icon: 'image-fill' }"></md-tab>
        <md-tab id="tab-upload" md-label="Add New"
                :md-template-data="{ icon: 'upload-cloud-2-line' }"></md-tab>
        <md-tab id="tab-add" md-label="Add From URL"
                :md-template-data="{ icon: 'file-text-line' }"></md-tab>
      </md-tabs>
    </md-app-toolbar>
    
    <md-app-drawer md-permanent="full">
      <md-list>
        <md-list-item @click="imageTypeFilter = null" :class="!imageTypeFilter && 'md-active'">
          <md-icon></md-icon>
          <span class="md-list-item-text">All images</span>
        </md-list-item>
        <md-list-item v-for="type in imageTypes" :key="type"
                      @click="imageTypeFilter = type ? type : missingCategory"
                      :class="imageTypeFilter === (type ? type : missingCategory) && 'md-active'">
          <md-icon>
            <ri v-if="!type" n="question-line" />
          </md-icon>          
          <span class="md-list-item-text" v-if="type">
            {{ humanize(type) }}
          </span>          
          <span class="md-list-item-text" v-else>
            Uncategorized
          </span>
        </md-list-item>
        <md-list-item @click="imageTypeFilter = missingAltText"
                      :class="imageTypeFilter === missingAltText && 'md-active'">
          <md-icon><ri n="error-warning-line" /></md-icon>
          <span class="md-list-item-text">
            Missing Alt Text
          </span>
        </md-list-item>
      </md-list>
    </md-app-drawer>
    
    <md-app-content :style="activeTab === 'tab-image-details' ? 'background: whitesmoke;padding-bottom:3rem;min-height:700px' : null">
      <div v-if="activeTab === 'tab-image-details'">
        <form-card :no-layout="true" @submit="updateImage(viewingImage)" :sending="sending"
                   @delete="dropImage(viewingImage)"
                   :help="chooseAfterSavingDetails ? `We recommend entering a short description of your image (known as “alt text”) before you continue, for accessibility and SEO.` : ''">
          <div class="md-layout">
            <template v-if="!chooseAfterSavingDetails">
              <md-field class="md-layout-item md-size-25">
                <label>Width</label>
                <md-input disabled :value="viewingImage.width + ' px'" />
              </md-field>
              <md-field class="md-layout-item md-size-25">
                <label>Height</label>
                <md-input disabled :value="viewingImage.height + ' px'" />
              </md-field>            
              <md-field class="md-layout-item md-size-25">
                <label>Format</label>
                <md-input disabled :value="viewingImage.format" />
              </md-field>            
              <md-field class="md-layout-item md-size-25">
                <label>Size</label>
                <md-input disabled :value="Math.floor(viewingImage.bytes / 1024) + ' kB'" />
              </md-field>
            </template>
            
            <md-field class="md-layout-item md-size-100" v-if="viewingImage.piesExtra">
              <label>Alt text</label>
              <md-input type="text" v-model="viewingImage.piesExtra.alt_text" />
            </md-field>

            <md-field class="md-layout-item md-size-100" v-if="viewingImage.piesExtra">
              <label>Category</label>
              <md-select v-model="viewingImage.piesExtra.type">
                <md-option v-for="type in imageTypes" :key="type" :value="type">{{ humanize(type) }}</md-option>
              </md-select>
            </md-field>

            <md-field class="md-has-value md-borderless md-layout-item md-size-100" v-if="viewingImage.piesExtra">
              <label>Disable image optimizations?</label>
              <md-checkbox v-model="viewingImage.piesExtra.disableNuxtImg" :value="true" />
            </md-field>

          </div>
        </form-card>
        <img style="max-height: 400px; margin: 0 auto; display: block" :src="viewingImage.secure_url" />
      </div>
      
      <div v-if="activeTab === 'tab-library' || activeTab === 'tab-upload'">
        <draggable :list="images"  class="library" v-if="activeTab === 'tab-library'">
          <div class="library-entry" :key="image.public_id" v-for="image in images">
            <template v-if="deletedImages.indexOf(image.public_id) === -1">
              
              <img :src="`https://res.cloudinary.com/pies/image/upload/w_200/${image.public_id}`"
                   style="width: 200px; height: auto">
              <div class="meta">
                <span v-html="image.piesExtra && image.piesExtra.alt_text ? image.piesExtra.alt_text : '[No Alt Text]'">
                </span>
                <button v-if="isRoot" @click="activeTab = 'tab-image-details'; viewingImage = {...image, piesExtra: image.piesExtra || {}};"
                        class="btn btn-primary">Details</button>
                <button v-if="!isRoot"
                        @click="chooseOrPromptForAltText(image)"
                        class="btn btn-primary">Choose</button>
              </div>
            </template>
            <template v-else>
              
            </template>
          </div>
        </draggable>
        
        <vue-dropzone ref="dropzone" id="image-library-dropzone"
                      :options="dropzoneOptions"
                      v-on:vdropzone-sending="sendingEvent"
                      v-on:vdropzone-success="successEvent">
        </vue-dropzone>

        <p class="text-center md-subheading mt-2">
          <small>
            <i v-if="typeof imageTypeFilter === 'string'">
              Your image will be uploaded into the "{{ humanize(imageTypeFilter) }}" folder.
              To upload into a different folder instead, use the menu on the left.
            </i>
            <i v-else>
              Your image will be uploaded as uncategorized.
              To upload into a specific folder instead, use the menu on the left.
            </i>
          </small>
        </p>

        <div class="library" v-if="importedUrlImages.length">
          <h5>Imported Successfully</h5>
          <div class="library-entry" :key="image.public_id" v-for="image in importedUrlImages">
            <img :src="`https://res.cloudinary.com/pies/image/upload/w_200/${image.public_id}`"
                 style="width: 200px; height: auto">
            <div class="meta">
              <span>
                {{image.width}} pixels wide by
                {{image.height}} pixels tall
              </span>
              <button @click="$emit('close', image)"
                      class="btn btn-primary">Choose</button>
            </div>
          </div>
        </div>

      </div>
      
      <div v-if="activeTab === 'tab-add'">
        
        <md-field>
          <label>Image URL (to import multiple images, hit return and add one URL per line)</label>
          <md-textarea md-autogrow v-model="importUrls"></md-textarea>
          <md-button @click="importFromUrls">Import</md-button>
        </md-field> 

        <p class="text-center md-subheading mt-2">
          <small>
            <i v-if="typeof imageTypeFilter === 'string'">
              Your image will be uploaded into the "{{ humanize(imageTypeFilter) }}" folder.
              To upload into a different folder instead, use the menu on the left.
            </i>
            <i v-else>
              Your image will be uploaded as uncategorized.
              To upload into a specific folder instead, use the menu on the left.
            </i>
          </small>
        </p>
        
        <div v-if="importUrlErrors.length">
          <h5>The following imports encountered errors</h5>
          <dl>
            <div v-for="{ url, err, info }, index in importUrlErrors" :key="index">
              <dt>
                <a target="_blank" :href="url">{{ url }}</a>
              </dt>
              <dd>
                {{ err }}
                <div style="display:flex;align-items:center;margin-left:1em;font-style:italic">
                  <ri n="information-line" style="margin-right: .5em" />
                  {{ info }}
                </div>
              </dd>
            </div>
          </dl>
        </div>
        
        <div class="library" v-if="importedUrlImages.length">
          <h5>Imported Successfully</h5>
          <div class="library-entry" :key="image.public_id" v-for="image in importedUrlImages">
            <img :src="`https://res.cloudinary.com/pies/image/upload/w_200/${image.public_id}`"
                 style="width: 200px; height: auto">
            <div class="meta">
              <span>
                {{image.width}} pixels wide by
                {{image.height}} pixels tall
              </span>
              <button @click="$emit('close', image)"
                      class="btn btn-primary">Choose</button>
            </div>
          </div>
        </div>
      </div>
      
    </md-app-content>

  </md-app>
    
</dialog-layout>
</template>

<script>
import draggable from 'vuedraggable'
import { post } from 'axios';

export default {
  name: 'image-library',

  components: {
    draggable,
  },
  
  props: ['initialTab', 'initialImageTypeFilter', 'isRoot', 'closeOnAdd', 'allowMultiple'],
  
  data () {
    return {
      activeTab: this.initialTab || '',
      sending: false,

      chooseAfterSavingDetails: false,
      
      viewingImage: null,
      
      imageTypeFilter: this.initialImageTypeFilter || null,
      importUrls: '',

      importedUrlImages: [],
      importUrlErrors: [],

      deletedImages: [],
      refreshImages: 0,
    };
  },

  watch: {
    imageTypeFilter () {
      this.viewingImage = null;
      this.importedUrlImages = [];
      if (this.activeTab === 'tab-image-details') {
        this.activeTab = 'tab-library';
      }
    },
  },
  
  methods: {
    humanize: o => o ? o.split('-').map(i => `${i[0].toUpperCase()}${i.substring(1)}`).join(' ') + 's' : '',

    missingCategory: o => !o.piesExtra?.type,
    missingAltText: o => !o.piesExtra?.alt_text,

    chooseOrPromptForAltText (info) {
      if (info.piesExtra?.alt_text) {
        this.$emit('close', info);
        return;
      }
      this.viewingImage = {
        ...info,
        piesExtra: info.piesExtra || {},
      };
      this.activeTab = 'tab-image-details';
      this.chooseAfterSavingDetails = true;
    },
    
    async dropImage (info) {
      this.deletedImages.push(info.public_id);
      await this.$store.dispatch('dropImageMetadata', info);
      this.refreshImages++;
      this.viewingImage = null;
      if (this.activeTab === 'tab-image-details') {
        this.activeTab = 'tab-library';
      }
    },
    async registerImage (info, callback) {
      info = {...info};
      let type = this.imageTypeFilter;
      if (typeof type !== 'string') {
        type = null;
      }
      info.piesExtra = {
        type,
      };
      await this.$store.commit('putImage', {
        key: info.public_id.replace('/', ':'),
        info,
      });
      await this.$store.dispatch('registerImageMetadata', {
        info,
      });

      if (callback) {
        callback(info);
      }

    },
    async updateImage (info) {
      this.sending = true;
      await this.$store.commit('putImage', {
        key: info.public_id.replace('/', ':'),
        info,
      });
      await this.$store.dispatch('registerImageMetadata', {
        info,
      });
      this.sending = false;
      if (this.chooseAfterSavingDetails) {
        this.$emit('close', info);
      }
    },
    importFromUrls () {
      [...this.importUrlRows].forEach(url => {

        if (!(url.startsWith('https://') || url.startsWith('http://') || url.startsWith('ftp://') || url.startsWith('data:'))) {
          this.importUrlErrors.push({
            url,
            err: 'Error: Could not import from URL',
            info: 'Make sure that the URL begins with https://, http://, ftp://, or is a data: URL.'
          });
          this.importUrls = this.importUrlRows.filter(o => o !== url).join('\n');
          return;
        }
        
        post('https://api.cloudinary.com/v1_1/pies/image/upload', {
          file: url,
          folder: 'text-widget-upload',
          upload_preset: 'uth5vfjo',
        }).then(response => {
          this.registerImage(response.data);
          this.importedUrlImages.push(response.data);
          this.importUrls = this.importUrlRows.filter(o => o !== url).join('\n');
        }).catch(err => {
          console.log(err.response);
          let info = 'You may need to try again.';
          if (err.response?.status === 400) {
            info = 'Could not process file: double check that the URL refers to an image.'
          }
          if (err.response?.status === 400) {
            info = 'Not found: make sure the URL is correct.'
          }
          this.importUrlErrors.push({ url, err, info });
          this.importUrls = this.importUrlRows.filter(o => o !== url).join('\n');
        });
      })
    },
    successEvent (file, response) {
      this.registerImage(response, this.closeOnAdd ? () => this.$emit('close', response) : null);
      this.$refs.dropzone.removeFile(file);
      this.importedUrlImages = [response];
    },
    sendingEvent (file, xhr, formData) {
      formData.append('folder', 'test-widget-upload');
      formData.append('upload_preset', 'uth5vfjo');
    },
  },
  
  computed: {
    importUrlRows () {
      return this.importUrls.split('\n').map(o => o.trim()).filter(o => o);
    },
    dropzoneOptions () {
      return {
        dictDefaultMessage: 'Add new: click or drop files here to upload',
        url: 'https://api.cloudinary.com/v1_1/pies/image/upload',
        acceptedFiles: 'image/*,application/pdf,.psd',
      };
    },

    imageTypes () {
      let values = Object.values(this.allImages).map(o => o.piesExtra?.type || null);
      const hasUncategorized = values.indexOf(null) !== -1;
      values = [
        ...values.filter(o => o),
        'photo',
        'logo',
        'background',
        'icon',
        'endorser-logo',
        'endorser-photo',
        'press-logo',
        'graphics-and-icon',
      ];
      if (hasUncategorized) {
        values.push(null);
      }
      return [
        ...(new Set(values))
      ];
    },

    allImages () {
      this.refreshImages;
      let imageKeys = Object.keys(this.$store.state.repo.images).filter(
        key => this.deletedImages.indexOf(key) === -1
      );

      let images = {};
      imageKeys.forEach(key => {
        images[key] = this.$store.state.repo.images[key];
      });
      return images;
    },
    
    images () {
      this.refreshImages;
      return Object.values(this.allImages).filter(o => {
        if (this.imageTypeFilter === null) return true;
        if (typeof this.imageTypeFilter === 'function') return this.imageTypeFilter(o);
        return (o.piesExtra?.type === this.imageTypeFilter);
      });
    },
  },
}
</script>

<style scoped>
.library .md-list.md-theme-default .md-list-item-container.md-active .md-list-item-content * {
  color: var(--md-theme-default-primary-on-background, #448aff);
}
.md-drawer {
  width: auto;
  padding-right: 2rem;
  font-size: 2rem;
}
.md-toolbar .md-tabs {
  width: 100%;
  margin-bottom: 0;
}
dl {
  max-width: 100%;
}
dt, dt a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.library {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
h5 {
  flex: 100%;
  text-transform: uppercase;
  font-size: .9em;
  font-weight: 300;
}
.library-entry {
  position: relative;
  margin: 1rem; width: 200px; background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADBJREFUOE9jfPbs2X8GPEBSUhKfNAPjqAHDIgz+//+PNx08f/4cfzoYNYCBceiHAQC5flV5JzgrxQAAAABJRU5ErkJggg==");
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
  color: black;
  font-size: .8rem;
  line-height: 1;
  padding: 1em;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
.library-entry .meta::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: #eee;
  opacity: 0;
}
.library-entry:hover .meta {
  opacity: 1;
}
.library-entry:hover .meta::after {
  opacity: .3;
}
.library-entry:hover .meta span {
  background: #eee;
  display: block;
  width: 100%;
  z-index: 9;
}
.library-entry:hover .meta > * {
  opacity: 1;
  z-index: 9;
}
</style>
