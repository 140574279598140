<template>
<div>
  <img v-if="image && !allowMultiple && !hidePreview"
       :src="image" style="max-height: 200px; max-width: 200px; object-fit: contain; margin-bottom: 1rem;">
  <vue-dropzone ref="dropzone"
                :id="dzid"
                :options="dropzoneOptions"
                v-on:vdropzone-max-files-exceeded="$refs.dropzone.removeFile($event)"
                v-on:vdropzone-sending="sendingEvent"
                v-on:vdropzone-success="successEvent">
  </vue-dropzone>
</div>
</template>
<script>
export default {
  name: 'image-dropzone',

  props: ['extraUploadMetadata', 'allowMultiple', 'dzid', 'hidePreview'],

  computed: {
    dropzoneOptions () {
      return {
        dictDefaultMessage: this.allowMultiple ? 'Click or drop files here to upload' : 'Click or drop a file here to upload',
        url: 'https://api.cloudinary.com/v1_1/pies/image/upload',
        acceptedFiles: 'image/*,application/pdf,.psd',
        maxFiles: this.allowMultiple ? null : 1,
        thumbnailMethod: 'contain',
      };
    },
  },
  methods: {
    registerImage (info) {
      info = {...info};

      if (this.extraUploadMetadata) {
        info.piesExtra = this.extraUploadMetadata;
      }
      this.$store.commit('putImage', {
        key: info.public_id.replace('/', ':'),
        info,
      });
      this.$store.dispatch('registerImageMetadata', {
        info,
      });
    },    
    successEvent (file, response) {
      this.registerImage(response);
      !this.allowMultiple && this.$refs.dropzone.removeFile(file);
      this.image = `https://res.cloudinary.com/pies/image/upload/${response.public_id}`;
      this.$emit('upload', this.image);
    },
    sendingEvent (file, xhr, formData) {
      formData.append('folder', 'test-widget-upload');
      formData.append('upload_preset', 'uth5vfjo');
    },
  },

  data () {
    return { image: '' };
  },
}
</script>
