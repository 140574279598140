import { MediaUnit } from './core.js';
import { rgbToHex, parseRgbColor, getContrast, hexToRgba, colorRef } from '../color-utils.js';
import { writeStyleString } from '../css-utils.js';

export const hero_background_gradient = new MediaUnit('hero_background_gradient', 'Hero Background Gradient', 'hero', {

  relevantArgs: [
    'image', 'secondary_image', 'media_side', 'heading', 'content',
  ],
  
  styles: ({ metadata, primaryColor, media_side }) => ({
    '--image-aspect-ratio': 'none',    
    '--media-unit--media__width': '50%',    
    '--image-width': '9999',
    '--image-height': `${metadata.height}`,
    '--image-offset-x': '0',
    '--image-object-position': media_side === 'left' ? 'left' : 'right',
    'flex-direction': media_side === 'left' ? '' : 'row-reverse',    
    'color': getContrast(primaryColor),
  }),

  contentStyles: () => ({
    'padding': '3rem',
    'flex-basis': '50% !important',
  }),

  mediaStyles: () => ({
    'flex-basis': '50% !important',
    '--image-aspect-ratio': 'none',    
  }),

  template: ({ image, heading, secondary_image, content, styles, mediaStyles, contentStyles, contentClass, primaryColor, media_side, metadata, secondaryImageMaxWidth = '100%', secondaryImageMarginBottom = '5' }) => {

    let first_column = '';
    if (content === '<p></p>') content = '';
    if (!heading && !content) {
      secondaryImageMarginBottom = '0';
    }

    if (secondary_image) {
      first_column = `    <div class="media-unit--content-wrapper">
      <img style="max-width:${secondaryImageMaxWidth};margin-bottom:${secondaryImageMarginBottom}rem" src="${secondary_image}">
    </div>`;
    }
    let second_column = '';
    if (content === '<p></p>') content = '';
    if (heading || content) {
      second_column = `
<div class="media-unit--content-wrapper">
      ${heading ? '<h1>' + heading + '</h1>' : ''}
      ${content}
    </div>`;
    }

    return `
<media-unit uuid=""
    content-class="${contentClass || ''} has-wrapped-content alignment-centered-apart direction-column"
    styles="${styles}" media-styles="${mediaStyles}" content-styles="${contentStyles}">
  <template v-slot:backgrounds>
    <background uuid="" image="${image}" repeat="no-repeat" size="contain" 
                position="${media_side === 'left' ? 'left' : 'right'} calc(var(--image-offset-x) * 1px) top 0"></background>
    <background uuid="" gradient="linear" to="${media_side === 'left' ? 'right' : 'left'}" 
                start="${hexToRgba(primaryColor, 0)}" 
                end="${hexToRgba(primaryColor, 1)}" 
                end-at="calc(100vh * (${metadata.width} - 10) / ${metadata.height})"></background>
  </template>
  <template v-slot:default>
    ${first_column}    
    ${second_column}
  </template>
</media-unit>`;
  },  
});

export const hero_background_image_driven_gradient = new MediaUnit('hero_background_image_driven_gradient', 'Hero Background Image Driven Gradient', 'hero', {

  relevantArgs: [
    'image', 'secondary_image', 'media_side', 'heading', 'content',
  ],
  
  styles: ({ metadata, primaryColor, media_side }) => ({
    '--image-aspect-ratio': 'none',    
    '--media-unit--media__width': '50%',    
    '--image-width': '9999',
    '--image-height': `${metadata.height}`,
    '--image-offset-x': '0',
    '--image-object-position': media_side === 'left' ? 'left' : 'right',
    'flex-direction': media_side === 'left' ? '' : 'row-reverse',    
    'background-color': primaryColor,
    'color': getContrast(primaryColor),
  }),

  contentStyles: () => ({
    'padding-top': '20rem',
    'padding-bottom': '20rem',
    'padding-left': '3rem',
    'padding-right': '3rem',
    'flex-basis': '50% !important',
  }),

  mediaStyles: () => ({
    'flex-basis': '50% !important',
    '--image-aspect-ratio': 'none',    
  }),

  template: ({ heading, secondary_image, content, styles, mediaStyles, contentStyles, contentClass, primaryColor, metadata, gradientDepth, aspectRatio, media_side = 'left', secondaryImageMaxWidth = '100%', secondaryImageMarginBottom = '5' }) => {

    let first_column = '';
    if (content === '<p></p>') content = '';
    if (!heading && !content) {
      secondaryImageMarginBottom = '0';
    }
    if (secondary_image) {
      first_column = `    <div class="media-unit--content-wrapper">
      <img style="max-width:${secondaryImageMaxWidth};margin-bottom:${secondaryImageMarginBottom}rem" src="${secondary_image}">
    </div>`;
    }
    let second_column = '';
    if (content === '<p></p>') content = '';
    if (heading || content) {
      second_column = `
<div class="media-unit--content-wrapper">
      ${heading ? '<h1>' + heading + '</h1>' : ''}
      ${content}
    </div>`;
    }
    
    if (primaryColor.startsWith('#')) {
      primaryColor = primaryColor.replace('#', '');
    } else {
      let hexcolor = parseRgbColor(primaryColor);
      hexcolor = rgbToHex(hexcolor[0], hexcolor[1], hexcolor[2]);
      primaryColor = hexcolor.replace('#', '');
    }
    
    return `
<media-unit uuid=""
    content-class="${contentClass || ''} has-wrapped-content alignment-centered-apart direction-column"
    styles="${styles}" media-styles="${mediaStyles}" content-styles="${contentStyles}">
  <template v-slot:backgrounds>
    <background uuid="" image="https://res.cloudinary.com/pies/image/upload/w_2100,c_limit/e_gradient_fade,x_${media_side === 'left' ? '-' : ''}${gradientDepth}/ar_${aspectRatio},c_pad,g_${media_side === 'left' ? 'west' : 'east'}/b_rgb:${primaryColor}/fl_progressive:steep/${metadata.public_id}" repeat="no-repeat" size="cover" 
                position="${media_side === 'left' ? 'left' : 'right'} top"></background>
  </template>
  <template v-slot:default>
    ${first_column}
    ${second_column}
  </template>
</media-unit>`;
  },  
});

export const hero_background_no_gradient = new MediaUnit('hero_background_no_gradient', 'Hero Background No Gradient', 'hero', {

  relevantArgs: [
    'image', 'secondary_image', 'media_side', 'heading', 'content',
  ],
  
  styles: ({ metadata, primaryColor, media_side = 'left' }) => ({
    '--image-aspect-ratio': 'none',    
    '--media-unit--media__width': '50%',    
    '--image-width': '9999',
    '--image-height': `${metadata.height}`,
    '--image-offset-x': '0',
    '--image-object-position': media_side === 'left' ? 'left' : 'right',
    'flex-direction': media_side === 'left' ? '' : 'row-reverse',    
    'color': getContrast(primaryColor),
  }),

  contentStyles: () => ({
    'padding-top': '20rem',
    'padding-bottom': '20rem',
    'padding-left': '3rem',
    'padding-right': '3rem',
    'flex-basis': '50% !important',
  }),

  mediaStyles: () => ({
    'flex-basis': '50% !important',
    '--image-aspect-ratio': 'none',
  }),

  template: ({ image, heading, secondary_image, content, styles, mediaStyles, contentStyles, contentClass, primaryColor, media_side = 'left', secondaryImageMaxWidth = '100%', secondaryImageMarginBottom = '5' }) => {

    let first_column = '';
    if (content === '<p></p>') content = '';
    if (!heading && !content) {
      secondaryImageMarginBottom = '0';
    }
    if (secondary_image) {
      first_column = `    <div class="media-unit--content-wrapper">
      <img style="max-width:${secondaryImageMaxWidth};margin-bottom:${secondaryImageMarginBottom}rem" src="${secondary_image}">
    </div>`;
    }
    let second_column = '';
    if (content === '<p></p>') content = '';
    if (heading || content) {
      second_column = `
<div class="media-unit--content-wrapper">
      ${heading ? '<h1>' + heading + '</h1>' : ''}
      ${content}
    </div>`;
    }
    
    return `
<media-unit uuid=""
    content-class="${contentClass || ''} has-wrapped-content alignment-centered-apart direction-column"
    styles="${styles}" media-styles="${mediaStyles}" content-styles="${contentStyles}">
  <template v-slot:backgrounds>
    <background uuid="" image="${image}" repeat="no-repeat" size="cover" 
                position="${media_side === 'left' ? 'left' : 'right'} calc(var(--image-offset-x) * 1px) top 0"></background>
    <background uuid="" 
                color="${primaryColor}" opacity=".7"></background>
  </template>
  <template v-slot:default>
    ${first_column}
    ${second_column}
  </template>
</media-unit>`;
  },  
});

export const hero_background_no_tint = new MediaUnit('hero_background_no_tint', 'Hero Background No Tint', 'hero', {

  relevantArgs: [
    'image', 'secondary_image', 'media_side', 'heading', 'content',
  ],
  
  styles: ({ metadata, primaryColor, media_side = 'left' }) => ({
    '--image-aspect-ratio': 'none',    
    '--media-unit--media__width': '50%',    
    '--image-width': '9999',
    '--image-height': `${metadata.height}`,
    '--image-offset-x': '0',
    '--image-object-position': media_side === 'left' ? 'left' : 'right',
    'flex-direction': media_side === 'left' ? '' : 'row-reverse',    
    'color': getContrast(primaryColor),
  }),

  contentStyles: () => ({
    'padding-top': '20rem',
    'padding-bottom': '20rem',
    'padding-left': '3rem',
    'padding-right': '3rem',
    'flex-basis': '50% !important',
  }),

  mediaStyles: () => ({
    'flex-basis': '50% !important',
    '--image-aspect-ratio': 'none',
  }),

  template: ({ image, heading, secondary_image, content, styles, mediaStyles, contentStyles, contentClass, media_side = 'left', secondaryImageMaxWidth = '100%', secondaryImageMarginBottom = '5' }) => {

    let first_column = '';
    if (content === '<p></p>') content = '';
    if (!heading && !content) {
      secondaryImageMarginBottom = '0';
    }
    if (secondary_image) {
      first_column = `    <div class="media-unit--content-wrapper">
      <img style="max-width:${secondaryImageMaxWidth};margin-bottom:${secondaryImageMarginBottom}rem" src="${secondary_image}">
    </div>`;
    }
    let second_column = '';
    if (content === '<p></p>') content = '';
    if (heading || content) {
      second_column = `
<div class="media-unit--content-wrapper">
      ${heading ? '<h1>' + heading + '</h1>' : ''}
      ${content}
    </div>`;
    }    
    
    return `
<media-unit uuid=""
    content-class="${contentClass || ''} has-wrapped-content alignment-centered-apart direction-column"
    styles="${styles}" media-styles="${mediaStyles}" content-styles="${contentStyles}">
  <template v-slot:backgrounds>
    <background uuid="" image="${image}" repeat="no-repeat" size="cover" 
                position="${media_side === 'left' ? 'left' : 'right'} calc(var(--image-offset-x) * 1px) top 0"></background>
  </template>
  <template v-slot:default>
    ${first_column}
    ${second_column}
  </template>
</media-unit>`;
  },  
});


import storeObj from '../store.js';

export const hero_shape_divider = new MediaUnit('hero_shape_divider', 'Hero Shape Divider', 'hero', {
  styles: ({ metadata, primaryColor, media_side = 'right', min_height, elevation, styles }) => {

    const colors = colorRef(primaryColor, storeObj.store);
    
    return {
      '--image-width': `${metadata.width}`,
      '--image-height': `${metadata.height}`,
      '--image-object-position': media_side === 'left' ? 'left' : 'right',
      
      'background-color': colors.primary,
      'color': colors.contrast,
      
      'flex-direction': media_side === 'left' ? '' : 'row-reverse',
      'min-height': min_height,
      'box-shadow': elevation,
      ...styles,
    };
  },
  contentStyles: ({ contentStyles }) => ({
    'padding-left': '3rem',
    'padding-right': '3rem',
    'padding-top': '3rem',
    'padding-bottom': '3rem',
    ...contentStyles,
  }),
  mediaStyles: ({ shape, media_side = 'right', background_image }) => {
    const shapes = {
      'diagonal': {
        'left': 'polygon(0 0, 100% 0%, 75% 100%, 0% 100%)',
        'right': 'polygon(25% 0, 0 100%, 100% 100%, 100% 0)',
      },
      'arrow': {
        'left': 'polygon(0% 0%, 88% 0, 100% 50%, 88% 100%, 0% 100%)',
        'right': 'polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 12% 50%, 0% 0%)',
      },
      'striped': {
        'left': 'polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)',
        'right': 'polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)',
      },
      'rounded': {
        'left': 'circle(80% at 20% 50%)',
        'right': 'circle(80% at 80% 50%)',
      },
      'none': {
        'left': 'none',
        'right': 'none',
      }
    };

    if (shapes[shape]) {
      shape = shapes[shape][media_side];
    } 

    const zIndex = background_image ? '2' : null;

    return {
      'clip-path': shape,
      'z-index': zIndex,
    };
  },
  
  template: ({
    image, heading, secondary_image, content, styles, mediaStyles, contentStyles, contentClass,
    background_image,
    contentWrapperStyles, headingStyles, secondaryImageMaxWidth = '100%', secondaryImageMarginBottom = '5'
  }) => {
    let first_column = '';
    if (content === '<p></p>') content = '';
    if (!heading && !content) {
      secondaryImageMarginBottom = '0';
    }
    if (secondary_image) {
      first_column = `    <div class="media-unit--content-wrapper">
      <img style="max-width:${secondaryImageMaxWidth};margin-bottom:${secondaryImageMarginBottom}rem" src="${secondary_image}">
    </div>`;
    }

    if (contentWrapperStyles && Object.keys(contentWrapperStyles).length) {
      contentWrapperStyles = `style="${writeStyleString(contentWrapperStyles)}"`;
    }
    let second_column = '';
    if (content === '<p></p>') content = '';
    if (heading || content) {
      second_column = `
<div class="media-unit--content-wrapper" ${contentWrapperStyles}>
      ${heading ? `<h1 style="${headingStyles}">${heading}</h1>` : ''}
      ${content}
    </div>`;
    }

    let background_slot = '';
    if (background_image) {
      background_slot = `<template v-slot:backgrounds>
  <background uuid="" opacity=".3" image="${background_image}" position="center" attachment="" size="cover" repeat="no-repeat"></background>
</template>
      `;      
    }

    return `  
<media-unit uuid="" ${image ? 'image="' + image + '"' : ''}
    content-class="${contentClass || ''} has-wrapped-content alignment-centered-apart direction-column"
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}">
  ${background_slot}
  <template v-slot:default>
    ${first_column}
    ${second_column}
  </template>
</media-unit>`;
  }
});

export const hero_shape_background_divider = new MediaUnit('hero_shape_background_divider', 'Hero Shape Background Divider', 'hero', {
  styles: ({ metadata, primaryColor, media_side = 'right', min_height, elevation, styles = {} }) => ({
    '--image-width': `${metadata.width}`,
    '--image-height': `${metadata.height}`,        
    '--media-unit--media__width': `${metadata.width / metadata.height > 1 ? '50%' : '0%'}`,
    '--image-object-position': media_side === 'left' ? 'left' : 'right',
    'color': colorRef(primaryColor, storeObj.store).contrast,
    'flex-direction': media_side === 'left' ? '' : 'row-reverse',
    'min-height': min_height,
    'box-shadow': elevation,
    ...styles,
  }),
  contentStyles: ({ shape, media_side, primaryColor, backgroundOpacity, contentStyles = {} }) => {
    const shapes = {
      'diagonal': {
        'right': 'polygon(0 0, 100% 0%, 75% 100%, 0% 100%)',
        'left': 'polygon(25% 0, 0 100%, 100% 100%, 100% 0)',
      },
      'arrow': {
        'right': 'polygon(0% 0%, 88% 0, 100% 50%, 88% 100%, 0% 100%)',
        'left': 'polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 12% 50%, 0% 0%)',
      },
      'striped': {
        'right': 'polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)',
        'left': 'polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)',
      },
      'rounded': {
        'right': 'circle(80% at 20% 50%)',
        'left': 'circle(80% at 80% 50%)',
      },
      'none': {
        'right': 'none',
        'left': 'none',
      }
    };
    const paddings = {
      'diagonal': {
        'right': '3rem 12rem 3rem 3rem',
        'left': '3rem 3rem 3rem 9rem',
      },
      'arrow': {
        'right': '3rem 6rem 3rem 3rem',
        'left': '3rem 3rem 3rem 6rem',
      },
      'striped': {
        'right': '3rem 6rem 3rem 6rem',
        'left': '3rem 6rem 3rem 6rem',
      },
      'rounded': {
        'right': '3rem 9rem 3rem 3rem',
        'left': '3rem 3rem 3rem 9rem',
      },
      'none': {
        'right': '3rem 3rem 3rem 3rem',
        'left': '3rem 3rem 3rem 3rem',
      }
    };

    let padding = '3rem 3rem 3rem 3rem';
    if (paddings[shape]) {
      padding = paddings[shape][media_side];
    }

    if (shapes[shape]) {
      shape = shapes[shape][media_side];
    } 
    
    const colors = colorRef(primaryColor, storeObj.store);
    return {
      'clip-path': shape,
      'padding': padding,
      '--background-color': colors.primary,
      'background-color': hexToRgba(primaryColor, backgroundOpacity || .8),
      ...contentStyles,
    };
  },
  mediaStyles: () => ({
    '--image-aspect-ratio': 'none',
  }),

  template: ({ image, heading, secondary_image, content, styles, mediaStyles, contentStyles, contentClass, media_side, imageAttachment, contentWrapperStyles, headingStyles, secondaryImageMaxWidth = '100%', secondaryImageMarginBottom = '5', foreground_image }) => {
    let first_column = '';
    if (content === '<p></p>') content = '';
    if (!heading && !content) {
      secondaryImageMarginBottom = '0';
    }
    if (secondary_image) {
      first_column = `    <div class="media-unit--content-wrapper">
      <img style="max-width:${secondaryImageMaxWidth};margin-bottom:${secondaryImageMarginBottom}rem" src="${secondary_image}">
    </div>`;
    }

    if (contentWrapperStyles && Object.keys(contentWrapperStyles).length) {
      contentWrapperStyles = `style="${writeStyleString(contentWrapperStyles)}"`;
    }
    let second_column = '';
    if (content === '<p></p>') content = '';
    if (heading || content) {
      second_column = `
<div class="media-unit--content-wrapper" ${contentWrapperStyles}>
      ${heading ? `<h1 style="${headingStyles}">${heading}</h1>` : ''}
      ${content}
    </div>`;
    }

    return `  
<media-unit uuid="" 
    content-class="${contentClass || ''} has-wrapped-content alignment-centered-apart direction-column"
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" ${foreground_image ? `image="${foreground_image}"` : ''}>
  <template v-slot:backgrounds>
    <background uuid="" image="${image}" repeat="no-repeat" size="cover" attachment="${imageAttachment || ''}"
                position="${media_side === 'left' ? 'left' : 'right'} 0 center"></background>
  </template>
  <template v-slot:default>
    ${first_column}
    ${second_column}
  </template>
</media-unit>`;
  }
});

export const hero_photo = new MediaUnit('hero_photo', 'Photo', 'hero', {
  styles: ({ metadata, primaryColor }) => ({
    '--image-width': `${metadata.width}`,
    '--image-height': `${metadata.height}`,        
    '--media-unit--media__width': '45%',
    '--image-object-position': 'center',
    'background-color': primaryColor,
    'color': getContrast(primaryColor),
    'padding': '5rem',
  }),
  contentStyles: () => ({
    'margin-right': '-2.5rem',
    'align-self': 'center',
    'justify-self': 'center',
    'z-index': 99,
  }),
  mediaStyles: () => ({
    'border': '15px solid white',
    'align-self': 'center',
    'justify-self': 'center',
    'z-index': 99,    
  }),
  template: ({ image, heading, content, styles, mediaStyles, contentStyles, primaryColor, metadata }) => `  
<media-unit uuid="" image="${image}"
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}">
  <template v-slot:backgrounds>
    <background uuid="" image="https://s3.amazonaws.com/static.pies.pccc.co/website-default-files/cand-site-bg-alt-3.jpg" repeat="no-repeat" size="contain" position="top right"></background>
    <background uuid="" gradient="linear" to="left" 
                start="${hexToRgba(primaryColor, 0)}" 
                end="${hexToRgba(primaryColor, 1)}" 
                end-at="calc(100vh * (${metadata.width} - 10) / ${metadata.height})"></background>
  </template>
  <template v-slot:default>
    ${heading ? '<h1>' + heading + '</h1>' : ''}
    ${content}
  </template>
</media-unit>`,

});

export const hero_band = new MediaUnit('hero_band', 'Band', 'hero', {
  styles: ({ metadata, primaryColor }) => ({
    '--image-width': `${metadata.width}`,
    '--image-height': `${metadata.height}`,        
    '--media-unit--media__width': '55%',
    '--image-object-position': 'right',
    'background-color': primaryColor,
    'color': getContrast(primaryColor),    
  }),
  contentStyles: () => ({
    'padding': '3rem',
  }),
  mediaStyles: () => ({
    'clip-path': 'polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)',
    'margin-left': '5vw',
    'margin-right': '-5vw',
  }),
});

export const hero_steph_reversed = new MediaUnit('hero_steph_reversed', 'Steph Reversed', 'hero', {
  styles: ({ metadata, primaryColor }) => ({
    '--image-width': `${metadata.width}`,
    '--image-height': `${metadata.height}`,        
    '--media-unit--media__width': '55%',
    '--image-object-position': 'left',
    'flex-direction': 'row-reverse',
    'background-color': primaryColor,
    'color': getContrast(primaryColor),    
  }),
  contentStyles: () => ({
    'padding': '3rem',
  }),
  mediaStyles: () => ({
    'clip-path': 'polygon(25% 0, 0 100%, 100% 100%, 100% 0)',
  }),
});

export const hero_michele = new MediaUnit('hero_michele', 'Michele', 'hero', {
  styles: ({ metadata, primaryColor }) => ({
    '--image-width': `${metadata.width}`,
    '--image-height': `${metadata.height}`,        
    '--media-unit--media__width': '75%',
    '--image-object-position': 'top',
    'background-color': primaryColor,
    'color': getContrast(primaryColor),    
  }),
  contentStyles: () => ({
    'padding': '3rem',
  }),  
});

