<template>
<div>

  <div class="input-group" style="justify-content:center">
    <input class="form-control" :placeholder="placeholder" type="url"
           v-model="image" v-show="false">
    <img v-if="showImage" :src="image" style="max-width:200px;max-height:200px;"/>
    <span class="input-group-text">
      <div @click="openEditor" v-if="imageIsEditable">
        <ri n="pencil-line"></ri>
      </div>
      <div v-if="allowLibrary" @click="openLibrary">
        <ri n="book-2-fill"></ri>
      </div>
      <div v-if="allowDelete && this.value" @click="$emit('deleteImage', value)">
        <ri :n="deleteIcon"></ri>
      </div>
    </span>
  </div>
</div>
</template>

<script>
import CloudinaryImageEditor from './CloudinaryImageEditor.vue'
import ImageLibrary from './ImageLibrary.vue'
import { openDialog } from '../modal-dialog.js';

export default {
  name: 'ImageInput',
  props: {
    imageCategory: { type: String, default: '' },
    uploadPreset: { type: String, default: 'uth5vfjo' },
    placeholder: { type: String, default: 'Image' },
    value: { type: String },
    showImage: { type: Boolean, default: true },    
    allowDelete: { type: Boolean, default: false },
    deleteIcon: { type: String, default: 'delete-bin-line' },    
    allowLibrary: { type: Boolean, default: true },
    allowMultiple: { type: Boolean, default: false },
    extraUploadMetadata: { type: Object, default: null },
  },
  computed: {
    repoImages () {
      return this.$store.state?.repo?.images || {};
    },
    imageKey () {
      return this.value ? this.value.split('/').slice(-2).join(':') : null;
    },
    metadata () {
      return this.repoImages[this.imageKey];
    },
    imageIsEditable () {
      return this.imageKey && this.metadata;
    },
    image: {
      get () {
        return this.value;
      },
      set (val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {

    async openEditor () {
      const { src } = await openDialog(CloudinaryImageEditor, {
        imageId: this.metadata.public_id,
        originalWidth: this.metadata.width,
        originalHeight: this.metadata.height,
        value: this.image,
      });

      this.image = src;
    },

    async openLibrary () {
      const metadata = await openDialog(ImageLibrary, {
        initialImageTypeFilter: this.imageCategory || null,
      });
      if (metadata) {
        this.image = `https://res.cloudinary.com/pies/image/upload/${metadata.public_id}`;
      }
    },
    
    startUpload () {
      window.cloudinary.openUploadWidget(
        {
          cloudName: "pies",
          uploadPreset: this.uploadPreset,
          folder: 'test-widget-upload',
          multiple: this.allowMultiple,
          sources: ['local', 'url'],
        }, 
        (error, result) => {
          if (!error && result && result.event === "success") {

            let info = {...result.info};
            if (this.extraUploadMetadata) {
              info = {...info, piesExtra: this.extraUploadMetadata};
            }
            
            this.$store.commit('putImage', {
              key: info.public_id.replace('/', ':'),
              info,
              
            });
            this.$store.dispatch('registerImageMetadata', {
              info,
            });
            this.image = `https://res.cloudinary.com/pies/image/upload/${info.public_id}`;
          }
        });
    }
  },
};
</script>

<style scoped>
img {
  object-fit: contain;
}
.input-group-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.input-group-text > div {
  padding: .75rem;
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;  
}
.input-group-text > div:hover {
  background: lightyellow;
}
</style>
