<template>
<dialog-layout height=".95" width=".95">

  <dialog-close-button @close="$emit('close')" />

  <div ref="rasterizing-target" v-html="html">
  </div>

  <media-unit-live-preview-editor
    v-if="ready"
    :uuid="uuid"
    @save="rasterize"
    @discard="$emit('close')"
    />
  
</dialog-layout>
</template>

<script>
import domtoimage from "dom-to-image-more";
import { post } from 'axios';
import $ from 'cash-dom';

import MediaUnitLivePreviewEditor from '../MediaUnitLivePreviewEditor.vue';

export default {
  name: 'rasterizer',
  props: [
    'html',
  ],
  components: {
    MediaUnitLivePreviewEditor,
  },
  methods: {

    async rasterize () {
      let el = this.$refs['rasterizing-target'].querySelector('.style-holder');

      domtoimage.toPng(el).then(async png => {
        post('https://api.cloudinary.com/v1_1/pies/image/upload', {
          file: png,
          folder: 'test-widget-upload',
          upload_preset: 'uth5vfjo',
        }).then(async response => {
          const info = {
            ...response.data,
            piesExtra: {
              type: 'social',
            }
          };

          await this.$store.commit('putImage', {
            key: info.public_id.replace('/', ':'),
            info,
          });
          await this.$store.dispatch('registerImageMetadata', {
            info,
          });
          window.open(`https://res.cloudinary.com/pies/image/upload/${info.public_id}`, '_blank');

          /*
          const transparentUrl = `https://res.cloudinary.com/pies/image/upload/e_bgremoval/${info.public_id}`;
        
          post('https://api.cloudinary.com/v1_1/pies/image/upload', {
            file: transparentUrl,
            folder: 'test-widget-upload',
            upload_preset: 'uth5vfjo',
          }).then(async response2 => {

            const info2 = {
              ...response2.data,
              piesExtra: {
                type: 'logo',
                alt_text: `${this.candidateName} for ${this.candidateOffice}`,
              }
            };
            
            await this.$store.commit('putImage', {
              key: info2.public_id.replace('/', ':'),
              info: info2,
            });
            await this.$store.dispatch('registerImageMetadata', {
              info: info2,
            });
          
            h1.style.transform = '';

            const transparentBaseUrl = `https://res.cloudinary.com/pies/image/upload/${info2.public_id}`,
                  solidBaseUrl = `https://res.cloudinary.com/pies/image/upload/${info.public_id}`;

            this.$store.commit('setLoading', false);
            
           this.$emit('close', {
             ...this.$data,
             logoImage: this.textBasedLogoStyles['border-radius'] === '0px' ? solidBaseUrl : transparentBaseUrl,
             logoImageIsTextBased: true,
           });
          });
          */
        });
      });
    },
  },
  mounted () {
    this.ready = true;
  },
  data () {
    return {
      uuid: $(this.html).find('[id]').attr('id'),
      ready: false,
    };
  },
}
</script>
