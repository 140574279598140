export const platform_three_columns = {
  block: 'platform_three_columns',
  args: {
    padImages: true,
  },
};

export const platform_three_columns_images_inside = {
  block: 'platform_three_columns_images_inside',
  args: {
    tilePadding: '2',
    includeImagesInTilePadding: true,
    padImages: true,
  },
};


export const platform_three_columns_colors_separated = {
  block: 'platform_three_columns_colors_buttons',
  args: {
    tilePadding: '2',
    padImages: true,
    styles: {
      'max-width': '1400px',
      'overflow': 'visible',
      'margin-top': '2rem',
    },
  },
};
