export const platform_image_and_text_columns = { 
  block: 'platform_image_and_text_columns',
};

export const platform_entry_image_and_text_columns = {
  block: 'platform_entry_image_and_text_columns',
};

export const platform_image_behind_text = {
  block: 'platform_image_behind_text',
};
