import { MediaUnit } from './core.js';
import { writeStyleString } from '../css-utils.js';
import { colorRef } from '../color-utils.js';
import storeObj from '../store.js';

const writeStyles = o => writeStyleString(o, false, true);

export const multi_columns = new MediaUnit('multi_columns', 'Multi Columns', 'multi_columns', {
  styles: ({ primaryColor }) => {
    const colors = colorRef(primaryColor, storeObj.store);    
    return {
      'background-color': colors.primary,
      'color': colors.contrast,
      'padding-left': '3rem',
      'padding-right': '3rem',
      'padding-top': '3rem',
      'padding-bottom': '3rem',      
    };
  },
  mediaStyles: () => ({
    'display': 'none',
  }),
  contentStyles: ({ gutterWidth }) => ({
    'text-align': 'left',
    'margin-left': `-${gutterWidth}px`,
  }),

  template: ({
    styles, contentStyles, mediaStyles, contentClass = 'has-wrapped-content direction-row',
    columns, gutterWidth, paddingX, paddingY,
  }) => {

    const wrapped = columns.map(
      ({ content, width, backgroundColor }) => {

        const colors = backgroundColor ? colorRef(backgroundColor, storeObj.store) : {};
        
        const wrapperStyles = {
          'flex-basis': `${width}%`,
          'margin-left': `${gutterWidth}px`,
          'padding-left': `${paddingX}rem`,
          'padding-right': `${paddingX}rem`,
          'padding-top': `${paddingY}rem`,
          'padding-bottom': `${paddingY}rem`,
          'background-color': colors.primary,
          'color': colors.contrast,
        };
        
        return `
    <div class="media-unit--content-wrapper" style="${writeStyles(wrapperStyles)}">
      ${content}
    </div>
        `}).join('\n');
    
    return `
<media-unit uuid="" 
            image=""
            styles="${styles}" c
            content-styles="${contentStyles}" content-class="${contentClass}" 
            media-styles="${mediaStyles}">
  <template v-slot:default>
    ${wrapped}
  </template>
</media-unit>
`;
  },
    
});
