<template>
  <div class="editor">
    <div class="toolbar">
      <div class="button-set">
        <VueCustomTooltip v-if="Object.keys(mergeVars).length" label="Insert merge variable" position="is-left">      
          <button @click="toggleSubmenu('insert-merge-var')">
            <ri n="braces-line" />
          </button>
        </VueCustomTooltip>

        <div class="submenu submenu-wide" :class="submenu === 'insert-merge-var' ? 'shown' : ''">
          <button v-for="{ label, code }, index in mergeVars" :key="index"
                  style="font-size: 10px; text-transform: uppercase"
                  @click="insert(code)">
            {{ label }}
          </button>
        </div>
      </div>
    </div>

    <textarea ref="editor" rows="10" style="border:none" v-model="realValue"></textarea>
  </div>
</template>

<script>
import * as textFieldEdit from 'text-field-edit';

export default {
  props: {
    value: String,
    mergeVars: Array,
  },
  watch: {
    realValue () {
      this.$emit('input', this.realValue);
    },
  },
  methods: {
    insert (text) {
      textFieldEdit.insert(this.$refs['editor'], text);
      this.$refs['editor'].focus();
    },
    toggleSubmenu (type) {
      if (this.submenu === type) {
        this.submenu = null;
      } else {
        this.submenu = type;
      }
    },
    
  },
  data () {
    return {
      submenu: null,
      realValue: this.value || '',
    }
  },
}
</script>

<style>
.editor {
  border: 3px solid black;
  border-radius: 15px;
}
.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 3px solid black;
}
.toolbar button {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  padding: 10px 5px;
}
.toolbar .button-set {
  position: relative;
  display: inline-block;
}
.toolbar .button-set .submenu {
  position: absolute;
  left: -5px;
  border-radius: 15px;
  background: white;
  border: 3px solid black;
  border-top-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 99;
  display: flex;
  height: 0;
  width: 0;
  opacity: 0;
  overflow: hidden;
  transition: all .1s ease;
}
.toolbar .button-set .submenu.shown {
  opacity: 1;
  flex-direction: column;
  height: auto;
  width: 50px;
  align-items: center;
  border-radius: 0;
}
.toolbar .button-set .submenu.submenu-wide {
  width: 150px;
}

.toolbar .button-set button.is-active {
  background: #eee;
  font-weight: bold;
}

.toolbar .button-set .submenu button.is-active {
  width: 100%;
  background: #eee;
  font-weight: bold;
}
.toolbar .button-set .submenu.shown.horizontal {
  opacity: 1;
  height: 46.39px;
}
</style>
