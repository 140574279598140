import { MediaUnit } from './core.js';

export const two_columns = new MediaUnit('two_columns', 'Two Columns', 'two_columns', {
  styles: () => ({
    'padding-left': '3rem',
    'padding-right': '3rem',
    'padding-top': '3rem',
    'padding-bottom': '3rem',
    '--form-field-color': '#eeeeee',
    '--form-field-border-color': '#000000',
  }),
  mediaStyles: () => ({
    'display': 'none',
  }),
  contentStyles: () => ({
    'text-align': 'left',
  }),

  template: ({
    styles, contentStyles, mediaStyles, contentClass = 'has-wrapped-content direction-row',
    heading, image,
    firstColumnWidth = 50,
    content, secondaryContent
  }) => {
    const headingEl = heading ? `<h1 style="text-align: left">${heading}</h1>` : '';
    const secondMarginTop = heading ? 'margin-top: 2rem;' : '';
    
    const secondColumnWidth = 100 - firstColumnWidth;

    const imageEl = image ? `<img style="margin-bottom: 3rem" src="${image}">` : '';
    
    return `
<media-unit uuid="" 
            image=""
            styles="${styles}"
            content-styles="${contentStyles}" content-class="${contentClass}" 
            media-styles="${mediaStyles}">
  <template v-slot:default>
    <div class="media-unit--content-wrapper" style="flex-basis: ${firstColumnWidth}%; padding-right: 3rem;">
      ${headingEl}
      ${content}
    </div>
    <div class="media-unit--content-wrapper" style="flex-basis: ${secondColumnWidth}%; ${secondMarginTop}">
      ${imageEl}
      ${secondaryContent}
    </div>
  </template>
</media-unit>
`;
  },
    
});
