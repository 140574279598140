import { MediaUnit, imageMetadata } from './core.js';
import { getContrast } from '../color-utils.js';

export const platform_image_and_text_columns = new MediaUnit(
  'platform_image_and_text_columns', 'platform_image_and_text_columns', 'platform_one_image', {

    styles: ({ metadata, primaryColor }) => ({
      '--image-width': `${metadata.width}`,
      '--image-height': `${metadata.height}`,        
      '--media-unit--media__width': '50%',
      '--image-object-position': 'top',
      'background': primaryColor,
      'color': getContrast(primaryColor),
    }),
    contentStyles: () => ({
      'padding': '3rem',
      'text-align': 'left',      
    }),
    
    template: ({ image, heading, content, styles, mediaStyles, contentStyles }) => `
<media-unit uuid="" image="${image}"
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}"
    content-class="has-wrapped-content direction-row alignment-stretch-centered">
  <template v-slot:default>
    <div class="media-unit--content-wrapper" style="">
      <h1 style="text-align: left">${heading}</h1>
      ${content}
    </div>
  </template>
</media-unit>`,
});

export const platform_entry_image_and_text_columns = new MediaUnit(
  'platform_entry_image_and_text_columns', 'platform_entry_image_and_text_columns', 'platform_one_image', {

    styles: ({ metadata, primaryColor }) => ({
      '--image-width': `${metadata.width}`,
      '--image-height': `${metadata.height}`,        
      '--media-unit--media__width': '10%',
      '--image-object-position': 'top',
      'background': primaryColor,
      'color': getContrast(primaryColor),
      'margin-top': '1rem',
      'margin-bottom': '1rem',
      'max-width': '1200px',
      'margin-left': 'auto',
      'margin-right': 'auto',
    }),
    mediaStyles: ({ primaryColor, image }) => ({
      'border': `.5rem solid ${primaryColor}`,
      'display': image ? null : 'none',
    }),
    contentStyles: () => ({
      'text-align': 'left',
      'padding-left': '1rem',
    }),
    
    template: ({ image, heading, content, styles, mediaStyles, contentStyles }) => `
<media-unit uuid="" image="${image}"
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}"
    content-class="has-wrapped-content direction-row alignment-stretch">
  <template v-slot:default>
    <div class="media-unit--content-wrapper" style="">
      <h1 style="text-align: left">${heading}</h1>
      ${content}
    </div>
  </template>
</media-unit>`,
});

export const platform_image_behind_text = new MediaUnit(
  'platform_image_behind_text', 'platform_image_behind_text', 'platform_one_image', {

    styles: ({ primaryColor }) => ({
      '--image-width': `0`,
      '--image-height': `0`,
      '--media-unit--media__width': '0%',
      '--image-object-position': 'top',
      'background': primaryColor,
      'color': getContrast(primaryColor),
      '--theme--font__size': '2rem',
      '--theme--font__line-height': '1.1',
      'min-height': '800px',
    }),
    mediaStyles: () => ({
      'flex': '0',
      'display': 'none',
    }),    
    contentStyles: () => ({
      'padding': '3rem',
    }),
    
    template: ({ image, heading, content, styles, mediaStyles, contentStyles }) => `
<media-unit uuid="" image="${image}" content-class="alignment-stretch-centered has-wrapped-content"
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}">
  <template v-slot:backgrounds>
    <background uuid="" image="${image}" repeat="no-repeat" size="cover" position="center" attachment="fixed" opacity=".3">
    </background>
  </template>  
  <template v-slot:default>
    <div class="media-unit--content-wrapper" style="margin-left: auto; margin-right: auto; max-width: 800px;">
      <h1>${heading}</h1>
      ${content}
    </div>
  </template>
</media-unit>`,
});

export const platform_three_columns = new MediaUnit(
  'platform_three_columns', 'platform_three_columns', 'platform_columns', {

    styles: ({ primaryColor }) => ({
      '--image-width': `0`,
      '--image-height': `0`,
      '--media-unit--media__width': '0%',
      'background': primaryColor,
      'color': getContrast(primaryColor),
      '--theme--font__size': '1.1rem',
      '--theme--font__line-height': '1.1',
      '--theme--font__size__heading': '1.4rem',
      '--mobile--content-wrapper__padding-bottom': '1rem',
      '--mobile--content-wrapper__margin-top': '.25rem',
      '--mobile--content-wrapper__margin-bottom': '.25rem',            
    }),
    mediaStyles: () => ({
      'flex': '0',
      'display': 'none',
    }),
    contentStyles: ({ perRow }) => {
      let flexWrap = perRow ? {'flex-wrap': 'wrap'} : '';
      return {
        'padding-top': '3rem',
        'padding-bottom': '3rem',
        'padding-left': '3rem',
        'padding-right': '3rem',      
        'flex-basis': '100%',
        'margin': '-1rem',
        ...flexWrap,
      };
    },
    
    template: ({ issues, styles, mediaStyles, contentStyles, issuesFeed, issuesFeedConfig, perRow, noFlexGrow, padImages }) => {
      let extraCss = [];
      if (perRow) { 
        extraCss.push(`flex-basis: ${90 / parseInt(perRow)}%`);
      } else {
        extraCss.push(`flex-basis: 100%`);
      }
      if (noFlexGrow) {
        extraCss.push('flex-grow: 0');
      }
      extraCss = extraCss.join(';');
      
      let wrapped = '';
      if (issuesFeed) {
        wrapped = `<div v-for="entry in feeds['${issuesFeedConfig.uuid}']" class="media-unit--content-wrapper" style="margin: 1rem; display: flex; flex-direction: column; ${extraCss}">
      <img :src="'https://res.cloudinary.com/pies/image/upload/w_300,ar_16:9,${padImages ? 'c_pad,b_auto' : 'c_fill,g_auto'}/'+entry['image']" style="margin-bottom: 1rem;">
      <h2>{{ entry['title'] }}</h2>
      {{ entry['blurb'] }}
    </div>`;
      } else {
        wrapped = (issues || []).map(({ image, heading, content }) => {
          let img = '';
          if (image) {
            img = `<img src="https://res.cloudinary.com/pies/image/upload/w_300,ar_16:9,${padImages ? 'c_pad,b_auto' : 'c_fill,g_auto'}/${imageMetadata(image).public_id}" style="margin-bottom: 1rem;">`;
          }
          return `<div class="media-unit--content-wrapper" style="margin: 1rem; display: flex; flex-direction: column; ${extraCss}">
      ${img}
      <h2>${heading}</h2>
      ${content}
    </div>
          `;
        }).join('\n');
      }
      
      return `
<media-unit uuid="" 
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" content-class="has-wrapped-content direction-row alignment-stretch">
  <template v-slot:default>
    ${wrapped}
  </template>
</media-unit>`;
    }
});

export const platform_three_columns_colors = new MediaUnit(
  'platform_three_columns', 'platform_three_columns', 'platform_columns', {

    styles: ({ primaryColor }) => ({
      '--image-width': `0`,
      '--image-height': `0`,
      '--media-unit--media__width': '0%',
      'background-color': primaryColor,
      'color': getContrast(primaryColor),
      '--theme--font__size': '1.1rem',
      '--theme--font__line-height': '1.1',
      '--theme--font__size__heading': '1.4rem',
      '--mobile--content-wrapper__padding-bottom': '1rem',
      '--mobile--content-wrapper__margin-top': '.25rem',
      '--mobile--content-wrapper__margin-bottom': '.25rem',            
    }),
    mediaStyles: () => ({
      'flex': '0',
      'display': 'none',
    }),
    contentStyles: ({ perRow }) => {
      let flexWrap = perRow ? {'flex-wrap': 'wrap'} : '';
      return {
        'padding-top': '0',
        'padding-bottom': '0',
        'padding-left': '0',
        'padding-right': '0',      
        'flex-basis': '100%',
        'margin': '-1rem',
        ...flexWrap,
      };
    },
    
    template: ({ issues, styles, mediaStyles, contentStyles, issuesFeed, issuesFeedConfig, perRow, noFlexGrow, padImages, tilePadding }) => {
      let extraCss = [];
      if (perRow) { 
        extraCss.push(`flex-basis: ${90 / parseInt(perRow)}%`);
      } else {
        extraCss.push(`flex-basis: 100%`);
      }
      if (noFlexGrow) {
        extraCss.push('flex-grow: 0');
      }
      extraCss = extraCss.join(';');

      let wrapped = '';
      if (issuesFeed) {
        wrapped = `<div v-for="entry in feeds['${issuesFeedConfig.uuid}']" class="media-unit--content-wrapper" style="margin: 0; display: flex; flex-direction: column; ${extraCss}">
      <h2>{{ entry['title'] }}</h2>
      {{ entry['blurb'] }}
    </div>`;
      } else {
        wrapped = (issues || []).map(({ heading, content, image, primaryColor }) => {
          let colorCss = '';
          if (primaryColor) {
            colorCss = `background-color: ${primaryColor}; color: ${getContrast(primaryColor)};`;
          }
          let img = '';
          if (image) {
            img = `<img src="https://res.cloudinary.com/pies/image/upload/w_300,ar_16:9,${padImages ? 'c_pad,b_auto' : 'c_fill,g_auto'}/${imageMetadata(image).public_id}" style="margin-bottom: 1rem;">`;
          }
          
          return `<div class="media-unit--content-wrapper" style="margin: 0; ${colorCss} display: flex; padding-top: ${tilePadding || '.5'}rem; padding-bottom: ${tilePadding || '.5'}rem; padding-left: ${tilePadding || '.5'}rem; padding-right: ${tilePadding || '.5'}rem; flex-direction: column; align-items: center; justify-content: center; ${extraCss}">
      ${img}
      <h2>${heading}</h2>
      ${content}
    </div>
          `;
        }).join('\n');
      }
      
      return `
<media-unit uuid="" 
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" content-class="has-wrapped-content direction-row alignment-stretch">
  <template v-slot:default>
    ${wrapped}
  </template>
</media-unit>`;
    }
});

export const platform_three_columns_colors_buttons = new MediaUnit(
  'platform_three_columns', 'platform_three_columns', 'platform_columns', {

    styles: () => ({
      '--image-width': `0`,
      '--image-height': `0`,
      '--media-unit--media__width': '0%',
      '--theme--font__size': '1.1rem',
      '--theme--font__line-height': '1.1',
      '--theme--font__size__heading': '1.4rem',
      '--mobile--content-wrapper__padding-bottom': '1rem',
      '--mobile--content-wrapper__margin-top': '.25rem',
      '--mobile--content-wrapper__margin-bottom': '.25rem',            
    }),
    mediaStyles: () => ({
      'flex': '0',
      'display': 'none',
    }),
    contentStyles: ({ perRow }) => {
      let flexWrap = perRow ? {'flex-wrap': 'wrap'} : '';
      return {
        'padding-top': '0',
        'padding-bottom': '0',
        'padding-left': '0',
        'padding-right': '0',      
        'flex-basis': '100%',
        'margin': '-1rem',
        ...flexWrap,
      };
    },
    
    template: ({ issues, styles, mediaStyles, contentStyles, issuesFeed, issuesFeedConfig, perRow, noFlexGrow, tilePadding, tilePaddingX, padImages, primaryColor }) => {
      let extraCss = [];
      if (perRow) { 
        extraCss.push(`flex-basis: ${90 / parseInt(perRow)}%`);
      } else {
        extraCss.push(`flex-basis: 100%`);
      }
      if (noFlexGrow) {
        extraCss.push('flex-grow: 0');
      }
      extraCss = extraCss.join(';');

      let defaultColorCss = `background-color: ${primaryColor}; color: ${getContrast(primaryColor)};`;

      let wrapped = '';
      if (issuesFeed) {
        wrapped = `<div v-for="entry in feeds['${issuesFeedConfig.uuid}']" class="media-unit--content-wrapper" style="margin: 0; display: flex; flex-direction: column; ${extraCss}">
      <h2>{{ entry['title'] }}</h2>
      {{ entry['blurb'] }}
    </div>`;
      } else {
        wrapped = (issues || []).map(({ heading, content, image, primaryColor }) => {
          let colorCss = defaultColorCss;
          if (primaryColor) {
            colorCss = `background-color: ${primaryColor}; color: ${getContrast(primaryColor)};`;
          }
          let img = '';
          if (image) {
            img = `<img src="https://res.cloudinary.com/pies/image/upload/w_300,ar_16:9,${padImages ? 'c_pad,b_auto' : 'c_fill,g_auto'}/${imageMetadata(image).public_id}" style="margin-bottom: 1rem;">`;
          }
          
          return `<div class="media-unit--content-wrapper" style="margin-top: 1rem; margin-bottom: 1rem; margin-left: 2rem; margin-right: 2rem; ${colorCss} display: flex; padding-top: ${tilePadding || '.5'}rem; padding-bottom: ${tilePadding || '.5'}rem; padding-left: ${tilePaddingX || tilePadding || '.5'}rem; padding-right: ${tilePaddingX || tilePadding || '.5'}rem; flex-direction: column; align-items: center; justify-content: center; box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); border-radius: 25px; ${extraCss}">
      ${img}
      <h2>${heading}</h2>
      ${content}
    </div>
          `;
        }).join('\n');
      }
      
      return `
<media-unit uuid="" 
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" content-class="has-wrapped-content direction-row alignment-stretch">
  <template v-slot:default>
    ${wrapped}
  </template>
</media-unit>`;
    }
});

export const platform_three_columns_images_inside = new MediaUnit(
  'platform_three_columns_images_inside', 'platform_three_columns_images_inside', 'platform_columns', {

    styles: () => ({
      '--image-width': `0`,
      '--image-height': `0`,
      '--media-unit--media__width': '0%',
      '--theme--font__size': '1.1rem',
      '--theme--font__line-height': '1.1',
      '--theme--font__size__heading': '1.4rem',
      '--mobile--content-wrapper__padding-bottom': '1rem',
      '--mobile--content-wrapper__margin-top': '3rem',
      '--mobile--content-wrapper__margin-bottom': '3rem',      
    }),
    mediaStyles: () => ({
      'flex': '0',
      'display': 'none',
    }),
    contentStyles: ({ perRow }) => {
      let flexWrap = perRow ? {'flex-wrap': 'wrap'} : '';

      return {
        'padding': '3rem',
        'flex-basis': '100%',
        'margin': '-1rem',
        ...flexWrap,
      };
    },
    
    template: ({ issues, issuesFeed, issuesFeedConfig, styles, mediaStyles, contentStyles, primaryColor, tileMargin, tilePadding, borderRadius, perRow, noFlexGrow, padImages, includeImagesInTilePadding }) => {
      let extraCss = [];
      if (perRow) { 
        extraCss.push(`flex-basis: ${90 / parseInt(perRow)}%`);
      } else {
        extraCss.push(`flex-basis: 100%`);
      }
      if (noFlexGrow) {
        extraCss.push('flex-grow: 0');
      }
      extraCss = extraCss.join(';');
      
      let wrapped = '';
      let imageStyle;
      tilePadding = tilePadding || '.5';
      if (includeImagesInTilePadding) {
        imageStyle = 'margin-bottom: 1rem;';
      } else {
        imageStyle = `margin-bottom: 1rem; margin-top: -${tilePadding}rem; margin-left: -${tilePadding}rem; margin-right: -${tilePadding}rem; width: calc(100% + ${tilePadding * 2}rem; max-width: none;`;
      }

      const mainPrimaryColor = primaryColor;
      
      if (issuesFeed) {
        wrapped = `<div v-for="entry in feeds['${issuesFeedConfig.uuid}']" class="media-unit--content-wrapper" style="margin: ${tileMargin || '2'}rem; background: ${primaryColor}; color: ${getContrast(primaryColor)}; padding-top: ${tilePadding}rem; padding-bottom: 1rem; padding-left: ${tilePadding}rem; padding-right: ${tilePadding}rem; border-radius: ${borderRadius || '5'}px; ${extraCss}">
      <img :src="'https://res.cloudinary.com/pies/image/upload/w_500,ar_16:9,${padImages ? 'c_pad,b_auto' : 'c_fill,g_auto'}/'+entry['image']" style="${imageStyle}">
      <h2>{{ entry['title'] }}</h2>
      {{ entry['blurb'] }}
    </div>`;
      } else {
        wrapped = (issues || []).map(({ image, heading, content, link, linkText, primaryColor }) => {
          let img = '';
          if (image) {
            img = `<img src="https://res.cloudinary.com/pies/image/upload/w_500,ar_16:9,${padImages ? 'c_pad,b_auto' : 'c_fill,g_auto'}/${imageMetadata(image).public_id}" style="${imageStyle}">`;
          }
          
          return `<div class="media-unit--content-wrapper" style="margin: ${tileMargin || '2'}rem; background: ${primaryColor || mainPrimaryColor}; color: ${getContrast(primaryColor || mainPrimaryColor)}; padding-top: ${tilePadding}rem; padding-bottom: 1rem; padding-left: ${tilePadding}rem; padding-right: ${tilePadding}rem; border-radius: ${borderRadius || '5'}px; ${extraCss}">
      ${img}
      <h2>${heading}</h2>
      ${content}
      ${link ? `<h6 style="text-align: right;padding-right: 1em;padding-bottom: 1em;text-transform: uppercase;font-size: 1rem;right: 0;bottom: 0;position: absolute;"><a href="${link}">${linkText || 'Read More'}</a></h6>` : ''}
    </div>
      `;
        }).join('\n');
      }

      return `
<media-unit uuid="" 
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" content-class="has-wrapped-content direction-row alignment-stretch">
  <template v-slot:default>
    ${wrapped}
  </template>
</media-unit>`;
    },
});

export const platform_three_columns_colors_inside = new MediaUnit(
  'platform_three_columns_images_inside', 'platform_three_columns_images_inside', 'platform_columns', {

    styles: () => ({
      '--image-width': `0`,
      '--image-height': `0`,
      '--media-unit--media__width': '0%',
      '--theme--font__size': '1.1rem',
      '--theme--font__line-height': '1.1',
      '--theme--font__size__heading': '1.4rem',
      '--mobile--content-wrapper__padding-bottom': '1rem',
      '--mobile--content-wrapper__margin-top': '1.5rem',
      '--mobile--content-wrapper__margin-bottom': '1.5rem',      
    }),
    mediaStyles: () => ({
      'flex': '0',
      'display': 'none',
    }),
    contentStyles: ({ perRow }) => {
      let flexWrap = perRow ? {'flex-wrap': 'wrap'} : '';

      return {
        'padding': '0',
        'flex-basis': '100%',
        'margin': '-1rem',
        ...flexWrap,
      };
    },
    
    template: ({ issues, issuesFeed, issuesFeedConfig, styles, mediaStyles, contentStyles, primaryColor, tileMargin, tilePadding, borderRadius, perRow, noFlexGrow }) => {
      let extraCss = [];
      if (perRow) { 
        extraCss.push(`flex-basis: ${90 / parseInt(perRow)}%`);
      } else {
        extraCss.push(`flex-basis: 100%`);
      }
      if (noFlexGrow) {
        extraCss.push('flex-grow: 0');
      }
      extraCss = extraCss.join(';');
      
      let wrapped = '';
      
      if (issuesFeed) {
        wrapped = `<div v-for="entry in feeds['${issuesFeedConfig.uuid}']" class="media-unit--content-wrapper" style="margin: ${tileMargin || '2'}rem; background: ${primaryColor}; color: ${getContrast(primaryColor)}; padding-top: ${tilePadding || '.5'}rem; padding-bottom: 1rem; padding-left: ${tilePadding || '.5'}rem; padding-right: ${tilePadding || '.5'}rem; border-radius: ${borderRadius || '5'}px; ${extraCss}">
      <h2>{{ entry['title'] }}</h2>
      {{ entry['blurb'] }}
    </div>`;
      } else {
        console.log('tile margin is', tileMargin);
        wrapped = (issues || []).map(({ heading, content, primaryColor }) => `<div class="media-unit--content-wrapper" style="margin: ${tileMargin || '2'}rem; background: ${primaryColor}; color: ${getContrast(primaryColor)}; padding-top: ${tilePadding || '.5'}rem; padding-bottom: 1rem; padding-left: ${tilePadding || '.5'}rem; padding-right: ${tilePadding || '.5'}rem; border-radius: ${borderRadius || '5'}px; ${extraCss}">
      <h2>${heading}</h2>
      ${content}
    </div>
        `).join('\n');
      }

      return `
<media-unit uuid="" 
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" content-class="has-wrapped-content direction-row alignment-stretch">
  <template v-slot:default>
    ${wrapped}
  </template>
</media-unit>`;
    },
});

export const platform_three_columns_images_stretched_inside = new MediaUnit(
  'platform_three_columns_images_stretched_inside', 'platform_three_columns_images_stretched_inside', 'platform_columns', {

    styles: () => ({
      '--image-width': `0`,
      '--image-height': `0`,
      '--media-unit--media__width': '0%',
      '--theme--font__size': '1.1rem',
      '--theme--font__line-height': '1.1',
      '--theme--font__size__heading': '1.4rem',
      '--mobile--content-side__padding-left': 0,
      '--mobile--content-side__padding-right': 0,
      '--mobile--content-side__padding-top': 0,
      '--mobile--content-side__padding-bottom': 0,
      '--mobile--content-wrapper__padding-left': '3rem',
      '--mobile--content-wrapper__padding-right': '3rem',
      '--mobile--content-wrapper__padding-top': '6rem',
      '--mobile--content-wrapper__padding-bottom': '6rem',
      '--mobile--content-wrapper__margin-top': '1.5rem',
      '--mobile--content-wrapper__margin-bottom': '1.5rem',
    }),
    mediaStyles: () => ({
      'flex': '0',
      'display': 'none',
    }),
    contentStyles: ({ perRow }) => {
      let flexWrap = perRow ? {'flex-wrap': 'wrap'} : {'flex-wrap': 'nowrap'};

      return {
        'padding': '3rem',
        'flex-basis': '100%',
        'margin': '-1rem',
        ...flexWrap,
      };
    },
    
    template: ({ issues, issuesFeed, issuesFeedConfig, styles, mediaStyles, contentStyles, primaryColor, perRow, noFlexGrow, padImages }) => {

      let extraCss = [];
      if (perRow) { 
        extraCss.push(`flex-basis: ${90 / parseInt(perRow)}%`);
      } else {
        extraCss.push(`flex-basis: 100%`);
      }
      if (noFlexGrow) {
        extraCss.push('flex-grow: 0');
      }
      extraCss = extraCss.join(';');

      const mainPrimaryColor = primaryColor;
      
      let wrapped = '';
      if (issuesFeed) {
        wrapped = `<div v-for="entry in feeds['${issuesFeedConfig.uuid}']" class="media-unit--content-wrapper" style="margin: 0; color: ${getContrast(primaryColor)}; padding: 6rem; position: relative; ${extraCss}">
  <background uuid="" :image="'https://res.cloudinary.com/pies/image/upload/w_500,ar_16:9,${padImages ? 'c_pad,b_auto' : 'c_fill,g_auto'}/'+entry['image']" repeat="no-repeat" size="cover" position="center"></background>
  <background uuid="" color="${primaryColor}" opacity=".8"></background>
  <h2>{{ entry['title'] }}</h2>
  {{ entry['blurb'] }}
</div>`;
      } else {
        wrapped = (issues || []).map(({ image, heading, content, link, linkText, primaryColor }) => `<div class="media-unit--content-wrapper" style="margin: 1rem; color: ${getContrast(primaryColor || mainPrimaryColor)}; padding: 6rem; position: relative; ${extraCss}">
  <background uuid="" image="https://res.cloudinary.com/pies/image/upload/w_500,ar_16:9,${padImages ? 'c_pad,b_auto' : 'c_fill,g_auto'}/${imageMetadata(image).public_id}" repeat="no-repeat" size="cover" position="center"></background>
  <background uuid="" color="${primaryColor || mainPrimaryColor}" opacity=".8"></background>
  <h2>${heading}</h2>
  ${content}
  ${link ? `<h6 style="text-align: right;padding-right: 1em;padding-bottom: 1em;text-transform: uppercase;font-size: 1rem;right: 0;bottom: 0;position: absolute;"><a href="${link}">${linkText || 'Read More'}</a></h6>` : ''}
</div>`).join('\n');
      }
      
      return `
<media-unit uuid="" 
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" content-class="has-wrapped-content direction-row alignment-stretch">
  <template v-slot:default>
    ${wrapped}
  </template>
</media-unit>`;
    }
});
