<template>
<div style="display:flex;align-items:center">
  <md-field>
    <label>Search</label>
    <md-input v-model="searching" />
  </md-field>
  <font-picker ref="picker"
               :active-font="realValue"
               :key="renderKey"
               @change="realValue = $event.family" :options="options"
               api-key="AIzaSyA5CKPUiLITXA1Bw2pmycPKVyULsuT_7R8"></font-picker>
</div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'font-family-picker',
  props: ['value'],

  methods: {
    filterFonts: debounce(function() {
      const val = this.searching;
      if (this.fontList.length === 0) {
        this.fontList = [...this.$refs.picker.fontManager.fonts];
      }
      if (!val) {
        this.$refs.picker.fontManager.fonts = [...this.fontList];
      } else {
        this.$refs.picker.fontManager.fonts = this.fontList.filter(o => o.family.toLowerCase().indexOf(val.toLowerCase()) !== -1);
      }
      this.$refs.picker.fontManager.previewIndex = 0;
      this.$refs.picker.fontManager.downloadPreviews(5);
    }, 500),
  },
  
  watch: {
    searching () {
      this.filterFonts();
    },
    realValue () {
      this.$emit('input', this.realValue);
    },
  },
  data () {
    return {
      fontList: [],
      renderKey: 0,
      realValue: this.value,
      searching: '',
      options: {
        limit: 2000,
      }
    };
  },
}
</script>

<style scoped>
.md-field {
  min-height: 55px;
  margin-right: 5px;
}
.md-field label {
  top: 28px;
}
</style>
