<template>
<div>
  <div class="wizard-app" v-if="!exquisiteCorpse">
    <div v-if="!loggedIn" class="wizard" style="--wizard-step: 0">
      <div>
        <wizard-card icon="account-circle-line"
                     title="Hi there! We're not sure who you are."
                     subtitle="Do you want to log in to an existing account, or are you just trying things out?">
          
          <template #actions>
            <a v-if="$store.state.allowLogin" :href="$store.state.ssoEndpoint" style="width: 100%" class="btn">Log in</a>
            <button style="width: 100%" class="btn btn-primary"
                    @click="initializeAnonymous">Just trying things out!</button>
          </template>
          
        </wizard-card>
      </div>
    </div>
    
    <div v-if="loggedIn && wizardStepIndex < 5" class="wizard"
         :style="`--wizard-step: ${wizardStepIndex}; ${wizardStepIndex === 4 ? 'width: calc(1000px + 2rem)' : ''}`">
      <div>
        
        <wizard-card :step="0" @step="wizardStepIndex = $event"
                     :title="`Welcome, ${this.loggedInUser}!`"
                     subtitle="It looks like you don't have a PIES website yet. We'll walk you through getting started."
                     icon="time-line">
          <div class="mb-5">In about ten minutes, you'll have a beautiful website!</div>
          <template v-slot:actions>
            <button class="btn btn-primary" @click.prevent="initializeLoggedIn">Let's go!</button>
          </template>
        </wizard-card>
        
        <wizard-card :step="1" @step="wizardStepIndex = $event"
                     title="First: does your campaign have a logo?"
                     subtitle="If so, upload it here, in the highest resolution you have available. Transparent backgrounds are ideal!"
                     icon="remixicon-line">
          <div v-if="!noLogo">
            <image-dropzone dzid="logo"
                            :hide-preview="true"
                            :extra-upload-metadata="{ type: 'logo' }"
                            @upload="logoImage = $event" />
          </div>

          <div :style="logoMiniPreviewStyles" v-if="logoImage">
            <img :src="logoImage" />
          </div>

          <div v-if="logoImage">
            <md-switch v-model="invertColors">Invert colors</md-switch>
          </div>
          
          <div class="mt-3 mb-5">
            <button v-if="!noLogo" @click.prevent="openLogoBuilder" class="w-100 btn">I don't have a logo!</button>
            <button v-if="noLogo" @click.prevent="noLogo = false" class="w-100 btn">Wait, I do have a logo after all.</button>
          </div>

        </wizard-card>

        <wizard-card :step="2" @step="wizardStepIndex = $event"
                     title="Next, let's upload a high-resolution photo of you, the candidate."
                     subtitle="A wide-format full-body photo taken outdoors is best. Photos with your familiy or action shots work great!"
                     icon="camera-line">
          <div class="mb-5">
            <image-dropzone dzid="hero"
                            :extra-upload-metadata="{ type: 'photo' }"
                            @upload="heroImage = $event" />              
          </div>
        </wizard-card>

        <wizard-card :step="3" @step="wizardStepIndex = $event"
                     style="min-height:600px"
                     title="We've chosen a color palette for your website based on these images."
                     subtitle="If you have a specific palette you'd like to use instead, you can change these here. Or you can see how it looks with the colors we've picked &mdash; you can always tweak it later."
                     icon="robot-line">
          <div>
            <div class="d-flex mb-3" style="justify-content: space-around">
              <color-picker v-model="logoPrimaryColor" expand-to="center" />
              <color-picker v-model="heroPrimaryColor" expand-to="center" />
              <color-picker v-model="logoSecondaryColor" expand-to="center-left" />
              <color-picker v-model="heroSecondaryColor" expand-to="center-left" />
            </div>
            <div :style="`font-family: ${logoFont.name}`">
              And the font we've chosen for you is "{{ logoFont.name }}"

              <font-picker :active-font="logoFont.name"
                           @change="logoFont.name = $event.family"
                           api-key="AIzaSyA5CKPUiLITXA1Bw2pmycPKVyULsuT_7R8"></font-picker>

              <font-picker :active-font="contentFont.name"
                           @change="contentFont.name = $event.family"
                           api-key="AIzaSyA5CKPUiLITXA1Bw2pmycPKVyULsuT_7R8"></font-picker>

            </div>
          </div>
        </wizard-card>
        
        <wizard-card :step="4" @step="wizardStepIndex = $event"
                     title="Last step: upload a few more images of yourself, your family, and your district."
                     subtitle="A variety of settings, formats, and sizes is great! Feel free to upload as many photos as you have available."
                     icon="landscape-line"
                     style="min-width: 1000px!important;">
          <div style="overflow:scroll;max-height:600px!important;" class="mb-5">
            <image-dropzone dzid="photos"
                            :allow-multiple="true"
                            :extra-upload-metadata="{ type: 'photo' }" />
          </div>
        </wizard-card>
        
      </div>
    </div>
  </div>
  
  <link v-if="logoFont" :key="logoFont.family"
        :href="`https://fonts.googleapis.com/css?family=${logoFont.name}:400,600,700,800&display=swap`"
        type="text/css" rel="stylesheet" />

  <link v-if="contentFont" :key="contentFont.family"
        :href="`https://fonts.googleapis.com/css?family=${contentFont.name}:400,600,700,800&display=swap`"
        type="text/css" rel="stylesheet" />
  
  <div v-if="exquisiteCorpse" style="display: block">
    
    <md-speed-dial class="md-top-left" md-direction="bottom"
                   md-event="click"
                   style="z-index:9999;position:fixed">
      <md-speed-dial-target class="md-primary">
        <ri n="cake-2-line" />
      </md-speed-dial-target>
      
      <md-speed-dial-content>
        <md-button class="md-icon-button" @click="invertColors = !invertColors">
          <ri n="function-line" />
          Invert Colors
        </md-button>
        <md-button class="md-icon-button" @click="capWidth = !capWidth">
          <ri n="arrow-left-right-line" />
          {{ capWidth ? 'Make Full Width' : 'Cap Width' }}
        </md-button>
        <md-button class="md-icon-button" @click="advanceButtonRadius">
          <ri n="radio-button-line" />
          Change Buttons
        </md-button>
        <md-button class="md-icon-button" @click="cycleBlocks">
          <ri n="anticlockwise-2-line" />
          View Another Option
        </md-button>
        <md-button class="md-icon-button" @click="popupIntro">
          <ri n="information-line" />
          Show Popup Intro
        </md-button>
        <md-button class="md-icon-button" @click="wizardStepIndex--; exquisiteCorpse = '';">
          <ri n="arrow-left-line" />
          Take Me Back to the Wizard!
        </md-button>
        <md-button class="md-icon-button" @click="initProject">
          <ri n="error-warning-line" />
          I'm Ready!
        </md-button>
      </md-speed-dial-content>
    </md-speed-dial>
    
    <div :style="styles" v-if="exquisiteCorpse">
      
      <component :is="$store.state.repo.config.layout.headerType || 'right-aligned-header'"
                 id="masthead" class="site-header"
                 :style="$store.state.repo.config.layout.headerStyle"
                 :logo="$store.state.repo.config.layout.logo"
                 :social="$store.state.repo.config.layout.social"
                 tag="header"
                 :links="[...$store.state.repo.config.layout.header]"
                 :donate="{...$store.state.repo.config.layout.donate}" />
      
      <user-content ref="user-content"
                    :html="exquisiteCorpse"
                    :use-block-edit-options="true">
        <template v-slot:block-edit-speed-dial="{ block }">
          <md-speed-dial-content>
            <md-button class="md-icon-button md-speed-dial-button-rounded" @click="foo(block)">
              <snow-flake v-if="frozenSectionUuids.indexOf(block.uuid) === -1" size="30" color="white" />
              <ri n="blur-off-line" v-else />
            </md-button>
            <md-button v-if="frozenSectionUuids.indexOf(block.uuid) === -1"
                       class="md-icon-button md-speed-dial-button-rounded" @click="flipMediaSide(block)">
              <ri v-if="mediaSideForUuid(block.uuid) === 'left'" n="anticlockwise-2-line" />
              <ri v-else n="clockwise-2-line" />
            </md-button>
          </md-speed-dial-content>
        </template>
      </user-content>

      <footer class="masthead site-header" :style="footerStyle">
        <div class="my-3 social-icons">
          <a href="#" v-for="link in $store.state.repo.config.layout.social" :key="link.href">
            <font-awesome-icon size="3x" class="mx-3" :icon="['fab', link.platform]" />
          </a>
        </div>

        <nav class="site-navigation main-navigation"><button aria-controls="primary-menu" aria-expanded="false" class="menu-toggle">
        Primary Menu
      </button> <div class="menu-menu-1-container"><ul class="primary-menu menu nav-menu"><li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item current_page_item"><!----> <a href="/" aria-current="page" class="nuxt-link-exact-active nuxt-link-active" style="color:white;">
              Home
            </a></li><li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item current_page_item"><a href="https://example.com" style="color: white">
              About
            </a> <!----></li><li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item current_page_item"><!----> <a href="/issues" style="color:white;" class="">
              Get Involved
            </a></li><li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item current_page_item"><!----> <a href="/issues" style="color:white;" class="">
              Platform &amp; Priorities
            </a></li> <li class="menu-item-donate menu-item menu-item-action"><a href="https://example.com">
              Donate
        </a></li></ul></div></nav>
        <div class="disclaimer" v-html="$store.state.repo.config.layout.disclaimer"></div>
      </footer>
      
    </div>
  </div>
      
  
</div>

</template>

<script>
import smartcrop from 'smartcrop';
import Vue from 'vue';
import WizardCard from './WizardCard.vue';
import UserContent from './UserContent.vue';
import ImageDropzone from './ImageDropzone.vue';
import ColorPicker from './ColorPicker.vue';
import { getContrast, hexToRgba, writeStyleString, complement } from '../utils.js';
import { Typefont } from 'Typefont/src/index.js';
import { renderCorpus } from '../utils.js';
import Corpus from '../exquisite-corpse.js';
import $ from "cash-dom";
import anime from 'animejs/lib/anime.es.js';
import { openDialog } from '../modal-dialog.js';

import LogoBuilder from './dialogs/LogoBuilder.vue';

const choose = arr => arr[Math.floor(Math.random() * arr.length)];
const outerHtml = $dom => [...$dom].map(o => o.outerHTML || '').join('\n').trim();

const recommendedLogoFonts =  [
  'Libre Baskerville',
  'Architects Daughter',
  'Shadows Into Light',
  'PT Sans Narrow',
  'Fjalla One',
  'Oswald',
  'Merriweather',
  'Permanent Marker',
  'Mukta',
  'Libre Franklin',
  'Arvo',
  'Barlow',
  'Signika',
  'Abel',
  'Lato',
];

export default {
  name: 'StartFresh',
  components: {
    UserContent,
    WizardCard,
    ImageDropzone,
    ColorPicker,
  },
  watch: {
    
    async wizardStepIndex () {
      if (this.wizardStepIndex > 4) {
        if (!this.logoImage) {
          await this.$swal.fire({
            title: "Hang on a second...",
            text: "We'll need a logo before we can continue. You can just build one in a few minutes with our logo builder. (You can always replace this later!)",
            icon: 'warning',
            customClass: {
              confirmButton: 'md-button md-primary md-theme-default',
            },
            buttonsStyling: false,
          });
          this.wizardStepIndex = 1;
          return;
        }
        if (!this.heroImage) {
          await this.$swal.fire({
            title: "Hang on a second...",
            text: "We'll need at least one photo before we can continue. It doesn't need to be professionally shot or even very high-resolution -- you can just upload one from your phone or Facebook feed. If you don't have any photos of yourself, you can use a photo of a local landmark or an outdoor landscape.",
            icon: 'warning',
            customClass: {
              confirmButton: 'md-button md-primary md-theme-default',
            },
            buttonsStyling: false,
          });
          this.wizardStepIndex = 2;
        }        
      }
    },
    
    computedHeroPrimaryColor (val) {
      this.heroPrimaryColor = val;
    },
    
    computedHeroSecondaryColor (val) {
      this.heroSecondaryColor = val;
    },
    
    logoImageMetadata (val) {
      console.log('computed metadata', val);      
      this.logoPrimaryColor = this.computedLogoPrimaryColor;
    },
    
    computedLogoPrimaryColor (val) {
      console.log('computed', val);
      this.logoPrimaryColor = this.computedLogoPrimaryColor;
    },
    
    computedLogoSecondaryColor (val) {
      this.logoSecondaryColor = val;
    },

    logoImageIsTextBased () {
      if (this.logoImageIsTextBased) {
        this.logoSize = '600px';
      } else {
        this.logoSize = '450px';
      }
    },
    
    logoImage () {
      if (this.logoImageIsTextBased) {
        return;
      }
      this.detectFont();
    },
    
    async logoSize () {
      if (!this.exquisiteCorpse) return;
      this.exquisiteCorpse = await this.exquisiteCorpseContent();
    },
    
    async invertColors () {
      if (this.logoImageIsTextBased && this.logoImageInverted) {
        if (this.invertColors) {
          this.logoImage = this.logoImageOriginal;
        } else {
          this.logoImage = this.logoImageInverted;
        }
      }
      if (!this.exquisiteCorpse) return;
      this.exquisiteCorpse = await this.exquisiteCorpseContent();
    },
    
    async capWidth () {
      if (!this.exquisiteCorpse) return;
      this.exquisiteCorpse = await this.exquisiteCorpseContent();
    },
    sectionMediaSides: {
      deep: true,
      async handler (val) {
        console.log(val);
        if (!this.exquisiteCorpse) return;      
        this.exquisiteCorpse = await this.exquisiteCorpseContent();
      },
    },
    
    async contentFilled (val) {
      if (val) {
        if (!this.exquisiteCorpse) {
          window.setTimeout(this.popupIntro, 1500);
        }
        this.currentPreset = this.exquisiteCorpsePreset.map((o, index) => {
          if (this.frozenSections.indexOf(index) !== -1) {
            return this.currentPreset[index];
          } else {
            return choose(o[2]);
          }
        });
        this.exquisiteCorpse = await this.exquisiteCorpseContent();
        await this.$nextTick();
        for (var i = 0; i < this.currentPreset.length; ++i) {
          this.proposeImageGravity(i);
        }
      }
    },
  },
  methods: {
    
    async proposeImageGravity (index) {
      let spec = this.exquisiteCorpsePreset[index][1](),
          imgSrc = spec.image;
      
      if (!imgSrc) {
        return;
      }
      let imgId = this.srcToPublicId(imgSrc, '/');
      imgSrc = `/api/image-proxy/${imgId}`;
      
      let img = document.createElement("img");
      img.src = imgSrc;
      
      img.addEventListener('load', async () => {
        const result = await smartcrop.crop(img, { width: 300, height: 300 });
        let positionX = 'center',
            positionY = 'center';
        if ((result.topCrop.x + (result.topCrop.width / 2)) > img.naturalWidth / 2) {
          positionX = 'right';
        } else {
          positionX = 'left';
        }
        if ((result.topCrop.y + (result.topCrop.height / 2)) > img.naturalHeight / 2) {
          positionX = 'bottom';
        } else {
          positionX = 'top';
        }        
        console.log(imgSrc, result.topCrop, positionX, positionY);
        if (positionX !== this.sectionMediaSides[index]) {
          this.flipMediaSide({}, index);
        }
      });
    },
    
    mediaSideForUuid (uuid) {
      let dom = $(this.exquisiteCorpse);
      let that = this;
      let index = -1;
      let returnVal = 'left';
      
      dom.find("media-unit").each(function() {
        index += 1;
        if ($(this).attr("uuid") !== uuid) {
          return;
        }
        returnVal = that.sectionMediaSides[index];
      });
      return returnVal;
    },
    
    cycleLogoBackgroundRemoval () {
      const imageId = this.logoImage.split('/').slice(-2).join('/');
      const opts = ['', 'e_make_transparent/', 'e_bgremoval/'];
      let index = opts.indexOf(this.logoImageBackgroundRemovalOption) + 1;
      if (index >= opts.length) {
        index = 0;
      }
      this.logoImageBackgroundRemovalOption = opts[index];
      this.logoImage = `https://res.cloudinary.com/pies/image/upload/${this.logoImageBackgroundRemovalOption}${imageId}`;
    },
    
    async openLogoBuilder () {
      this.noLogo = true;
      const result = await openDialog(LogoBuilder);
      if (!result) {
        return;
      }
      this.noLogo = false;
      this.logoImageOriginal = result.logoImageOriginal;
      this.logoImageInverted = result.logoImageInverted;
      this.logoImageIsTextBased = result.logoImageIsTextBased;
      
      this.logoImage = this.logoImageInverted;
    },
    
    advanceButtonRadius () {
      const index = this.buttonRadiusOptions.indexOf(this.buttonRadius) + 1;
      if (index >= this.buttonRadiusOptions.length) {
        this.buttonRadius = this.buttonRadiusOptions[0];
      } else {
        this.buttonRadius = this.buttonRadiusOptions[index];
      }
    },
    
    choose: o => choose(o),
    
    flipMediaSide ({ uuid }, index) {
      let dom = $(this.exquisiteCorpse);
      let that = this;
      
      if (typeof index === 'undefined') {
        index = -1;
        dom.find("media-unit").each(function() {
          index += 1;
          if ($(this).attr("uuid") !== uuid) {
            return;
          }
          const val = (that.sectionMediaSides[index] === 'left' ? 'right' : 'left');
          that.$set(that.sectionMediaSides, index, val);
        });
      } else {
        const val = (that.sectionMediaSides[index] === 'left' ? 'right' : 'left');
        that.$set(that.sectionMediaSides, index, val);
      }
    },
    
    foo (block) {
      let dom = $(this.exquisiteCorpse);
      let that = this;
      var index = -1;
      dom.find("media-unit").each(function() {
        index += 1;
        if ($(this).attr("uuid") === block.uuid) {
          if (that.frozenSections.indexOf(index) !== -1) {
            that.frozenSections = that.frozenSections.filter(o => o !== index);
            anime({            
              targets: `[id="${block.uuid}"]`,
              easing: 'easeInOutSine',
              duration: 550,
              scale: [
                { value: .95 },
                { value: 1.05 },
                { value: .97 },
                { value: 1.03 },
                { value: .99 },
                { value: 1 },
              ],
            });
          } else {
            that.frozenSections.push(index);
            const xMax = 16,
                  rMax = -2;
            anime({
              targets: `[id="${block.uuid}"]`,
              easing: 'easeInOutSine',
              duration: 550,
              translateX: [
                {
                  value: xMax * -1,
                },
                {
                  value: xMax,
                },
                {
                  value: xMax/-2,
                },
                {
                  value: xMax/2,
                },
                {
                  value: 0
                }
              ],
              rotate: [
                {
                  value: rMax * -1,
                },
                {
                  value: rMax,
                },
                {
                  value: rMax/-2,
                },
                {
                  value: rMax/2,
                },
                {
                  value: 0
                }
              ],
            });
          }
        }
      });
    },
    
    popupIntro () {
      let popup = `
<div style="text-align:left"><p>Here's an example of how your site can look. Play around to customize! When you see something you like in one of the sections, just <b>double-click it</b> and then click on the freeze button to keep it for your new website: <span style="box-shadow:0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%); display:inline-flex;align-items:center;justify-content:center;font-size:30px;width:50px;height:50px;background:purple;border-radius:50px;vertical-align:top"><snow-flake  size="30" color="white "/></span></p>
<p>If a section's text is covering your face, you can flip its position with the rotate button: <span style="box-shadow:0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);font-weight:normal!important;vertical-align:top;display:inline-flex;align-items:center;justify-content:center;font-size:30px;color:white;width:50px;height:50px;background:purple;border-radius:50px"><i class="ri-anticlockwise-2-line"></i></span>
<p>
On the next screen you'll be able to adjust, swap, and fine-tune the photos, text, colors, and positioning &mdash; so don't worry if things don't fit perfectly right away. You'll be able to add new sections and reorder them there too, along with additional pages for your site. (And yes, you'll still be able to switch the layouts of each homepage section later, too, if you wish to.)
</p>
<p>
Would you like to see the colors arranged differently?
Or would you like to see different options?
</p>
<p style="vertical-align:top;font-weight:900!important;line-height:1;display:flex;align-items:flex-start;">
<span style="transform:rotate(-35deg);font-weight:normal!important;font-size:4rem;margin-top:-1rem"><i class="ri-arrow-up-circle-line"></i></span><span>Once you've closed this popup, you can click the big purple cake in the upper left corner of the screen to change things, or to move on to the next step when you're happy with how things feel:
<span style="box-shadow:0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);font-weight:normal!important;vertical-align:top;display:inline-flex;align-items:center;justify-content:center;font-size:30px;color:white;width:50px;height:50px;background:purple;border-radius:50px"><i class="ri-cake-2-line"></i></span></span>
</p></div>`;
      popup = Vue.compile(popup);
      popup = (new Vue({
        render: popup.render,
        staticRenderFns: popup.staticRenderFns,
      })).$mount().$el.outerHTML;
      
      this.$swal.fire({
        html: popup,
        backdrop: false,
        width: '60%',
        icon: 'info',
        customClass: {
          confirmButton: 'md-button md-primary md-theme-default',
        },
        buttonsStyling: false,
        reverseButtons: true,
      });
    },

    async finishCyclingSection (leavingUuid, replacementCode) {
      let replacementHtml = $((new Vue({
        render: Vue.compile(replacementCode).render,
      })).$mount().$el.outerHTML);

      let leavingHtml = $(`.media-unit[id="${leavingUuid}`);

      replacementHtml.css('position', 'absolute')
        .css('top', leavingHtml.position().top)
        .css('left', leavingHtml.position().left)
        .css('width', leavingHtml.outerWidth())
        .css('margin-left', '100vw')
        .css('filter', 'blur(3px) grayscale(.7)')
        .insertAfter(leavingHtml);

      await Promise.all([
        anime({
          targets: `[data-block-type][id="${leavingHtml.attr('id')}"]`,
          translateX: '-100vw',
          duration: 500,
          easing: 'easeOutExpo',
        }).finished,
        anime({
          targets: `[data-block-type][id="${replacementHtml.attr('id')}"]`,
          marginLeft: 0,
          duration: 250,
          easing: 'linear',
        }).finished,
      ]);
    },

    async exquisiteCorpseContent (animationFn) {
      if (this.$refs['user-content']) {
        this.$refs['user-content'].hoveredOver = null;
        this.$refs['user-content'].speedDialState = null;
      }
      let dom = $(this.exquisiteCorpse);

      let promises = this.exquisiteCorpsePreset.map(async (block, index) => {

        const currentBlock = dom.children("media-unit").eq(index);
              
        if (this.frozenSections.indexOf(index) !== -1) {
          return outerHtml(currentBlock);
        }
        
        const phylum = block[0];
        const chosenPreset = this.currentPreset[index];
        const presetName = chosenPreset.preset;
        const preset = Corpus.presets[phylum][presetName];

        const form = Corpus.types[phylum].args;

        let defaulted = {};
        Object.keys(form).forEach(key => {
          if (form[key].type === 'image') {
            let images = Object.values(this.$store.state.repo.images);
            if (form[key].subtype) {
              images = images.filter(i => i.piesExtra?.type === form[key].subtype);
            }
            let image = choose(images);
            defaulted[key] = image && form[key].fill_blank_image !== false ? `https://res.cloudinary.com/pies/image/upload/${image.public_id}` : '';
          } else if (form[key].type === 'object_list') {
            defaulted[key] = new Array(form[key].default || 5).fill().map(() => {
              let object = {};
              Object.keys(form[key].fields).forEach(subkey => {
                if (form[key].fields[subkey].type === 'image') {
                  let images = Object.values(this.$store.state.repo.images);
                  if (form[key].fields[subkey].subtype) {
                    images = images.filter(i => i.piesExtra?.type === form[key].fields[subkey].subtype);
                  }
                  let image = choose(images);
                  object[subkey] = image && form[key].fields[subkey].fill_blank_image !== false  ? `https://res.cloudinary.com/pies/image/upload/${image.public_id}` : '';
                } else {
                  object[subkey] = form[key].fields[subkey].default;
                }
              });
              return object;
            });
          } else {
            defaulted[key] = form[key].default;
          }
        });
        
        let args = {
          ...defaulted,
          ...preset?.args || {},
        };

        Object.entries(this.universalBlockArgs).forEach(o => {
          const arg = o[0], val = o[1];
          if (!args[arg]) {
            args[arg] = val;
          } else if (typeof args[arg] === 'object') {
            args[arg] = {
              ...args[arg],
              ...val,
            };
          } else {
            args[arg] = val;
          }
        });
        
        Object.entries(block[1]({ args, preset, presetName })).forEach(o => {
          const arg = o[0], val = o[1];
          if (!args[arg]) {
            args[arg] = val;
          } else if (typeof args[arg] === 'object') {
            args[arg] = {
              ...args[arg],
              ...val,
            }
          } else {
            args[arg] = val;
          }
        });

        Object.entries(chosenPreset?.args || {}).forEach(o => {
          const arg = o[0], val = o[1];
          if (!args[arg]) {
            args[arg] = val;
          } else if (typeof args[arg] === 'object') {
            args[arg] = {
              ...args[arg],
              ...val,
            };
          } else {
            args[arg] = val;
          }
        });

        if (typeof preset === 'undefined') {
          console.error('Cannot find a preset for ', presetName);
        }
        let newBlockCode = renderCorpus(preset.block, args, preset.defaultArgs);
        if (currentBlock.length && animationFn) {
          await animationFn(currentBlock.attr("uuid"), newBlockCode);
        }
        return newBlockCode;
      });

      let resolved = []; // resolve promises sequentially so block sliding effect isn't all at once... maybe
      for (var i = 0; i < promises.length; ++i) {
        const r = await promises[i];
        resolved.push(r);
      }

      return '<div>' + resolved.join('\n') + '</div>';
    },
    
    srcToPublicId: (src, separator) => src.split('/').slice(-2).join(separator || ':'),
    hexToRgba: (val, o) => hexToRgba(val, o),
    writeStyleString: (val) => writeStyleString(val),
    
    async detectFont () {
      const logoImageProxy = `/api/image-proxy/${this.srcToPublicId(this.logoImage, '/')}`;
      const result = await Typefont(logoImageProxy);
      if (result.length) {
        this.logoFont = {...result[0]};
      } else {
        this.logoFont = { name: 'Roboto' };
      }
    },

    async initializeLoggedIn () {
      if (!this.websiteExists) {
        this.$store.commit('setLoading', true);
        await this.$store.dispatch('initializeLoggedIn');
        this.$store.commit('setLoading', false);
      }
      this.wizardStepIndex++;
    },
    
    async initializeAnonymous () {
      this.$store.commit('setLoading', true);
      await this.$store.dispatch('initializeAnonymous');
      this.$store.commit('setLoading', false);
    },

    async cycleBlocks () {
      this.currentPreset = this.exquisiteCorpsePreset.map((o, index) => {
        if (this.frozenSections.indexOf(index) !== -1) {
          return this.currentPreset[index];
        } else {
          let nextPreset = o[2].indexOf(this.currentPreset[index]) + 1;
          if (nextPreset >= o[2].length) {
            nextPreset = [0];
          }
          nextPreset = o[2][nextPreset];
          return nextPreset;
        }
      });
      this.exquisiteCorpse = await this.exquisiteCorpseContent(this.finishCyclingSection);
    },
    
    async initProject () {
      this.$store.commit('setLoading', true);
      
      await this.$store.dispatch('fetchRepoContents');
      
      let config = {
        ...this.$store.state.repo.config.layout,
        logo: this.logoImage,
        style: writeStyleString(this.styles),
        footerStyle: writeStyleString(this.footerStyle),
      };
      
      const fonts = [
        { 'source': 'google', 'family': this.logoFont.name },
        { 'source': 'google', 'family': this.contentFont.name },
      ];
      
      await this.$store.dispatch('updateConfigs', {
        fonts: fonts,
        layout: config,
      });
            
      await this.$store.dispatch('immediatelyWritePageContent', {
        key: 'index',
        title: 'My Home Page',
        html: this.exquisiteCorpse,
      });
      
      this.$store.commit('doneInitializing');
      this.$store.commit('setLoading', false);
    },
  },
  
  computed: {
        
    footerStyle () {
      const base = this.styles;
      return {
        ...base,
        '--theme--color__heavy': this.invertColors ? this.logoSecondaryColor : this.heroSecondaryColor,
      };
    },
    
    universalBlockArgs () {
      let styles = {};
      if (this.capWidth) {
        styles['max-width'] = '1400px';
        styles['margin-bottom'] = '3rem';
      }
      return {
        primaryColor: this.invertColors ? this.heroPrimaryColor : this.logoPrimaryColor,
        styles,
      };
    },
    
    frozenSectionUuids () {
      let dom = $(this.exquisiteCorpse);
      let that = this;
      var index = -1;

      let uuids = [];
      dom.find("media-unit").map(function() {
        index += 1;
        if (that.frozenSections.indexOf(index) !== -1) {
          uuids.push($(this).attr('uuid'));
        }
      });
      return uuids;
    },
    
    contentFilled () {
      return (this.heroImage && this.wizardStepIndex > 4) && (
        this.logoImage || (this.noLogo && this.candidateName && this.candidateOffice)
      );
    },

    loggedInUser () {
      return `${this.login.user.name || this.login.user.username} (${this.login.campaign.slug})`;
    },
    
    loggedIn () {
      return (this.login.campaign?.slug && this.login.user?.username) ? true : false;
    },
    websiteExists () {
      return (this.loggedIn && this.login.website?.uuid) ? true : false;
    },
    
    login () {
      return this.$store.state.login;
    },
    
    images () {
      return this.$store.state.repo?.images || {};
    },
    
    logoImageMetadata () {
      if (!this.logoImage) return {};
      return this.images[this.srcToPublicId(this.logoImage)] || {};
    },
    
    heroImageMetadata () {
      if (!this.heroImage) return {};
      return this.images[this.srcToPublicId(this.heroImage)] || {};
    },

    logoMiniPreviewStyles () {
      return {
        ...this.styles,
        'padding': '2rem',
        'background-color': 'var(--theme--color__heavy)',
      };
    },
    
    styles () {

      const baseStyles = {
        '--theme--font__heading': this.logoFont ? `"${this.logoFont.name}"` : null,
        '--theme--font__text': this.logoFont ? `"${this.contentFont.name}"` : null,
        '--theme--font__size': '1.3rem',
        '--theme--font__scale-ratio': '1.1',
        '--theme--font__letter-spacing': '.01em',
        '--theme--font__line-height': '1.5',
        '--theme--font__line-height__heading': '1.35',
        '--theme--font__line-height__button': '1.5',
        '--button-radius': this.buttonRadius,
      };
      
      if (!this.invertColors) {
        return {
          ...baseStyles,
          '--button-color': this.heroPrimaryColor,
          '--button-text-color': getContrast(this.heroPrimaryColor),
          '--button-color__hover': this.heroSecondaryColor,
          '--link-color': this.heroPrimaryColor,
          '--link-color__hover': this.heroSecondaryColor,
          '--button-text-color__hover': getContrast(this.heroSecondaryColor),
          '--theme--color__heavy': this.logoPrimaryColor,
          '--theme--color__secondary': this.logoPrimaryColor,
          '--theme--color__text': getContrast(this.logoPrimaryColor),
        };
      }
      return {
        ...baseStyles,          
        '--button-color': this.logoPrimaryColor,
        '--button-text-color': getContrast(this.logoPrimaryColor),
        '--button-color__hover': this.logoSecondaryColor,
        '--button-text-color__hover': getContrast(this.logoSecondaryColor),

        '--link-color': this.logoPrimaryColor,
        '--link-color__hover': this.logoSecondaryColor,

        '--theme--color__heavy': this.heroPrimaryColor,
        '--theme--color__secondary': this.heroPrimaryColor,
        '--theme--color__text': getContrast(this.heroPrimaryColor),
      };
    },

    computedHeroPrimaryColor () {
      let colors = this.heroImageMetadata.colors || [['#677c3d'], ['#f4e2bf']];
      return colors.map(o => o[0]).filter(o => o !== '#FFFFFF' && o !== '#000000')[0] || '#677c3d';
    },
    computedHeroSecondaryColor () {
      let colors = this.heroImageMetadata.colors || [['#677c3d'], ['#f4e2bf']];
      colors = colors.map(o => o[0]).filter(o => o !== '#FFFFFF' && o !== '#000000');

      if (colors[1]) {
        return colors[1];
      }
      return complement(colors[0]);
    },
    computedLogoPrimaryColor () {
      let colors = this.logoImageMetadata.colors || [['#1c3137'], ['#15618d']];
      return colors.map(o => o[0]).filter(o => o !== '#FFFFFF' && o !== '#000000')[0] || '#1c3137';
    },
    computedLogoSecondaryColor () {
      let colors = this.logoImageMetadata.colors || [['#1c3137'], ['#15618d']];
      colors = colors.map(o => o[0]).filter(o => o !== '#FFFFFF' && o !== '#000000');
      if (colors[1]){
        return colors[1];
      }
      return complement(colors[0]);
    },
  },

  mounted () {
    let logoImage = choose(Object.values(this.images).filter(o => o.piesExtra?.type === 'logo'));
    if (logoImage) {
      this.logoImage = `https://res.cloudinary.com/pies/image/upload/${logoImage.public_id}`;
    }
    let heroImage = choose(Object.values(this.images).filter(o => o.piesExtra?.type === 'photo'));
    if (heroImage) {
      this.heroImage = `https://res.cloudinary.com/pies/image/upload/${heroImage.public_id}`;
    }

    if (this.logoImage && this.heroImage) {
      this.wizardStepIndex = 5;
    }
  },
  
  data () {
    let chosenLogoFont = choose(recommendedLogoFonts);
    
    return {
      logoImageIsTextBased: false,

      logoImageBackgroundRemovalOption: '',
      
      wizardStepIndex: 0,
      noLogo: false,

      showAllFonts: false,
      
      showMoreLogoOptions: false,

      recommendedLogoFonts,
      
      candidateName: '',
      candidateOffice: '',

      heroPrimaryColor: '',
      heroSecondaryColor: '',
      logoPrimaryColor: '#ffffff',
      logoSecondaryColor: '#000000',

      exquisiteCorpse: '',
      invertColors: false,
      capWidth: false,

      logoFont: { name: chosenLogoFont },
      contentFont: { name: 'Nunito' },

      buttonRadius: 0,
      logoSize: '450px',
      
      buttonRadiusOptions: [0, '5px', '15px', '125px'],
      
      logoImage: '',
      logoImageOriginal: '',
      logoImageInverted: '',
      heroImage: '',
      currentPreset: [],
      frozenSections: [],
      sectionMediaSides: ['left', 'left', 'left', 'left', 'left', 'left'],
      exquisiteCorpsePreset: [
        [
          'hero', 
          () => ({
            heading: "Let’s do this together.",
            content: `<signup-form uuid=""></signup-form>`,
            image: this.heroImage,
            secondary_image: this.logoImage,
            secondaryImageMaxWidth: this.logoSize,
            
            styles: {
              'margin-bottom': "5rem",
              'margin-top': this.capWidth ? '2rem' : '',
              'box-shadow': this.capWidth ? '0 0.5rem 1rem rgb(0 0 0 / 15%)' : '',
            },
            media_side: this.sectionMediaSides[0],

          }),
          [
            { preset: 'hero_michele', args: {
              styles: {
                '--media-unit--media__width': '85%',
              },
              contentStyles: {
                'padding-top': '6rem',
                'padding-bottom': '6rem',
              },
            } },
            { preset: 'hero_michele', args: {
              styles: {
                'max-width': '1400px',
                '--media-unit--media__width': '50%',
                'box-shadow': '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
              },
              contentStyles: {
                'padding-top': '6rem',
                'padding-bottom': '6rem',
              },
            } },
            { preset: 'hero_background_michele_vertical_banner', args: {
              contentStyles: {
                'padding-top': '7rem',
                'padding-bottom': '7rem',
              },
            } },
            { preset: 'hero_image_breakout_band', args: {
              styles: {
                'margin-top': '10rem',
                'margin-bottom': '10rem',
              },
              secondaryImageMarginBottom: '3',
            } },
            { preset: 'hero_image_breakout_band', args: {
              styles: {
                'margin-top': '0rem',
                'margin-bottom': '10rem',
              },
              mediaStyles: {
                'margin-top': '4rem',
                'margin-bottom': '4rem',
              },
              secondaryImageMarginBottom: '3',
              background_image: "https://res.cloudinary.com/pies/image/upload/text-widget-upload/ctvuikk94msybx2ftnny.jpg",
            } },
            { preset: 'hero_daquan', args: {
              styles: {
                'margin-top': '3rem',
                'max-width': '1400px',
                'box-shadow': '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
              },
            } },
            { preset: 'hero_background_no_tint', args: {
              contentStyles: {
                'padding-top': '8rem',
                'padding-bottom': '8rem',
              },
            } },
            { preset: 'hero_background_gradient', args: {
            } },
            { preset: 'hero_steph', args: {
            } },
          ],
        ],
        [
          'why_running',
          () => ({
            styles: {
              'margin-bottom': "5rem",
            },
            image: `https://res.cloudinary.com/pies/image/upload/${choose(Object.values(this.images).filter(o => o.piesExtra?.type === 'photo')).public_id}`,
            media_side: this.sectionMediaSides[1],
          }),
          [
            { preset: 'why_running' },
            { preset: 'why_running_2' },
            { preset: 'why_running_photo_shadow_box_3' },
          ],
        ],
        [
          'platform_columns',
          () => ({
            styles: {
              'margin-bottom': "2.5rem",
            },
            media_side: this.sectionMediaSides[2],
          }),
          [
            { preset: 'platform_three_columns_images_stretched_inside' },
            { preset: 'platform_three_columns_images_inside_no_padding' },
          ],
        ],
        [
          'join_donate',
          () => ({
            secondaryColor: this.invertColors ? this.logoSecondaryColor : this.heroSecondaryColor,
            media_side: this.sectionMediaSides[3],
            styles: {
              'box-shadow': this.capWidth ? '0 0.5rem 1rem rgb(0 0 0 / 15%)' : '',
            },
            
          }),
          [
            { preset: 'join_donate_columns' },
            { preset: 'donate_only_bar' },
            { preset: 'donate_bar_no_text' },
          ],
        ],
        [
          'long_text',
          () => ({
            styles: {
            },
            heading: "About Me",
            media_side: this.sectionMediaSides[4],
          }),
          [
            { preset: 'meet_alicia', args: {
              primaryColor: '#ffffff',
              styles: {
                'margin-top': '0rem',
                'margin-bottom': '0rem',
                'padding-top': "2rem",
                'padding-bottom': "2rem",
                'padding-left': "5rem",
                'padding-right': "5rem",
                'max-width': '1200px',
              },
            } },
          ],
        ],
        [
          'hero',
          ({ presetName }) => {
            let val = {
              heading: "Let's do this together.",
              secondary_image: null,
              styles: {
                '--media-unit--media__width': '50%',
                'box-shadow': this.capWidth ? '0 0.5rem 1rem rgb(0 0 0 / 15%)' : '',
              },
              media_side: this.sectionMediaSides[5],
              firstSideFlex: this.capWidth ? '1' : '.5',
            };
            if (presetName === 'join_only_bar' && this.capWidth) {
              val.styles['padding-left'] = val.styles['padding-right'] = '0';
            }
            return val;
          },
          [
            { preset: 'hero_michele', args: {
              contentStyles: {
                'padding-top': '2rem',
                'padding-bottom': '2rem',
              },
              content: `<p>Insert a brief message here about why your campaign is a movement that people should join. It's a good place for two or three sentences (about 35 words) that urge people to volunteer, donate, or otherwise get involved in the campaign!</p><signup-form style="margin-left: auto; margin-right: auto; margin-top: 0" />`,
              
            } },
            { preset: 'hero_background_michele', args: {
              contentStyles: {
                'padding-top': '2rem',
                'padding-bottom': '2rem',
              },
              content: `<p>Insert a brief message here about why your campaign is a movement that people should join. It's a good place for two or three sentences (about 35 words) that urge people to volunteer, donate, or otherwise get involved in the campaign!</p><signup-form style="margin-left: auto; margin-right: auto; margin-top: 0" />`,              
            } },
            { preset: 'hero_background_michele', args: {
              styles: {
                'padding-left': '10rem',
                'padding-right': '10rem',
              },
              contentStyles: {
                'padding-top': '2rem',
                'padding-bottom': '2rem',
              },
              content: `<p>Insert a brief message here about why your campaign is a movement that people should join. It's a good place for two or three sentences (about 35 words) that urge people to volunteer, donate, or otherwise get involved in the campaign!</p><signup-form style="margin-left: auto; margin-right: auto; margin-top: 0" />`,              
            } },
            { preset: 'join_only_bar', args: {
              content: `<p>Insert a brief message here about why your campaign is a movement that people should join. It's a good place for two or three sentences (about 35 words) that urge people to volunteer, donate, or otherwise get involved in the campaign!</p>`,
              
            } },
          ],
        ],
        /*
        ['hero_shape_divider', () => ({
          heading: "My Platform",
          content: "<ul><li>Foo</li><li>Foo</li><li>Foo</li><li>Foo</li><li>Foo</li><li>Foo</li></p>",
          image: `https://res.cloudinary.com/pies/image/upload/${choose(Object.values(this.images).filter(o => o.piesExtra?.type === 'photo')).public_id}`,
          primaryColor: this.invertColors ? this.heroPrimaryColor : this.logoPrimaryColor,
        })],
        ['meet_alicia', () => ({
          heading: "Meet Alicia",
          content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p><p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p><p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`,
          image: `https://res.cloudinary.com/pies/image/upload/${choose(Object.values(this.images).filter(o => o.piesExtra?.type === 'photo')).public_id}`,
        })], */
      ],
    };
  },
}
</script>

<style scoped>
.md-speed-dial .md-primary .md-button {
  border-radius: 0 80px 80px 0;
}
.md-speed-dial .md-primary {
  background: purple !important;
  opacity: .3;
}
.md-speed-dial:hover .md-primary {
  opacity: 1;
}
.md-speed-dial-content {
  align-items: flex-start;
}
.md-speed-dial:not(.md-active) .md-speed-dial-content {
  height: 0;
}
.md-speed-dial .md-speed-dial-content .md-button {
  width: auto;
  border-radius: 0 80px 80px 0;
  align-self: stretch;
}
.md-speed-dial .md-speed-dial-content .md-button.md-button.md-speed-dial-button-rounded {
  border-radius: 80px;
  width: 55px;
  height: 55px;
}
.md-speed-dial .md-speed-dial-content .md-button >>> .md-ripple {
  justify-content: flex-start;
}
.md-speed-dial .md-speed-dial-content .md-button.md-button.md-speed-dial-button-rounded >>> .md-ripple {
  justify-content: center;
}
.md-speed-dial .md-speed-dial-content .md-button >>> .md-button-content {
  display: flex;
  align-items: center;
  color: white;
  padding-right: .5em;
}
.md-speed-dial .md-speed-dial-content .md-button.md-button.md-speed-dial-button-rounded >>> .md-button-content {
  padding-right: 0;
}
.md-speed-dial [class^="ri-"],
.md-speed-dial [class*=" ri-"] {
  color: white;
  font-size: 2.5em;
}
.md-speed-dial .md-speed-dial-content .md-button [class^="ri-"],
.md-speed-dial .md-speed-dial-content .md-button [class*=" ri-"] {
  margin-right: .5em;
}
.md-speed-dial .md-speed-dial-content .md-button.md-speed-dial-button-rounded [class^="ri-"] {
  margin-right: 0;
}
.md-speed-dial .md-speed-dial-content .md-button {
  background: purple !important;
}
.md-speed-dial .md-speed-dial-content .md-button:hover {
  background: #500550 !important;
}
.md-speed-dial .md-speed-dial-content [class^="ri-"],
.md-speed-dial .md-speed-dial-content [class*=" ri-"] {
  color: white;
  font-size: 1.5em;
}
</style>
<style>
.swal2-popup {
  max-height: 85vh;
  overflow: scroll;
}
.wizard {
  width: calc(460px + 2rem);
  overflow-x: hidden;
}
.wizard > div {
  display: flex;
  flex-wrap: nowrap;
  width: max(100vw, 460px + 2rem);
  transition: transform .5s ease;
  transform: translateX(calc(-1 * (460px + 2rem) * var(--wizard-step)));
}
.wizard > div .card {
  margin: 0 1rem;
  text-align: center;
}

.wizard-app {
  font-family: "Open Sans", sans-serif;
}
.wizard-app .btn {
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 700;
  background-color: rgb(26, 147, 193);
  border: none;
  color: white !important;
  text-decoration: none !important;
  letter-spacing: 0px;
  font-size: 1.1rem;
  padding: .25rem .5rem;
}
.wizard-app .btn:hover {
  background-color: rgb(26, 147, 193);
  filter: brightness(1.2);
  color: white;
}
.wizard-app .btn-primary, 
.wizard-app .btn-primary:hover {
  background-color: rgb(154, 40, 139);
}
.wizard-app {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
</style>
