export const join_donate_columns = {
  block: 'join_donate_columns',
};

export const donate_only_bar = {
  block: 'donate_only_bar',
};

export const donate_bar_no_text = {
  block: 'donate_bar_no_text',
};
