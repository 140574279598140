import { render, staticRenderFns } from "./MdSwitchLeftRight.vue?vue&type=template&id=8652bf0e&scoped=true&"
import script from "./MdSwitchLeftRight.vue?vue&type=script&lang=js&"
export * from "./MdSwitchLeftRight.vue?vue&type=script&lang=js&"
import style0 from "./MdSwitchLeftRight.vue?vue&type=style&index=0&id=8652bf0e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8652bf0e",
  null
  
)

export default component.exports