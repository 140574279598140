<template>
<div>
  <form novalidate class="md-layout" @submit.prevent="$emit('submit')">
    <md-card class="md-layout-item md-size-100" :style="innerStyle">
      <md-card-header v-if="hasHeader">
        <slot name="header">
          <div v-if="title" class="md-title">
            {{ title }}
          </div>
          <md-subheader v-if="help" class="help">
            {{ help }}
          </md-subheader>
        </slot>
      </md-card-header>
      
      <md-card-content>
        <div :class="noLayout ? '' : 'md-layout'">
          <div :class="noLayout ? '' : 'md-layout-item md-size-100'">
            <slot></slot>
          </div>
        </div>
      </md-card-content>
      <md-progress-bar md-mode="indeterminate" v-if="sending && !noActions" />
      <md-card-actions v-if="!noActions" :md-alignment="alignment">
        <md-button v-if="hasDelete" @click.prevent.stop="$emit('delete')"
                   class="md-accent" :disabled="sending">{{ deleteButton || 'Delete' }}</md-button>
        
        <slot name="actions"></slot>
        
        <md-button type="submit" v-if="hasSubmit"
                   class="md-primary" :disabled="sending || submitDisabled">{{ saveButton || 'Save' }}</md-button>
      </md-card-actions>
    </md-card>
  </form>
  
  <slot name="append"></slot>
</div>
</template>

<script>
export default {
  name: 'form-card',
  props: [
    'sending', 'title', 'help', 'deleteButton', 'saveButton', 'submitEnabled',
    'innerStyle', 'buttonAlignment',
    'noLayout',
  ],
  computed: {

    submitDisabled () {
      if (typeof this.submitEnabled === 'undefined') {
        return false;
      }
      if (typeof this.submitEnabled === 'function') {
        return !this.submitEnabled();
      }
      return !this.submitEnabled;
    },
    
    alignment () {
      if (this.buttonAlignment) {
        return this.buttonAlignment;
      } else if (this.hasDelete || this.$slots.actions) {
        return 'space-between';
      } else {
        return 'right';
      }
    },
    hasHeader () {
      if (typeof this.$slots.header !== 'undefined') return true;
      if (typeof this.$scopedSlots.header !== 'undefined') return true;      
      if (this.title) return true;
      if (this.help) return true;
      return false;
    },
    hasActions () {
      return typeof this.$slots.actions !== 'undefined';
    },
    hasDelete () {
      return typeof this.$listeners['delete'] !== 'undefined';
    },
    hasSubmit () {
      return typeof this.$listeners['submit'] !== 'undefined';
    },
    noActions () {
      return !(this.hasSubmit || this.hasDelete || this.hasActions);
    },
  },
}
</script>

<style scoped>
form.md-layout {
  margin-bottom: 1rem;
}
.md-subheader.help {
  border-left: 3px solid #cccccc;
  padding-left: 7px;
  margin-left: 7px;
  min-height: 1.5rem;
  margin-top: 8px;
  margin-bottom: 8px;
}
.flexed-inside .md-card-content .md-layout-item {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
</style>
