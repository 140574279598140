<template>
<div>
  
  <div :style="layoutConfigStyles" id="style-holder">
    
    <link v-for="font in $store.state.repo.config.fonts" :key="font.family"
          :href="font.family ? `https://fonts.googleapis.com/css2?family=${font.family}:wght@400;700&display=swap` : null"
          type="text/css" rel="stylesheet" />

    <div ref="style-container"></div>
    
    <div class="hero-section">
      
      <component :is="repo.config.layout.headerType || 'right-aligned-header'"
                 id="masthead" class="site-header" :style="repo.config.layout.headerStyle"
                 :logo="repo.config.layout.logo"
                 :social="repo.config.layout.social"
                 tag="header"
                 :links="[...repo.config.layout.header]"
                 :donate="{...repo.config.layout.donate}"
                 ref="header" />
      
      <user-content v-if="viewingPageContent"
                    :html="viewingPageContent"
                    :entry="viewingPageRelatedContentEntry"
                    :css="$store.getters.activePage.style"
                    :on-add="appendMediaUnit"
                    :use-block-edit-options="true"
                    :use-live-hover-edits="false"
                    @click="collapseBirthdayCake"
                    >
      </user-content>
      
      <footer class="masthead site-header" :style="repo.config.layout.footerStyle">
        <div class="my-3 social-icons">
          <a @click="interceptLinks" :href="link.href" v-for="link in repo.config.layout.social" :key="link.href">
            <font-awesome-icon size="3x" class="mx-3" :icon="['fab', link.platform]" />
          </a>
        </div>

        <nav class="site-navigation main-navigation">
          <button aria-controls="primary-menu" aria-expanded="false" class="menu-toggle">
          </button>
          <div class="menu-menu-1-container">
            <ul class="primary-menu menu nav-menu">
              <li v-for="link, index in repo.config.layout.footer" :key="index"
                  class="menu-item"
                  :class="link.button ? 'menu-item-action' : ''"
                  :style="`${link.color ? '--button-text-color: ' + link.color + ';' : ''}${link.background ? '--button-color: ' + link.background + ';' : ''}`">

                <a @click="interceptLinks" :href="link.to || link.href">
                  {{ link.title }}
                </a>
              </li>
              <li v-if="repo.config.layout.donate"
                  class="menu-item-donate menu-item menu-item-action">
                <a @click="interceptLinks" :href="repo.config.layout.donate.href">
                  {{ repo.config.layout.donate.title || 'Donate' }}
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div class="disclaimer" v-html="repo.config.layout.disclaimer"></div>
      </footer>
      
    </div>
  </div>
  <div>
    
    <md-speed-dial class="md-top-left"
                   md-direction="bottom"
                   md-event="click"
                   style="z-index:999;position:fixed"
                   ref="birthday-cake-speed-dial"
                   >
      <md-speed-dial-target class="md-primary" ref="birthday-cake">
        <ri n="cake-2-line" />
      </md-speed-dial-target>

      <md-speed-dial-content>
        <md-button class="md-icon-button" @click="changePage">
          <ri n="folders-line" />
          Pages
        </md-button>

        <md-button class="md-icon-button" @click="editGlobalStyles">
          <ri n="brush-line" />
          Site Design
        </md-button>

        <md-button class="md-icon-button" @click="editPageMeta">
          <ri n="share-line" />
          Meta Tags &amp; Snippets
        </md-button>

        <md-button class="md-icon-button" @click="editFooter">
          <ri n="layout-bottom-line" />
          Header &amp; Footer
        </md-button>

        <md-button class="md-icon-button" @click="openImageLibrary">
          <ri n="image-line" />
          Image Library
        </md-button>

        <md-button v-if="$store.getters.userIsPCCCStaff" class="md-icon-button" @click="openFileUploader">
          <ri n="image-line" />
          File Library
        </md-button>
        
        <md-button v-if="$store.getters.userIsPCCCStaff" class="md-icon-button" @click="openTranslationManager">
          <ri n="translate-2" />
          Translations
        </md-button>
        
        <md-button v-if="$store.getters.userIsPCCCStaff" class="md-icon-button" @click="openFormManager">
          <ri n="input-cursor-move" />
          Form Library
        </md-button>

        <md-button v-if="$store.getters.userIsPCCCStaff" class="md-icon-button" @click="openLogoBuilder">
          <ri n="input-cursor-move" />
          Logo Builder
        </md-button>
        
        <md-button v-if="$store.getters.userIsPCCCStaff" class="md-icon-button" @click="openMetaImageBuilder">
          <ri n="input-cursor-move" />
          Meta Image Builder
        </md-button>
        
        <md-button class="md-icon-button" @click="openDeploymentManager">
          <rocket-ship :size="21" color="#ffffff" />
          Publish!
        </md-button>

        <md-button v-if="$store.getters.userIsPCCCStaff" class="md-icon-button" @click="showPageCode">
          <ri n="code-line" />
          Page Code
        </md-button>

        <md-button v-if="$store.state.history.length > 1" class="md-icon-button" @click="undo">
          <ri n="arrow-go-back-line" />
          Undo
        </md-button>
        
        <md-button class="md-icon-button" @click="logout">
          <ri n="logout-circle-line" />
          Log Out
        </md-button>

      </md-speed-dial-content>
    </md-speed-dial>
    
  </div>
</div>
</template>

<script>
import { get } from 'axios';
import { mixin as clickaway } from 'vue-clickaway';
import ImageInput from './ImageInput.vue'
import MultiImageInput from './MultiImageInput.vue'
import JQuery from 'jquery';
import Vue from 'vue';
import $ from 'cash-dom';
import BoxLayout from './dialogs/BoxLayout.vue'
import { mapState } from 'vuex';

import v4 from 'uuid/v4';
import { parseStyles, writeStyleString, renderCorpus } from '../utils.js';
import { getBoxLayout, updateBoxLayout } from '../box-layout-utils.js';

import LogoBuilder from './dialogs/LogoBuilder.vue';
import DeploymentManager from './dialogs/DeploymentManager.vue';
import DialogAddContentBlock from './DialogAddContentBlock.vue';
import PageList from './dialogs/PageList.vue';
import GlobalStyleEditor from './dialogs/GlobalStyleEditor.vue';
import PageCodeEditor from './dialogs/PageCodeEditor.vue';
import PageStyleEditor from './dialogs/PageStyleEditor.vue';
import PageMetaEditor from './dialogs/PageMetaEditor.vue';
import FooterManager from './FooterManager.vue';
import ImageLibrary from './ImageLibrary.vue';
import FileUploader from './FileUploader.vue';
import TranslationManager from './dialogs/TranslationManager.vue';
import FormManager from './FormManager.vue';
import Rasterizer from './utilities/Rasterizer.vue';
import { openDialog } from '../modal-dialog.js';

const clipPathChoices = [
  'polygon(0 0, 100% 0%, 75% 100%, 0% 100%)',
  'polygon(0% 0%, 88% 0, 100% 50%, 88% 100%, 0% 100%)',
  'circle(80% at 20% 50%)',
  'none',
];

/*const blockGallery = {
  a: {
  html: `<media-unit uuid="" image="https://placekitten.com/500/500" styles="min-height: 400px"><h1>Insert your content here</h1></media-unit>`,
  },
  b: {
  html: `<media-unit uuid="" image="https://placekitten.com/500/1000" styles="min-height: 100vh; --media-unit--media__clip-path: none; --media-unit__background: blue; --media-unit--content__background: red;"><h1>Insert your content here</h1></media-unit>`,
  },
  c: {
  html: `<media-unit uuid="" styles="--media-unit__background: url('https://placekitten.com/1200/600') center center/cover no-repeat fixed; --media-unit--media__width: 0%" content-styles="padding: 15rem; color: white;"><h1>Insert your content here</h1></media-unit>`,
  },
  d: {
  html: `<tile-row uuid="" columns="3">
  <tile uuid="" title="Foo" href="http://example.com" image="http://placekitten.com/200/200">
  Foo bar baz
  </tile>
  <tile uuid="" title="Foo" href="http://example.com" image="http://placekitten.com/200/200">
  Foo bar baz
  </tile>
  <tile uuid="" title="Foo" href="http://example.com" image="http://placekitten.com/200/200">
  Foo bar baz
  </tile>
  </tile-row>`
  },
  e: {
  html: `<tile-row uuid="" columns="2">
  <tile uuid="" title="Foo" href="http://example.com" image="http://placekitten.com/200/200">
  Foo bar baz
  </tile>
  <tile uuid="" title="Foo" href="http://example.com" image="http://placekitten.com/200/200">
  Foo bar baz
  </tile>
  <tile uuid="" title="Foo" href="http://example.com" image="http://placekitten.com/200/200">
  Foo bar baz
  </tile>
  <tile uuid="" title="Foo" href="http://example.com" image="http://placekitten.com/200/200">
  Foo bar baz
  </tile>
  </tile-row>`
  },
  };*/

const outerHtml = $dom => [...$dom].map(o => o.outerHTML || '').join('\n').trim();

export default {
  name: 'HelloWorld',
  props: {
    initialData: Object,
  },
  mixins: [ clickaway ],
  methods: {

    async logout () {
      await get('/api/logout');
      window.location.reload();
    },
    
    interceptLinks (e) {
      e.preventDefault();
      let href = $(e.target).attr('href');
      if (!href) return;
      if (href.startsWith('/')) {
        href = href.substring(1) || 'index';
        if (href in this.repo.pages) {
          this.$store.dispatch('fetchPage', { key: href });
        } else {
          openDialog(PageList, {
            showMeta: false,
            selectable: false,
            hidePageList: true,
            hideRedirects: true,
            initialTab: 'tab-add-new',
          });
        }
      }
    },

    async collapseBirthdayCake () {
      if (this.$refs['birthday-cake-speed-dial'].MdSpeedDial.active) {
        await this.$nextTick();
        this.$refs['birthday-cake-speed-dial'].MdSpeedDial.active = false;
      }
    },
    async openLogoBuilder () {
      const result = await openDialog(LogoBuilder);
      console.log(result.logo);
    },
    
    async openMetaImageBuilder () {
      const { block, value } = await openDialog(DialogAddContentBlock, {
        filterCorpusTypes: ['hero'],
        initialValue: {
          content: '<p></p>',
          heading: '',
        }
      });
      
      let code = $('<div class="style-holder">'+renderCorpus(block, value).trim()+'</div>');
      code.attr('style', this.$store.state.repo.config.layout.style);
      let html = (new Vue({
        render: Vue.compile(outerHtml(code)).render,
      })).$mount().$el.outerHTML;
      
      html = html.replace(/https:\/\/res.cloudinary.com\/pies\/image\/upload\//g, '/api/image-proxy/');
      const result = await openDialog(Rasterizer, { html });
      console.log(result);
    },
    
    async openDeploymentManager () {
      const result = await openDialog(DeploymentManager);
      console.log(result);
    },
    
    setBackgroundImage (uuid, src) {
      
      const componentUuid = this.editingComponent.uuid;
      let newContent = JQuery(this.viewingPageContent);
      let targetEl = newContent
          .find(`[uuid=${componentUuid}] template`).toArray()
          .filter(o => o.hasAttribute('v-slot:backgrounds'))[0].innerHTML;
      let bgSlot = JQuery('<div>').html(targetEl);
      let targetBg = bgSlot.find(`background[uuid=${uuid}]`);
      targetBg.attr('image', src);
      newContent
        .find(`[uuid=${componentUuid}] template`).toArray()
        .filter(o => o.hasAttribute('v-slot:backgrounds'))[0].innerHTML = bgSlot.html();
      this.$store.commit('updateActivePageContent', newContent[0].outerHTML);
      
      //console.log(uuid, src);
    },
    
    getBoxLayout (style) {
      return getBoxLayout(style);
    },
    updateBoxLayout (style) {
      const newStyles = updateBoxLayout(style, this.editingComponent.styles);
      this.changeEditingComponentProp('styles', newStyles);
    },
    
    changeLogoImage (val) {
      let config = {...this.repo.config.layout, logo: val};
      this.$store.dispatch('writeLayoutConfig', { config: config });
    },
    
    parseFont (family) {
      if (!family) {
        return family;
      }
      return family.replace(/"/g, '');
    },
    
    setViewingPageByLink (to) {
      if (this.repo.pages[to]) {
        this.viewingPage = to;
      }
    },
    
    async openImageLibrary () {
      await openDialog(ImageLibrary, { isRoot: true });
    },

    async openFileUploader () {
      await openDialog(FileUploader, { isRoot: true });
    },
    
    async openTranslationManager () {
      await openDialog(TranslationManager);
    },
    
    async openFormManager () {
      await openDialog(FormManager, { isRoot: true });
    },

    undo () {
      this.$store.commit('undo');
    },
    
    async showPageCode () {
      await openDialog(PageCodeEditor);
    },
    async showPageStyle () {
      await openDialog(PageStyleEditor);
    },
    
    async editGlobalStyles () {
      await openDialog(GlobalStyleEditor);
    },
    async editFooter () {
      await openDialog(FooterManager);
    },
    async editPageMeta () {
      await openDialog(PageMetaEditor);
      //await openDialog(PageList, { showMeta: true, selectable: false, only: [ this.viewingPage ] });
    },
    async changePage () {
      let key = await openDialog(PageList, { showMeta: false, selectable: true });
      if (typeof key === 'undefined')
        return;
      this.$store.dispatch('fetchPage', { key });
    },
    
    finishAppendingMediaUnit(html, insertAfter) {
      let newEl = JQuery(html);
      newEl.attr("uuid", v4());
      newEl.find("[uuid]").each(function() { JQuery(this).attr("uuid", v4()); });
      let newContent = JQuery(this.viewingPageContent);
      
      if (insertAfter) {
        newEl.insertAfter(newContent.find(`[uuid="${insertAfter}"]`));
      } else {
        newContent.append(newEl);
      }
      this.$store.commit('updateActivePageContent', newContent[0].outerHTML);
    },
    
    async appendMediaUnit (block, insertAfter, filterCorpusTypes) {
      
      let args = {};
      if (!block) {
        const choice = await openDialog(DialogAddContentBlock, { filterCorpusTypes });
        if (typeof choice === 'undefined') return;
        block = choice.block;
        args = choice.value;
      }
      
      const body = renderCorpus(block, args);
      this.finishAppendingMediaUnit(body, insertAfter);
    },
    
    changeEditingComponentStyles (key, val) {
      let styles = {...this.editingComponent.styles};
      styles[key] = val;
      styles = Object.entries(styles).map(([k, v]) => `${k}:${v}`).join(';');
      this.changeEditingComponentProp('styles', styles);
    },
    
    changeEditingComponentContentStyles (key, val) {
      let styles = {...this.editingComponent.contentStyles};
      styles[key] = val;
      styles = Object.entries(styles).map(([k, v]) => `${k}:${v}`).join(';');
      this.changeEditingComponentProp('content-styles', styles);
    },
    
    changeEditingComponentContent (val) {
      const uuid = this.editingComponent.uuid;
      let newContent = JQuery(this.viewingPageContent);
      let targetEl = newContent.find(`[uuid=${uuid}] template`).toArray().filter(o => o.hasAttribute('v-slot:default'))[0] || newContent.find(`[uuid=${uuid}]`)[0];
      JQuery(targetEl).html(val);
      this.$store.commit('updateActivePageContent', newContent[0].outerHTML);
    },
    changeEditingComponentProp (key, val) {
      const uuid = this.editingComponent.uuid;
      let newContent = JQuery(this.viewingPageContent);
      newContent.find(`[uuid=${uuid}]`).attr(key, val);
      this.$store.commit('updateActivePageContent', newContent[0].outerHTML);      
    },
    changeEditingComponentImage (val) {
      let styles = {...this.editingComponent.styles};
      
      const metadata = this.$store.state.repo.images[val.split('/').slice(-2).join(':')];
      if (metadata) {
        let width = metadata.width,
            height = metadata.height;
        
        const components = val.split('/').map(o => o.split(',')),
              dimensions = components.filter(o => o[0] === '$v_1')[0];
        
        if (dimensions) {
          let adjustedWidth = dimensions.map(o => o.match(/^w_(?<width>\d+)/)?.groups?.width).filter(o => o)[0],
              adjustedHeight = dimensions.map(o => o.match(/^h_(?<height>\d+)/)?.groups?.height).filter(o => o)[0];
          
          if (adjustedWidth && adjustedHeight) {
            width = adjustedWidth;
            height = adjustedHeight;
          }
        }
        
        styles['--media-unit--media__aspect-ratio'] = `${height / width * 100}%`;
        styles['--image-width'] = width;
        styles['--image-height'] = height; 
      }
      styles = Object.entries(styles).map(([k, v]) => `${k}:${v}`).join(';');
      
      const uuid = this.editingComponent.uuid;
      let newContent = JQuery(this.viewingPageContent);
      newContent.find(`[uuid=${uuid}]`)
        .attr('image', val)
        .attr('styles', styles);
      
      this.$store.commit('updateActivePageContent', newContent[0].outerHTML);      
    },
    
    async changeFont (font, cssVar) {
      const styles = {
        ...this.layoutConfigStyles,
        ...{[cssVar]: `"${font.family}"`}
      },
            css = writeStyleString(styles),
            layoutConfig = {...this.repo.config.layout, ...{style: css}};
      
      const fonts = [
        ...this.$store.state.repo.config.fonts.filter(o => {
          return Object.values(styles).indexOf(`"${o.family}"`) !== -1;
        }),
        { 'source': 'google', 'family': font.family },
      ];
      
      this.$store.dispatch('updateConfigs', { fonts: fonts, layout: layoutConfig });
    },
  },
  components: {
    ImageInput,
    MultiImageInput,
    BoxLayout,
  },
  
  watch: {
    globalCss () {
      this.$refs['style-container'].innerHTML = '';
      let style = document.createElement('style');
      style.innerText = this.globalCss;
      this.$refs['style-container'].append(style);
    },
  },
  
  computed: {
    
    ...mapState([
      'repo',
    ]),
    
    globalCss () {
      return this.repo?.globalCss || '';
    },
    
    viewingPage: {
      get () {
        return this.$store.state.activePageKey;
      },
      set (key) {
        this.$store.dispatch('fetchPage', { key });
      },
    },
    
    viewingPageRelatedContentEntry () {
      return this.$store.getters.activePage.contentEntry;
    },
    
    viewingPageContent: {
      get () {
        return this.$store.getters.activePage.html;
      },
      set (val) {
        this.$store.commit('updateActivePageContent', val);
      },
    },
    
    fontConfig () {
      return this.$store.state.fonts;
    },
    
    layoutConfigStyles: {
      get () {
        return parseStyles(this.repo.config.layout.style);
      },
      set (val) {
        const css = writeStyleString(val),
              config = {...this.repo.config.layout, ...{style: css}};
        this.$store.dispatch('writeLayoutConfig', { config });
      },
    },
    
    buttonRadius: {
      get () {
        return this.layoutConfigStyles['--button-radius'];
      },
      set (val) {
        this.layoutConfigStyles = {
          ...this.layoutConfigStyles,
          ...{'--button-radius': val},
        };
      },
    },
    
    themeColorHeavy: {
      get () {
        return this.layoutConfigStyles['--theme--color__heavy'];
      },
      set (val) {
        this.layoutConfigStyles = {
          ...this.layoutConfigStyles,
          ...{'--theme--color__heavy': val},
        };
      },
    },
    
    headerStyles: {
      get () {
        return parseStyles(this.repo.config.layout.headerStyle);
      },
      set (val) {
        const css = writeStyleString(val),
              config = {
                ...this.repo.config.layout,
                ...{headerStyle: css}
              };
        this.$store.dispatch('writeLayoutConfig', { config });
      },
    },
    
    editingComponent () {
      return {
        ...this.$store.state.activeComponent,
        ...this.$store.getters.activeComponent,
      };
    },
    
  },
  data () {
    return {
      
      boxLayoutSelected: false,
      
      showDrawer: false,
      
      options: {
        mediaUnitClipPath: clipPathChoices,
      },
      ...this.initialData,
    };
  },
  async mounted () {
    await this.$store.dispatch('fetchRepoContents');
    
    this.$refs['style-container'].innerHTML = '';
    let style = document.createElement('style');
    style.innerText = this.globalCss;
    this.$refs['style-container'].append(style);

    if (this.repo.pages['index']) {
      this.viewingPage = 'index';
    } else {
      this.viewingPage = Object.values(this.repo.pages)[0].key;
    }

    if (this.$refs['header']) {
      this.$refs['header'].$el.addEventListener('click', this.interceptLinks, true);
    }
    if (this.$refs['footer']) {
      this.$refs['footer'].$el.addEventListener('click', this.interceptLinks, true);
    }
  },
}
</script>

<style scoped>
.md-speed-dial .md-primary .md-button {
  border-radius: 0 80px 80px 0;
}
.md-speed-dial .md-primary {
  background: purple !important;
  opacity: .3;
}
.md-speed-dial:hover .md-primary {
  opacity: 1;
}
.md-speed-dial-content {
  align-items: flex-start;
}
.md-speed-dial:not(.md-active) .md-speed-dial-content {
  height: 0;
}
.md-speed-dial .md-speed-dial-content .md-button {
  width: auto;
  border-radius: 0 80px 80px 0;
  align-self: stretch;
}
.md-speed-dial .md-speed-dial-content .md-button >>> .md-ripple {
  justify-content: flex-start;
}
.md-speed-dial .md-speed-dial-content .md-button >>> .md-button-content {
  display: flex;
  align-items: center;
  color: white;
  padding-right: .5em;
}
.md-speed-dial [class^="ri-"],
.md-speed-dial [class*=" ri-"] {
  color: white;
  font-size: 2.5em;
}
.md-speed-dial .md-speed-dial-content .md-button [class^="ri-"],
.md-speed-dial .md-speed-dial-content .md-button [class*=" ri-"] {
  margin-right: .5em;
}
.md-speed-dial .md-speed-dial-content .md-button {
  background: purple !important;
}
.md-speed-dial .md-speed-dial-content .md-button:hover {
  background: #500550 !important;
}
.md-speed-dial .md-speed-dial-content [class^="ri-"],
.md-speed-dial .md-speed-dial-content [class*=" ri-"] {
  color: white;
  font-size: 1.5em;
}
.toolbar-button {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  padding: 10px 5px;
}
.offcanvas, .offcanvas .btn {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.offcanvas {
  z-index: 99999;
  width: 65rem !important;
}

label.gfield_label {
  color: black !important;
}
.hero-section {
  transition: opacity 0.8s ease;
    opacity: 1;
}
.hero-section.hero-section__fade {
    transition: opacity 0.3s ease;    
    opacity: 0;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.offcanvas-handle {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2rem;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-right: 2px dashed black;
  opacity: 0.3;
  transition: all .3s ease;
  cursor: pointer;
}
.offcanvas-handle:hover {
  opacity: 1;
  border-right: 2px solid black;
}
</style>
