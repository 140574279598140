<template>
<a class="nuxt-link" :href="to">
  <slot></slot>
</a>
</template>

<script>
export default {
  name: 'nuxt-link',
  props: ['to'],
}
</script>
