import { MediaUnit, imageMetadata } from './core.js';

export const image_columns = new MediaUnit(
  'image_columns', 'image_columns', 'image_gallery', {

    styles: () => ({
      'align-items': 'flex-start', 
      '--media-unit--media__width': '0%',
      'padding-top': '0', 'padding-bottom': '0',
    }),

    contentStyles: ({ spaceBetween }) => {
      let r = {
        'padding-left': '0',
        'padding-right': '0',
        'padding-top': '0',
        'padding-bottom': '0',
        'flex-wrap': 'wrap',
      };
      if (spaceBetween) {
        r = {
          ...r, 
          'margin-left': `-${spaceBetween}px`,
          'margin-right': `-${spaceBetween}px`,
          'margin-top': `-${spaceBetween}px`,
          'margin-bottom': `-${spaceBetween}px`,          
        };
      }
      return r;
    },

    mediaStyles: () => ({
      'display': 'none',
      '--media-unit--media__width': '0%',
      '--media-unit--media__aspect-ratio': '',
      '--image-width': '',
      '--image-height': '',
    }),

    template: ({ styles, contentStyles, mediaStyles, aspectRatio, perRow, spaceBetween, noFlexGrow, images, clickBehavior }) => {
      let content = (images || [])
        .map(({ image }) => image)
        .map(imageMetadata)
        .map(info => `https://res.cloudinary.com/pies/image/upload/ar_${aspectRatio},${info.piesExtra?.type === 'logo' ? 'c_pad' : 'c_fill,g_auto'}/${info.public_id}`);

      let extraCss = [];
      perRow = perRow || images.length;
      extraCss.push(`flex-basis: ${90 / parseInt(perRow)}%`);

      if (noFlexGrow) {
        extraCss.push('flex-grow: 0');
      }
      if (spaceBetween) {
        extraCss.push(`margin-top: ${spaceBetween}px`);
        extraCss.push(`margin-left: ${spaceBetween}px`);
        extraCss.push(`margin-bottom: ${spaceBetween}px`);        
        extraCss.push(`margin-right: ${spaceBetween}px`);
      }

      let wrapperOpen = () => '';
      let wrapperClose = () => '';
      if (clickBehavior === 'download') {
        wrapperOpen = (src) => `<a target="_blank" download href="https://res.cloudinary.com/pies/image/upload/${imageMetadata(src).public_id}">`;
        wrapperClose = () => '</a>';
      }
      
      extraCss = extraCss.join(';');      
      content = content
        .map((src, i) => `<div class="media-unit--content-wrapper" style="${extraCss}">${wrapperOpen(src, i)}<img style="width:100%" src="${src}">${wrapperClose(src, i)}</div>`)
        .join('\n');
      
      return `
<media-unit uuid="" 
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" content-class="has-wrapped-content direction-row">
  <template v-slot:default>
    ${content}
  </template>
</media-unit>`;
    },
});
