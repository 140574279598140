<template>
<dialog-layout>
  <div>
    <div class="media-unit-image-slider">
      <a href="#" @click.prevent="cycleActiveBlockImageObjectPosition">
        <ri :n="imageObjectPosition == 'center' ? 'focus-2-line' : `layout-${imageObjectPosition}-2-line`" />
      </a>
      <input type="range" min="0" max="100" step="1"
             v-model="activeBlockMediaWidth">
      <a href="#" @click.prevent="activeBlockHeightIsLocked = !activeBlockHeightIsLocked">
        <ri v-if="activeBlockHeightIsLocked" n="lock-line" />
        <ri v-else n="lock-unlock-line" />
      </a>

      <input type="range" min="0" max="100" step="1"
             v-model="activeBlockHeight">
    </div>      
  
    <button type="button" class="btn btn-secondary" @click="$emit('close', { props: { styles: writeStyleString(styles) }})">Close</button>
    
  </div>
</dialog-layout>
</template>

<script>
import $ from "cash-dom";
import { writeStyleString } from '../css-utils.js';

//const outerHtml = $dom => [...$dom].map(o => o.outerHTML || '').join('\n').trim();

export default {
  name: 'DialogMediaUnitManipulator',
  props: [
    'component',
    'uuid',
  ],
  methods: {
    writeStyleString: obj => writeStyleString(obj || {}),
    cycleActiveBlockImageObjectPosition () {
      this.imageObjectPosition = {
        top: 'right',
        right: 'bottom',
        bottom: 'left',
        left: 'center',
        center: 'top',
      }[this.imageObjectPosition];
    },
    updateStyles (key, val) {
      $(`[id="${this.uuid}"]`).css(key, val);
      this.styles[key] = val;
    },
    updateContentStyles (key, val) {
      $(`[id="${this.uuid}"] > div:not(.media-side)`).css(key, val);
      this.contentStyles[key] = val;
    },
  },
  watch: {
    activeBlockMediaWidth (val) {
      this.updateStyles('--media-unit--media__width', `${val}%`);
    },
    activeBlockHeight (val) {
      this.updateStyles('height', `${val}vh`);
    },
    activeBlockHeightIsLocked (val) {
      let el = $(`[id="${this.uuid}"]`);
      //let dom = $(this.html);
      //let current = dom.find(`[uuid="${this.$store.state.activeComponent.uuid}"]`),
      //    styles = parseStyles(current.attr('styles'));
      
      if (val) {
        const height = el.outerHeight(),
              _1vh = window.innerHeight / 100,
              heightInVh = height / _1vh;
        
        el.css('height', `${heightInVh}vh`);
        this.styles = {...this.styles, ...{'height': `${heightInVh}vh`}};
      } else {
        el.css('height', '');
        delete this.styles['height'];
      }
      
      //current.attr('styles', writeStyleString(styles));
      //this.$store.commit('updateActivePageContent', outerHtml(dom));
    },
    imageObjectPosition (val) {
      let el = $(`[id="${this.uuid}"]`);
      el.css('--image-object-position', val);
      this.styles['--image-object-position'] = val;
    },
  },
  data () {

    let width = this.component.styles['--media-unit--media__width'] || '50%';
    let match = width.match(/(?<width>\d+)%/);
    if (!match || !match.groups || !match.groups.width) return;
    width = match.groups.width;

    return {
      styles: {...this.component.styles},
      contentStyles: {...this.component.contentStyles},
      activeBlockHeightIsLocked: !!this.component.styles['height'],
      activeBlockMediaWidth: width,
      activeBlockHeight: this.component.styles['height'] || null,
      imageObjectPosition: this.component.styles['--image-object-position'],
    };
  },
}
</script>

<style>
.media-unit-image-slider {
  border-radius: 150px;
  top: 85vh;
  background: #eee;
  padding: 1rem;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 38%) !important;
}

.media-unit-image-slider a {
  text-decoration: none;
}
.media-unit-image-slider i {
  color: black;
}
</style>
