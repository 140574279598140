<template>
<dialog-layout ref="layout" class="media-unit-editor"
               :previewable="true"
               @unmask="$emit('unmask')"
               @remask="$emit('remask')"
               :key="uuid" :width=".96" :height=".9"
               >
  
  <dialog-close-button @close="maybeClose" />
  
  <md-app>
    <md-app-toolbar :data-md-toolbar-collapsed="!dirty">
      
      <div class="md-toolbar-row">
        <app-tabs :active-tab="activeTab"
                  :tabs="previewTabs" @tab-changed="activeTab = $event" />
      </div>
      
      <div class="md-toolbar-row" v-if="dirty">
        <div class="md-toolbar-section-start">
          <md-button class="md-accent" @click="discardChanges">
            Revert Changes
          </md-button>
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-primary" @click="saveComponent">
            {{ saving ? '&hellip;' : 'Save Changes' }}
          </md-button>
        </div>
      </div>
      
    </md-app-toolbar>
    
    <md-app-drawer v-show="subtabs[activeTab]" md-permanent="clipped">
      
      <md-list>
        <template v-for="tab in subtabs[activeTab]">
          <md-list-item v-if="tab.expands" md-expand :key="tab.name"> 
            <md-icon><ri :n="tab.icon" /></md-icon>
            <span class="md-list-item-text">{{ tab.label }}</span>
            
            <md-list slot="md-expand">
              <md-list-item v-for="subtab, index in tab.expands" class="md-inset" :key="index"
                            @click="activeSubtab = `${tab.name}-${subtab.name}`">
                <md-icon><ri :n="subtab.icon" /></md-icon>
                <span class="md-list-item-text">{{ subtab.label }}</span>
              </md-list-item>
            </md-list>
          </md-list-item>
          <template v-if="!tab.expands">
            <md-list-item v-if="tab.name !== 'wrapperStyles' || contentIsWrapped"
                          :key="tab.name" @click="activeSubtab = tab.name">
              <md-icon><ri :n="tab.icon" /></md-icon>
              <span class="md-list-item-text">{{ tab.label }}</span>
            </md-list-item>
          </template>
        </template>
        
        <template v-if="activeTab === 'tab-content'">
          
          <template v-if="contentIsFed">
            <md-list-item @click="activeSubtab = 'tiptap'">
              <md-icon><ri n="file-text-line" /></md-icon>
              <span class="md-list-item-text">Content Template</span>
            </md-list-item>
          </template>
          
          <template v-else-if="contentIsWrapped">
            <md-list-item md-expand>
              <md-icon><ri n="drag-move-2-line" /></md-icon>
              <span class="md-list-item-text">Spacing</span>
              
              <md-list slot="md-expand">
                <md-list-item v-for="subtab, index in htmls" class="md-inset" :key="index"
                              @click="activeHtmlIndex = index; activeSubtab = 'content-layout'">
                  <span class="md-list-item-text">Content {{index === 0 ? '' : '#' + (index+1)}}</span>
                </md-list-item>
              </md-list>
            </md-list-item>
            
            <md-list-item v-for="html, index in htmls" :key="index"
                          @click="activeHtmlIndex = index; activeSubtab = 'tiptap'">
              <md-icon><ri n="file-text-line" /></md-icon>
              <span class="md-list-item-text">Content {{index === 0 ? '' : '#' + (index+1)}}</span>
            </md-list-item>
          </template>
          <md-list-item v-else @click="activeSubtab = 'tiptap'">
            <md-icon><ri n="file-text-line" /></md-icon>
            <span class="md-list-item-text">Content</span>
          </md-list-item>

          <!--
          <md-list-item v-if="contentIsFed || contentIsWrapped">
            <md-switch v-model="useContentFeed">Use Feed</md-switch>
          </md-list-item>
          <md-list-item v-if="useContentFeed" @click="buildFeed">
            <md-icon><ri n="rss-line" /></md-icon>
            <span class="md-list-item-text">Configure Feed</span>
          </md-list-item>
          -->
          
        </template>
        
      </md-list>
    </md-app-drawer>
    
    
    <md-app-content v-if="activeTab === 'tab-mobile'">
      <div class="row">
        <div class="col">
          <div>
            
            <label>Image behavior:
              <select v-model="mobileClass">
                <option v-for="type, label in options.mobileClassChoices" :key="type" :value="type">{{label}}</option>
              </select>
            </label>
            
            <div>
              <label>
                Text size: <css-value-input v-model="styles['--theme--mobile--font__size']"
                                            :inherited="this.$store.getters.styles['--theme--mobile--font__size']" />
              </label>
            </div>
            <div>
              <label>
                Line height: <css-value-input v-model="styles['--theme--mobile--font__line-height']"
                                              :inherited="this.$store.getters.styles['--theme--mobile--font__line-height']" />
              </label>
            </div>
            <div>
              <label>
                Letter spacing: <css-value-input v-model="styles['--theme--mobile--font__letter-spacing']"
                                                 :inherited="this.$store.getters.styles['--theme--mobile--font__letter-spacing']" />
              </label>
            </div>
            
            <div>
              <label>
                Heading base text size:
                <css-value-input v-model="styles['--theme--mobile--font__size__heading']"
                                 :inherited="this.$store.getters.styles['--theme--mobile--font__size__heading'] || this.$store.getters.styles['--theme--font__size__heading'] || this.$store.getters.styles['--theme--font__size']" />
              </label>
            </div>
            <div>
              <label>
                Heading Line height:
                <css-value-input v-model="styles['--theme--font__line-height__heading']"
                                 :inherited="this.$store.getters.styles['--theme--font__line-height__heading']" />
              </label>
            </div>
            <div>
              <label>
                Heading Letter spacing:
                <css-value-input v-model="styles['--theme--font__letter-spacing__heading']"
                                 :inherited="this.$store.getters.styles['--theme--font__letter-spacing__heading']" />
              </label>
            </div>
            
            <div>
              <label>
                Button base text size:
                <css-value-input v-model="styles['--theme--mobile--font__size__button']"
                                 :inherited="this.$store.getters.styles['--theme--mobile--font__size__button']" />
              </label>
            </div>
            <div>
              <label>
                Button Line height:
                <css-value-input v-model="styles['--theme--mobile--font__line-height__button']"
                                 :inherited="this.$store.getters.styles['--theme--mobile--font__line-height__button']" />
              </label>
            </div>
            <div>
              <label>
                Button Letter spacing:
                <css-value-input v-model="styles['--theme--mobile--font__letter-spacing__button']"
                                 :inherited="this.$store.getters.styles['--theme--mobile--font__letter-spacing__button']" />
              </label>
            </div>
            
          </div>
        </div>
        <div class="col-4 text-center">
          <iframe style="min-height: 800px; border: 1px solid black; margin: 0 auto;"
                  class="shadow-lg" width="400" :srcdoc="iframeSrc"></iframe>
        </div>
      </div>
    </md-app-content>
    
    <md-app-content v-if="activeTab === 'tab-wrapper'">
      
      <template v-if="contentIsWrapped">
        
        <select v-model="contentStyles['flex-direction']">
          <option value=""></option>
          <option value="row">Arrange in Columns</option>
          <option value="column">Stack Vertically</option>
          <option value="row-reverse">Arrange in Columns, Reversed</option>
          <option value="columns-reverse">Stack Vertically, Reversed</option>
        </select>
        
        <div v-for="html, index in htmls" :key="index" class="background-overlay-editor"
             @click="activeHtmlIndex = index"
             :style="index === activeHtmlIndex ? 'background:lightyellow' : ''">
          Section #{{index+1}}: <i>{{ excerpt(html) }}</i>
          
          <div class="btn-row controls">
            <button v-if="index !== 0"
                    @click.stop="moveContentWrapperUp(index)" class="btn"><ri n="arrow-up-line" /></button>
            <button v-if="index !== htmls.length - 1"
                    @click.stop="moveContentWrapperDown(index)" class="btn"><ri n="arrow-down-line" /></button>
            
            <button @click.stop="removeContentWrapper(index)" class="btn"><ri n="delete-bin-line" /></button>
          </div>
        </div>
        
        <button @click="addContentWrapper" class="btn"><ri n="add-line" /></button>
      </template>
      
    </md-app-content>
    
    <md-app-content v-if="activeTab === 'tab-structured-content'">
      
      <div v-if="activeSubtab === 'content'">
      </div>
    </md-app-content>
    
    <md-app-content v-if="activeTab === 'preview'"
                    :style="`--preview-background: ${contentStyles['background-color'] || styles['background-color'] || contentStyles['background'] || styles['background'] || '#eee'}`"
                    >      
      <div style="min-height: 1200px">
        <iframe style="width: 100%; min-height: 800px; border: 1px solid black; margin: 0 auto; visibility: hidden"
                class="shadow-lg" @load="$event.target.style['visibility'] = 'visible'"
                :srcdoc="iframeSrc"></iframe>
      </div>
    </md-app-content>
    
    <md-app-content v-if="activeTab === 'tab-content'"
                    :style="`--preview-background: ${contentStyles['background-color'] || styles['background-color'] || contentStyles['background'] || styles['background'] || '#eee'}`"
                    >      
      <div v-if="activeSubtab === 'content-layout'" :key="`content-layout-${activeHtmlIndex}`">
        <label>
          Background:
          <color-picker
            expand-to="bottom-left"
            key="contentWrapperBackground"
            v-model="contentWrapperStyles[activeHtmlIndex]['background']"
            fallback="rgba(0,0,0,0)" />
          
        </label>
        
        <label>
          Horizontal alignment:
          <select v-model="contentWrapperStyles[activeHtmlIndex]['align-self']">
            <option value=""></option>
            <option value="center">Centered</option>
            <option value="flex-start">Left</option>
            <option value="flex-end">Right</option>
            <option value="stretch">Full width</option>
          </select>
        </label>
        <label>
          Height:
          <select v-model="contentWrapperStyles[activeHtmlIndex]['flex-basis']">
            <option value="">Auto</option>
            <option value="100%">Full height</option>
          </select>
        </label>
        
        <label>
          Shadow:
          <select v-model="contentWrapperStyles[activeHtmlIndex]['box-shadow']">
            <option value="">Auto</option>
            <option v-for="{ value, label } in elevations" :key="value" :value="value">{{ label }}</option>
          </select>
        </label>
        
        <select v-model="contentWrapperStyles[activeHtmlIndex]['display']">
          <option></option>
          <option value="flex">flex</option>
          <option value="block">block</option>
        </select>
        <select v-if="contentWrapperStyles[activeHtmlIndex]['display'] === 'flex'" v-model="contentWrapperStyles[activeHtmlIndex]['align-items']">
          <option></option>
          <option value="center">center</option>
          <option value="flex-start">flex-start</option>
          <option value="flex-end">flex-end</option>      
        </select>
        <select v-if="contentWrapperStyles[activeHtmlIndex]['display'] === 'flex'" v-model="contentWrapperStyles[activeHtmlIndex]['justify-content']">
          <option></option>
          <option value="center">center</option>
          <option value="space-around">space-around</option>
          <option value="space-between">space-between</option>      
        </select>
        <select v-if="contentWrapperStyles[activeHtmlIndex]['display'] === 'flex'" v-model="contentWrapperStyles[activeHtmlIndex]['flex-direction']">
          <option></option>
          <option value="row">row</option>
          <option value="column">column</option>
          <option value="row-reverse">row-reverse</option>
          <option value="column-reverse">column-reverse</option>
        </select>
        <select v-if="contentWrapperStyles[activeHtmlIndex]['display'] === 'flex'" v-model="contentWrapperStyles[activeHtmlIndex]['flex-wrap']">
          <option></option>
          <option value="wrap">wrap</option>
          <option value="nowrap">nowrap</option>
          <option value="wrap-reverse">wrap-reverse</option>
        </select>
        
        <box-layout :value="getBoxLayout(contentWrapperStyles[activeHtmlIndex])"
                    @input="updateContentWrapperBoxLayout" :layout-only="true" />
      </div>
      
      <div v-if="activeSubtab === 'wrapperStyles'" key="content-wrapperStyles'">
        <div class="wrapper-previews">
          
          <div v-for="alignmentClass in [null, 'stretch-apart', 'stretch-centered', 'fit-centered']"
               :key="alignmentClass"
               class="wrapper-preview has-wrapped-content"
               @click="setContentClass('alignment', alignmentClass)"
               :class="`${contentClass.filter(o => o.startsWith('direction-') || o.startsWith('display-')).join(' ')} alignment-${alignmentClass} ${contentClass.indexOf('alignment-' + alignmentClass) !== -1 ? 'active' : ''}`">
            <div v-for="html, index in htmls" :key="index" class="media-unit--content-wrapper">
              <p>
                Lorem ipsum dolor sit amet.
              </p>
              <img src="https://placekitten.com/75/40">            
              <p v-if="index > 0">
                Consectetur adipiscing elit, sed do eiusmod tempor.
              </p>
              <p v-if="index > 1">
                Lorem ipsum dolor sit amet.
              </p>            
            </div>
          </div>
        </div>
        
      </div>
      
      <div v-if="activeSubtab === 'wrapper'" key="content-wrapper'">
        <div class="wrapper-previews">
          <div :class="contentIsWrapped && htmls.length === 1 && contentClass.indexOf('direction-column') === -1 ? 'active' : ''"
               @click="setWrapper(1)" class="wrapper-preview wrapper-preview-flex">
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
          </div>
          <div :class="contentIsWrapped && htmls.length === 2 && contentClass.indexOf('direction-column') === -1 ? 'active' : ''"
               @click="setWrapper(2)" class="wrapper-preview wrapper-preview-flex">
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
          </div>
          <div :class="contentIsWrapped && htmls.length === 3 && contentClass.indexOf('direction-column') === -1 ? 'active' : ''"
               @click="setWrapper(3)" class="wrapper-preview wrapper-preview-flex">
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
          </div>
          <div :class="contentIsWrapped && htmls.length === 4 && contentClass.indexOf('direction-column') === -1 ? 'active' : ''"
               @click="setWrapper(4, 'row', 'first-child-fill')"
               class="wrapper-preview wrapper-preview-flex display-first-child-fill">
            <div>
              <p><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</b></p>
            </div>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
          </div> 
          <div :class="contentIsWrapped && htmls.length === 2 && contentClass.indexOf('direction-column') !== -1 ? 'active' : ''"
               @click="setWrapper(2, 'column')" class="wrapper-preview wrapper-preview-flex direction-column">
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
          </div>
          
          <div :class="contentIsWrapped && htmls.length === 3 && contentClass.indexOf('direction-column') !== -1 ? 'active' : ''"
               @click="setWrapper(3, 'column')" class="wrapper-preview wrapper-preview-flex direction-column">
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>            
            </div>
          </div>
          
          <div :class="!contentIsWrapped ? 'active' : ''"
               @click="setWrapper(0)" class="wrapper-preview wrapper-preview-flex">
            <div style="background:transparent;background: transparent;display:flex;align-items:center;justify-content:center;flex-direction:column;color:white;">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div>
          </div>
          
        </div>
        
        
      </div>
      <div v-if="activeSubtab === 'tiptap'" key="content-tiptap">
        <code-editor v-if="contentIsFed"
                     :merge-vars="mergeVars" :value="htmls[activeHtmlIndex]" @input="updateHtml"></code-editor>
        <tiptap v-else :merge-vars="mergeVars" :value="htmls[activeHtmlIndex]" @input="updateHtml"></tiptap>
      </div>
      
      <div v-if="activeSubtab === 'image'" class="row">
        <div class="col">
          <div>
            <label>Video:
              <input v-model="video" />
            </label>

            <label>Shape:
              <select v-model="clipPath">
                <option v-for="type, label in options.clipPathChoices" :key="label" :value="type">
                  {{label}}
                </option>
              </select>
            </label>
          </div>
          <div>
            <label>Image anchor:
              <select v-model="styles['--image-object-position']">
                <option v-for="type, label in options.imagePositionChoices" :key="type" :value="type">
                  {{label}}
                </option>
              </select>
            </label>
          </div>
          
        </div>
        <div class="col-4">
          <multi-image-input :image="image" @input="updateImage" />
        </div>
      </div>
      
      <div v-if="activeSubtab === 'background'" key="content-background">

        <label>
          Background color:
          
          <color-picker key="backgroundColor"
                        v-model="backgroundColor"
                        expand-to="bottom-left"
                        fallback="rgba(0,0,0,0)" />
        </label>

        <label>Overlays:</label>
        
        <div class="background-overlay-editor"
             v-for="background, index in backgrounds" :key="index">
          
          Opacity: <vue-material-slider :min="0" :max="1" :step-size=".01" v-model="background.opacity" />
          
          <md-field v-if="background.type === 'video'">
            <md-input type="text" v-model="background.video" />
          </md-field>
          
          <div v-else-if="background.type === 'image'">
            <multi-image-input :image="background.image"
                               @input="background.image = $event" />
            <select v-model="background.position">
              <option>top</option>
              <option>left</option>
              <option>right</option>
              <option>bottom</option>
              <option>center</option>
            </select>
            
            <select v-model="background.size">
              <option>cover</option>
              <option>contain</option>
            </select>        
            
            <select v-model="background.repeat">
              <option>no-repeat</option>
              <option>repeat</option>
            </select>
            
            <select v-model="background.attachment">
              <option></option>
              <option>fixed</option>
            </select>
            
          </div>
          <textarea v-else-if="background.type === 'gradient'" :value="JSON.stringify(background)"></textarea>
          <color-picker key="background.type === 'color'" v-else-if="background.color" v-model="background.color"/>
          
          <div v-else>
          </div>
          
          <div class="btn-row controls">
            <button v-if="index !== 0"
                    @click="moveBackgroundDeeper(index)" class="btn"><ri n="arrow-up-line" /></button>
            <button v-if="index !== backgrounds.length - 1"
                    @click="moveBackgroundCloser(index)" class="btn"><ri n="arrow-down-line" /></button>
            <button @click="removeBackground(index)" class="btn"><ri n="delete-bin-line" /></button>
          </div>
        </div>
        <div class="btn-row controls">
          <select class="btn" ref="background-type-select">
            <option></option>
            <option>image</option>
            <option>video</option>        
            <option>gradient</option>
            <option>color</option>
          </select>
          
          <button @click="addBackground" class="btn"><ri n="add-line" /></button>
      </div>

        
      </div>
    </md-app-content>
    
    <md-app-content v-if="activeTab === 'tab-design'">
      
      <div v-if="activeSubtab === 'preview'" key="preview'" style="min-height: 1200px">
        <iframe style="width: 100%; min-height: 800px; border: 1px solid black; margin: 0 auto; visibility: hidden"
                class="shadow-lg" @load="$event.target.style['visibility'] = 'visible'"
                :srcdoc="iframeSrc"></iframe>
      </div>

      <div v-if="activeSubtab === 'layout'">
        <box-layout :value="boxLayout" @input="updateLayout" :layout-only="true" />
      </div>
    </md-app-content>
    
  </md-app>
  
</dialog-layout>
</template>

<script>
import { getBoxLayout, updateBoxLayout } from '../../box-layout-utils.js';
import { writeStyleString, parseStyles } from '../../css-utils.js';

import Tiptap from '../Tiptap.vue';
import CodeEditor from '../CodeEditor.vue';
import MultiImageInput from '../MultiImageInput.vue';
import BoxLayout from '../BoxLayout.vue';
import CssValueInput from '../inputs/CssValueInput.vue';
import ColorPicker from '../ColorPicker.vue';
import FeedBuilder from './FeedBuilder.vue';
import Vue from 'vue';
import { openDialog } from '../../modal-dialog.js';

import $ from 'cash-dom';

const outerHtml = $dom => [...$dom].map(o => o.outerHTML || '').join('\n').trim();
const writeStyles = styles => writeStyleString(styles, true, true);

const parseFont = (family) => {
  if (!family) {
    return family;
  }
  return family.replace(/"/g, '');
};

export default {
  name: 'MediaUnitEditor',
  
  props: ['initialTab', 'initialSubtab', 'onlyShowTab'],
  
  components: {
    Tiptap,
    CodeEditor,
    MultiImageInput,
    BoxLayout,
    CssValueInput,
    ColorPicker,
  },
  
  methods: {
    
    async maybeClose () {
      if (this.dirty) {
        const result = await this.$swal.fire({
          title: "You have unsaved changes.",
          text: "Before you go on, do you want to save your changes, revert them, or cancel & stay on this page?",
          icon: 'warning',
          showConfirmButton: true,
          showDenyButton: true,
          showCancelButton: true,
          buttonsStyling: false,
          reverseButtons: true,
          confirmButtonText: 'Save',
          denyButtonText: 'Revert',
          cancelButtonText: 'Stay',
          customClass: {
            confirmButton: 'md-button md-primary md-theme-default',
            denyButton: 'md-button md-accent md-theme-default',
            cancelButton: 'md-button md-theme-default',
          },
        });
        if (result.isDismissed) {
          return;
        }
        if (result.isDenied) {
          await this.discardChanges();
        }
        if (result.isConfirmed) {
          await this.saveComponent();
        }
      }
      this.$emit('close');
    },
    
    async buildFeed () {
      const { uuid, config } = await openDialog(FeedBuilder, {
        page: this.$store.state.activePageKey,
        initial: this.contentIsFed,
      });
      this.contentIsWrapped = true;
      
      const columns = Object.keys(this.$store.getters.feeds[uuid][0]);
      this.contentIsFed = {
        uuid,
        config,
        columns,
      };
      this.htmls = [ this.htmls[0] ];
    },
    
    writeStyles (styles, opts) {
      if (opts?.withFonts) {
        styles = {...styles};
        
        const globalAccentFont = parseFont(this.$store.getters.styles['--theme--font__heading']) || '',
              globalTextFont = parseFont(this.$store.getters.styles['--theme--font__text']) || '',
              globalButtonFont = parseFont(this.$store.getters.styles['--theme--font__button']) || '';
        if (globalAccentFont !== (this.accentFont || '')) {
          styles['--theme--font__heading'] = this.accentFont ? `"${this.accentFont}"` : '';
        }
        if (globalButtonFont !== (this.buttonFont || '')) {
          styles['--theme--font__button'] = this.buttonFont ? `"${this.buttonFont}"` : '';
        }
        if (globalTextFont !== (this.textFont || '')) {
          styles['--theme--font__text'] = this.textFont ? `"${this.textFont}"` : '';
        }
      }
      return writeStyles(styles);
    },
        
    iframeWrapper (content) {
      let allFonts = [
        this.accentFont,
        this.buttonFont,
        this.textFont,
      ].filter(o => o)
          .map(o => ({ family: o }));
      allFonts = [...allFonts, ...this.$store.state.repo.config.fonts];
      const fonts = allFonts
            .filter(o => o && o.family)
            .map(({ family }) => `https://fonts.googleapis.com/css2?family=${family}:wght@400;700&display=swap`)
            .map(href => `<link rel='stylesheet' type='text/css' href="${href}" />`)
            .join('\n');
      
      return `
<html><head>
  ${fonts}
  <my-script type="text/javascript">
    window.onload = function() {    
      if (parent) {        
        var oHead = document.getElementsByTagName("head")[0];        
        var arrStyleSheets = parent.document.getElementsByTagName("style");        
        for (var i = 0; i < arrStyleSheets.length; i++)            
          oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
        var arrStyleSheets = parent.document.getElementsByTagName("link");        
        for (var i = 0; i < arrStyleSheets.length; i++) {           
          if (arrStyleSheets[i].rel !== 'stylesheet' && arrStyleSheets[i].type !== 'text/css') continue;
          if (arrStyleSheets[i].href.endsWith('.css')) {
            oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
          }
        }
      }
    }
  </my-script>
</head>
<body>
  <div class="md-theme-default" style='${this.$store.state.repo.config.layout.style}'>
${content}
</div>
</body></html>`.replace(/my-script/g, "script");
      
    },
    
    setContentClass (category, suffix) {
      if (!suffix) {
        this.contentClass = this.contentClass.filter(o => !o.startsWith(category));
      } else {
        this.contentClass = [
          ...this.contentClass.filter(o => !o.startsWith(category)),
          `${category}-${suffix}`,
        ];
      }
      if (category === 'direction' || category === 'alignment' || category === 'display') {
        if (this.contentClass.indexOf('has-wrapped-content') === -1) {
          this.contentClass.push('has-wrapped-content');
        }
      }
      this.blockAdvancedMode = this.blockAdvancedMode || true;      
    },
    
    setWrapperStyles (styles) {
      this.contentWrapperStyles = this.htmls.map(() => ({...styles}));
      if (this.contentClass.indexOf('has-wrapped-content') === -1) {
        this.contentClass.push('has-wrapped-content');
      }
      this.blockAdvancedMode = this.blockAdvancedMode || true;
    },
    
    setWrapper (num, direction, extraContentClass) {
      if (this.contentIsFed) { 
        num = 1;
      }
      
      if (num === 0) {
        this.contentIsWrapped = false;
        while (this.htmls.length > 1) {
          this.removeContentWrapper(this.htmls.length - 1);
          this.contentClass = this.contentClass.filter(o => o !== 'has-wrapped-content');
          this.setContentClass('direction', null);
          this.setContentClass('display', null);
          this.setContentClass('alignment', null);
        }
        return;
      } 
      
      this.contentIsWrapped = true;
      if (this.contentClass.indexOf('has-wrapped-content') === -1) {
        this.contentClass.push('has-wrapped-content');
      }
      direction = direction || 'row';
      this.setContentClass('direction', direction);
      
      this.setContentClass('display', extraContentClass);
      
      while (this.htmls.length < num) {
        this.addContentWrapper();
      }
      while (this.htmls.length > num) {
        this.removeContentWrapper(this.htmls.length - 1);
      }
      this.blockAdvancedMode = this.blockAdvancedMode || true;
      this.compilePreview();
    },
    
    excerpt (html) {
      return $(html).eq(0).text();
    },
    
    getBoxLayout: val => getBoxLayout(val),
    
    maskHovered () {
      this.$refs.layout.maskHovered();
    },
    
    mount () {
      this.mounted = false;

      this.blockAdvancedMode = this.component.state.props['data-block-advanced-mode'] || false;
      this.image = this.component.state.props.image;
      this.video = this.component.state.props.video;
      this.mobileClass = this.component.state.props['class'];
      
      this.clipPath = this.component.getters.mediaStyles['clip-path'];
      this.flexDirection = this.component.getters.styles['flex-direction'];
      this.mediaWidth = this.component.getters.mediaStyles['flex-basis'];
      this.mediaZIndex = this.component.getters.mediaStyles['z-index'];
      
      this.contentStyles = {...this.component.getters.contentStyles};
      this.contentClass = [...this.component.getters.contentClass];
      
      this.backgroundColor = this.component.getters.styles['background-color'] || this.backgroundColor;
      this.textColor = this.component.getters.styles['color'] || this.textColor;
      this.backgrounds = [...this.component.getters.backgrounds].map(o => ({
        ...o,
        type: o.type ? o.type : o.video ? 'video' : o.image ? 'image' : o.gradient ? 'gradient' : o.color ? 'color' : '',
      }));
      
      this.accentFont = parseFont(this.component.getters.styles['--theme--font__heading']) || this.accentFont;
      this.textFont = parseFont(this.component.getters.styles['--theme--font__text']) || this.textFont;
      this.buttonFont = parseFont(this.component.getters.styles['--theme--font__button']) || this.buttonFont;
      
      this.boxLayout = getBoxLayout(this.component.getters.styles);
      
      this.contentIsWrapped = this.component.getters.contentIsWrapped;
      this.contentIsFed = this.component.getters.contentIsFed ? JSON.parse(JSON.stringify(this.component.getters.contentIsFed)) : null;
      this.useContentFeed = this.contentIsFed ? true : false;
      
      if (this.contentIsWrapped) {
        this.contentWrapperStyles = [...this.component.getters.contentWrapperStyles];
        this.htmls = [...this.component.getters.contentTemplates];
      } else {
        this.contentWrapperStyles = [{}];
        this.htmls = [this.componentHtml];
      }
      
      this.activeHtmlIndex = 0;
      
      this.styles = {...this.component.getters.styles};
      
      this.$nextTick(() => {
        this.mounted = true;
        this.compiledPreview = outerHtml($(`[id="${this.uuid}"]`));
      });
    },
    
    discardChanges () {
      if (!this.uuid) return;
      this.mount();
      
      let pageHtml = this.$store.getters.activePage.html,
          dom = $(pageHtml),
          component = dom.find(`[uuid="${this.uuid}"]`),
          code = outerHtml(component);
      
      const feeds = this.$store.getters.feeds;
      
      let replacementHtml = $((new Vue({
        render: Vue.compile(code).render,
        data () {
          return {
            feeds,
          };
        },
      })).$mount().$el.outerHTML);
      
      $(`[id="${this.uuid}"]`).replaceWith(replacementHtml);
      this.dirty = false;      
    },
    
    generateComponentCode () {
      if (!this.uuid) return;
      let pageHtml = this.$store.getters.activePage.html,
          dom = $(pageHtml),
          component = dom.find(`[uuid="${this.uuid}"]`);
      
      let styles = parseStyles(updateBoxLayout(this.boxLayout, this.styles));
      
      styles['background-color'] = this.backgroundColor;
      styles['color'] = this.textColor;
      styles['flex-direction'] = this.flexDirection;
      
      let mediaStyles = {...this.component.getters.mediaStyles};
      mediaStyles['clip-path'] = this.clipPath;
      mediaStyles['max-width'] = mediaStyles['min-width'] = mediaStyles['flex-basis'] = this.mediaWidth;
      
      const globalAccentFont = parseFont(this.$store.getters.styles['--theme--font__heading']) || '',
            globalTextFont = parseFont(this.$store.getters.styles['--theme--font__text']) || '',
            globalButtonFont = parseFont(this.$store.getters.styles['--theme--font__button']) || '';
      if (globalAccentFont !== (this.accentFont || '')) {
        styles['--theme--font__heading'] = this.accentFont ? `"${this.accentFont}"` : '';
      }
      if (globalButtonFont !== (this.buttonFont || '')) {
        styles['--theme--font__button'] = this.buttonFont ? `"${this.buttonFont}"` : '';
      }
      if (globalTextFont !== (this.textFont || '')) {
        styles['--theme--font__text'] = this.textFont ? `"${this.textFont}"` : '';
      }
      
      const firstImage = (this.image || '').split(/, /g)[0];
      if (firstImage) {
        const metadata = this.$store.state.repo.images[firstImage.split('/').slice(-2).join(':')];
        if (metadata) {
          let width = metadata.width,
              height = metadata.height;
          
          const components = firstImage.split('/').map(o => o.split(',')),
                dimensions = components.filter(o => o[0] === '$v_1')[0];
          
          if (dimensions) {
            let adjustedWidth = dimensions.map(o => o.match(/^w_(?<width>\d+)/)?.groups?.width).filter(o => o)[0],
                adjustedHeight = dimensions.map(o => o.match(/^h_(?<height>\d+)/)?.groups?.height).filter(o => o)[0];
            
            if (adjustedWidth && adjustedHeight) {
              width = adjustedWidth;
              height = adjustedHeight;
            }
          }
          
          styles['--media-unit--media__aspect-ratio'] = `${height / width * 100}%`;
          styles['--image-width'] = width;
          styles['--image-height'] = height; 
        }
      }
      

      component
        .attr('styles', writeStyles(styles))
        .attr('media-styles', writeStyles(mediaStyles))
        .attr('content-styles', writeStyles(this.contentStyles))
        .attr('content-class', this.contentClass.join(' '))
        .attr('image', this.image)
        .attr('video', this.video)
        .attr('class', this.mobileClass);

      if (this.blockAdvancedMode) {
        component.attr('data-block-advanced-mode', this.blockAdvancedMode);
      }

      let contentSlot = [
        ...dom.find(`[uuid="${this.uuid}"] template`)
      ].filter(o => o.hasAttribute('v-slot:default'))[0] || dom.find(`[uuid="${this.uuid}"]`)[0];

      if (this.contentIsWrapped) {
        let vFor = '';
        if (this.contentIsFed) {
          vFor = ` v-for="entry in feeds['${this.contentIsFed.uuid}']"`;
        }
        
        let innerHtml = [];
        for (let i = 0; i < this.htmls.length; ++i) {
          innerHtml.push(`<div${vFor} class='media-unit--content-wrapper' style="${writeStyles(this.contentWrapperStyles[i])}">${this.htmls[i]}</div>`);
        }
        contentSlot.innerHTML = innerHtml.join('\n');
      } else {
        contentSlot.innerHTML = this.htmls[0];
      }

      let backgrounds = this.backgrounds.map(props => (
        Object.entries(props).map(([k, v]) => `${k}="${v}"`).join(' ')
      )).map(propString => `<background ${propString}></background>`).join('\n\t');
      let backgroundSlot = [
        ...dom.find(`[uuid="${this.uuid}"] template`)
      ].filter(o => o.hasAttribute('v-slot:backgrounds'))[0];
      if (backgroundSlot) {
        backgroundSlot.remove();
      }
      let backgroundsCode = $(`<template v-slot:backgrounds></template>`);
      backgroundsCode[0].innerHTML = backgrounds;
      dom.find(`[uuid="${this.uuid}"]`).prepend(backgroundsCode);

      return outerHtml(component);
    },

    compilePreview () {
      if (!this.mounted) return;
      let code = this.generateComponentCode();
      const feeds = this.$store.getters.feeds;
      
      let replacementHtml = $((new Vue({
        render: Vue.compile(code).render,
        data () {
          return {
            feeds,
          };
        },
      })).$mount().$el.outerHTML);

      $(`[id="${this.uuid}"]`).replaceWith(replacementHtml);
      this.compiledPreview = outerHtml(replacementHtml);
      
      this.dirty = true;
    },
    
    removeBackground (idx) {
      this.backgrounds.splice(idx, 1);
    },
    moveBackgroundDeeper (idx) {
      let bg = this.backgrounds.splice(idx, 1);
      this.backgrounds.splice(idx - 1, 0, bg[0]);
    },
    moveBackgroundCloser (idx) {
      let bg = this.backgrounds.splice(idx, 1);
      this.backgrounds.splice(idx + 1, 0, bg[0]);
      this.backgrounds = [...this.backgrounds];
    },
    
    addBackground () {
      const type = this.$refs['background-type-select'].value;
      if (!type) return;

      let background = {
        opacity: 1,
        type,
      };
      
      if (type === 'image') {
        background.image = 'https://via.placeholder.com/800x800';
        background.size = 'cover';
        background.repeat = 'no-repeat';
        background.position = 'top';
        background.attachment = '';
      } else if (type === 'video') {
        background.video = 'https://www.youtube.com/watch?v=uCIsBFV87-U';
      } else if (type === 'gradient') {
        background.gradient = 'linear';
      } else if (type === 'color') {
        background.color = 'white';
      }
      
      this.backgrounds.push(background);
    },

    addContentWrapper () {
      let clonedStyles = {...this.contentWrapperStyles[this.activeHtmlIndex]};
      this.contentWrapperStyles.push(clonedStyles);
      this.htmls.push('<p>Your Content Here</p>');
      this.activeHtmlIndex = this.htmls.length - 1;
    },
    removeContentWrapper (idx) {
      this.contentWrapperStyles.splice(idx, 1);
      let removing = this.htmls.splice(idx, 1)[0];
      if (removing.trim() === '<p>Your Content Here</p>') removing = '';
      this.htmls[this.htmls.length - 1] += removing;
      this.activeHtmlIndex = this.htmls.length - 1;
    },
    moveContentWrapperUp (idx) {
      let styles = this.contentWrapperStyles.splice(idx, 1);
      this.contentWrapperStyles.splice(idx - 1, 0, styles[0]);
      let html = this.htmls.splice(idx, 1);
      this.htmls.splice(idx - 1, 0, html[0]);
      this.activeHtmlIndex = idx - 1;
    },
    moveContentWrapperDown (idx) {
      let styles = this.contentWrapperStyles.splice(idx, 1);
      this.contentWrapperStyles.splice(idx + 1, 0, styles[0]);
      this.contentWrapperStyles = [...this.contentWrapperStyles];
      let html = this.htmls.splice(idx, 1);
      this.htmls.splice(idx + 1, 0, html[0]);
      this.htmls = [...this.htmls];
      this.activeHtmlIndex = idx + 1;
    },
    

    updateContentWrapperBoxLayout (box) {
      this.contentWrapperStyles[this.activeHtmlIndex] = parseStyles(updateBoxLayout(
        box, this.contentWrapperStyles[this.activeHtmlIndex]
      ));
      this.compilePreview();
    },
    
    updateLayout (box) {
      this.boxLayout = getBoxLayout(parseStyles(updateBoxLayout(box, this.component.getters.styles)));
    },
    updateImage (val) {
      this.image = val;
    },
    updateHtml (val) {
      this.htmls[this.activeHtmlIndex] = val;
      this.blockAdvancedMode = this.blockAdvancedMode || true;
      this.compilePreview();
    },

    async saveComponent () {
      if (this.saving) return;
      
      let code = this.generateComponentCode();

      let pageHtml = this.$store.getters.activePage.html,
          dom = $(pageHtml);
      dom.find(`[uuid="${this.uuid}"]`).replaceWith(code);

      this.saving = true;
      await this.$store.dispatch('immediatelyWritePageContent', {
        key: this.$store.state.activePageKey,
        html: outerHtml(dom)
      });

      let registeredFonts = [...this.$store.state.repo.config.fonts],
          fontsDirty = false;
      
      if (registeredFonts.map(o => parseFont(o.family)).indexOf(this.accentFont) === -1) {
        registeredFonts.push({ source: 'google', family: this.accentFont });
        fontsDirty = true;
      }
      if (registeredFonts.map(o => parseFont(o.family)).indexOf(this.textFont) === -1) {
        registeredFonts.push({ source: 'google', family: this.textFont });
        fontsDirty = true;
      }
      if (registeredFonts.map(o => parseFont(o.family)).indexOf(this.buttonFont) === -1) {
        registeredFonts.push({ source: 'google', family: this.buttonFont });
        fontsDirty = true;
      }
      if (fontsDirty) {
        await this.$store.dispatch('updateConfigs', { fonts: registeredFonts });
      }
      
      this.saving = false;
      this.dirty = false;
    },
  },

  watch: {

    async useContentFeed () {
      if (!this.useContentFeed) {
        this.contentIsFed = null;
      } else {
        await this.buildFeed();
      }
    },
    
    contentClass: {
      deep: true,
      handler () {
        this.compilePreview();
      },
    },
    
    contentStyles: {
      deep: true,
      handler () {
        this.compilePreview();
      },
    },
    boxLayout: {
      deep: true,
      handler () {
        this.compilePreview();
      },
    },
    contentWrapperBoxLayout: {
      deep: true,
      handler () {
        this.compilePreview();
      },
    },
    contentWrapperStyles: {
      deep: true,
      handler () {
        this.compilePreview();
      },
    },
    styles: {
      deep: true,
      handler () {
        this.compilePreview();
      },
    },
    htmls: {
      deep: true,
      handler () {
        this.compilePreview();
      },
    },
    image () {
      this.compilePreview();
    },
    mobileClass () {
      this.compilePreview();
    },
    clipPath () {
      this.compilePreview();
    },
    video () {
      this.compilePreview();
    },
    flexDirection () {
      this.compilePreview();
    },
    mediaWidth () {
      this.compilePreview();
    },
    mediaZIndex () {
      this.compilePreview();
    },
    backgrounds: {
      deep: true,
      handler () {
        this.compilePreview();
      },
    },
    accentFont () {
      this.compilePreview();
    },
    buttonFont () {
      this.compilePreview();
    },
    textFont () {
      this.compilePreview();
    },
        
    backgroundColor () {
      this.compilePreview();
    },
    textColor () {
      this.compilePreview();
    },
    componentImage () {
      this.image = this.componentImage;
    },
  },
  
  computed: {

    mergeVars () {
      if (!this.contentIsFed || !this.contentIsFed.columns) {
        return [];
      }

      return this.contentIsFed.columns.map(o => {
        const type = this.contentIsFed.config.dir;
        return {
          label: `${type}: ${o}`,
          code: `{{ entry.${o} }}`,
        };
      });
    },
    
    blockType () {
      return this.component.state.props['data-block-type'];
    },

    blockArgs () {
      try {
        return JSON.parse(this.component.state.props['data-block-args']);
      } catch(err) {
        return {};
      }
    },

    iframeSrc () {
      return this.iframeWrapper(this.compiledPreview);
    },
    
    tiptapProps () {
      return {
        value: this.html,
      };
    },

    uuid () {
      return this.component.state.uuid;
    },
    
    componentHtml () {
      return this.component.getters.contentTemplate;
    },
    componentImage () {
      return this.component.state.props.image;
    },
    
    component () {
      return {
        state: this.$store.state.activeComponent,
        getters: this.$store.getters.activeComponent,
      };
    },
  },

  data () {
    return {

      elevations: [
        {label: "", value: ''},        
        {label: "None", value: 'none'},
        {label: "Bootstrap - Small", value: '0 .125rem .25rem rgba(0,0,0,.075)'},
        {label: "Bootstrap - Medium", value: '0 0.5rem 1rem rgb(0 0 0 / 15%)'},
        {label: "Bootstrap - Large", value: '0 1rem 3rem rgb(0 0 0 / 18%)'},
        {label: "Shallow", value: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'},
        {label: "Medium", value: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'},
        {label: "Raised", value: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)'},
        {label: "Extra raised", value: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)'},
        {label: 'From bottom right', value: 'black 5px 5px 5px 0px'},
      ],
      
      blockAdvancedMode: false,

      previewTabs: [
        { id: 'tab-content', icon: 'settings-5-line', label: 'Content' },
        { id: 'preview', icon: 'eye-line', label: 'Preview' },
      ],
      
      subtabs: {
        'tab-structured-content': [
          { name: 'content', icon: 'eye-line', label: 'Content' },
        ],
        'tab-design': [
          { name: 'preview', icon: 'eye-line', label: 'Preview' },
          { name: 'layout', icon: 'drag-move-2-line', label: 'Spacing' },
          { name: 'background', icon: 'bring-to-front', label: 'Background' },
        ],
        'tab-content': [
          { name: 'wrapper', icon: 'table-line', label: 'Display' },
          { name: 'wrapperStyles', icon: 'align-vertically', label: 'Alignment' },
          { name: 'background', icon: 'bring-to-front', label: 'Background & Overlays' },
          { name: 'image', icon: 'image-line', label: 'Images' },
        ],
      },
      
      compiledPreview: '',
      
      options: {
        imagePositionChoices: {
          '': '',
          'Centered': 'center',
          'Top': 'top',
          'Bottom': 'bottom',
          'Left': 'left',
          'Right': 'right',
        },
        mobileClassChoices: {
          'Default': '',
          'Image below content': 'media-unit__mobile-reversed',
          'Image behind content': 'media-unit__mobile-absolute',
          'Image hidden': 'media-unit__mobile-imageless',
          'Disable altogether': 'media-unit__mobile-hidden',
        },
        clipPathChoices: {
          'Diagonal to upper right, at right': 'polygon(0 0, 100% 0%, 75% 100%, 0% 100%)',
          'Diagonal to upper right, at left': 'polygon(25% 0, 0 100%, 100% 100%, 100% 0)',
          'Arrow pointing right, at right': 'polygon(0% 0%, 88% 0, 100% 50%, 88% 100%, 0% 100%)',
          'Arrow pointing right, at left': 'polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 12% 50%, 0% 0%)',
          'Striped diagonals to upper right': 'polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)',
          'Rounded at right': 'circle(80% at 20% 50%)',
          'No shape': 'none',
        },
      },

      contentStyles: {},
      contentClass: [],
      
      mounted: false,
      dirty: false,
      saving: false,
      
      activeTab: this.initialTab || 'tab-content',
      activeSubtab: this.initialSubtab || '',

      htmls: [''],
      activeHtmlIndex: 0,
      
      mobileClass: '',
      
      image: '',
      video: '',
      clipPath: '',
      flexDirection: '',
      mediaWidth: '',
      mediaZIndex: '',
      
      backgroundColor: '',
      textColor: '',

      accentFont: '',
      buttonFont: '',
      textFont: '',
      
      backgrounds: [],

      boxLayout: {},
      styles: {},
      contentWrapperStyles: [{}],
      contentIsWrapped: false,
      contentIsFed: null,
      useContentFeed: false,
      contentWrapperBoxLayout: {},
    }
  },

  mounted () {
    this.mount();
  },
}
</script>

<style scoped>
.md-horizontal-radio-field > label {
  margin-bottom: -1rem;
}
.md-horizontal-radio-field .md-field {
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
}
.md-horizontal-radio-field .md-field >>> .md-radio label.md-radio-label {
  padding-left: 3px;
}
.md-app-toolbar[data-md-toolbar-collapsed] {
  min-height: 15px;
}
label {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
label input.form-control {
  max-width: 5rem;
  padding: .75rem .25rem;
  text-align: right;
}
label input.form-control::-webkit-outer-spin-button, 
label input.form-control::-webkit-inner-spin-button {
  margin-left: .25rem;
}


.wrapper-previews {
  display: flex;
  flex-wrap: wrap;
}
.wrapper-preview {
  background: var(--preview-background, #eee);
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 48%);
  flex: 0 0 30%;
  align-self: stretch;
  margin: 1rem;
  padding: 1rem;
  min-height: 10rem;
  filter: brightness(0.8) opacity(0.8);
}
.wrapper-preview.active {
  filter: none;
}
.wrapper-preview-flex {
  display: flex;
}
.wrapper-preview > * {
  margin: .5em;
  border: 1px solid #ccc;
  padding: .5em;
  flex: 1;
  background: white;
}
.wrapper-preview-flex.direction-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.wrapper-preview-flex.direction-column > * {  
  width: 50%;
}
.wrapper-preview-flex.display-first-child-fill {
  flex-wrap: wrap;
}
.wrapper-preview-flex.display-first-child-fill > :first-child {
  flex: 100%;
}
.wrapper-preview-flex.align-stretch > * {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.wrapper-preview-flex.align-flex-center > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.wrapper-preview-flex.align-center {
  align-self: stretch;
  justify-content: center;
  align-items: center;
}
.wrapper-preview-flex.align-center > * {
  align-self: center;
}
.wrapper-preview-flex.flex-items > * {
  display: flex;
}
.wrapper-preview p {
  font-size: .4rem;
  line-height: 1;
}
.wrapper-preview img {
  font-size: .4rem;
  margin-bottom: 1.5em;
  }
.wrapper-preview p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.media-unit-editor .md-tabs {
  margin-bottom: 1rem;
}
.media-unit-editor .md-toolbar .md-tabs {
  width: 100%;
  margin-bottom: 0;
}
.media-unit-editor .md-drawer {
  width: auto;
  padding-right: 2rem;
  font-size: 2rem;
}

.media-unit-editor .controls {
  font-family: "Roboto", sans-serif;    
}
.background-overlay-editor {
  background: #eee;
  margin: 1rem auto;
  padding: 1rem;
}
.media-unit-editor .md-tabs {
  margin-bottom: 1rem;
}
.media-unit-editor .btn-row {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-unit-editor .md-content {
}
</style>
