export const platform_three_columns_images_inside_no_padding = {
  block: 'platform_three_columns_images_inside',
  args: {
    tilePadding: '2',
    borderRadius: '0',
  },
};

export const platform_three_columns_images_stretched_inside = {
  block: 'platform_three_columns_images_stretched_inside',
};
