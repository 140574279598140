<template>
  <dialog-layout>
    <tiptap v-bind="$props" @input="updateHtml"></tiptap>

    <button @click="$emit('close', html)">Close dialog</button>
  </dialog-layout>
</template>

<script>
import Tiptap from './Tiptap.vue';

export default {
  name: 'DialogTiptap',
  components: Tiptap,
  props: Tiptap.props,
  methods: {
    updateHtml (val) {
      this.html = val;
    },
  },
  data () {
    return {
      html: this.value,
    };
  },
}
</script>
