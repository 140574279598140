<template>
  <dialog-layout>
          <div class="row">
            <div class="col-5">
              <img class="preview-image" :src="imgSrc">
            </div>
            
            <div class="col-7 text-start pe-5">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Size
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-6">
                          <div><b>Original width</b>: {{originalWidth}} pixels</div>
                          <div><b>Original height</b>: {{originalHeight}} pixels</div>
                          <div><b>Original aspect ratio</b>: {{originalAspectRatio.replace('/', ' x ')}}</div>
                          <hr>
                          <div v-if="aspectRatio"><b>Current aspect ratio</b>: {{aspectRatio.replace('/', ' x ')}}</div>
                        </div>
                        <div class="col-6">
                          <div class="row">
                            <label class="col-2 col-form-label">Width</label>
                            <div class="col">
                              <input v-model.number="transforms.width" class="form-control" type="number" min="1" :max="originalWidth">
                            </div>
                          </div>                
                          <div class="row">
                            <label class="col-2 col-form-label">Height</label>
                            <div class="col">
                              <input v-model.number="transforms.height" class="form-control" type="number" min="1" :max="originalHeight">
                            </div>
                          </div>                
                          <div class="row">
                            <label class="col-2 col-form-label">Fit</label>
                            <div class="col">
                              <select v-model="transforms.fit" class="form-control">
                                <option value=""></option>
                                <option value="pad">Pad</option>
                                <option value="fill">Crop</option>                      
                              </select>
                            </div>
                          </div>
                          
                          <div class="row" v-if="transforms.fit === 'pad'">
                            <label class="col-2 col-form-label">Background Color</label>
                            <div class="col">
                              <select v-model="transforms.pad_background" class="form-control">
                                <option value=""></option>
                                <option value="auto">Auto</option>
                                <option value="manual">Manual</option>                      
                              </select>
                            </div>
                          </div>
                          
                          <div class="row" v-if="transforms.fit === 'pad' && transforms.pad_background === 'manual'">
                            <label class="col-2 col-form-label">Background Color</label>
                            <div class="col">
                              <input type="color" v-model="transforms.pad_background_color">
                            </div>
                          </div>
                          
                          <div class="row" v-if="transforms.fit === 'fill'">
                            <label class="col-2 col-form-label">Gravity</label>
                            <div class="col">
                              <select v-model="transforms.gravity" class="form-control">
                                <option value=""></option>
                                <option value="center">Center</option>
                                <option value="auto">Auto</option>
                                <option value="faces">Faces</option>
                                <option value="north">North</option>
                                <option value="south">South</option>
                                <option value="east">East</option>
                                <option value="west">West</option>
                                <option value="north_east">Northeast</option>
                                <option value="north_west">Northwest</option>
                                <option value="south_east">Southeast</option>
                                <option value="south_west">Southwest</option>
                                <option value="xy_center">Manual</option>                      
                              </select>
                            </div>
                          </div>
                          
                          <div class="row" v-if="transforms.fit === 'fill' && transforms.gravity == 'xy_center' && (transforms.width >= transforms.height)">
                            <label class="col-2 col-form-label">X</label>
                            <div class="col">
                              <input v-model="transforms.gravity_manual_x" type="range" min="0" :max="originalWidth">
                            </div>
                          </div>
                          <div class="row" v-if="transforms.fit === 'fill' && transforms.gravity == 'xy_center' && (transforms.height >= transforms.width)">
                            <label class="col-2 col-form-label">Y</label>
                            <div class="col">
                              <input v-model="transforms.gravity_manual_y" type="range" min="0" :max="originalHeight">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Photo Effects
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="row">
                        <label class="col-2 col-form-label">Tint?</label>
                        <div class="col">
                          <input type="checkbox" v-model="transforms.tint">
                        </div>
                      </div>
                      <div class="row" v-if="transforms.tint">
                        <label class="col-2 col-form-label">Amount</label>
                        <div class="col">
                          <input v-model="transforms.tint_opacity" type="range" min="0" :max="100">
                        </div>
                      </div>
                      <div class="row" v-if="transforms.tint">
                        <label class="col-2 col-form-label">Color</label>
                        <div class="col">
                          <input v-model="transforms.tint_background_color" type="color">
                        </div>
                      </div>
                      <div class="row">
                        <label class="col-2 col-form-label">Blur?</label>
                        <div class="col">
                          <input type="checkbox" v-model="transforms.blur">
                        </div>
                      </div>
                      <div class="row" v-if="transforms.blur">
                        <label class="col-2 col-form-label">Amount</label>
                        <div class="col">
                          <input v-model.number="transforms.blur_amount" type="range" min="0" :max="1000">
                        </div>
                      </div>                      
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Graphic Effects
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      
                      <div class="row">
                        <label class="col-2 col-form-label">Make Transparent</label>
                        <div class="col">
                          <input type="checkbox" v-model="transforms.make_transparent">
                        </div>
                      </div>

                      <div class="row">
                        <label class="col-2 col-form-label">Replace Predominant Color</label>
                        <div class="col">
                          <color-picker :disable-alpha="true"
                                        :value="transforms.replace_color_with"
                                        @input="transforms.replace_color_with = hex($event)"
                                        expand-to="top-right" />
                        </div>
                      </div>

                      

                      <!--
                      <div class="row">
                        <label class="col-2 col-form-label">Swap Colors</label>
                        <div class="col">
                          <input type="color" v-model="transforms.swap_1">
                          <input type="color" v-model="transforms.swap_2">
                          <button v-if="transforms.swap_1 && transforms.swap_2"
                                  class="btn" @click.prevent="makeColorSwap(transforms.swap_1.replace('#', ''), transforms.swap_2.replace('#', ''))">
                            Swap!
                          </button>
                        </div>
                      </div>

                      <div class="row">
                        <label class="col-2 col-form-label">Replace Color</label>
                        <div class="col">
                          <input type="color" v-model="transforms.replace_color">
                          <input type="color" v-model="transforms.replace_color_with">
                          <button v-if="transforms.replace_color && transforms.replace_color_with"
                                  class="btn" @click.prevent="makeColorReplacement(transforms.replace_color.replace('#', ''), transforms.replace_color_with.replace('#', ''))">
                            Replace!
                          </button>
                        </div>
                      </div>
                      -->
                      
                    </div>
                  </div>
                </div>      

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="$emit('close')">Close</button>
                  <button @click="$emit('close', { src: imgSrc, transforms })" type="button" class="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </div>
  </dialog-layout>
</template>

<script>
import ColorPicker from './ColorPicker.vue';
import Fraction from 'fraction.js';
import { parseRgbColor, rgbToHex } from '../color-utils.js';

export default {
  name: 'CloudinaryImageEditor',
  components: {
    ColorPicker,
  },
  methods: {
    hex: rgba => {
      return rgbToHex(...parseRgbColor(rgba));
    },
    makeColorSwap (colorOne, colorTwo) {
      this.transforms.color_swaps.push([colorOne, colorTwo]);
    },
    makeColorReplacement (colorOne, colorTwo) {
      this.transforms.color_replacements.push([colorOne, colorTwo]);
    },
    
    parseSrc () {
      let transforms = {
        
        swap_1: null,
        swap_2: null,
        
        replace_color: null,
        replace_color_with: null,
        
        width: null,
        height: null,
        
        fit: null,
        gravity: null,
        gravity_manual_x: null,
        gravity_manual_y: null,
        
        pad_background: null,
        pad_background_color: null,
        
        tint: null,
        tint_opacity: 50,
        tint_background_color: '#ffffff',
        
        blur: null,
        blur_amount: 0,
        
        make_transparent: false,
        
        color_swaps: [],
        color_replacements: [],      
      };
      
      const components = this.value.split('/').map(o => o.split(','));
      
      let dimensions = components.filter(o => o[0] === '$v_1')[0];
      if (dimensions) {
        transforms.width = dimensions.map(o => o.match(/^w_(?<width>\d+)/)?.groups?.width).filter(o => o)[0] || transforms.width;
        transforms.height = dimensions.map(o => o.match(/^h_(?<height>\d+)/)?.groups?.height).filter(o => o)[0] || transforms.height;
        transforms.fit = dimensions.map(o => o.match(/^c_(?<fit>.+)/)?.groups?.fit).filter(o => o)[0] || transforms.fit;
        transforms.gravity = dimensions.map(o => o.match(/^g_(?<gravity>.+)/)?.groups?.gravity).filter(o => o)[0] || transforms.gravity;
        transforms.gravity_manual_x = dimensions.map(o => o.match(/^x_(?<gravity_manual_x>\d+)/)?.groups?.gravity_manual_x).filter(o => o)[0] || transforms.gravity_manual_x;
        transforms.gravity_manual_y = dimensions.map(o => o.match(/^y_(?<gravity_manual_y>\d+)/)?.groups?.gravity_manual_y).filter(o => o)[0] || transforms.gravity_manual_y;                
        
      }
      
      let tint = components.filter(o => o[0] === '$v_2')[0];
      if (tint) {
        tint = {
          tint: true,
          tint_background_color: '#' + (tint.map(o => o.match(/^b_rgb:(?<color>.*)/)?.groups?.color).filter(o => o)[0] || 'ffffff'),
          tint_opacity: 100 - parseInt(tint.map(o => o.match(/^o_(?<opacity>\d+)/)?.groups?.opacity).filter(o => o)[0] || '50'),
      };
      transforms = {...transforms, ...tint};
    }

    return transforms;
  },
      
  },
  computed: {
    originalAspectRatio: ({ originalWidth, originalHeight }) => (Fraction(originalWidth / originalHeight).toFraction()),
    aspectRatio: ({ width, height }) => (height ? Fraction(width / height).toFraction() : 0),

    transformCommands: function() {
      let tr = [];
      if (this.transforms.width || this.transforms.height || this.transforms.fit) {
        let s = ['$v_1'];
        this.transforms.width && s.push(`w_${this.transforms.width}`);
        this.transforms.height && s.push(`h_${this.transforms.height}`);
        if (this.transforms.fit) {
          s.push(`c_${this.transforms.fit}`);
          
          if (this.transforms.fit === 'fill') {
            this.transforms.gravity && s.push(`g_${this.transforms.gravity}`);
            this.transforms.gravity_manual_x && s.push(`x_${this.transforms.gravity_manual_x}`);
            this.transforms.gravity_manual_y && s.push(`y_${this.transforms.gravity_manual_y}`);
          }
          
          if (this.transforms.fit === 'pad') {
            if (this.transforms.pad_background === 'auto') {
              s.push('b_auto');
            }
            else {
              this.transforms.pad_background_color && s.push(`b_rgb:${this.transforms.pad_background_color.replace('#', '')}`);
            }
          }
          
        }
        tr.push(s);
      }
      
      if (this.transforms.tint) {
        let s = ['$v_2'];
        
        this.transforms.tint_opacity && s.push(`o_${100 - this.transforms.tint_opacity}`);
        this.transforms.tint_background_color && s.push(`b_rgb:${this.transforms.tint_background_color.replace('#', '')}`);
        
        tr.push(s);
      }
      
      if (this.transforms.blur) {
        let s = ['$v_3'];
        
        if (this.transforms.blur_amount) {
          s.push(`e_blur:${this.transforms.blur_amount}`);
        } else {
          s.push(`e_blur`);
        }
        tr.push(s);
      }

      if (this.transforms.replace_color_with) {
        let s = ['$v_4'];
        s.push(`e_replace_color:${this.transforms.replace_color_with.replace('#', '')}`);
        tr.push(s);
      }

      if (this.transforms.make_transparent) {
        tr.push([`e_make_transparent`]);
      }

      this.transforms.color_swaps.forEach(o => {
        let s = [];

        s.push(`e_replace_color:FF0080:1:${o[0]}`);
        s.push(`e_replace_color:${o[0]}:1:${o[1]}`);
        s.push(`e_replace_color:${o[1]}:1:FF0080`);
        
        tr.push(...s);
      });

      this.transforms.color_replacements.forEach(o => {
        let s = [];

        s.push(`e_replace_color:${o[1]}:1:${o[0]}`);

        tr.push(...s);
      });

      tr.push('')
      return tr;
    },
    transformsAsUrl: function() {
      return this.transformCommands.join('/');
    },
    imgSrc: function() {
      return `https://res.cloudinary.com/pies/image/upload/${this.transformsAsUrl}${this.imageId}`
    },
  },
  props: {
    imageId: String,
    value: String,
    originalWidth: Number,
    originalHeight: Number,
  },

  watch: {
    transforms () {
      
    },
    value () {
      const transforms = this.parseSrc();
      this.transforms = transforms;
    },
  },
  
  data () {

    const transforms = this.parseSrc();
    return {
      showModal: false,
      transforms,
    };
    
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img.preview-image {
  max-width: 100%;
  max-height: 50vh;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 90%);
}
</style>
