<template>
<dialog-layout>
  <div>
    <div class="media-unit-image-slider">
      <a href="#" @click.prevent="cycleActiveBlockImageObjectPosition">
        <ri :n="imageObjectPosition == 'center' ? 'focus-2-line' : `layout-${imageObjectPosition}-2-line`" />
      </a>
      <input type="range" min="0" max="100" step="1"
             v-model="activeBlockMediaWidth">
      <a href="#" @click.prevent="activeBlockHeightIsLocked = !activeBlockHeightIsLocked">
        <ri v-if="activeBlockHeightIsLocked" n="lock-line" />
        <ri v-else n="lock-unlock-line" />
      </a>    
    </div>

    foo
    <div class="media-unit-image-slider">
      <input type="range" min="-200" max="200" step=".25"
             v-model="contentMarginLeft['amount']">
      <input type="range" min="0" max="100" step=".25"
             v-model="contentWidth['amount']">
      <input type="range" min="-100" max="100" step=".25"
             v-model="contentMarginTop['amount']">
    </div>
    
    <button type="button" class="btn btn-secondary" @click="$emit('close', { props: { 'content-styles': writeStyleString(contentStyles), styles: writeStyleString(styles) } })">Close</button>

  </div>
</dialog-layout>
</template>

<script>
//import $ from "cash-dom";
import { parseCssUnitValue } from '../css-utils.js';
import DialogMediaUnitManipulator from './DialogMediaUnitManipulator.vue';

export default {
  name: 'DialogMediaUnitManipulatorWithContentKnobs',
  extends: DialogMediaUnitManipulator,
  watch: {
    contentMarginLeft: {
      handler (val) {
        this.updateContentStyles('margin-left', `${val.amount}${val.unit}`);
      },
      deep: true,
    },
    contentMarginTop: {
      handler (val) {
        this.updateContentStyles('margin-top', `${val.amount}${val.unit || 'vh'}`);
      },
      deep: true,
    },
    contentWidth: {
      handler (val) {
        this.updateContentStyles('min-width', `${val.amount}${val.unit || 'vw'}`);
      },
      deep: true,
    },
  },
  data () {
    return {
      contentMarginLeft: parseCssUnitValue(this.component.contentStyles['margin-left']),
      contentMarginTop: parseCssUnitValue(this.component.contentStyles['margin-top']) || {amount: 0, unit: 'vh'},
      contentWidth: parseCssUnitValue(this.component.contentStyles['min-width']) || {amount: 0, unit: 'vw'},
    };
  },
}
</script>

<style>
.media-unit-image-slider {
  border-radius: 150px;
  top: 85vh;
  background: #eee;
  padding: 1rem;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 38%) !important;
}

.media-unit-image-slider a {
  text-decoration: none;
}
.media-unit-image-slider i {
  color: black;
}
</style>
