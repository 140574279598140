<template>
<transition name="bounce">
  <md-card class="md-card-transparent-unless-hovered" v-if="isMounted">
    <md-card-content>
      <div>
        <md-field class="md-has-value md-borderless">
          <label>Size</label>
          <vue-material-slider :min="1" :max="100" 
                               v-model="maxWidth.amount" />
        </md-field>
      </div>
    
    <md-field class="md-has-value md-borderless"
              style="flex:0">
      <label>Space above</label>
      <vue-material-slider :vertical="true"
                           v-bind="sliderPropsForUnit(marginTop.unit)"
                           :invert="true"
                           :thumbLabel="true"
                           v-model="marginTop.amount" />
    </md-field>
    <md-field class="md-has-value md-borderless"
              style="flex:0">
      <label>Space below</label>
      <vue-material-slider :vertical="true"
                           v-bind="sliderPropsForUnit(marginBottom.unit)"
                           :invert="true"
                           :thumbLabel="true"
                           v-model="marginBottom.amount" />
    </md-field>
    
    <md-field class="md-has-value md-borderless"
              style="flex:0">
      <label>Space left</label>
      <vue-material-slider :vertical="true"
                           v-bind="sliderPropsForUnit(marginLeft.unit)"
                           :invert="true"
                           :thumbLabel="true"
                           v-model="marginLeft.amount" />
    </md-field>
    <md-field class="md-has-value md-borderless"
              style="flex:0">
      <label>Space right</label>
      <vue-material-slider :vertical="true"
                           v-bind="sliderPropsForUnit(marginRight.unit)"
                           :invert="true"
                           :thumbLabel="true"
                           v-model="marginRight.amount" />
    </md-field>

    <div v-if="backgroundImageEl">
      <md-field class="md-has-value md-borderless" style="flex:0">
        <label>Background image position:</label>
        <vue-material-slider v-bind="sliderPropsForUnit(backgroundImagePositionY.unit)"
                             :vertical="true"
                             v-model="backgroundImagePositionY.amount" />
      </md-field>
      <md-field class="md-has-value md-borderless" style="flex:0">
        <vue-material-slider v-bind="sliderPropsForUnit(backgroundImagePositionX.unit)"
                             v-model="backgroundImagePositionX.amount" />
      </md-field>
    </div>
    
    <div style="flex-shrink:0">

      <md-field v-if="hasMediaSide" class="md-has-value md-borderless">
        <label>Image side</label>
        <md-switch class="md-switch-left-right" v-model="imageOnRight">Image on {{ imageOnRight ? 'right' : 'left' }} side</md-switch>
      </md-field>
    </div>

    <div>
      <md-field class="md-has-value md-borderless"
                style="flex:0">
        <label>Height</label>
        <vue-material-slider :vertical="true"
                             :min="0" :max="30"
                             v-model="contentPaddingY.amount" />
      </md-field>
      <md-field class="md-has-value md-borderless"
                style="flex:0">
        <label>Padding</label>
        <vue-material-slider :min="0" :max="10"
                             v-model="paddingX.amount" />
      </md-field>
    </div>
    
    <div>
      <md-field class="md-has-value"
                v-if="hasMediaSideContent">
        <label>Image behavior</label>
        <md-select v-model="imagePinned">
          <md-option :value="false">Stretch image</md-option>
          <md-option :value="true">Pin image</md-option>            
        </md-select>
      </md-field>

      <md-field class="md-has-value md-borderless"
                v-if="hasMediaSideContent">
        <label>Image position: horizontal</label>
        <vue-material-slider :min="-100" :max="200"
                             :thumb-label="true"
                             v-model="imageObjectPositionX.amount" />
      </md-field>
    </div>    

    <md-field class="md-has-value md-borderless"
              style="flex:0"
              v-if="hasMediaSideContent">
      <label>Vertical</label>
      <vue-material-slider :vertical="true" :min="-100" :max="200"
                           :thumb-label="true"
                           v-model="imageObjectPositionY.amount" :invert="true" />
    </md-field>
    
    </md-card-content>
    <md-card-actions style="justify-content: center;">
      <md-button style="color: green; font-size: 2rem;" @click="save">
          <ri n="checkbox-circle-line" />
      </md-button>
      <md-button style="color: darkred; font-size: 2rem;" @click="discard">
        <ri n="close-circle-line" />
      </md-button>
    </md-card-actions>
  </md-card>
</transition>
</template>

<script>
import { parseCssUnitValue } from '../css-utils.js';

export default {
  name: 'media-unit-live-preview-editor',
  props: ['el'],

  methods: {

    save () {
      this.$emit('save', { uuid: this.uuid,
                           style: this.emittable.style,
                           mediaStyle: this.emittable.mediaStyle,
                           contentStyle: this.emittable.contentStyle,
                           backgroundImageStyle: this.backgroundImageStyle,
                           classList: [...this.el.classList] });
    },
    discard () {
      this.$emit('discard', { uuid: this.uuid });
    },
    
    sliderPropsForUnit (val) {
      if (val === 'px') {
        return {
          min: -600,
          max: 600,
          stepSize: 1,
        }
      }
      if (val === '%') {
        return {
          min: -100,
          max: 200,
          stepSize: .1,
        }
      }
      if (val === 'rem') {
        return {
          min: -20,
          max: 20,
          stepSize: .01,
        }
      }
      if (val === 'em') {
        return {
          min: -20,
          max: 20,
          stepSize: .01,
        }
      }
      if (val === 'vh') {
        return {
          min: 0,
          max: 100,
          stepSize: .01,
        }
      }
      if (val === 'vw') {
        return {
          min: 0,
          max: 100,
          stepSize: .01,
        }
      }
      return {
        min: -10,
        max: 10,
        stepSize: 1,
      }
    },
  },
  
  computed: {
    emittable () {
      let composed = {};
      let obj, source;

      obj = {};
      source = this.style;
      Object.keys(source).forEach(key => {
        if (source[key]) {
          obj[key] = source[key];
        }
      });
      composed.style = source;
      
      obj = {};
      source = this.mediaStyle;
      Object.keys(source).forEach(key => {
        if (source[key]) {
          obj[key] = source[key];
        }
      });
      composed.mediaStyle = source;

      obj = {};
      source = this.contentStyle;
      Object.keys(source).forEach(key => {
        if (source[key]) {
          obj[key] = source[key];
        }
      });
      composed.contentStyle = source;

      return composed;
    },
  },
  watch: {
    backgroundImagePositionY: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.backgroundImageEl.style.setProperty('background-position-y', `${val.amount}${val.unit}`);
      },
    },
    backgroundImagePositionX: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.backgroundImageEl.style.setProperty('background-position-x', `${val.amount}${val.unit}`);
      },
    },
    marginTop: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.setProperty('margin-top', `${val.amount}${val.unit}`);
      },
    },
    marginBottom: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;        
        this.el.style.setProperty('margin-bottom', `${val.amount}${val.unit}`);
      },
    },
    marginLeft: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.setProperty('margin-left', `${val.amount}${val.unit}`);
      },
    },
    marginRight: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;        
        this.el.style.setProperty('margin-right', `${val.amount}${val.unit}`);
      },
    },
    mediaWidth: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.setProperty('--media-unit--media__width', `${val.amount}${val.unit || '%'}`);
      },
    },
    imageObjectPositionX: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.setProperty('--image-object-position', `${val.amount}${val.unit} ${this.imageObjectPositionY.amount}${this.imageObjectPositionY.unit}`);
      },
    },
    imageObjectPositionY: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.setProperty('--image-object-position', `${this.imageObjectPositionX.amount}${this.imageObjectPositionX.unit} ${val.amount}${val.unit}`);
      },
    },
    
    boxShadow (val) {
      if (!this.isMounted) return;
      this.el.style.boxShadow = val;
    },
    
    mediaBoxShadow (val) {
      if (!this.isMounted) return;
      this.mediaEl.style.boxShadow = val;
    },

    contentBoxShadow (val) {
      if (!this.isMounted) return;
      this.contentEl.style.boxShadow = val;
    },
    
    maxWidth: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.maxWidth = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },

    paddingX: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.paddingLeft = this.el.style.paddingRight = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },
    paddingY: {
      deep: true,
      handler (val) {
        if (!this.isMounted) return;
        this.el.style.paddingTop = this.el.style.paddingBottom = (val && val.amount) ? `${val.amount}${val.unit}` : null;
      },
    },
    
    fixedHeight (val) {
      if (!this.isMounted) return;
      this.el.style.height = val ? `${val}px` : null;
    },
    
    useFixedHeight (val) {
      if (!this.isMounted) return;
      if (val) {
        this.fixedHeight = this.el.offsetHeight;
        this.imagePinned = true;
      } else {
        this.fixedHeight = null;
        this.imagePinned = false;
      }
    },
    hasMediaSide (val) {
      if (!this.isMounted) return;
      if (val) {
        this.mediaEl.style.display = null;
      } else {
        this.mediaEl.style.display = 'none';
      }
    },
    imagePinned (val) {
      if (!this.isMounted) return;
      if (val) {
        this.el.classList.add('media-untethered');
      } else {
        this.el.classList.remove('media-untethered');
      }
    },

    imageOnRight (val) {
      if (!this.isMounted) return;
      if (val) {
        this.el.style.flexDirection = 'row-reverse';
      } else {
        this.el.style.flexDirection = null;
      }
    }
  },
  mounted () {

    this.maxWidth = parseCssUnitValue(this.el.style.maxWidth || '100%');
    this.marginTop = parseCssUnitValue(this.el.style.marginTop || '0px');
    this.marginBottom = parseCssUnitValue(this.el.style.marginBottom || '0px');
    this.marginLeft = parseCssUnitValue(this.el.style.marginLeft || '0px');
    this.marginRight = parseCssUnitValue(this.el.style.marginRight || '0px');

    this.paddingX = parseCssUnitValue(this.el.style.paddingLeft || '0rem');
    this.paddingY = parseCssUnitValue(this.el.style.paddingTop || '0rem');

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  data () {
    return {
      isMounted: false,

      backgroundImagePositionX: {},
      backgroundImagePositionY: {},
      
      hasMediaSide: null,
      hasMediaSideContent: null,

      elevations: [
        {label: "", value: ''},        
        {label: "None", value: 'none'},
        {label: "Bootstrap - Small", value: '0 .125rem .25rem rgba(0,0,0,.075)'},
        {label: "Bootstrap - Medium", value: '0 0.5rem 1rem rgb(0 0 0 / 15%)'},
        {label: "Bootstrap - Large", value: '0 1rem 3rem rgb(0 0 0 / 18%)'},
        {label: "Shallow", value: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'},
        {label: "Medium", value: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'},
        {label: "Raised", value: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)'},
        {label: "Extra raised", value: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)'},
        {label: 'From bottom right', value: 'black 5px 5px 5px 0px'},
      ],

      boxShadow: "",
      mediaBoxShadow: "",
      contentBoxShadow: "",
      
      cappedWidth: "",
      fixedHeight: null,
      useFixedHeight: null,
      imagePinned: false,

      maxWidth: {},      
      marginTop: {},
      marginBottom: {},
      marginLeft: {},
      marginRight: {},
      
      mediaWidth: {},

      imageOnRight: null,

      imageObjectPositionX: {},
      imageObjectPositionY: {},

      paddingX: {},
      paddingY: {},

      contentPaddingY: {},
    };
  }
}
</script>

<style>
body .md-menu-content.md-select-menu {
  z-index: 999999;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(.9);
    background-color: rgba(255,255,255,1);
  }
  50% {
    transform: scale(1.1);
    background-color: rgba(255,255,255,.6);
  }
  100% {
    transform: scale(1);
    background-color: rgba(255,255,255,.1);    
  }
}
</style>
<style scoped>
body .md-card.md-card-transparent-unless-hovered {
  background-color: rgba(255,255,255,0.1);
  transition: background-color .3s ease;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  z-index: 99999;
}
body .md-card.md-card-transparent-unless-hovered:hover {
  background-color: rgba(255,255,255,1);
}
body .md-card.md-card-transparent-unless-hovered .md-card-actions {
  opacity: 0;
  max-height: 0;
  transition: all .3s ease;
}
body .md-card.md-card-transparent-unless-hovered:hover .md-card-actions {
  opacity: 1;
  max-height: 100px;
  transition: opacity 1.2s ease-in, max-height .3s ease-in;
}
body .md-card.md-card-transparent-unless-hovered .md-card-actions .md-button {
  padding-top: .5rem;
  height: auto;
}
body .md-card.md-card-transparent-unless-hovered .md-card-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  margin-left: -.5rem;
  margin-right: -.5rem;  
}
body .md-card.md-card-transparent-unless-hovered .md-card-content > * {
  flex: 1;
  max-width: 200px;
  margin-left: 1rem;
  margin-right: 1rem;  
}
body .md-card.md-card-transparent-unless-hovered .md-divider {
  align-self: center;
  transform: rotate(90deg);
  width: 850px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.md-borderless:after {
  display: none;
}
>>> .md-switch.md-switch-left-right .md-switch-container {
  background-color: #cfa9c8;
  --md-theme-default-accent-on-: #cfa9c8;
  --md-theme-default-switchvariant: #800080;
  --md-theme-default-accent: #800080;
}
>>> .md-switch.md-switch-left-right .md-ripple {
  display: none;
}
</style>
