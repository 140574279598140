<template>
<dialog-layout>
  <md-button style="position: absolute; top: 0; right: 0; transform: scale(1.5)"
             class="md-icon-button md-accent" @click="$emit('close')">
    <md-icon>
      <ri n="close-circle-line" />
    </md-icon>
  </md-button>

  <md-app>
    <md-app-toolbar>
      <md-tabs
        md-alignment="fixed" class="controls md-transparent" @md-changed="activeTab = $event">
        
        <template slot="md-tab" slot-scope="{ tab }">
          <div class="mb-1">
            <ri class="ri-xl mb-2 d-block" :n="tab.data.icon" />
            <b>{{ tab.label }}</b>
          </div>
        </template>
        
        <md-tab v-if="!hidePageList"
                id="tab-file-list" md-label="Go To"
                :md-template-data="{ icon: 'file-list-line' }" ></md-tab>
        <md-tab id="tab-add-new" md-label="Add New"
                :md-template-data="{ icon: 'file-add-line' }" ></md-tab>            
        <md-tab v-if="!hideRedirects"
                id="tab-redirects" md-label="Redirects"
                :md-template-data="{ icon: 'guide-line' }" ></md-tab>
      </md-tabs>
    </md-app-toolbar>

    <md-app-drawer v-show="subtabs[activeTab]" md-permanent="clipped">
      <md-list>
        <md-list-item v-for="tab in subtabs[activeTab]" :key="tab.name"
                      md-expand>
          <md-icon><ri :n="tab.icon" /></md-icon>
          <span class="md-list-item-text">{{ tab.label }}</span>

          <md-list slot="md-expand">
            <md-list-item v-for="page in pagesByType[tab.name]" :key="page.key"
                          class="md-inset"
                          @click="pageToPreview = page; selectedType = tab.name; promptAddNew = false">
              <span class="md-list-item-text">{{ page.label }}</span>

              <md-button v-if="pageToPreview === page" class="go-button"
                         @click.stop.prevent="promptAddNew = true">
                Choose
              </md-button>
              
            </md-list-item>
          </md-list>
          
        </md-list-item>
      </md-list>

    </md-app-drawer>
    
    <md-app-content>

      <div v-if="activeTab === 'tab-add-new'"
           :style="promptAddNew ? '' : 'position: relative; padding-bottom: 200px; min-height: 80vh; background: whitesmoke;'">
        <dialog-add-page v-if="promptAddNew" @close="addPage" />
      
        
        <iframe v-else-if="exquisiteCorpse"
                class="shadow-lg"
                style="width: 1600px; margin: 0 auto; 
                       height: 1600px; 
                       max-width: none;
                       transform: scale(.5);
                       position: absolute;
                       top: -385px;
                       left: -250px;
                       background: white;"
                :srcdoc="iframeWrapper(exquisiteCorpseCompiled)"></iframe>
      </div>

      <div v-if="activeTab === 'tab-redirects'">
        <md-table  v-model="redirects">
          <md-table-row>
            <md-table-head>Path</md-table-head>
            <md-table-head>Target</md-table-head>
            <md-table-head></md-table-head>
          </md-table-row>
          <md-table-row v-for="item, index in redirects" :key="index">
            <md-table-cell>
              <span>{{ item.source }}</span>
            </md-table-cell>
            <md-table-cell>
              <span><a target="_blank" :href="item.destination">{{ item.destination }}</a></span>
            </md-table-cell>
            <md-table-cell>
              <status-button @action="deleteRedirect(index)">
                Delete
              </status-button>
            </md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>
              <md-field md-inline>
                <label>Path</label>
                <md-input v-model="newRedirect.source" />
              </md-field>
            </md-table-cell>
            <md-table-cell>
              <md-field md-inline>
                <label>Target</label>
                <md-input type="url" v-model="newRedirect.destination" />
              </md-field>
            </md-table-cell>
            <md-table-cell>
              <status-button @action="addRedirect">
                Add
              </status-button>
            </md-table-cell>
          </md-table-row>
          
        </md-table>
      </div>
      
  <md-table v-if="activeTab === 'tab-file-list'" v-model="pages">
    <md-table-row slot="md-table-row" slot-scope="{ item }">
      <md-table-cell class="go-cell" width="10">
        <md-button class="primary-button" @click.prevent="$emit('close', item.key)">Go!</md-button>        
      </md-table-cell>
      <md-table-cell md-label="Title">
        <span><a style="text-decoration: underline; color: inherit"
                 href="#" @click.prevent="$emit('close', item.key)">{{ computedPage(item.key).title }}</a></span>
      </md-table-cell>
      <md-table-cell md-label="Path">
        <span>{{ item.key }}</span>
      </md-table-cell>

      <md-table-cell>
        <status-button @action="deletePage(item.key)">Delete</status-button>
      </md-table-cell>
      
      <template v-if="showMeta">
        <md-table-cell v-for="key in allMetaKeys" :key="key" :md-label="key"
                       class="meta"
                       :class="editingMeta.item === item && editingMeta.key === key ? 'editing-meta' : null">

          <template v-if="key.indexOf('image') !== -1">

            <template v-if="editingMeta.item === item && editingMeta.key === key">
              <image-input
                :value="editingMeta.value"
                upload-preset="uth5vfjo"
                @input="editingMeta.value = $event" />

              <div class="editing-meta-buttons">
                <button @click="editMeta" class="btn btn-primary"><ri n="check-line" /></button>
                <button @click="editingMeta = {}" class="btn"><ri n="close-line" /></button>
                <button @click="removeMeta" class="btn">
                  <ri n="delete-bin-line" />
                </button>
              </div>

            </template>
            <span v-else @click="editingMeta = { item, key, value: item.metaObj[key] }">
              <img :src="item.metaObj[key]">
            </span>
          </template>

          <template v-else>
            <template v-if="editingMeta.item === item && editingMeta.key === key">
              <textarea v-model="editingMeta.value"></textarea>
              <div class="editing-meta-buttons">
                <button @click="editMeta" class="btn btn-primary"><ri n="check-line" /></button>
                <button @click="editingMeta = {}" class="btn"><ri n="close-line" /></button>
                <button @click="removeMeta" class="btn">
                  <ri n="delete-bin-line" />
                </button>
              </div>
            </template>
            
            <span v-else @click="editingMeta = { item, key, value: item.metaObj[key] }">{{ item.metaObj[key] }}</span>
          </template>
          
        </md-table-cell>

        <md-table-cell md-label="">
          <input class="form-control" v-model="newMetaKey" />
          <button @click="addMeta(item)" class="btn"><ri n="add-line" /></button>
        </md-table-cell>
        
      </template>
      
    </md-table-row>
  </md-table>
    </md-app-content>
  </md-app>
</dialog-layout>
</template>

<script>
import DialogAddPage from '../DialogAddPage.vue';
import { renderCorpus } from '../../utils.js';
import { computedPage } from '../../page-utils.js';
import { get } from 'axios';
import Vue from 'vue';

const choose = arr => arr[Math.floor(Math.random() * arr.length)];

export default {
  name: 'PageList',
  
  watch: {
    pageToPreview () {
      this.exquisiteCorpse = this.exquisiteCorpseContent(this.pageToPreview.corpse);
    },
    
    exquisiteCorpse () {
      let html = (new Vue({
        render: Vue.compile(this.exquisiteCorpse).render,
      })).$mount().$el.outerHTML;
      
      this.exquisiteCorpseCompiled = html;
    },
  },
  
  data () {
    return {
      
      promptAddNew: false,
      
      redirectStatus: {},
      addingStatus: null,
      
      exquisiteCorpse: '',
      exquisiteCorpseCompiled: '',
      
      selectedType: null,
      pageToPreview: null,
      
      newPageHeading: null,
      newPageImage: null,
      
      newRedirect: {
        permanent: false,
        source: '',
        destination: '',
      },
      
      pagesByType: {
        volunteer: [
          {
            key: 'stacked',
            label: "Volunteer Form",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('background'),
                heading: this.newPageHeading || 'Page Title',
              })],
              ['why_running', () => ({
                heading: "Join Our People-Powered Campaign",
                content: '<volunteer-form style="max-width:100%;margin-left:auto;margin-right:auto"></volunteer-form>',
                styles: {
                  'margin-top': '4rem',
                  'margin-bottom': '2rem',
                  '--form-field-color': '#eeeeee',
                  '--form-field-border-color': '#000000',
                },
              })],
              ['image_columns', () => ({
                images: [
                  { image: this.randomImage('photo') },
                  { image: this.randomImage('photo') },
                  { image: this.randomImage('photo') },
                ],
                styles: {
                  'max-width': '1200px',
                  'margin-top': '4rem',
                  'margin-bottom': '4rem',
                },
              })],
            ],
          },
        ],
        contact: [
          {
            key: 'contact_form',
            label: "Form and Text",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'Contact Us',
              })],
              ['two_columns', () => ({
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  '--form-field-color': '#eeeeee',
                  '--form-field-border-color': '#000000',
                },
                heading: "Send Us Your Message",
                firstColumnWidth: 75,
                content: `<contact-us-form uuid="" style="max-width:100%;margin-left:auto;margin-right:auto"></contact-us-form>`,
                secondaryContent: `<h1 style="text-align:center">Contact Information</h1><p style="text-align:center"><b>Phone</b>: (111) 234-5678</p><p style="text-align:center"><b>Email</b>: email@example.com</p><p style="text-align:center"><b>Address</b>: 123 Main St, Townsville, ST 12345</p>`
              })],
            ],
          },
        ],
        privacy: [
          {
            key: 'custom_banner',
            label: "Privacy Policy Text",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'Privacy Policy',
              })],
              ['meet_alicia', () => ({
                heading: '',
                image: '',
                styles: {
                  'background-color': '#ffffff',
                  'color': '#000000',
                  'max-width': '1200px',
                },
              })],
            ],
          },
        ],
        custom: [
          {
            key: 'custom_banner',
            label: "Text With Banner",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'Page Title',
              })],
              ['meet_alicia', () => ({
                heading: 'Heading Text',
                styles: {
                  'background-color': '#ffffff',
                  'color': '#000000',
                  'max-width': '1200px',
                },
                image: this.randomImage('photo')
              })],
            ],
          },
          {
            key: 'custom_text_form',
            label: "Text and Form",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'Page Title',
              })],
              ['two_columns', () => ({
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  '--form-field-color': '#eeeeee',
                  '--form-field-border-color': '#000000',
                },
                firstColumnWidth: 60,
                secondaryContent: `<signup-form uuid="" style="margin-left:auto;margin-right:auto"></signup-form>`,
              })],
            ],
          },
          {
            key: 'custom_form_text',
            label: "Form and Text",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'Page Title',
              })],
              ['two_columns', () => ({
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  '--form-field-color': '#eeeeee',
                  '--form-field-border-color': '#000000',
                },
                firstColumnWidth: 75,
                content: `<signup-form uuid="" style="max-width:100%;margin-left:auto;margin-right:auto"></signup-form>`,
                secondaryContent: `<h1 style="text-align:left">Lorem ipsum dolor sit amet.</h1><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`
              })],
            ],
          },
        ],
        about: [
          {
            key: 'text_about',
            label: "Text-Based",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'About',
              })],
              ['meet_alicia', () => ({
                styles: {
                  'background-color': '#ffffff',
                  'color': '#000000',
                  'max-width': '1200px',
                },
                image: this.randomImage('photo'),
                heading: '',
              })],
            ],
          },
          {
            key: 'compact_about',
            label: "Large Intro Paragraph",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'About',
              })],
              ['why_running_photo_shadow_box', () => ({
                styles: {
                  'align-items': 'flex-start',
                  'margin-top': '2rem',
                  'margin-bottom': '0',
                  '--media-unit--media__width': '20%',
                  'background-color': '#ffffff',
                  'color': '#000000',
                },
                mediaStyles: {'box-shadow': ''},
                contentStyles: {
                  'align-self': '',
                  'padding-left': '0', 'margin-right': '0', 'padding-top': '0', 'padding-bottom': '0',
                  'margin-top': '-.5em', 'padding-right': '4em',
                },
                image: this.randomImage('photo'),
                heading: '',
                content: `
<p style="font-size: 1.6em; line-height: 1.6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
`,
              })],
              ['why_running_photo_shadow_box', () => ({
                styles: {'margin-bottom': '1rem',
                         'align-items': 'flex-start',
                         '--media-unit--media__width': '0%',
                         '--media-unit--media__aspect-ratio': '',
                         '--image-width': '',
                         '--image-height': '',
                         'background-color': '#ffffff',
                         'color': '#000000',
                        },
                mediaStyles: {'box-shadow': '', 'display': 'none'},
                contentStyles: {
                  'align-self': '',
                  'padding-left': '0', 'margin-right': '0', 'padding-top': '0', 'padding-bottom': '0',
                },
                image: '',
                heading: '',
                content: `
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
`,
              })],
              ['why_running_photo_shadow_box', () => ({
                styles: {'align-items': 'flex-start', 
                         '--media-unit--media__width': '0%',
                         'padding-top': '0', 'padding-bottom': '0',
                         'background-color': '#ffffff',
                         'color': '#000000',
                        },
                contentStyles: {
                  'align-self': '',
                  'padding-left': '0', 'margin-right': '0', 'padding-top': '0', 'padding-bottom': '0',
                },
                mediaStyles: {'box-shadow': '', 'display': 'none'},                
                heading: '',
                contentClass: "has-wrapped-content direction-row",
                content: `
    <div class="media-unit--content-wrapper"><img style="width:100%" src="${this.randomImage('photo', 'ar_16:9,c_fill,g_auto')}"></div>
    <div class="media-unit--content-wrapper"><img style="width:100%" src="${this.randomImage('photo', 'ar_16:9,c_fill,g_auto')}"></div>
`,
              })],
            ],
          },
        ],
        endorsements: [
          {
            key: 'compact_endorsements',
            label: "Compact",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('background'),
                heading: this.newPageHeading || 'Endorsements',
                styles: {
                  'margin-bottom': '1rem',
                },
              })],
              ['endorsements_three_columns_images_inside', () => ({
                endorsements: [
                  { image: this.randomImage('endorser-logo') },
                  { image: this.randomImage('endorser-logo') },
                  { image: this.randomImage('endorser-logo') },
                  { image: this.randomImage('endorser-logo') },
                ],
              })],
              ['endorsements_three_columns_images_inside', () => ({
                endorsements: [
                  { image: this.randomImage('endorser-logo') },
                  { image: this.randomImage('endorser-logo') },
                  { image: this.randomImage('endorser-logo') },
                  { image: this.randomImage('endorser-logo') },
                ],
              })],
              ['two_columns', () => ({
                styles: {
                  'background-color': '#ffffff',
                  'color': '#000000',
                  'margin-top': '1rem',
                  'max-width': '1200px',
                },
                contentStyles: {
                  'text-align': 'center',
                },
                heading: '',
                content: `
<h2 style="text-align:center;text-decoration:underline">Elected Officials</h2>
<ul style="list-style-type:none">
<li>State Senator Name</li>
<li>State Representative Name</li>
<li>Mayor Name</li>
<li>Town Councillor Name</li>
</ul>
<h2 style="text-align:center;text-decoration:underline">Local Organizations</h2>
<ul style="list-style-type:none">
<li>Org 1</li>
<li>Org 2</li>
<li>Org 3</li>
<li>Org 4</li>
<li>Org 5</li>
<li>Org 6</li>
</ul>`,
                secondaryContent: `<h2 style="text-align:center;text-decoration:underline">Community Members</h2>
<ul style="list-style-type:none">
<li>Small Business Owner</li>
<li>Respected Local Activist</li>
<li>PTA President</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
</ul>`
              })],              
            ],
          },
          {
            key: 'photo_quote_endorsements',
            label: "Photos and Quotes",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('background'),
                heading: this.newPageHeading || 'Endorsements',
                styles: {
                  'margin-bottom': '1rem',
                },
              })],
              ['why_running_photo_shadow_box', () => ({
                image: this.randomImage('photo'),
                styles: {
                  'background-color': 'transparent',
                },
                contentStyles: {
                  'background-color': 'var(--background-color)',
                  'padding-right': '3rem',
                },
                headingStyles: {
                  'width': '100%',
                  'order': '9',
                  'text-align': 'right',
                  'margin-top': '2rem',
                  'margin-bottom': '0',
                },
              })],
              ['why_running_photo_shadow_box', () => ({
                image: this.randomImage('photo'),
                styles: {
                  'background-color': 'transparent',
                  'flex-direction': 'row',
                },
                mediaStyles: {
                  'z-index': '2',
                },
                contentStyles: {
                  'background-color': 'var(--background-color)',
                  'padding-right': '3rem',
                  'z-index': '1',
                },
                headingStyles: {
                  'width': '100%',
                  'order': '9',
                  'text-align': 'right',
                  'margin-top': '2rem',
                  'margin-bottom': '0',
                },
              })],
              ['why_running_photo_shadow_box', () => ({
                image: this.randomImage('photo'),
                styles: {
                  'background-color': 'transparent',
                },
                contentStyles: {
                  'background-color': 'var(--background-color)',
                  'padding-right': '3rem',
                },
                headingStyles: {
                  'width': '100%',
                  'order': '9',
                  'text-align': 'right',
                  'margin-top': '2rem',
                  'margin-bottom': '0',
                },
              })],
              ['why_running_photo_shadow_box', () => ({
                image: this.randomImage('photo'),
                styles: {
                  'background-color': 'transparent',
                  'flex-direction': 'row',
                },
                mediaStyles: {
                  'z-index': '2',
                },
                contentStyles: {
                  'background-color': 'var(--background-color)',
                  'padding-right': '3rem',
                  'z-index': '1',
                },
                headingStyles: {
                  'width': '100%',
                  'order': '9',
                  'text-align': 'right',
                  'margin-top': '2rem',
                  'margin-bottom': '0',
                },
              })],
              ['why_running_photo_shadow_box', () => ({
                image: this.randomImage('photo'),
                styles: {
                  'background-color': 'transparent',
                },
                contentStyles: {
                  'background-color': 'var(--background-color)',
                  'padding-right': '3rem',
                },
                headingStyles: {
                  'width': '100%',
                  'order': '9',
                  'text-align': 'right',
                  'margin-top': '2rem',
                  'margin-bottom': '0',
                },
              })],
            ],
          },
          {
            key: 'text_quote_endorsements',
            label: "Text-Based Quotes",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'Endorsements',
                styles: {
                  'margin-bottom': '1rem',
                },
              })],
              ['platform_entry_image_and_text_columns', () => ({
                heading: 'Endorser Name, Position, and Location',
                image: '',
                primaryColor: '#efefef',
                mediaStyles: {
                  'display': 'none',
                },
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  'margin-bottom': '3rem',
                  '--image-width': '1600',
                  '--image-height': '900',
                },
                contentStyles: {
                  'padding-top': '1rem',
                  'padding-right': '2rem',
                  'padding-bottom': '2rem',
                },
              })],
              ['platform_entry_image_and_text_columns', () => ({
                heading: 'Endorser Name, Position, and Location',
                image: '',
                primaryColor: '#efefef',
                mediaStyles: {
                  'display': 'none',
                },
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  'margin-bottom': '3rem',
                  '--image-width': '1600',
                  '--image-height': '900',
                },
                contentStyles: {
                  'padding-top': '1rem',
                  'padding-right': '2rem',
                  'padding-bottom': '2rem',
                },
              })],
              ['platform_entry_image_and_text_columns', () => ({
                heading: 'Endorser Name, Position, and Location',
                image: '',
                primaryColor: '#efefef',
                mediaStyles: {
                  'display': 'none',
                },
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  'margin-bottom': '3rem',
                  '--image-width': '1600',
                  '--image-height': '900',
                },
                contentStyles: {
                  'padding-top': '1rem',
                  'padding-right': '2rem',
                  'padding-bottom': '2rem',
                },
              })],
              ['platform_entry_image_and_text_columns', () => ({
                heading: 'Endorser Name, Position, and Location',
                image: '',
                primaryColor: '#efefef',
                mediaStyles: {
                  'display': 'none',
                },
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  'margin-bottom': '3rem',
                  '--image-width': '1600',
                  '--image-height': '900',
                },
                contentStyles: {
                  'padding-top': '1rem',
                  'padding-right': '2rem',
                  'padding-bottom': '2rem',
                },
              })],
            ],
          },
          {
            key: 'text_endorsements',
            label: "Text-Based",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'Endorsements',
                styles: {
                  'margin-bottom': '1rem',
                },
              })],
              ['two_columns', () => ({
                styles: {
                  'background-color': '#ffffff',
                  'color': '#000000',
                  'margin-top': '1rem',
                  'max-width': '1200px',
                },
                contentStyles: {
                  'text-align': 'center',
                },
                heading: '',
                content: `
<h2 style="text-align:center;text-decoration:underline">Elected Officials</h2>
<ul style="list-style-type:none">
<li>State Senator Name</li>
<li>State Representative Name</li>
<li>Mayor Name</li>
<li>Town Councillor Name</li>
</ul>
<h2 style="text-align:center;text-decoration:underline">Local Organizations</h2>
<ul style="list-style-type:none">
<li>Org 1</li>
<li>Org 2</li>
<li>Org 3</li>
<li>Org 4</li>
<li>Org 5</li>
<li>Org 6</li>
</ul>`,
                secondaryContent: `<h2 style="text-align:center;text-decoration:underline">Community Members</h2>
<ul style="list-style-type:none">
<li>Small Business Owner</li>
<li>Respected Local Activist</li>
<li>PTA President</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
<li>Name</li>
</ul>`
              })],
            ],
          },          
        ],
        news: [
          {
            key: 'extended_platform',
            label: "Extended",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo', 'c_crop,ar_16:9'),
                heading: this.newPageHeading || 'In the News',
              })],
              ['platform_entry_image_and_text_columns', () => ({
                heading: 'Article title, 3/12/2022',
                content: `<p>A short excerpt from the article, two or three sentences long. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>`,
                image: this.randomImage('press-logo', 'c_pad,ar_3:1'),
                mediaStyles: {
                  'border': 'none',
                },
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  'margin-bottom': '3rem',
                  '--image-width': '1600',
                  '--image-height': '900',                  
                },
                contentStyles: {
                  'padding-top': '1rem',
                  'padding-right': '2rem',
                  'padding-bottom': '2rem',
                },
              })],
            ],
          },
        ],
        platform: [
          {
            key: 'compact_platform',
            label: "Compact",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'My Platform',
              })],
              ['why_running', () => ({
                heading: "As a long-time community activist and a trusted CPA, I know that affordable health care, housing, and education are the keys to investing in our communities.",
                content: '',
                styles: {
                  'margin-top': '4rem',
                  'margin-bottom': '2rem',
                },                
              })],
              ['platform_three_columns', () => ({
                styles: {
                  'max-width': '1400px',
                  'background-color': '#ffffff',
                  'color': '#000000',
                },
                issues: [
                  { image: this.randomImage('photo'), heading: 'Topic', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                  { image: this.randomImage('photo'), heading: 'Topic', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                  { image: this.randomImage('photo'), heading: 'Topic', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                ],
              })],
              ['platform_three_columns', () => ({
                styles: {
                  'max-width': '1400px',
                  'background-color': '#ffffff',
                  'color': '#000000',
                },                
                issues: [
                  { image: this.randomImage('photo'), heading: 'Topic', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                  { image: this.randomImage('photo'), heading: 'Topic', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                  { image: this.randomImage('photo'), heading: 'Topic', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                ],
              })],
            ],
          },
          {
            key: 'extended_platform',
            label: "Extended",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo', 'c_crop,ar_16:9'),
                heading: this.newPageHeading || 'My Platform',
              })],
              ['platform_entry_image_and_text_columns', () => ({
                heading: 'Topic',
                image: this.randomImage('photo', 'c_crop,ar_16:9'),
                mediaStyles: {
                  'border': 'none',
                },
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  'margin-bottom': '3rem',
                  '--image-width': '1600',
                  '--image-height': '900',                  
                },
                contentStyles: {
                  'padding-top': '1rem',
                  'padding-right': '2rem',
                  'padding-bottom': '2rem',
                },
              })],
              ['platform_entry_image_and_text_columns', () => ({
                heading: 'Topic',
                image: this.randomImage('photo', 'c_crop,ar_16:9'),
                mediaStyles: {
                  'border': 'none',
                },
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  'margin-bottom': '3rem',
                  '--image-width': '1600',
                  '--image-height': '900',                  
                },                
                contentStyles: {
                  'padding-top': '1rem',
                  'padding-right': '2rem',
                  'padding-bottom': '2rem',
                },
              })],
              ['platform_entry_image_and_text_columns', () => ({
                heading: 'Topic',
                image: this.randomImage('photo', 'c_crop,ar_16:9'),
                mediaStyles: {
                  'border': 'none',
                },
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  'margin-bottom': '3rem',
                  '--image-width': '1600',
                  '--image-height': '900',                  
                },
                contentStyles: {
                  'padding-top': '1rem',
                  'padding-right': '2rem',
                  'padding-bottom': '2rem',
                },                
              })],
              ['platform_entry_image_and_text_columns', () => ({
                heading: 'Topic',
                image: this.randomImage('photo', 'c_crop,ar_16:9'),
                mediaStyles: {
                  'border': 'none',
                },
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  'margin-bottom': '3rem',
                  '--image-width': '1600',
                  '--image-height': '900',                  
                },
                contentStyles: {
                  'padding-top': '1rem',
                  'padding-right': '2rem',
                  'padding-bottom': '2rem',
                },                
              })],
              ['platform_entry_image_and_text_columns', () => ({
                heading: 'Topic',
                image: this.randomImage('photo', 'c_crop,ar_16:9'),
                mediaStyles: {
                  'border': 'none',
                },
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  'margin-bottom': '3rem',
                  '--image-width': '1600',
                  '--image-height': '900',                  
                },
                contentStyles: {
                  'padding-top': '1rem',
                  'padding-right': '2rem',
                  'padding-bottom': '2rem',
                },
              })],
              ['platform_entry_image_and_text_columns', () => ({
                heading: 'Topic',
                image: this.randomImage('photo', 'c_crop,ar_16:9'),
                mediaStyles: {
                  'border': 'none',
                },
                styles: {
                  'max-width': '1200px',
                  'margin-top': '3rem',
                  'margin-bottom': '3rem',
                  '--image-width': '1600',
                  '--image-height': '900',
                },
                contentStyles: {
                  'padding-top': '1rem',
                  'padding-right': '2rem',
                  'padding-bottom': '2rem',
                },
              })],
            ],
          },
          {
            key: 'sectioned_platform',
            label: "Superextended",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'My Platform',
              })],
              ['why_running', () => ({
                heading: "As a long-time community activist and a trusted CPA, I know that affordable health care, housing, and education are the keys to investing in our communities.",
                content: '',
                styles: {
                  'margin-top': '4rem',
                  'margin-bottom': '4rem',
                },
              })],
              ['platform_image_and_text_columns', () => ({
                heading: 'Topic 1',
                image: this.randomImage('photo'),
              })],
              ['platform_three_columns_images_inside', () => ({
                styles: {
                  'max-width': '1400px',
                },
                issues: [
                  { image: this.randomImage('photo'), heading: 'Subtopic A', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                  { image: this.randomImage('photo'), heading: 'Subtopic B', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                  { image: this.randomImage('photo'), heading: 'Subtopic C', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                ],
              })],
              ['platform_image_and_text_columns', () => ({
                heading: 'Topic 2',
                image: this.randomImage('photo'),
              })],
              ['platform_three_columns_images_inside', () => ({
                styles: {
                  'max-width': '1400px',
                },                
                issues: [
                  { image: this.randomImage('photo'), heading: 'Subtopic A', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                  { image: this.randomImage('photo'), heading: 'Subtopic B', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                  { image: this.randomImage('photo'), heading: 'Subtopic C', content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>' },
                ],
              })],
            ],
          },
          {
            key: 'text_platform',
            label: "Text-Based",
            corpse: [
              ['banner_header', () => ({
                image: this.newPageImage || this.randomImage('photo'),
                heading: this.newPageHeading || 'My Platform',
              })],
              ['meet_alicia', () => ({
                styles: {
                  'background-color': '#ffffff',
                  'color': '#000000',
                  'max-width': '1200px',
                },
                image: this.randomImage('photo'),
                heading: '',
              })],
              ['meet_alicia', () => ({
                styles: {
                  'background-color': '#ffffff',
                  'color': '#000000',
                  'max-width': '1200px',
                },
                image: this.randomImage('photo'),
                heading: '',
                media_side: 'right',
              })],
              ['meet_alicia', () => ({
                styles: {
                  'background-color': '#ffffff',
                  'color': '#000000',
                  'max-width': '1200px',
                },
                image: this.randomImage('photo'),
                heading: '',
              })],
              
            ],
          },          
        ],
      },
        
      subtabs: {
        'tab-add-new': [
          { name: 'about', icon: 'contacts-book-2-line', label: 'About' },
          { name: 'platform', icon: 'question-answer-line', label: 'Platform / Issues' },
          { name: 'volunteer', icon: 'hand-heart-line', label: 'Volunteer' },
          { name: 'contact', icon: 'questionnaire-line', label: 'Contact' },
          { name: 'news', icon: 'youtube-line', label: 'News' },
          { name: 'endorsements', icon: 'check-double-line', label: 'Endorsements' },
          { name: 'privacy', icon: 'government-line', label: 'Privacy' },
          { name: 'custom', icon: 'layout-column-line', label: 'Custom' },
        ],
      },
      
      activeTab: this.initialTab || '',
      editingMeta: {},
      newMetaKey: '',
    };
  },
  
  props: [
    'initialTab',
    'hidePageList',
    'hideRedirects',
    'showMeta',
    'selectable',
    'only',
  ],

  components: {
    DialogAddPage,
  },
  
  methods: {

    iframeWrapper (content) {
      const fonts = this.$store.state.repo.config.fonts
            .filter(o => o && o.family)
            .map(({ family }) => `https://fonts.googleapis.com/css2?family=${family}:wght@400;700&display=swap`)
            .map(href => `<link rel='stylesheet' type='text/css' href="${href}" />`)
            .join('\n');

      return `
<html>
  <head>
  ${fonts}
  <my-script type="text/javascript">
    window.onload = function() {    
      if (parent) {        
        var oHead = document.getElementsByTagName("head")[0];        
        var arrStyleSheets = parent.document.getElementsByTagName("style");        
        for (var i = 0; i < arrStyleSheets.length; i++)            
          oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
        arrStyleSheets = parent.document.getElementsByTagName("link");        
        for (var i = 0; i < arrStyleSheets.length; i++) { 
          if (arrStyleSheets[i].rel !== 'stylesheet' && arrStyleSheets[i].type !== 'text/css') {
            continue;
          }
          if (arrStyleSheets[i].href.endsWith('.css')) {
            var link = arrStyleSheets[i].cloneNode(true);
            oHead.appendChild(link); 
          }
        }
      }
    }
  </my-script>
  <style>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
  </style>
  </head>
  <body style='background:transparent;padding-bottom:200px;'>
    <div style='${this.$store.state.repo.config.layout.style}'>${content}</div>
  </body>
</html>`.replace(/my-script/g, "script");

    },
    
    computedPage (o) {
      if (!o) return { title: '' };
      return computedPage(o, this.$store.state);
    },
    
    async deleteRedirect (index) {
      let redirects = this.$store.state.repo.redirects
          .filter((o, i) => i !== index);
      this.$set(this.redirectStatus, index, 'deleting');
      await this.$store.dispatch('writeRedirects', redirects);
      this.$set(this.redirectStatus, index, null);
    },
    
    async addRedirect () {
      if (!this.newRedirect.source.startsWith('/')) {
        this.newRedirect.source = `/${this.newRedirect.source}`;
      }
      let redirects = [
        ...this.$store.state.repo.redirects,
        this.newRedirect,
      ];
      this.addingStatus = 'adding';
      await this.$store.dispatch('writeRedirects', redirects);
      this.newRedirect = {
        permanent: false,
        source: '',
        destination: '', 
      };
      this.addingStatus = null;
    },

    async deletePage (key) {
      await this.$store.dispatch('deletePage', { key });
    },
    
    async movePage (page) {
      const [ userResult, pageResult ] = await Promise.all([
        this.$swal.fire({
          title: "Foo",
          input: 'text',
        }),
        get(`/api/pages?page=${page.key}`),
      ]);

      console.log(page);
      console.log(userResult.value);
      console.log(pageResult.data);
      
    },
    
    async addPage ({ title, slug, bannerImage }) {
      while (slug[0] === '/') {
        slug = slug.replace(/^\//, '');
      }
      while (slug[slug.length - 1] === '/') {
        slug = slug.replace(/\/$/, '');
      }

      this.newPageHeading = title;
      this.newPageImage = bannerImage;

      this.exquisiteCorpse = this.exquisiteCorpseContent(this.pageToPreview.corpse);
      
      this.$store.commit('setLoading', true);
      await this.$store.dispatch('immediatelyWritePageContent', { key: slug, title, html: this.exquisiteCorpse });
      this.$emit('close', slug);
      this.$store.commit('setLoading', false);
    },
    
    randomImage (type, transforms) {
      let images = Object.values(this.$store.state.repo.images);
      if (type) {
        images = images.filter(o => o.piesExtra?.type === type);
      }
      
      if (!images.length) {
        images = Object.values(this.$store.state.repo.images);
      }

      let image = choose(images).public_id;
      if (transforms) {
        transforms = transforms + '/';
      }
      return `https://res.cloudinary.com/pies/image/upload/${transforms || ''}${image}`;
    },

    exquisiteCorpseContent (corpse) {
      return '<div>' + corpse
        .map(block => renderCorpus(block[0], block[1]()))
        .join('\n') + '</div>';
    },

    async addMeta (item) {
      const newMeta = [
        ...item.head.meta,
        { name: this.newMetaKey, hid: this.newMetaKey, property: this.newMetaKey, value: '', content: '' },
      ];
      const newHead = {
        ...item.head,
        meta: newMeta,
      };
      this.$store.commit('setLoading', true);
      await this.$store.dispatch('updatePageHead', { key: item.key, head: newHead });
      this.$store.commit('setLoading', false);
      this.newMetaKey = '';
    },
    
    async removeMeta () {
      const { item, key } = this.editingMeta;
      const newMeta = (item.head?.meta || []).filter(meta => (meta.name !== key && meta.property !== key));
      const newHead = {
        ...item.head,
        meta: newMeta,
      };
      this.$store.commit('setLoading', true);
      await this.$store.dispatch('updatePageHead', { key: item.key, head: newHead });
      this.$store.commit('setLoading', false);
      this.editingMeta = {};
    },

    async editMeta () {
      const { item, key, value } = this.editingMeta;      
      
      let newMeta = (item.head?.meta || []).filter(meta => (meta.name !== key && meta.property !== key));
      newMeta.push({
        hid: key,
        name: key,
        property: key,
        value: value,
        content: value,
      });
      
      const newHead = {
        ...item.head,
        meta: newMeta,
      };
      this.$store.commit('setLoading', true);
      await this.$store.dispatch('updatePageHead', { key: item.key, head: newHead });
      this.$store.commit('setLoading', false);
      this.editingMeta = {};
    },
  },
  
  computed: {
    
    allMetaKeys () {
      let meta = [];
      this.pages.map(({ metaObj }) => Object.keys(metaObj)).forEach(metaKeys => {
        meta = [...meta, ...metaKeys];
      });
      return [...new Set(meta)].sort();
    },

    redirects () {
      return (this.$store.state.repo.redirects || []).map(
        o => ({...o, type: o.permanent ? 'Redirect - Permanent' : 'Redirect'})
      );
    },
    
    pages () {
      let pages = [];
      Object.keys(this.$store.state.repo.pages)
        .filter(key => !this.only || this.only.indexOf(key) !== -1)
        .forEach(key => {
          const keyForHead = key.split('/').pop(); // only use the last path component in head obj
          const head = this.$store.state.repo.config.head[keyForHead] || {},
                meta = head.meta || [];
          
          let metaObj = {};
          meta.forEach(o => {
            metaObj[o.property || o.name] = o.content || o.value;
          });
          
          pages.push({
            key,
            metaObj,
            head,
            moving: false,
          });
        });
      return pages;
    },
  },
}
</script>

<style scoped>
.md-drawer {
  width: auto;
  min-width: 250px;
  font-size: 2rem;
}
.md-table .md-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: #eee;
  margin: 0;
}
.md-table .md-button.primary-button {
  background: purple;
  color: white;
  font-weight: bold;
}

.md-table >>> .md-table-cell-container {
  display: flex;
  align-items: center;
}
.md-table >>> .md-table-head-label {
  color: black;
}
.md-table .go-cell >>> .md-table-cell-container {
  padding: 6px 0;
}
.md-tabs {
  margin-bottom: 1rem;
}
.md-toolbar .md-tabs {
  width: 100%;
  margin-bottom: 0;
}
.md-toolbar .md-tabs >>> .md-button {
  margin-top: .5rem;
  padding-top: .5rem;
  margin-bottom: .5rem;
  min-height: 52px;
}
.md-button.go-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: purple;
  color: white;
  margin: 0;
}
.md-table div {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
}
.md-table .meta {
  position: relative;
}
.md-table .meta img {
  max-width: 100%;
  max-height: 100%;
}
.md-table .meta span {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
}
.md-table .meta span:hover {
  background: lightyellow;
}
.editing-meta .md-table-cell-container {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.editing-meta .md-table-cell-container textarea {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.editing-meta-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editing-meta-buttons .btn {
    font-size: 0.9rem;
    padding: 0.1rem .5rem;
    min-width: 0;
}
</style>
