<template>
<div class="editor">
  <editor-content :editor="editor" />
</div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import { Node } from "@tiptap/core";
import Text from '@tiptap/extension-text'
import Paragraph from '@tiptap/extension-paragraph'
import FontSize from '@/tiptap/font-size.js';
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import Link from '@tiptap/extension-link'

const OneLiner = Node.create({
  name: "oneLiner",
  topNode: true,
  content: "text*",
});

export default {
  props: {
    value: String,
  },
  
  components: {
    EditorContent,
  },
  
  data () {
    return {
      editor: null,
    }
  },
  mounted () {
    console.log(this.value);
    this.editor = new Editor({
      extensions: [
        OneLiner,
        Text,
        Paragraph,
        TextStyle,
        Color,
        FontSize,
        Link.configure({
          openOnClick: false,
          HTMLAttributes: {
            target: null,
            rel: null,
          },
        }),
      ],
      content: this.value,
      onUpdate: () => {
        const input = this.editor.getHTML();
        this.$emit('input', input);
        console.log(input);
      },
    });
  },
  
  beforeDestroy() {
    this.editor.destroy()
  },  
}
</script>
