import { camelCase, kebabCase } from 'lodash';
import { writeStyleString } from './css-utils.js';

const updateBoxLayout = (style, currentStyles) => {
  currentStyles = {...currentStyles};
  Object.entries(style).forEach(entry => {
    const val = entry[1],
          key = kebabCase(entry[0]);
    if (typeof val === 'undefined') {
      delete currentStyles[key];
    } else {
      currentStyles[key] = val;
    }
  });
  return writeStyleString(currentStyles, true, true);
};

const getBoxLayout = (style) => {
  const boxLayoutKeys = [
    'margin-top', 'margin-bottom', 'margin-left', 'margin-right',
    'padding-top', 'padding-bottom', 'padding-left', 'padding-right',
    'border-top-left-radius', 'border-bottom-left-radius',
    'border-top-right-radius', 'border-bottom-right-radius',
    'width', 'max-width', 'min-width',
    'height', 'max-height', 'min-height',
    'background-color', 'color',
  ];

  let converted = {};
  boxLayoutKeys.forEach(key => {
    converted[camelCase(key)] = style[key];
  });
  
  return converted;
};

export { getBoxLayout, updateBoxLayout };
