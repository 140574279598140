<template>
<div style="width: 2000px; max-width: 100%; margin: 2rem auto">
  <md-table style="margin: 0 2rem" md-card  v-model="rows">
    <md-table-row slot="md-table-row" slot-scope="{ item }">
      <md-table-cell v-for="col in header"
                     :key="col"
                     :class="`col-${col}`"
                     :md-label="col"
                     :md-sort-by="col">{{ item[col] }}</md-table-cell>
    </md-table-row>
  </md-table>

  <md-speed-dial style="position: fixed; bottom: 2rem; right: 2rem; z-index: 999"
                 md-direction="top">
    <md-speed-dial-target class="md-primary" href="/api/form-submissions?format=csv">
      <md-icon><ri n="download-cloud-2-line" /></md-icon>
    </md-speed-dial-target>
<!--    
    <md-speed-dial-content>
      <md-button class="md-icon-button" href="/api/form-submissions?format=csv">
        <md-icon><ri n="download-cloud-2-line" /></md-icon>
      </md-button>
    </md-speed-dial-content>
    -->
  </md-speed-dial>
</div>
</template>

<script>
import { get } from 'axios';

export default {
  computed: {
    header () {
      let cols = [];
      this.rows.forEach(row => {
        Object.keys(row).forEach(col => {
          if (cols.indexOf(col) === -1) {
            cols.push(col);
          }
        });
      });
      return cols;
    },
  },
  async mounted () {
    this.rows = (await get('/api/form-submissions')).data;
  },
  data () {
    return {
      rows: [],
    }
  },
}
</script>

<style scoped>
.md-speed-dial .md-primary {
  background: purple !important;
}
.md-speed-dial .md-speed-dial-content .md-button {
  background: purple !important;
}
.md-speed-dial .md-speed-dial-content [class^="ri-"],
.md-speed-dial .md-speed-dial-content [class*=" ri-"] {
  color: white;
}
</style>
<style>
.md-table-cell {

}
td.md-table-cell.col-userAgent,
td.md-table-cell.col-referrer,
td.md-table-cell.col-location,
td.md-table-cell.col-timestamp {
  word-break: break-word !important;
}
</style>
