<template>
<dialog-layout style="min-height: min(80vh, 800px)">
  <dialog-close-button @close="$emit('close')" />

  <vue-dropzone ref="dropzone" id="image-library-dropzone"
                :options="dropzoneOptions"
                :awss3="awss3"
                v-on:vdropzone-s3-upload-error="s3UploadError"
                v-on:vdropzone-s3-upload-success="s3UploadSuccess">
  </vue-dropzone>
</dialog-layout>
</template>

<script>
export default {
  computed: {
    dropzoneOptions () {
      return {
        dictDefaultMessage: 'Add new: click or drop files here to upload',
        url: "https://httpbin.org/post",
      };
    },
    awss3 () {
      return {
        signingURL: 'https://dns-lookup-eta.vercel.app/api/s3upload',
        headers: {},
        params: { campaign: this.$store.state.login.campaign.slug },
        sendFileToServer: false,
        withCredentials: false,
      };
    },
  },
  methods: {
    s3UploadError (err) {
      console.error(err);
    },
    s3UploadSuccess (url) {
      console.log(url);
    },
  },
}
</script>
