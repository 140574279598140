
export const hero_steph = {
  block: 'hero_shape_divider',
  args: {
    shape: 'diagonal',
    media_side: 'left',        
    styles: {
      '--media-unit--media__width': '55%',
    },
  },
  defaultArgs: {
    content: `<p-buttons style="--button-width: 150px" :links="[{ href: 'https://example.com', text: 'Join' }, { href: 'https://example.com', text: 'Donate' }]"></p-button>`
  },
};

export const hero_background_steph = {
  block: 'hero_shape_background_divider',
  args: {
    ...hero_steph.args,
    styles: {
      '--media-unit--media__width': '35%',
    },
  },
  defaultArgs: {
    ...hero_steph.defaultArgs,
  },
};

export const hero_michele = {
  block: 'hero_shape_divider',
  args: {
    shape: 'none',
    media_side: 'left',        
    styles: {
      '--media-unit--media__width': '100%',
    },
    contentStyles: {
      'padding-top': '10rem',
      'padding-bottom': '10rem',
    },
  },
};

export const hero_background_michele = {
  block: 'hero_shape_background_divider',
  args: {
    ...hero_michele.args,
  },
  hideFromGallery: true,  
};

export const hero_background_michele_vertical_banner = {
  block: 'hero_shape_background_divider',
  args: {
    ...hero_michele.args,
    styles: {
      ...hero_michele.styles,
      'padding-left': '10rem',
      'padding-right': '10rem',
      '--media-unit--media__width': '80%',
    },
  },
};

export const hero_background_michele_vertical_banner_boxed = {
  block: 'hero_shape_background_divider',
  args: {
    ...hero_michele.args,
    styles: {
      ...hero_michele.styles,
      'padding-left': '10rem',
      'padding-right': '10rem',
      '--media-unit--media__width': '45%',
      'max-width': '1200px',
      'box-shadow': '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
      
    },
  },
};

export const hero_michele_reversed = {
  block: 'hero_shape_divider',
  args: {
    ...hero_michele.args,
    media_side: 'right',
  },
  hideFromGallery: true,    
};

export const hero_background_michele_reversed = {
  block: 'hero_shape_background_divider',
  args: {
    ...hero_michele.args,
    media_side: 'right',
  },
  hideFromGallery: true,    
};

export const hero_background_michele_vertical_banner_reversed = {
  block: 'hero_shape_background_divider',
  args: {
    ...hero_michele.args,
    media_side: 'right',
    styles: {
      ...hero_michele.styles,
      'padding-left': '10rem',
      'padding-right': '10rem',
      '--media-unit--media__width': '80%',
    },
    contentStyles: {
      'padding-top': '25rem',
      'padding-bottom': '25rem',      
    },
  },
  hideFromGallery: true,    
};


export const hero_background_gradient = {
  block: 'hero_background_image_driven_gradient',
  args: {},
};

export const hero_background_no_gradient = {
  block: 'hero_background_no_gradient',
  args: {},
};

export const hero_background_no_tint = {
  block: 'hero_background_no_tint',
  args: {},
};

export const hero_daquan = {
  block: 'hero_shape_divider',
  args: {
    shape: 'arrow',
    elevation: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    media_side: 'left',    
    styles: {
      '--media-unit--media__width': '35%',
      'max-width': '1400px',
      'box-shadow': '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    },
  },
};

export const hero_background_daquan = {
  block: 'hero_shape_background_divider',
  args: {
    ...hero_daquan.args,
  },
};

export const hero_daquan_reversed = {
  block: 'hero_shape_divider',
  args: {
    ...hero_daquan.args,
    media_side: 'right',
  },
  hideFromGallery: true,    
};

export const hero_background_daquan_reversed = {
  block: 'hero_shape_background_divider',
  args: {
    ...hero_daquan.args,
    media_side: 'right',
  },
  hideFromGallery: true,    
};

export const join_only_bar = {
  block: 'donate_only_bar',
  args: {
    interactiveComponent: `<signup-form uuid=""></signup-form>`,
    styles: {
      'padding-left': '10vw',
      'padding-right': '10vw',      
    },
  },
  hideFromGallery: true,
};

export const hero_image_breakout_band = {
  block: 'hero_shape_divider',
  args: {
    shape: 'none',
    media_side: 'left',
    secondaryImageMarginBottom: 0,
    elevation: '',
    styles: {
      'overflow': 'visible',
      'margin-top': '8rem',
      'margin-bottom': '8rem',
      'padding-left': '4rem',
      'padding-right': '4rem',
      '--media-unit--media__width': '40%',
    },
    mediaStyles: {
      'margin-top': '-5rem',
      'margin-bottom': '-5rem',
      'box-shadow': '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
      'margin-left': '4rem',
      'margin-right': '4rem',
    },
    contentStyles: {
      'margin-left': '4rem',
      'margin-right': '4rem',
      'padding-bottom': '2rem',
    },
  },
};

export const hero_image_why_running_photo = {
  block: 'hero_shape_divider',
  args: {
    shape: 'none',
    media_side: 'left',
    secondaryImageMarginBottom: 0,
    elevation: '',
    styles: {
      'margin-top': '8rem',
      'margin-bottom': '8rem',
      'padding-left': '8rem',
      'padding-right': '8rem',
      'padding-top': '5rem',
      'padding-bottom': '5rem',
      '--media-unit--media__width': '40%',
    },
    mediaStyles: {
      'margin-top': '5rem',
      'margin-bottom': '5rem',
      'box-shadow': '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    },
    contentStyles: {
      'margin-left': '8rem',
      'padding-top': '2rem',
      'padding-bottom': '2rem',
    },
  },
};
