import { startCase } from 'lodash';

function computedPage (key, state) {
  if (!key || !state.repo?.pages) {
    return {
      key,
      page: null,
      style: '',
      html: '',
      tree: {},
      contentEntry: {},
      head: {},
      title: '',
    };
  }

  const page = state.repo.pages[key],
        html = page.template,
        style = page.style,
        tree = {};

  let contentEntry = {};
  if (key.split('/').length === 2
      &&
      state.repo.contentTypes.indexOf(key.split('/')[0]) !== -1
      &&
      state.repo.contentPages[key.split('/')[0]][key]) {
    contentEntry = state.repo.contentPages[key.split('/')[0]][key];
  }

  let config = state.repo.config.head[key];
  
  let title = startCase(key || 'home');
  if (config && config.title) {
    title = config.title;
  } else if (config && config.meta && config.meta.find(o => o.hid === 'title')) {
    title = config.meta.find(o => o.hid === 'title').name;
  } else if (config && config.meta && config.meta.find(o => o.hid === 'og:title')) {
    title = config.meta.find(o => o.hid === 'og:title').name;
  }
      
  return {
    key: key === 'index' ? '' : key,
    page,
    style,
    html,
    tree,
    contentEntry,
    head: config,
    title,
    path: `/${key}`,        
  };
}

export {
  computedPage,
}
