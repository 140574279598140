<template>
<div class="dialog-container"
     @keydown.esc="$emit('close')"
     tabindex="0">

    <span class="dialog-mask" :style="maskStyles"></span>
    <component ref="component" :is="dialogComponent"
               @mouseover="notifyChildMaskUnhovered"
               @close="response => $emit('close', response)"
               @unmask="unmasked = true"
               @remask="unmasked = false"
               v-bind="props"/>
  </div>
</template>
<script>
export default {
  name: 'DialogWrapper',
  props: ['dialogComponent', 'props'],
  methods: {
    notifyChildMaskUnhovered () {
      console.log('wrapper knows!');
    },
    notifyChildMaskHovered () {
      this.$refs.component.maskHovered();
    },
  },
  computed: {
    maskStyles () {
      return this.unmasked ? {'background-color': 'rgba(0,0,0,0)'} : {};
    },
  },
  data () {
    return { unmasked: false, };
  },
}
</script>
<style>
.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9001;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
