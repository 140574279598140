export const why_running = {
  block: 'why_running',
};

export const why_running_2 = {
  block: 'why_running_2',
};

export const why_running_photo_shadow_box = {
  block: 'why_running_photo_shadow_box',
  args: {
    styles: {
      'padding-left': 0,
      'padding-right': 0,
      'padding-top': 0,
      'padding-bottom': 0,
    },
  },  
};

export const why_running_photo_shadow_box_2 = {
  block: 'why_running_photo_shadow_box',
  args: {
    styles: {
      'background-color': 'transparent',
    },
    contentStyles: {
      'background-color': 'var(--background-color)',
      'padding-right': '3rem',
    },
    headingStyles: {
      'width': '100%',
      'order': '9',
      'text-align': 'right',
      'margin-top': '2rem',
      'margin-bottom': '0',
    },
  },
};


export const why_running_photo_shadow_box_3 = {
  block: 'why_running_photo_shadow_box',
  args: {
    styles: {
      'background-color': 'transparent',
    },
    contentStyles: {
      'background-color': 'var(--background-color)',
      'margin-left': '2rem',
      'margin-right': '2rem',
    },
  },
};
