<template>
<md-app>
  <md-app-content>
    <md-field>
      <label>Search for</label>
      <md-input v-model="searchTerm" />
    </md-field>
    <div>
      <div class="library-entry" :key="image.public_id" v-for="image in icons">
        <img :src="image.preview_url"
             style="width: 200px; height: auto">
      </div>
    </div>
  </md-app-content>
    
  </md-app>
</template>

<script>
import { get } from 'axios';

export default {
  watch: {
    async searchTerm () {
      this.icons = (await get(`/api/icons?term=${this.searchTerm}`)).data;
    },
  },
  data () {
    return {
      icons: [],
      searchTerm: '',
    };
  },
  mounted () {
    this.searchTerm = 'pie';
  },
}
</script>

<style scoped>
.library .md-list.md-theme-default .md-list-item-container.md-active .md-list-item-content * {
  color: var(--md-theme-default-primary-on-background, #448aff);
}
.library-entry {
  position: relative;
  margin: 1rem; width: 200px; background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADBJREFUOE9jfPbs2X8GPEBSUhKfNAPjqAHDIgz+//+PNx08f/4cfzoYNYCBceiHAQC5flV5JzgrxQAAAABJRU5ErkJggg==");
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
