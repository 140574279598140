<template>
<dialog-layout style="min-height: min(80vh, 800px)" :width=".96">
  
  <dialog-close-button @close="$emit('close')" />
  <md-tooltip :md-active="true" md-direction="right">Top</md-tooltip>
  
  <md-app style="min-height: min(80vh, 800px)">
    
    <md-app-toolbar style="flex-wrap: nowrap">
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start" style="position:absolute">
          
          <md-button v-if="!editing" class="md-icon-button" @click="drawerOpen = true">
            <md-icon>
              <ri n="menu-fold-line" />
            </md-icon>
          </md-button>
          
          <md-button v-if="selectedType" :disabled="true" style="text-align: left">
            {{ selectedTab.label }}
            <br v-if="$store.getters.userIsPCCCStaff" />
            <template v-if="$store.getters.userIsPCCCStaff">{{ corpusCyclingPreset.block }}</template>
          </md-button>
          
        </div>
        
        <app-tabs v-if="activeTab && selectedType"
                  :active-tab="gallery ? 'gallery' : configuring ? 'settings' : 'preview'"
                  :tabs="previewTabs" @tab-changed="togglePreview" />
      </div>
    </md-app-toolbar>
    
    <md-app-drawer v-if="!drawerOpen && configuring && form" md-permanent="clipped">
      
      <md-list style="min-height:70vh">
        <template v-if="groupedForm.content">
          <md-subheader>Content</md-subheader>
          <md-list-item v-for="{ label, key } in groupedForm.content"
                        :key="key"
                        @click="activeFormTab = key;"
                        :class="activeFormTab === key ? 'md-active' : ''">
            
            <span class="md-list-item-text">{{ label || prettyPrint(key) }}</span>
          </md-list-item>
        </template>
        
        <md-divider v-if="groupedForm.content && groupedForm.options"></md-divider>
        
        <template v-if="groupedForm.design">
          <md-subheader>Design</md-subheader>
          <md-list-item v-for="{ label, key } in groupedForm.design" :key="key"
                        @click="activeFormTab = key;"
                        :class="activeFormTab === key ? 'md-active' : ''">
            <span class="md-list-item-text">{{ label || prettyPrint(key) }}</span>
          </md-list-item>
        </template>        
        
        <md-divider v-if="(groupedForm.design || groupedForm.content) && groupedForm.options"></md-divider>
        
        <template v-if="groupedForm.options">
          <md-subheader>Options</md-subheader>
          <md-list-item v-for="{ label, key } in groupedForm.options" :key="key"
                        @click="activeFormTab = key;"
                        :class="activeFormTab === key ? 'md-active' : ''">
            <span class="md-list-item-text">{{ label || prettyPrint(key) }}</span>
          </md-list-item>
        </template>        
        
        <div style="margin-bottom:0;margin-top:auto;" v-if="editing">
          <md-list-item>
            <md-button @click="$emit('close', { action: 'destructure'})" class="go-button">Destructure Content</md-button>
          </md-list-item>
        </div>        
        
      </md-list>
    </md-app-drawer>
    
    <md-app-drawer v-if="drawerOpen" :md-active.sync="drawerOpen">
      <md-list>
        
        <md-list-item v-for="tab in tabs"
                      :key="tab.name"
                      @click="selectType(tab)"
                      v-bind="maybeExpand(tab)">
          
          <md-icon><ri :n="tab.icon" /></md-icon>
          <span class="md-list-item-text">{{ tab.label }}</span>
          
          <a v-if="typeof subtabs[tab.name] !== 'object'"
             style="text-decoration:none" href="#" class="configuring"
             @click.stop.prevent="selectType(tab, 'configuring')">
            <md-icon><ri n="settings-5-line" /></md-icon>
          </a>
          
          <md-list v-if="typeof subtabs[tab.name] === 'object'" slot="md-expand">
            <md-list-item v-for="subtab in subtabs[tab.name]"
                          :key="subtab.name"
                          class="md-inset"
                          @click="selectType(subtab)">
              <span class="md-list-item-text">{{ subtab.label }}</span>
              
              <a style="text-decoration:none" href="#" class="configuring"
                 @click.stop.prevent="selectType(subtab, 'configuring')">
                <md-icon><ri n="settings-5-line" /></md-icon>
              </a>
              
              
            </md-list-item>
          </md-list>
          
        </md-list-item>
      </md-list>
      
    </md-app-drawer>
    <md-app-content style="background:whitesmoke">
      
      <md-empty-state v-if="!selectedType && !activeTab" style="max-width: 700px"
                      md-label="Choose a content block on the left to get started">
        
      </md-empty-state>
      
      <template v-if="configuring && form">
        
        <template v-if="formTab">
          
          <template v-if="formTab.type === 'object_list'">
            
            <md-switch @change="buildFeed" v-model="value[`${activeFormTab}Feed`]">Use a feed</md-switch>
            
            <template v-if="!value[`${activeFormTab}Feed`]">
              <draggable :list="value[activeFormTab]">
                <form-card v-for="object, index in value[activeFormTab]"
                           inner-style="border-left: 10px solid whitesmoke; cursor: move;"
                           :key="index" button-alignment="right"
                           @delete="value[activeFormTab].splice(index, 1)">
                  <template v-slot:header>
                    <div class="md-title"
                         v-if="formTab.list_entry_view && formTab.list_entry_view(object)"
                         v-html="formTab.list_entry_view(object)">
                    </div>
                    <div class="md-title"
                         v-else
                         v-html="`${formTab.object_label || prettyPrint(activeFormTab)} #${index+1}`">
                    </div>
                  </template>
                  
                  <div v-show="editingSubEntry === index">
                    <component v-for="subfield, subkey in formTab.fields" :key="subkey" :is="maybeMdField(subfield.type)">
                      
                      <label :for="`field-${activeFormTab}`">
                        {{ subfield.label || prettyPrint(subkey) }}
                      </label>
                      
                      <md-input v-if="subfield.type === 'text'" type="text" v-model="object[subkey]" />
                      <md-input v-else-if="subfield.type === 'number'" type="number" v-model="object[subkey]" />
                      <tiptap v-else-if="subfield.type === 'html'" v-model="object[subkey]"></tiptap>
                      <image-input :allow-delete="true" delete-icon="close-circle-line"
                                   @delete-image="object[subkey] = ''"
                                   :image-category="subfield.subtype"                                   
                                   v-else-if="subfield.type === 'image'" v-model="object[subkey]"></image-input>
                      <color-picker v-else-if="subfield.type === 'color'" v-model="object[subkey]"
                                    expand-to="top-right"
                                    fallback="#ffffff"></color-picker>
                      
                      <md-select v-else-if="subfield.type === 'select'"
                                 v-model="object[subkey]"
                                 :name="`field-${activeFormTab}`" :id="`field-${activeFormTab}`">
                        <md-option v-for="{ label, value } in subfield.options" :key="label" :value="value">
                          {{ label }}
                        </md-option>
                      </md-select>
                    </component>
                  </div>
                  
                  <template v-slot:actions>
                    <md-button class="md-primary"
                               v-if="editingSubEntry !== index"
                               @click="editingSubEntry = index">Edit</md-button>
                    <md-button class="md-primary"
                               v-else
                               @click="editingSubEntry = null">Done Editing</md-button>
                  </template>
                </form-card>
              </draggable>
              
              <form-card :title="`Add another ${(formTab.object_label || prettyPrint(activeFormTab)).toLowerCase()}`"
                         class="flexed-inside"
                         @submit="appendNewSubEntry">
                
                <component v-for="subfield, subkey in formTab.fields" :key="subkey" :is="maybeMdField(subfield.type)"
                           :style="subfield.type === 'html' ? 'flex:100%' : 'flex:1'">
                  
                  <label :for="`field-${activeFormTab}`">
                    {{ subfield.label || prettyPrint(subkey) }}
                  </label>
                  
                  <md-input v-if="subfield.type === 'text'" subfield.type="text" v-model="addingNewSubEntry[subkey]" />
                  <md-input v-else-if="subfield.type === 'number'" subfield.type="number" v-model="addingNewSubEntry[subkey]" />
                  <tiptap v-else-if="subfield.type === 'html'" v-model="addingNewSubEntry[subkey]"></tiptap>
                  <image-input v-else-if="subfield.type === 'image'"
                               :allow-delete="true"
                               delete-icon="close-circle-line"
                               @delete-image="addingNewSubEntry[subkey] = ''"
                               :image-category="subfield.subtype"
                               v-model="addingNewSubEntry[subkey]"></image-input>
                  <color-picker v-else-if="subfield.type === 'color'" v-model="addingNewSubEntry[subkey]"
                                fallback="#ffffff"></color-picker>
                  
                  <md-select v-else-if="subfield.type === 'select'"
                             v-model="addingNewSubEntry[subkey]"
                             :name="`field-${activeFormTab}`" :id="`field-${activeFormTab}`">
                    <md-option v-for="{ label, value } in subfield.options" :key="label" :value="value">
                      {{ label }}
                    </md-option>
                  </md-select>
                </component>
              </form-card>
            </template>
          </template>
          <form-card v-else="">
            <component :is="maybeMdField(formTab.type)" :key="activeFormTab">
              
              <label :for="`field-${activeFormTab}`">
                {{ formTab.label || prettyPrint(activeFormTab) }}
              </label>
              
              <md-input v-if="formTab.type === 'text'" type="text" v-model="value[activeFormTab]" />
              <md-input v-else-if="formTab.type === 'number'" type="number" v-model="value[activeFormTab]" />
              <tiptap v-else-if="formTab.type === 'html'" v-model="value[activeFormTab]"></tiptap>
              <image-input
                :allow-delete="true"
                delete-icon="close-circle-line"
                @deleteImage="value[activeFormTab] = ''"
                :image-category="formTab.subtype"
                v-else-if="formTab.type === 'image'"
                v-model="value[activeFormTab]"></image-input>
              <color-picker v-else-if="formTab.type === 'color'" v-model="value[activeFormTab]"
                            :disable-alpha="true"
                            fallback="#ffffff"></color-picker>
              
              <md-select v-else-if="formTab.type === 'select'"
                         v-model="value[activeFormTab]"
                         :name="`field-${activeFormTab}`" :id="`field-${activeFormTab}`">
                <md-option v-for="{ label, value } in formTab.options" :key="label" :value="value">
                  {{ label }}
                </md-option>
              </md-select>
            </component>
          </form-card>
          
        </template>
        <md-empty-state v-else style="max-width: 900px"
                        md-label="Choose a field on the left to add content or customize the design"
                        md-description="When you're ready, just click the Preview tab above to see how things will look.">
          <pro-tip>If you want to see what it looks like now (before customizing) just click Preview to see it.</pro-tip>
        </md-empty-state>
      </template>
      
      <template v-else-if="gallery">
        <form-card :inner-style="`position: relative; width: 100%; background: white; height: ${150 + 300 + Math.floor(Object.values(corpusTypePresets).length / 2) * 300}px`" title="Choose your favorite thing and click on it">
          <template v-slot:header>
            <div class="md-title">
              Choose your favorite thing and click on it
            </div>
            <md-subheader class="help">
              You'll be able to put in your own content next, and you can adjust images to fit at any time, so don't worry about that. If you want to do those customizations now, just click Customize above.
            </md-subheader>
          </template>
          <iframe class="shadow-lg"
                  v-for="block, index in Object.values(corpusTypePresets)"
                  :key="index"
                  style="width: 100%; margin: 0 auto; visibility: hidden; height: 600px; transform: scale(.4); position: absolute;"
                  :style="`left: calc(${index % 2 === 0 ? '-1' : '1'} * 95vw / 4); top: calc(-50px + ${Math.floor(index / 2) * 300}px);`"
                  @load="$event.target.style['visibility'] = 'visible'; $event.target.contentWindow.document.body.onclick = setPreset(index)"
                  :srcdoc="iframeWrapper(renderCorpus(block.block, block.args, block.defaultArgs))"></iframe>
        </form-card>
      </template>
      
      <template v-else-if="activeTab">
        <form-card style="position: relative" v-if="!cyclingPreset">

          <md-button style="position: absolute; left: -1rem; top: 50%;"
                     @click="cyclePresetLeft"
                     class="md-icon-button md-icon-swipe-button">
            <md-icon>
              <ri n="arrow-left-line" />
            </md-icon>
          </md-button>
          <md-button style="position: absolute; right: -1rem; top: 50%;"
                     @click="cyclePresetRight"
                     class="md-icon-button md-icon-swipe-button">
            <md-icon>
              <ri n="arrow-right-line" />
            </md-icon>
          </md-button>
          
          <iframe style="width: 100%; margin: 0 auto; visibility: hidden; height: 600px"
                  @load="$event.target.style['visibility'] = 'visible'"                  
                  :srcdoc="iframeWrapper(renderCorpus(activeTab, {}, {}, activeBlockOverrides))"></iframe>
          <template v-slot:actions>
            <md-button @click="customize">Customize</md-button>
            <md-button @click="$emit('close', { block: activeTab, value: {...defaulted, ...value}, overrides: activeBlockOverrides, designOverrides: activeBlockDesignOverrides })"
                       class="md-primary">Use this</md-button>
          </template>
        </form-card>

        <form-card style="position: relative" v-if="cyclingPreset">
          <md-button style="position: absolute; left: 0; top: 50%;"
                     @click="cyclePresetLeft"
                     class="md-icon-button md-icon-swipe-button">
            <md-icon>
              <ri n="arrow-left-line" />
            </md-icon>
          </md-button>
          <md-button style="position: absolute; right: 0; top: 50%;"
                     @click="cyclePresetRight"
                     class="md-icon-button md-icon-swipe-button">
            <md-icon>
              <ri n="arrow-right-line" />
            </md-icon>
          </md-button>

          <iframe style="width: 100%; margin: 0 auto; visibility: hidden; height: 600px"
                  @load="$event.target.style['visibility'] = 'visible'"
                  :srcdoc="iframeWrapper(renderCorpus(corpusCyclingPreset.block, corpusCyclingPreset.args, corpusCyclingPreset.defaultArgs))"></iframe>
        
          <template v-slot:actions>
            <md-button @click="customize">Customize</md-button>
            <md-button @click="$emit('close', { block: corpusCyclingPreset.block, value: {...defaulted, ...corpusCyclingPreset.defaultArgs, ...value, ...corpusCyclingPreset.args} })"
                       class="md-primary">Use this</md-button>
          </template>
        </form-card>
      </template>
      
    </md-app-content>

  </md-app>
</dialog-layout>

</template>

<script>
import Corpus from '../exquisite-corpse.js';
import { renderCorpus } from '../utils.js';
import Vue from 'vue';
import { startCase } from 'lodash';
import draggable from 'vuedraggable'
import ColorPicker from './ColorPicker.vue'
import FeedBuilder from './dialogs/FeedBuilder.vue';
import { openDialog } from '../modal-dialog.js';

const choose = arr => arr[Math.floor(Math.random() * arr.length)];

export default {
  name: 'dialog-add-content-block',
  
  components: {
    draggable,
    ColorPicker,
  },
  
  props: [
    'filterCorpusTypes',
    'editing',
    'startOnForm',
    'initialValue',
  ],
  
  methods: {
    
    async buildFeed ($event) {
      if (!$event) {
        return;
      }
      this.value[`${this.activeFormTab}FeedConfig`] = await openDialog(FeedBuilder, {
        page: this.$store.state.activePageKey,
        initial: this.value[`${this.activeFormTab}FeedConfig`],
      });
    },

    maybeExpand (tab) {
      if (typeof this.subtabs[tab.name] === 'object') {
        return {'md-expand': true};
      } else {
        return {};
      }
    },
    
    async selectType (tab, configuring) {
      if (this.subtabs[tab.name]) {
        return;
      }
      
      this.selectedType = tab.name;
      
      this.defaulted = {};
      this.value = this.editing ? JSON.parse(JSON.stringify(this.$store.getters.activeComponent.blockArgs)) : (this.initialValue || {});
      
      await this.$nextTick();
      
      this.activeTab = Object.values(this.corpusTypePresets)[0].block;
      this.drawerOpen = false;
      
      await this.$nextTick();
      
      this.cyclingPreset = Object.keys(this.corpusTypePresets)[0];
      
      Object.keys(this.form).forEach(key => {
        if (this.form[key].type === 'image') {
          let images = Object.values(this.$store.state.repo.images);
          if (this.form[key].subtype) {
            let filteredImages = images.filter(i => i.piesExtra?.type === this.form[key].subtype);
            if (filteredImages.length) {
              images = filteredImages;
            }
          }
          let image = choose(images);
          if (!this.defaulted[key]) {
            this.defaulted[key] = image && this.form[key].fill_blank_image !== false  ? `https://res.cloudinary.com/pies/image/upload/${image.public_id}` : '';
          }
        }
      });
      
      if (configuring) {
        this.value = {...this.defaulted, ...this.value};
        this.configuring = true;
        this.gallery = false;
      } else {
        this.configuring = false;
        this.gallery = true;
      }
    },
    
    setPreset (index) {
      return () => {
        this.cyclingPreset = Object.keys(this.corpusTypePresets)[index];
        this.configuring = true;
        this.gallery = false;
        
        const preset = this.corpusTypePresets[this.cyclingPreset];
        
        Object.keys(preset.defaultArgs || {}).forEach(key => {
          this.defaulted[key] = preset.defaultArgs[key] || this.defaulted[key];
        });
      };
    },
    
    cyclePresetRight () {
      const presets = Object.keys(this.corpusTypePresets);
      if (this.cyclingPreset === null) {
        this.cyclingPreset = presets[0];
        return;
      }
      const index = presets.indexOf(this.cyclingPreset);
      if (index >= presets.length - 1) {
        this.cyclingPreset = presets[0];
        return;
      }
      this.cyclingPreset = presets[index + 1];
    },
    
    cyclePresetLeft () {
      const presets = Object.keys(this.corpusTypePresets);
      if (this.cyclingPreset === null) {
        this.cyclingPreset = presets[presets.length - 1];
        return;
      }
      const index = presets.indexOf(this.cyclingPreset);
      if (index === 0) {
        this.cyclingPreset = presets[presets.length - 1];
        return;
      }
      this.cyclingPreset = presets[index - 1];
    },
    
    log: o => {
      console.log(o);
    },
    
    appendNewSubEntry () {
      this.value[this.activeFormTab] = this.value[this.activeFormTab] || [];
      this.value[this.activeFormTab].push(this.addingNewSubEntry);
      this.addingNewSubEntry = {};
    },
    
    maybeMdField: o => {
      if (o === 'html' || o === 'image' || o === 'object_list' || o === 'color') {
        return 'div';
      }
      return 'md-field';
    },
    
    prettyPrint: o => startCase(o),
    
    togglePreview (tab) {
      if (tab === 'save') {
        if (this.cyclingPreset) {
          this.$emit('close', { block: this.corpusCyclingPreset.block, value: {...this.defaulted, ...this.corpusCyclingPreset.defaultArgs, ...this.value, ...this.corpusCyclingPreset.args} });
          return;
        } else {
          this.$emit('close', { block: this.activeTab,
                                value: {...this.defaulted, ...this.value},
                                overrides: this.activeBlockOverrides,
                                designOverrides: this.activeBlockDesignOverrides });
          return;
        }
      }
      if (tab === 'gallery') {
        this.configuring = false;
        this.gallery = true;
      }
      if (tab === 'preview') {
        this.configuring = false;
        this.gallery = false;
      }
      if (tab === 'settings') {
        this.customize();
      }
    },
    
    customize () {
      this.configuring = true;
      this.gallery = false;
      this.value = {
        ...this.defaulted,
        ...this.value,
      };
    },
    
    iframeWrapper (content) {
      const fonts = this.$store.state.repo.config.fonts
            .filter(o => o && o.family)
            .map(({ family }) => `https://fonts.googleapis.com/css2?family=${family}:wght@400;700&display=swap`)
            .map(href => `<link rel='stylesheet' type='text/css' href="${href}" />`)
            .join('\n');
      
      return `
<html>
  <head>
  ${fonts}
  <my-script type="text/javascript">
    window.onload = function() {    
      if (parent) {        
        var oHead = document.getElementsByTagName("head")[0];        
        var arrStyleSheets = parent.document.getElementsByTagName("style");        
        for (var i = 0; i < arrStyleSheets.length; i++)            
          oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
        var arrStyleSheets = parent.document.getElementsByTagName("link");        
        for (var i = 0; i < arrStyleSheets.length; i++) {           
          if (arrStyleSheets[i].rel !== 'stylesheet' && arrStyleSheets[i].type !== 'text/css') continue;
          if (arrStyleSheets[i].href.endsWith('.css')) {
            oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
          }
        }
      }
    }
  </my-script>
  <style>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
  </style>
  </head>
  <body style='background:transparent'>
    <my-script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0" nonce="AbfZAi37"></my-script>
    <my-script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></my-script>
    <div id="app"></div>
    <div style='${this.$store.state.repo.config.layout.style}'>${content}</div>
  </body>
</html>`.replace(/my-script/g, "script");
      
    },
    
    renderCorpus (o, overrides = {}, defaults = {}, overridesToMerge = {}) {
      
      Object.keys(this.form).forEach(key => {
        if (this.value[key]) {
          return;
        }
        if (this.form[key].type === 'image') {
          let images = Object.values(this.$store.state.repo.images);
          if (this.form[key].subtype) {
            let filteredImages = images.filter(i => i.piesExtra?.type === this.form[key].subtype);
            if (filteredImages.length) {
              images = filteredImages;
            }
          }
          let image = choose(images);
          if (!this.defaulted[key]) {
            this.defaulted[key] = image && this.form[key].fill_blank_image !== false  ? `https://res.cloudinary.com/pies/image/upload/${image.public_id}` : '';
          }
        } else if (this.form[key].type === 'object_list') {
          this.defaulted[key] = new Array(this.form[key].default || 5).fill().map(() => {
            let object = {};
            Object.keys(this.form[key].fields).forEach(subkey => {
              if (this.form[key].fields[subkey].type === 'image') {
                let images = Object.values(this.$store.state.repo.images);
                if (this.form[key].fields[subkey].subtype) {
                  let filteredImages = images.filter(i => i.piesExtra?.type === this.form[key].fields[subkey].subtype);
                  if (filteredImages.length) {
                    images = filteredImages;
                  }
                }
                let image = choose(images);
                if (!object[subkey]) {
                  object[subkey] = image && this.form[key].fields[subkey].fill_blank_image !== false  ? `https://res.cloudinary.com/pies/image/upload/${image.public_id}` : '';
                }
              } else {
                object[subkey] = this.form[key].fields[subkey].default;
              }
            });
            return object;
          });
        } else {
          this.defaulted[key] = this.form[key].default;
        }
      });
      
      let blockArgs = {...this.defaulted, ...defaults, ...this.value, ...overrides};
      overridesToMerge = {};
      Object.keys(overridesToMerge).forEach(key => {
        blockArgs[key] = {
          ...blockArgs[key] || {},
          ...overridesToMerge[key],
        };
      });
      
      const code =  renderCorpus(o, blockArgs);
      
      const feeds = this.$store.getters.feeds;
      
      let html = (new Vue({
        render: Vue.compile(code).render,
        data () {
          return {
            feeds,
          };
        },
      })).$mount().$el.outerHTML;
      return html;
    },
    
    corpusByType (type) {
      console.log(Object.values(Corpus.blocks));
      
      return Object.values(Corpus.blocks).filter(o => o.type === type);
    },
  },
  computed: {
    activeBlockOverrides () {
      if (this.cyclingPreset) {
        return {};
      } 
      return {
        ...this.$store.getters.activeComponent.blockOverrides,
      };
    },
    activeBlockDesignOverrides () {
      if (this.cyclingPreset) {
        return {};
      } 
      return {
        ...this.$store.getters.activeComponent.blockDesignOverrides,
      };
    },
    
    selectedTab () {
      return this.tabs.find(o => o.name === this.selectedType) || {};
    },
    
    corpusCyclingPreset () {
      if (this.cyclingPreset) {
        let ret = this.corpusTypePresets[this.cyclingPreset] || {};
        ret = JSON.parse(JSON.stringify(ret));
        if (ret.args) {
          ret.args.styles = ret.args.styles || {};
          ret.args.mediaStyles = ret.args.mediaStyles || {};
          ret.args.contentStyles = ret.args.contentStyles || {};
        }
        return ret;
      } else {
        return {};
      }
    },
    
    corpusTypePresets () {
      const presets = Corpus.presets[this.selectedType];
      let obj = {};
      Object.keys(presets).forEach(key => {
        if (presets[key].hideFromGallery) return;
        obj[key] = presets[key];
      });
      return obj;
    },
    
    formTab () {
      return this.form[this.activeFormTab];
    },
    
    displayPreviewTabs () {
      if (this.selectedType) {
        return this.previewTabs;
      } else {
        return [];
      }
    },
    
    corpusByTypes () {
      let keys = {};
      Object.values(Corpus.blocks).forEach(b => {
        if (!keys[b.type]) {
          keys[b.type] = [];
        }
        keys[b.type].push(b);
      });
      return keys;
    },
    
    corpusTypes () {
      if (!this.filterCorpusTypes) {
        return Corpus.types;
      } else {
        let types = {};
        Object.entries(Corpus.types).forEach(o => {
          if (this.filterCorpusTypes.indexOf(o[0]) !== -1) {
            types[o[0]] = o[1];
          }
        });
        return types;
      }
    },
    form () {
      if (!this.selectedType) {
        return {};
      }
      return this.corpusTypes[this.selectedType].args;
    },
    
    groupedForm () {
      let menu = {
        'content': [],
        'design': [],
        'options': [],
      };
      Object.keys(this.form).forEach(key => {
        const val = this.form[key];
        if (['image', 'text', 'html', 'object_list'].indexOf(val.type) !== -1) {
          menu.content.push({ key, label: val.label });
        } else if (['color'].indexOf(val.type) !== -1) {
          menu.design.push({ key, label: val.label });
        } else {
          menu.options.push({ key, label: val.label });
        }
      });
      if (menu.content.length === 0) {
        delete menu.content;
      }
      if (menu.design.length === 0) {
        delete menu.design;
      }
      if (menu.options.length === 0) {
        delete menu.options;
      }
      return menu;
    },
  },
  
  async mounted () {
    if (this.editing && !this.selectedType) {
      await this.$nextTick();
      this.$emit('close', { action: 'destructure' });
    }
  },
  
  data () {
    
    let data = {
      drawerOpen: this.editing ? false : true,
      selectedType: this.editing ? Corpus.blocks[this.$store.getters.activeComponent.blockType]?.type : null,
      
      activeTab: this.editing ? this.$store.getters.activeComponent.blockType : null,
      activeFormTab: this.startOnForm || null,
      gallery: false,
      configuring: this.startOnForm ? true : false,
      
      value: this.editing ? JSON.parse(JSON.stringify(this.$store.getters.activeComponent.blockArgs)) : (this.initialValue || {}),
      addingNewSubEntry: {},
      editingSubEntry: null,
      
      cyclingPreset: null,
      
      defaulted: {},
      tabs: [
        { name: 'header', icon: 'flag-line', label: 'Page Banner or Divider' },
        { name: 'hero', icon: 'drag-move-2-line', label: 'Hero Unit / Call to Action' },
        { name: 'hero_background_image', icon: 'drag-move-2-line', label: 'Hero Unit With Background Image' },        
        { name: 'join_donate', icon: 'money-dollar-circle-line', label: 'Donate' },
        { name: 'long_text', icon: 'edit-2-line', label: 'Long Text Block' },
        { name: 'why_running', icon: 'chat-2-line', label: 'Short Text Block' },
        { name: 'platform', icon: 'question-answer-line', label: 'Issues' },
        { name: 'endorsements', icon: 'check-double-line', label: 'Endorsements' },
        { name: 'image_gallery', icon: 'image-add-line', label: 'Image Gallery' },
        { name: 'two_columns', icon: 'layout-column-line', label: 'Two Custom Columns' },
      ],
      subtabs: {
        endorsements: [
          { name: 'endorsements_text', icon: 'check-double-line', label: 'List of Endorsers' },
          { name: 'endorsements_one', icon: 'check-double-line', label: 'Single Endorsement' },          
          { name: 'endorsements_columns', icon: 'check-double-line', label: 'Organizations, With Logos' },
          { name: 'endorsements_columns_people', icon: 'check-double-line', label: 'People, With Photos' },
        ],
        platform: [
          { name: 'platform_one_image', icon: 'question-answer-line', label: 'Single Topic' },
          { name: 'platform_columns', icon: 'question-answer-line', label: 'Multiple Topics, With Photos' },
          { name: 'platform_columns_icons', icon: 'question-answer-line', label: 'Multiple Topics, With Icons' },
          { name: 'platform_columns_text', icon: 'question-answer-line', label: 'Multiple Topics, No Imagery' },
        ],
      },
      previewTabs: [
        { id: 'gallery', icon: 'shopping-bag-line', label: 'Gallery' },
        { id: 'settings', icon: 'settings-5-line', label: 'Customize' },
        { id: 'preview', icon: 'eye-line', label: 'Preview' },
        { id: 'save', icon: 'save-line', label: 'Save' },
      ],
    };
    if (this.filterCorpusTypes) {
      data.tabs = data.tabs.filter(o => this.filterCorpusTypes.indexOf(o.name) !== -1);
    }
    return data;
  },
}
</script>

<style scoped>
.md-button.go-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: purple;
  color: white !important;
  margin: 0 auto;
}
.md-list-item .configuring {
  display: none;
}
.md-list-item:hover .configuring {
  display: block;
  position: absolute;
  right: 1rem;
}
.md-drawer.md-active {
  padding-right: 0;
}
.md-drawer.md-active >>> .md-list-item-content {
  padding-right: 3rem;
  position: relative;
}
.md-list-item-content > .md-icon:first-child {
  margin-right: 12px;
}
.md-list .md-divider {
  margin-top: 12px;
  margin-left: -2rem;
  margin-right: -2rem;
}
.md-list .md-subheader {
  font-size: 12px;
  padding-top: 12px;
  margin-left: -10px;
}
.md-tabs {
  margin-bottom: 1rem;
}
.md-toolbar .md-tabs {
  width: 100%;
  margin-bottom: 0;
}
.md-drawer {
  width: auto;
  padding-right: 2rem;
  font-size: 2rem;
}
.md-icon-swipe-button {
  background: purple;
  --md-theme-default-icon-on-background: white;
}
</style>
