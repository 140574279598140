<template>
<div class="box-layout-input" :class="side">
  <div class="input-group">
    <input class="form-control" v-model="parsed.amount"
           :type="noUnit && 'number'"
           :step="stepSize"
           :class="noUnit && 'no-unit'" />
    <div v-if="!noUnit" class="input-group-append">
      <select class="form-control" v-model="parsed.unit" style="border-top-left-radius: 0; border-bottom-left-radius: 0">
        <option v-for="opt in units" :key="opt" :value="opt">{{opt}}</option>
      </select>
    </div>
  </div>
</div>
</template>

<script>
import { parseCssUnitValue } from '../../css-utils.js';

export default {
  name: 'BoxLayoutInput',
  props: ['side', 'value', 'noUnit', 'defaultUnit'],
  methods: {
    parseValue (value) {
      return parseCssUnitValue(value);
    },
  },
  computed: {
    stepSize () {
      if (this.parsed?.unit === 'px') {
        return 1;
      }
      if (this.parsed?.unit === 'em') {
        return .01;
      }
      return .1;
    },
    parsedAsString () {
      return `${this.parsed.amount}${this.parsed.unit}`;
    },
  },
  watch: {
    'parsed.unit' (val) {
      this.$emit('unit', val || '');
    },
    parsedAsString (val) {
      this.$emit('input', val);
    },
  },
  data () {
    return {
      units: [
        '', 'px', 'rem', 'em', '%', 'vh', 'vw', 'vmin', 'vmax',
      ],
      parsed: {
        amount: '',
        unit: '',
      },
    };
  },
  mounted () {
    let parsed = this.parseValue(this.value) || {amount: '', unit: ''};
    if (!parsed.unit) {
      parsed.unit = this.defaultUnit || '';
    }
    this.parsed = parsed;
  },
}
</script>

<style scoped>
input.form-control.no-unit {
  max-width: 5rem;
  padding: .75rem .25rem;
  text-align: right;
}
label input.form-control.no-unit::-webkit-outer-spin-button, 
label input.form-control.no-unit::-webkit-inner-spin-button {
  margin-left: .25rem;
} 

</style>
