<template>
<dialog-layout>
  <md-button style="position: absolute; top: 0; right: 0; transform: scale(1.5)"
             class="md-icon-button md-accent" @click="$emit('close')">
    <md-icon>
      <ri n="close-circle-line" />
    </md-icon>
  </md-button>

  
    <form-card class="mt-3" v-if="noLogo" :no-layout="true"
               title="No problem! We can make do with some text instead.">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <md-field>
            <label>What's your name?</label>
            <md-input type="text" v-model="candidateName" />
          </md-field>
        </div>
        <md-field>
          <label>What are you running for?</label>
          <md-input type="text" v-model="candidateOffice" />
        </md-field>
      
      <template v-if="candidateName && candidateOffice">
        <div class="md-layout-item md-size-100">        
          <md-field class="md-has-value md-borderless"
                    style="align-items: flex-start; justify-content: space-between; margin-bottom: -1rem">
            
            <div class="d-flex flex-column">
              <span style="visibility: hidden; position: unset; margin-bottom: .5em"
                    class="md-helper-text"></span>                
              <md-button class="go-button" @click="cycleLogoFont">Change font</md-button>
            </div>
            
            <div class="d-flex flex-column">
              <span style="position: unset; margin-bottom: .5em" class="md-helper-text">Or choose your own font:</span>
              <font-picker :active-font="logoFont.name"
                           :options="{ limit: 1000, families: showAllFonts ? null : recommendedLogoFonts }"
                           :key="showAllFonts ? 'all-fonts' : 'recommended-fonts'"
                           @change="logoFont.name = $event.family"
                           api-key="AIzaSyA5CKPUiLITXA1Bw2pmycPKVyULsuT_7R8"></font-picker>
              <md-switch v-model="showAllFonts">Show all font options</md-switch>

              <md-switch v-model="useSeparateFonts">Use separate fonts</md-switch>
              <font-picker v-if="useSeparateFonts && logoOfficeFont"
                           :active-font="logoOfficeFont.name"
                           :options="{ limit: 1000, families: showAllFonts ? null : recommendedLogoFonts }"
                           :key="showAllFonts ? 'office-all-fonts' : 'office-recommended-fonts'"
                           @change="logoOfficeFont.name = $event.family"
                           api-key="AIzaSyA5CKPUiLITXA1Bw2pmycPKVyULsuT_7R8"></font-picker>
            </div>
            
          </md-field>

          <md-field class="md-has-value md-borderless">
            <label style="margin-left: 0; margin-right: auto">Choose your colors:</label>
            <div style="margin-top: .5em;">
              <color-picker :disable-alpha="true" v-model="logoSecondaryColor" expand-to="bottom-right" />
            </div>
          </md-field>
          <md-field class="md-has-value md-borderless">
            <md-switch v-model="showMoreLogoOptions">More Options</md-switch>
          </md-field>
        </div>

        <div class="md-layout-item md-size-33">
          <md-field v-if="showMoreLogoOptions" class="md-has-value md-borderless">
            <label>Line height</label>
            <vue-material-slider :min="0" :max="3" :step-size=".01"
                                 v-model="textBasedLogoStyles['line-height']" />
          </md-field>
        </div>
        <div class="md-layout-item md-size-33">
          <md-field v-if="showMoreLogoOptions" class="md-has-value md-borderless">
            <label>Space below name</label>
            <vue-material-slider :min="0" :max="2" :step-size=".01"
                                 :value="textBasedLogoNameStyles['margin-bottom'].replace('em', '')"
                                 @input="textBasedLogoNameStyles['margin-bottom'] = $event + 'em'" />
          </md-field>
        </div>
        <div class="md-layout-item md-size-33">
          <md-field v-if="showMoreLogoOptions" class="md-has-value md-borderless">
            <label>Border</label>
            <vue-material-slider :min="0" :max="10"
                                 :value="textBasedLogoStyles['border-width'].replace('px', '')"
                                 @input="textBasedLogoStyles['border-width'] = $event + 'px'" />
            <color-picker :disable-alpha="true"
                          v-model="textBasedLogoStyles['border-color']"
                          :fallback="logoSecondaryColor"
                          :key="logoSecondaryColor"
                          expand-to="bottom-right" />
          </md-field>
        </div>
        <div class="md-layout-item md-size-33">
          <md-field v-if="showMoreLogoOptions && textBasedLogoStyles['border-width'] !== '0px'"
                    class="md-has-value md-borderless">
            <label>Rounding</label>
            <vue-material-slider :min="0" :max="100"
                                 :value="textBasedLogoStyles['border-radius'].replace('px', '')"
                                 @input="textBasedLogoStyles['border-radius'] = $event + 'px'" />
          </md-field>
        </div>
        <div class="md-layout-item md-size-33">
          <md-field v-if="showMoreLogoOptions && textBasedLogoStyles['border-width'] !== '0px'"
                    class="md-has-value md-borderless">
            <label>Height</label>
            <vue-material-slider :min="0" :max="5" :step-size=".01"
                                 :value="textBasedLogoStyles['padding-top'].replace('em', '')"
                                 @input="textBasedLogoStyles['padding-top'] = textBasedLogoStyles['padding-bottom'] = $event + 'em'" />
            
          </md-field>
        </div>
        <div class="md-layout-item md-size-33">
          <md-field v-if="showMoreLogoOptions && textBasedLogoStyles['border-width'] !== '0px'"
                    class="md-has-value md-borderless">
            <label>Width</label>
            <vue-material-slider :min="0" :max="5" :step-size=".01"
                                 :value="textBasedLogoStyles['padding-left'].replace('em', '')"
                                 @input="textBasedLogoStyles['padding-left'] = textBasedLogoStyles['padding-right'] = $event + 'em'" />
            
          </md-field>
        </div>

        <md-field v-if="showMoreLogoOptions" class="md-has-value md-borderless">
          <md-switch v-model="showEvenMoreLogoOptions">Even more Options</md-switch>
        </md-field>
        

        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field>
            <label>Name Alignment</label>
            <md-select v-model="textBasedLogoNameStyles['text-align']">
              <md-option value="left">left</md-option>
              <md-option value="right">right</md-option>
              <md-option value="center">center</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field>
            <label>Capitalization</label>
            <md-select v-model="textBasedLogoNameStyles['text-transform']">
              <md-option value="none">none</md-option>
              <md-option value="uppercase">uppercase</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field>
            <label>Office Alignment</label>
            <md-select v-model="textBasedLogoOfficeStyles['text-align']">
              <md-option value="left">left</md-option>
              <md-option value="right">right</md-option>
              <md-option value="center">center</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field>
            <label>Capitalization</label>
            <md-select v-model="textBasedLogoOfficeStyles['text-transform']">
              <md-option value="none">none</md-option>
              <md-option value="uppercase">uppercase</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field>
            <label>Style</label>
            <md-select v-model="textBasedLogoNameStyles['font-style']">
              <md-option value="normal">normal</md-option>
              <md-option value="italic">italic</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field>
            <label>Style</label>
            <md-select v-model="textBasedLogoOfficeStyles['font-style']">
              <md-option value="normal">normal</md-option>
              <md-option value="italic">italic</md-option>
            </md-select>
          </md-field>
        </div>
        
        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field class="md-has-value md-borderless">
            <label>Name rotation</label>
            <vue-material-slider :min="-45" :max="45" :step-size="1"
                                 :value="textBasedLogoNameStyles['transform'].split('rotate(')[1].split('deg)')[0]"
                                 @input="textBasedLogoNameStyles['transform'] = 'rotate(' + $event + 'deg)'" />
          </md-field>
        </div>
        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field class="md-has-value md-borderless">
            <label>Office rotation</label>
            <vue-material-slider :min="-45" :max="45" :step-size="1"
                                 :value="textBasedLogoOfficeStyles['transform'].split('rotate(')[1].split('deg)')[0]"
                                 @input="textBasedLogoOfficeStyles['transform'] = 'rotate(' + $event + 'deg)'" />
          </md-field>
        </div>


        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field class="md-has-value md-borderless">
            <label>Name overline</label>
            <vue-material-slider :min="0" :max="10" :step-size="1"
                                 :value="textBasedLogoNameStyles['border-top-width'].replace('px', '')"
                                 @input="textBasedLogoNameStyles['border-top-width'] = $event + 'px'" />
          </md-field>
        </div>
        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field class="md-has-value md-borderless">
            <label>Name underline</label>
            <vue-material-slider :min="0" :max="10" :step-size="1"
                                 :value="textBasedLogoNameStyles['border-bottom-width'].replace('px', '')"
                                 @input="textBasedLogoNameStyles['border-bottom-width'] = $event + 'px'" />
          </md-field>
        </div>
        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field class="md-has-value md-borderless">
            <label>Office overline</label>
            <vue-material-slider :min="0" :max="10" :step-size="1"
                                 :value="textBasedLogoOfficeStyles['border-top-width'].replace('px', '')"
                                 @input="textBasedLogoOfficeStyles['border-top-width'] = $event + 'px'" />
          </md-field>
        </div>
        <div class="md-layout-item md-size-25" v-if="showEvenMoreLogoOptions">
          <md-field class="md-has-value md-borderless">
            <label>Office underline</label>
            <vue-material-slider :min="0" :max="10" :step-size="1"
                                 :value="textBasedLogoOfficeStyles['border-bottom-width'].replace('px', '')"
                                 @input="textBasedLogoOfficeStyles['border-bottom-width'] = $event + 'px'" />
          </md-field>
        </div>
                
      </template>
      </div>
    </form-card>

    <div v-if="noLogo" class="mt-3" v-html="textBasedLogo"
         ref="text-based-logo"
         :style="{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center' }"></div>

    <button @click="rasterize" class="btn btn-primary w-100 mt-3">Use this!</button>

    <div ref="rasterized"></div>
  </dialog-layout>
</template>

<script>
import { writeStyleString } from '../../utils.js';
import ColorPicker from '../ColorPicker.vue';
import html2canvas from 'html2canvas';
import { post } from 'axios';

const choose = arr => arr[Math.floor(Math.random() * arr.length)];

const recommendedLogoFonts =  [
  'Libre Baskerville',
  'Architects Daughter',
  'Shadows Into Light',
  'PT Sans Narrow',
  'Fjalla One',
  'Oswald',
  'Merriweather',
  'Permanent Marker',
  'Mukta',
  'Libre Franklin',
  'Arvo',
  'Barlow',
  'Signika',
  'Abel',
  'Lato',
];

export default {
  components: {
    ColorPicker,
  },
  methods: {
    async rasterize () {
      this.$store.commit('setLoading', true);      
      let h1 = this.$refs['text-based-logo'].querySelector("h1");
      h1.style.transform = 'scale(600%)';
      html2canvas(h1).then(async canvas => {
        const png = canvas.toDataURL();
        
        post('https://api.cloudinary.com/v1_1/pies/image/upload', {
          file: png,
          folder: 'test-widget-upload',
          upload_preset: 'uth5vfjo',
        }).then(async response => {
          const info = {
            ...response.data,
            piesExtra: {
              type: 'logo',
              alt_text: `${this.candidateName} for ${this.candidateOffice}`,
            }
          };

          const transparentUrl = `https://res.cloudinary.com/pies/image/upload/e_bgremoval/${info.public_id}`;
          
          post('https://api.cloudinary.com/v1_1/pies/image/upload', {
            file: transparentUrl,
            folder: 'test-widget-upload',
            upload_preset: 'uth5vfjo',
          }).then(async response2 => {
            
            const info2 = {
              ...response2.data,
              piesExtra: {
                type: 'logo',
                alt_text: `${this.candidateName} for ${this.candidateOffice}`,
              }
            };
            
            await this.$store.commit('putImage', {
              key: info2.public_id.replace('/', ':'),
              info: info2,
            });
            await this.$store.dispatch('registerImageMetadata', {
              info: info2,
            });
            
            h1 = this.$refs['text-based-logo'].querySelector("h1");            
            h1.style.transform = 'scale(600%)';
            h1.style.backgroundColor = 'black';
            h1.style.color = 'white';
            
            html2canvas(h1).then(async canvas => {
              const png2 = canvas.toDataURL();
              
              post('https://api.cloudinary.com/v1_1/pies/image/upload', {
                file: png2,
                folder: 'test-widget-upload',
                upload_preset: 'uth5vfjo',
              }).then(async response => {
                const info3 = {
                  ...response.data,
                  piesExtra: {
                    type: 'logo',
                    alt_text: `${this.candidateName} for ${this.candidateOffice}`,
                  }
                };
                
                const transparentUrl2 = `https://res.cloudinary.com/pies/image/upload/e_bgremoval/${info3.public_id}`;
                post('https://api.cloudinary.com/v1_1/pies/image/upload', {
                  file: transparentUrl2,
                  folder: 'test-widget-upload',
                  upload_preset: 'uth5vfjo',
                }).then(async response3 => {
                  
                  const info4 = {
                    ...response3.data,
                    piesExtra: {
                      type: 'logo',
                      alt_text: `${this.candidateName} for ${this.candidateOffice}`,
                    }
                  };
                  
                  await this.$store.commit('putImage', {
                    key: info4.public_id.replace('/', ':'),
                    info: info4,
                  });
                  await this.$store.dispatch('registerImageMetadata', {
                    info: info4,
                  });
                  
                  const transparentBaseUrl = `https://res.cloudinary.com/pies/image/upload/${info2.public_id}`;
                  this.$store.commit('setLoading', false);
                  
                  this.$emit('close', {
                    ...this.$data,
                    logoImageOriginal: transparentBaseUrl,
                    logoImageInverted: `https://res.cloudinary.com/pies/image/upload/${info4.public_id}`,
                    logoImageIsTextBased: true,
                  });

                });
              });
            });

          });
        });
      });
    },
    cycleLogoFont () {
      this.logoFont = { name: choose(this.recommendedLogoFonts.filter(o => o !== this.logoFont.name)) };
    },
  },

  watch: {
    
    /*textBasedLogoHasBorder () {
      if (this.textBasedLogoHasBorder) {
        this.$set(this.textBasedLogoStyles, 'background-color', this.logoPrimaryColor);
      } else {
        delete this.textBasedLogoStyles['background-color'];
      }
    },

    logoPrimaryColor () {
      if (this.textBasedLogoHasBorder) {
        this.$set(this.textBasedLogoStyles, 'background-color', this.logoPrimaryColor);
      } else {
        delete this.textBasedLogoStyles['background-color'];
      }
    },*/

    useSeparateFonts (val) {
      if (val) {
        this.logoOfficeFont = { name: this.logoFont.name };
      } else {
        this.logoOfficeFont = null;
      }
    },
    
    logoOfficeFont: {
      deep: true,
      handler (val) {
        if (val) {
          this.textBasedLogoOfficeStyles['font-family'] = val.name;
        } else {
          this.textBasedLogoOfficeStyles['font-family'] = 'inherit';
        }
      },
    },
    
    logoFont: {
      deep: true,
      handler (val) {
        this.textBasedLogoStyles['font-family'] = val.name;
      },
    },

    logoSecondaryColor (val) {
      this.textBasedLogoStyles['color'] = val;
    },
    
  },
  computed: {

    textBasedLogoHasBorder () {
      return true; // this.textBasedLogoStyles['border-width'] !== '0px';
    },
    
    textBasedLogo () {
      if (!this.noLogo) {
        return '';
      }

      if (!this.candidateName || !this.candidateOffice) {
        return '';
      }

      return `
<h1 style="${writeStyleString(this.textBasedLogoStyles)}">
  <span style="${writeStyleString(this.textBasedLogoNameStyles)}">
    ${this.candidateName}
  </span>
  <br />
  <span style="${writeStyleString(this.textBasedLogoOfficeStyles)}">
    for ${this.candidateOffice}
  </span>
</h1>`;
      
    },
  },

  data () {
    let chosenLogoFont = choose(recommendedLogoFonts);

    return {
      noLogo: true,
      showAllFonts: false,
      
      showMoreLogoOptions: false,
      showEvenMoreLogoOptions: false,      
      useSeparateFonts: false,
      
      recommendedLogoFonts,
      
      textBasedLogoNameStyles: {
        'font-size': '2em',
        'display': 'inline-block',
        'margin-bottom': '0em',
        'text-transform': 'none',
        'border-style': 'solid',
        'border-top-width': '0px',
        'border-bottom-width': '0px',
        'border-left-width': '0px',
        'border-right-width': '0px',
        'font-style': 'normal',
        'width': '100%',
        'text-align': 'center',
        'transform': 'rotate(0deg)',
      },

      textBasedLogoOfficeStyles: {
        'display': 'inline-block',
        'text-transform': 'none',
        'font-style': 'normal',
        'border-top-width': '0px',
        'border-bottom-width': '0px',
        'width': '100%',
        'border-left-width': '0px',
        'border-right-width': '0px',
        'border-style': 'solid',
        'text-align': 'center',
        'transform': 'rotate(0deg)',
        'font-family': 'inherit',
      },
      
      textBasedLogoStyles: {
        'line-height': 1.15,
        'color': '',
        'font-family': chosenLogoFont,
        'border-width': '0px',
        'border-radius': '0px',
        'border-style': 'solid',
        'padding-top': '1em',
        'padding-bottom': '1em',
        'padding-right': '1em',
        'padding-left': '1em',
      },

      logoFont: { name: chosenLogoFont },
      logoOfficeFont: null,
      
      candidateName: '',
      candidateOffice: '',

      logoPrimaryColor: '#ffffff',
      logoSecondaryColor: '#000000',
    };
  },
}
</script>

<style scoped>
.md-button.go-button {
  height: auto;
  max-height: 2rem;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: purple;
  color: white;
  margin: 0;
}
.btn {
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 700;
  background-color: rgb(26, 147, 193);
  border: none;
  color: white !important;
  text-decoration: none !important;
  letter-spacing: 0px;
  font-size: 1.1rem;
  padding: .25rem .5rem;
}
.btn:hover {
  background-color: rgb(26, 147, 193);
  filter: brightness(1.2);
  color: white;
}
.btn-primary, 
.btn-primary:hover {
  background-color: rgb(154, 40, 139);
}
</style>
