<template>
<dialog-layout>
  <md-button style="position: absolute; top: 0; right: 0; transform: scale(1.5)"
             class="md-icon-button md-accent" @click="$emit('close')">
    <md-icon>
      <ri n="close-circle-line" />
    </md-icon>
  </md-button>

  <form-card @submit="saveLink" save-button="Add Link">
    <md-field class="md-has-value md-borderless">
      <label>
        Link to
      </label>
      <md-radio v-model="linkType" value="page">A page on this site</md-radio>
      <md-radio v-model="linkType" value="url">Another website URL</md-radio>
    </md-field>

    <template v-if="linkType === 'page'">
      <md-field v-show="!selectingPage">
        <label>Page</label>
        <md-input :placeholder="linkPage" :value="linkPage"
                  readonly @click="selectingPage = true"></md-input>
      </md-field>
    
      <md-table v-model="pages" v-show="selectingPage">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell class="go-cell" width="10">
            <md-button class="primary-button" @click.prevent="selectingPage = false; page = item.key">Select</md-button>
          </md-table-cell>
          <md-table-cell md-label="Title">
            <span>
              <a style="text-decoration: underline; color: inherit"
                 href="#" @click.prevent="selectingPage = false; page = item.key">
                {{ computedPage(item.key).title }}
              </a>
            </span>
          </md-table-cell>
          <md-table-cell md-label="Path">
            <span>{{ item.key }}</span>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </template>

    <md-field v-else-if="linkType === 'url'">
      <label>URL</label>
      <md-input v-model="url" />
    </md-field>
    
  </form-card>
  
</dialog-layout>
</template>

<script>
import { computedPage } from '../page-utils.js';

export default {
  name: 'PageList',

  data () {
    return {
      linkType: 'page',
      url: '',
      page: '',
      selectingPage: false,
    };
  },

  mounted () {
    if (this.previousUrl && this.previousUrl.startsWith('/')) {
      this.page = this.previousUrl;
    } else {
      this.linkType = 'url';
      this.url = this.previousUrl || '';
    }
  },
  
  props: [
    'previousUrl',
  ],

  methods: {

    saveLink () {
      let link = {};
      if (this.linkType === 'page') {
        link.to = this.page;
      }
      if (this.linkType === 'url') {
        link.href = this.url;
      }
      this.$emit('close', link);
    },
    
    computedPage (o) {
      if (!o) return { title: '' };
      return computedPage(o, this.$store.state);
    },

  },
  
  computed: {

    linkPage: {
      get () {
        if (this.linkType !== 'page') {
          return null;
        }
        const page = this.pages.filter(o => o.key === this.page.replace(/^\//, '').trim())[0];
        if (!page) {
          return null;
        }
        const computed = this.computedPage(page.key);
        return `${computed.title}: /${computed.key}`;
      },
      set (val) {
        this.linkType = 'page'
        this.page = `/${val}`;
      },
    },
    
    pages () {
      let pages = [];
      Object.keys(this.$store.state.repo.pages)
        .filter(key => !this.only || this.only.indexOf(key) !== -1)
        .forEach(key => {
          const keyForHead = key.split('/').pop(); // only use the last path component in head obj
          const head = this.$store.state.repo.config.head[keyForHead] || {},
                meta = head.meta || [];
          
          let metaObj = {};
          meta.forEach(o => {
            metaObj[o.property || o.name] = o.content || o.value;
          });
          
          pages.push({
            key,
            metaObj,
            head,
          });
        });
      return pages;
    },
  },
}
</script>

<style scoped>
.md-drawer {
  width: auto;
  min-width: 250px;
  font-size: 2rem;
}
.md-table .md-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: #eee;
  margin: 0;
}
.md-table .md-button.primary-button {
  background: purple;
  color: white;
  font-weight: bold;
}

.md-table >>> .md-table-cell-container {
  display: flex;
  align-items: center;
}
.md-table >>> .md-table-head-label {
  color: black;
}
.md-table .go-cell >>> .md-table-cell-container {
  padding: 6px 0;
}
.md-tabs {
  margin-bottom: 1rem;
}
.md-toolbar .md-tabs {
  width: 100%;
  margin-bottom: 0;
}
.md-toolbar .md-tabs >>> .md-button {
  margin-top: .5rem;
  padding-top: .5rem;
  margin-bottom: .5rem;
  min-height: 52px;
}
.md-button.go-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: purple;
  color: white;
  margin: 0;
}
.md-table div {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
}
.md-table .meta {
  position: relative;
}
.md-table .meta img {
  max-width: 100%;
  max-height: 100%;
}
.md-table .meta span {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
}
.md-table .meta span:hover {
  background: lightyellow;
}
.editing-meta .md-table-cell-container {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.editing-meta .md-table-cell-container textarea {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.editing-meta-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editing-meta-buttons .btn {
    font-size: 0.9rem;
    padding: 0.1rem .5rem;
    min-width: 0;
}
</style>
