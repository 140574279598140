<template>
  <dialog-layout>
    <multi-image-input :image="realImage" @input="updateImage"></multi-image-input>

    <div style="text-align:center">
      <img style="max-width: 100%; max-height: 40vh;" :src="realImage">
    </div>
    
    <button @click="$emit('close', realImage)">Close dialog</button>
  </dialog-layout>
</template>

<script>
import MultiImageInput from '../MultiImageInput.vue';

export default {
  name: 'DialogImageInput',
  components: { MultiImageInput },
  props: MultiImageInput.props,
  methods: {
    updateImage (val) {
      this.realImage = val;
    },
  },
  data () {
    return {
      realImage: this.image,
    };
  },
}
</script>
