<template>
<md-app style="min-height: min(80vh, 800px)">
  <md-app-toolbar>
  </md-app-toolbar>
  
  <md-app-drawer md-permanent="clipped">
    <md-list>
      <md-list-item v-for="tab in tabs"
                    :key="tab.id"
                    @click="activeTab = tab.id"
                    :class="activeTab === tab.id ? 'md-active' : ''">
        <md-icon><ri :n="tab.icon" /></md-icon>
        <span class="md-list-item-text">{{ tab.label }}</span>
      </md-list-item>
      <md-list-item @click="activeTab = '__add-new'"
                    :class="activeTab === '__add-new' ? 'md-active' : ''">
        <md-icon><ri n="folder-add-line" /></md-icon>
        <span class="md-list-item-text">Add New</span>
      </md-list-item>
    </md-list>
  </md-app-drawer>
  
  <md-app-content>
    <md-empty-state v-if="!activeTab">
      Choose a content type please.
    </md-empty-state>
    
    <div v-else>
      
      <md-field v-if="activeTab === '__add-new'">
        <label>Single</label>
        <md-file accept="*.csv" v-model="uploadedFile" @md-change="parseUploadedFile" />
      </md-field>
      
      <form-card v-if="rows.length" @submit="saveCollection">
        <div class="md-content md-table md-theme-default">
          <div class="md-content md-table-content md-scrollbar md-theme-default">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th class="md-table-head" v-for="col in columns" :key="col.id">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ col.label }}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <draggable :list="rows" tag="tbody">
                <tr class="md-table-row" v-for="item, index in rows" :key="index">
                  <td class="md-table-cell index-cell">
                    <div class="md-table-cell-container">
                      {{ index + 1 }}
                    </div>
                  </td>
                  <td class="md-table-cell" v-for="col in columns" :key="col.id">
                    <div class="md-table-cell-container">
                      <md-field class="md-borderless md-has-value">
                        <md-input v-model="item[col.id]" />
                      </md-field>
                    </div>
                  </td>
                </tr>
              </draggable>
              <tfoot>
                <tr class="md-table-row" v-for="newItem, index in newItems" :key="index">
                  <td class="md-table-cell index-cell">
                    <div class="md-table-cell-container">
                      {{ rows.length + index + 1 }}
                    </div>
                  </td>
                  <td class="md-table-cell" v-for="col in columns" :key="col.id">
                    <div class="md-table-cell-container">                  
                      <md-field class="md-borderless md-has-value">
                        <md-input @blur="maybeAppendNewItem" v-model="newItem[col.id]" />
                      </md-field>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </form-card>
    </div>
  </md-app-content>
</md-app>
</template>

<script>
import { startCase } from 'lodash';
import draggable from 'vuedraggable'
import Papa from 'papaparse';

const slugify = (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')
  
  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export default {
  components: {
    draggable,
  },

  methods: {

    async saveCollection () {
      let content_type = this.activeTab === '__add-new' ? this.uploadedFile : this.activeTab;
      content_type = slugify(content_type.replace(/\.csv$/, ''));
      const rows = (this.activeTab === '__add-new' ? this.adding : [...this.rows, ...this.newItems]).filter(o => {
        return (Object.values(o).filter(i => i && i.trim())).length;
      });
      await this.$store.dispatch('putContentSpreadsheet', { content_type, rows });
      this.newItems = [{}];
    },
    
    maybeAppendNewItem () {
      if (Object.values(this.newItems[this.newItems.length - 1]).filter(o => o).length) {
        this.newItems.push({});
      }
    },

    parseUploadedFile (fileList) {
      if (!fileList.length) {
        return;
      }
      Papa.parse(fileList[0], {
        complete: this.afterParse,
      });
    },

    afterParse (results) {
      const header = results.data.shift();
      this.adding = results.data.map(row => {
        let obj = {};
        row.forEach((col, i) => {
          const field = header[i];
          obj[field] = col;
        });
        return obj;
      });
    },
  },

  computed: {
    tabs () {
      return Object.keys(this.$store.state.repo.contentSpreadsheets).map(key => ({
        id: key,
        label: key,
        icon: 'brush-line',
      }));
    },

    columns () {
      if (!this.activeTab) {
        return [];
      }
      if (this.rows.length === 0) {
        return [];
      }
      return Object.keys(this.rows[0]).map(o => ({
        id: o,
        label: startCase(o),
      }));
    },

    rows () {
      if (!this.activeTab) {
        return [];
      }
      if (this.activeTab === '__add-new') {
        return this.adding;
      } 
      return this.$store.state.repo.contentSpreadsheets[this.activeTab];
    },
  },
  
  data () {
    return {
      table: null,
      activeTab: null,
      newItems: (new Array(1)).fill().map(() => ({})),

      adding: [],
      uploadedFile: null,
    };
  },


}
</script>

<style scoped>
.index-cell {
  width: 1em !important;
}
.index-cell .md-table-cell-container {
  font-size: 12px !important;
}
.md-table .md-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: #eee;
  margin: 0;
}
tbody tr:nth-child(even),
tfoot tr:nth-child(even) {
  background: #fafafa !important;
}
.md-table .md-button.primary-button {
  background: purple;
  color: white;
  font-weight: bold;
}

.md-table .md-table-cell-container {
  display: flex;
  align-items: center;
}
.md-table .md-table-head-label {
  color: black;
}
.md-table .go-cell .md-table-cell-container {
  padding: 6px 0;
}
.md-tabs {
  margin-bottom: 1rem;
}
.md-toolbar .md-tabs {
  width: 100%;
  margin-bottom: 0;
}
.md-toolbar .md-tabs >>> .md-button {
  margin-top: .5rem;
  padding-top: .5rem;
  margoin-bottom: .5rem;
  min-height: 52px;
}
.md-button.go-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: purple;
  color: white;
  margin: 0;
}
.md-table div {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
}
.md-table .meta {
  position: relative;
}
.md-table .meta img {
  max-width: 100%;
  max-height: 100%;
}
.md-table .meta span {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
}
.md-table .meta span:hover {
  background: lightyellow;
}
.editing-meta .md-table-cell-container {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.editing-meta .md-table-cell-container textarea {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.editing-meta-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editing-meta-buttons .btn {
    font-size: 0.9rem;
    padding: 0.1rem .5rem;
    min-width: 0;
}
.md-table .md-table-head-label {
  font-size: 12px;
}
.md-table-cell .md-field {
  height: 48px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.md-table-cell .md-field input {
  font-size: 13px;
}
</style>  
