<template>
<div class="color-container" v-on-clickaway="collapse" :style="styles">
  <div @click="expanded = !expanded" class="color-preview">
    <div class="color-checkerboard"></div>
    <div :style="{ backgroundColor: realValue }"></div>
  </div>
  <sketch-picker class="color-picker"
                 :style="position"
                 v-if="expanded" :value="realValue" @input="realValue = rgba($event)"
                 :preset-colors="presetColors"
                 :disable-alpha="disableAlpha" />
  <!--<ri v-if="clearable" n="close-line" />-->
</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { hexToRgba, getPalette } from '../color-utils.js';

export default {
  name: 'color-picker',
  props: ['value', 'fallback', 'extra', 'disableAlpha', 'expandTo', 'size', 'clearable'],
  mixins: [ clickaway ],
  watch: {
    value () {
      this.realValue = this.value;
    },
    realValue () {
      this.$emit('input', this.realValue);
    },
    expanded (val) {
      if (val) {
        this.$emit('color-picker-expanded');
      } else {
        this.$emit('color-picker-collapsed');
      }
    },
  },
  methods: {
    collapse () {
      this.expanded = false;
    },
    rgba: ({ hex, a }) => hexToRgba(hex, a),
  },
  computed: {
    styles () {
      let s = {};
      if (this.size) {
        s['--size'] = `${this.size}px`;
      }
      if (this.clearable) {
        s['display'] = 'flex';
      }
      return s;
    },
    position () {
      if (this.expandTo === 'center') {
        return { top: '100%', transform: 'translateY(-50%)' };
      }
      if (this.expandTo === 'center-left') {
        return { top: '100%', transform: 'translate(-50%, -50%)' };
      }
      if (this.expandTo === 'center-right') {
        return { top: '100%', transform: 'translate(20%, -50%)' };
      }
      if (this.expandTo === 'bottom-left') {
        return { right: 0 };
      }
      if (this.expandTo === 'top-left') {
        return { right: 0, bottom: 0 };
      }
      if (this.expandTo === 'top-right') {
        return { bottom: 0 };
      }
      return {};
    },
    extraPresets () {
      return Object.values(getPalette(this.extra || {}));
    },
    presetColors () {
      let colors = [
        ...this.extraPresets,
        ...[
          '--theme--color__heavy',
          '--theme--color__secondary',
          '--theme--color__accent',
          '--button-color',
          '--button-text-color',
          '--button-color__hover',
          '--button-text-color__hover',
          '--theme--color__text',
        ].map(o => this.$store.getters.styles[o])
      ].filter(o => o);
       
      return [...new Set(colors)];
    },
  },
  data () {
    return {
      realValue: this.value || this.fallback || 'rgba(0,0,0,0)',
      expanded: false,
    };
  },
}
</script>

<style scoped>
.color-container {
  position: relative;
  display: inline-block;
}
.color-preview {
  width: var(--size, 40px);
  height: var(--size, 40px);
  position: relative;
  border: 1px solid black;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 15%), inset 0 0 4px rgb(0 0 0 / 25%);
}
.color-preview .color-checkerboard {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADBJREFUOE9jfPbs2X8GPEBSUhKfNAPjqAHDIgz+//+PNx08f/4cfzoYNYCBceiHAQC5flV5JzgrxQAAAABJRU5ErkJggg==");
}
.color-preview > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.color-picker {
  position: absolute;
  z-index: 999;
}
</style>
