import types from './exquisite-corpse/types.js';
import * as WhyRunning from './exquisite-corpse/why-running.js';
import * as LongText from './exquisite-corpse/long-text.js';
import { hero_shape_background_divider, hero_background_image_driven_gradient, hero_background_gradient, hero_background_no_gradient, hero_background_no_tint, hero_shape_divider } from './exquisite-corpse/hero.js';
import * as Header from './exquisite-corpse/header.js';
import * as GenericShortTiles from './exquisite-corpse/generic-short-tiles.js';
import * as Platform from './exquisite-corpse/platform.js';
import * as JoinDonate from './exquisite-corpse/join_donate.js';
import * as Endorsements from './exquisite-corpse/endorsements.js';
import * as ImageGallery from './exquisite-corpse/image_gallery.js';
import * as TwoColumns from './exquisite-corpse/two-columns.js';
import * as MultiColumns from './exquisite-corpse/multi-columns.js';

import * as HeroPresets from './exquisite-corpse/presets/hero.js';
import * as HeaderPresets from './exquisite-corpse/presets/header.js';
import * as LongTextPresets from './exquisite-corpse/presets/long_text.js';
import * as WhyRunningPresets from './exquisite-corpse/presets/why-running.js';
import * as EndorsementsPresets from './exquisite-corpse/presets/endorsements.js';
import * as EndorsementsTextPresets from './exquisite-corpse/presets/endorsements-text.js';
import * as EndorsementsSinglePresets from './exquisite-corpse/presets/endorsement-single.js';
import * as EndorsementsPhotosPresets from './exquisite-corpse/presets/endorsements-photos.js';
import * as PlatformColumnsPresets from './exquisite-corpse/presets/platform-columns.js';
import * as PlatformColumnsIconsPresets from './exquisite-corpse/presets/platform-columns-icons.js';
import * as PlatformColumnsTextPresets from './exquisite-corpse/presets/platform-columns-text.js';
import * as PlatformPresets from './exquisite-corpse/presets/platform-single.js';
import * as JoinDonatePresets from './exquisite-corpse/presets/join_donate.js';
import * as TwoColumnsPresets from './exquisite-corpse/presets/two-columns.js';
import * as MultiColumnsPresets from './exquisite-corpse/presets/multi-columns.js';
import * as ImageGalleryPresets from './exquisite-corpse/presets/image_gallery.js';

let HeroBackgroundPresets = {};
HeroBackgroundPresets['hero_image_breakout_band'] = HeroPresets['hero_image_breakout_band'];
HeroBackgroundPresets['hero_image_why_running_photo'] = HeroPresets['hero_image_why_running_photo'];

export default {
  types,
  blocks: {
    hero_shape_divider,
    hero_shape_background_divider,
    hero_background_image_driven_gradient,     
    hero_background_gradient,
    hero_background_no_gradient,
    hero_background_no_tint,    
    ...WhyRunning,
    ...LongText,
    ...Header,
    ...GenericShortTiles,
    ...Platform,
    ...JoinDonate,
    ...Endorsements,
    ...ImageGallery,
    ...TwoColumns,
    ...MultiColumns,    
  },
  presets: {
    hero: {
      ...HeroPresets,
    },
    hero_background_image: {
      ...HeroBackgroundPresets,
    },
    header: {
      ...HeaderPresets,
    },
    long_text: {
      ...LongTextPresets,
    },
    why_running: {
      ...WhyRunningPresets,
    },
    endorsements_one: {
      ...EndorsementsSinglePresets,
    },
    endorsements_text: {
      ...EndorsementsTextPresets,
    },
    endorsements_columns: {
      ...EndorsementsPresets,
    },
    endorsements_columns_people: {
      ...EndorsementsPhotosPresets,
    },
    platform_one_image: {
      ...PlatformPresets,
    },
    platform_columns: {
      ...PlatformColumnsPresets,
    },
    platform_columns_icons: {
      ...PlatformColumnsIconsPresets,
    },
    platform_columns_text: {
      ...PlatformColumnsTextPresets,
    },
    join_donate: {
      ...JoinDonatePresets,
    },
    two_columns: {
      ...TwoColumnsPresets,
    },
    multi_columns: {
      ...MultiColumnsPresets,
    },
    image_gallery: {
      ...ImageGalleryPresets,
    },
  },
};
