<template>
<dialog-layout>
  <box-layout :value="realValue" @input="realValue = $event"></box-layout>

  <button class="btn" @click="$emit('close', realValue)">Save</button>
</dialog-layout>
</template>

<script>
import BoxLayout from '../BoxLayout.vue';

export default {

  components: {
    BoxLayout,
  },
  
  name: 'DialogBoxLayout',
  props: ['value'],

  methods () {
    
  },

  data () {
    return {
      realValue: this.value,
    };
  },

}
</script>

