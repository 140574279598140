export const meet_alicia = {
  block: 'meet_alicia',
  args: {
    primaryColor: '#ffffff',
    styles: {
      'padding-left': '6rem',
      'padding-right': '6rem',      
    },
  },
};

export const banner_centered = {
  block: 'banner_header',
  args: {
    contentHeight: '6',
    contentMaxWidth: '1000px',
    backgroundImageOpacity: '.1',
    contentStyles: {
      'margin-left': 'auto',
      'margin-right': 'auto',
    },
  },
};

export const banner_centered_box = {
  block: 'banner_header',
  args: {
    contentHeight: '6',
    contentMaxWidth: '1000px',
    backgroundImageOpacity: '1',
    imageAttachment: "fixed",
    contentStyles: {
      'margin-left': 'auto',
      'margin-right': 'auto',
      'background-color': 'var(--background-color)',
    },
  },
};

export const large_background_image_media_unit = {
  block: 'hero_shape_background_divider',
  args: {
    imageAttachment: "fixed",
    styles: {
      '--theme--font__size__heading': '3rem',
      '--media-unit--media__width': '30%',
    },
    contentStyles: {
      'background-color': 'var(--background-color)',
    },
    headingStyles: {
      'text-align': 'left',
    },
    contentWrapperStyles: {
      'display': 'block',
      'text-align': 'left',
    },
  },
};

export const stacked_centered = {
  block: 'stacked_centered',
  args: {
    primaryColor: '#ffffff',
  },
};

