<template>
  <md-field class="md-has-value md-borderless"
            style="flex:0"
            :style="labelSize ? `--label-size: ${labelSize}em` : ''">
    <md-checkbox v-if="allowAuto" v-model="auto"></md-checkbox>
    <md-checkbox v-else-if="!noSpaceForAutoCheckbox" style="visibility: hidden"></md-checkbox>
    <label v-if="!selectingUnit" @click="selectUnit">
      {{ label }}
      <div style="font-size:80%" v-if="!auto">
        (<VueCustomTooltip position="is-right" label="This is the definition of the unit in question"><span>{{ unit || 'unitless' }}</span></VueCustomTooltip>)
      </div>
    </label>
    <md-select v-if="selectingUnit" ref="select" v-model="unit" @md-selected="watchUnit">
      <md-option v-for="unit in unitOptions" :value="unit" :key="unit">{{ unit }}</md-option>
    </md-select>    
    <div v-if="amount === 'auto'">
      Auto
    </div>
    <div v-else>
      <md-input type="number" @input.native="watchAmount" v-model="amount"
                v-bind="sliderPropsForUnit"
                />
      <vue-material-slider v-bind="sliderPropsForUnit"
                           ref="slider"
                           :class="maybeNoAnimation"
                           :key="redrawSlider"
                           :invert="invert"
                           v-model="amount" />
    </div>
  </md-field>
</template>

<script>
export default {
  props: ['label', 'value', 'allowAuto', 'invert', 'noSpaceForAutoCheckbox', 'labelSize', 'sliderProps'],

  watch: {
    realValue () {
      this.$emit('input', this.realValue);
    },
  },

  methods: {
    async selectUnit () {
      if (this.auto) return;
      this.selectingUnit = true;
      await this.$nextTick();
      this.$refs.select.openSelect();
    },
    async watchUnit () {
      this.selectingUnit = false;
      this.redrawSlider += 1;      
    },
    async watchAmount ({ target }) {
      const val = target.value;
      if (val === null || val === '') {
        this.amount = 0;
      } 
      this.redrawSlider += 1;
    },
  },

  computed: {
    maybeNoAnimation () {
      if (this.manuallySettingSlider) {
        return 'no-animation';
      } else {
        return '';
      }
    },
    sliderPropsForUnit () {
      const val = this.unit;
      if (val === 'px') {
        return {
          min: -600,
          max: 600,
          stepSize: 1,
          ...(this.sliderProps || {}),
        }
      }
      if (val === '%') {
        return {
          min: -100,
          max: 200,
          stepSize: .1,
          ...(this.sliderProps || {}),
        }
      }
      if (val === 'rem') {
        return {
          min: -30,
          max: 30,
          stepSize: .01,
          ...(this.sliderProps || {}),
        }
      }
      if (val === 'em') {
        return {
          min: -50,
          max: 50,
          stepSize: .01,
          ...(this.sliderProps || {}),
        }
      }
      if (['vw', 'vh', 'vmin', 'vmax'].indexOf(val) !== -1) {
        return {
          min: 0,
          max: 100,
          stepSize: .01,
          ...(this.sliderProps || {}),
        }
      }
      return {
        min: -10,
        max: 10,
        stepSize: .01,
        ...(this.sliderProps || {}),
      }
    },
    realValue () {
      return {
        amount: this.amount,
        unit: this.unit,
      };
    },
    auto: {
      get () {
        return this.amount === 'auto';
      },
      set (val) {
        if (val) {
          this.valueBeforeAuto = { amount: this.amount, unit: this.unit };
          this.amount = 'auto';
          this.unit = '';
        } else {
          this.amount = this.valueBeforeAuto.amount;
          this.unit = this.valueBeforeAuto.unit;
          this.valueBeforeAuto = {};
        }
      }
    },
  },
  
  data () {
    return {
      redrawSlider: 0,
      manuallySettingSlider: false,
      
      amount: this.value.amount,
      unit: this.value.unit,

      selectingUnit: false,
      unitOptions: [
        'px', 'rem', '%', 'em', 'vw', 'vh', 'vmin', 'vmax', '',
      ],
      
      valueBeforeAuto: {
        amount: 0,
        unit: 'rem',
      },
    };
  },
}
</script>

<style scoped>
.slider.no-animation >>> * {
  transition: none !important;
}
.md-field {
  margin-bottom: 4px;
  padding-top: 0;
}
.md-field:before {
  display: none !important;
}
.md-field > label + div {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.md-select {
  max-width: var(--label-size, 4.5em) !important;
  border: 1px solid #eee;
  font-size: 12px;
}
.md-field.md-theme-default.md-has-value .md-input {
  max-width: 3.5em;
  min-width: 3.5em;
  font-size: 12px;
  text-align: right;
  font-family: monospace;
}
.md-checkbox {
  margin-right: 4px;
  align-items: center;
}
.md-checkbox >>> .md-checkbox-container {
  width: 15px;
  min-width: 15px;
  height: 15px;
}
.md-checkbox >>> .md-checkbox-container:after {
  width: 6px;
  height: 11px;
  top: -1px;
  left: 3px;
}
.slider-horizontal {
  min-width: 100px;
  margin-top: 0;
  padding-left: 0;
  margin-left: -2px;
}
.md-has-value {
  align-items: center;
}
.md-has-value label {
  position: unset;
  margin-right: 4px;
  text-align: left;
  line-height: 1.2;
  max-width: var(--label-size, 4.5em);
  min-width: var(--label-size, 4.5em);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>  
