export const platform_image_and_text_columns = { 
  block: 'platform_image_and_text_columns',
};

export const platform_entry_image_and_text_columns = {
  block: 'platform_entry_image_and_text_columns',
};

export const why_orunning_photo_shadow_box_2 = {
  block: 'why_running_photo_shadow_box',
  args: {
    styles: {
      'background-color': 'transparent',
    },
    contentStyles: {
      'background-color': 'var(--background-color)',
      'padding-right': '3rem',
    },
    headingStyles: {
      'width': '100%',
      'order': '9',
      'text-align': 'right',
      'margin-top': '2rem',
      'margin-bottom': '0',
    },
  },
};
