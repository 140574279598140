<template>
<form-card @submit="$emit('close', { title, slug, bannerImage })"
           :submit-enabled="slug && slug.trim()"
           title="Name your new page, please!"
           :sending="sending">
  <md-field>
    <label for="title">
      Title
    </label>
    <md-input id="title" type="text" v-model="title" />
  </md-field>
  <md-field>
    <label for="slug">
      URL Path
    </label>
    <md-input id="slug" type="text" v-model="slug" />
  </md-field>
  <div>
    <label for="image">
      Banner Image
    </label>
    <image-input v-model="bannerImage" />
  </div>
</form-card>
</template>

<script>
const choose = arr => arr[Math.floor(Math.random() * arr.length)];

const slugify = (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')
  
  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export default {
  name: 'dialog-add-page',
  watch: {
    title (val, old) {
      if (!this.slug || this.slug === slugify(old)) {
        this.slug = slugify(val);
      }
    },
    slug (val, old) {
      if (val && val !== old && val !== slugify(val)) {
        this.slug = slugify(val);
      }
    },
  },
  methods: {
    randomImage (type, transforms) {
      let images = Object.values(this.$store.state.repo.images);
      if (type) {
        images = images.filter(o => o.piesExtra?.type === type);
      }

      let image = choose(images).public_id;
      if (transforms) {
        transforms = transforms + '/';
      }
      return `https://res.cloudinary.com/pies/image/upload/${transforms || ''}${image}`;
    },
  },
  data () {
    return {
      title: '',
      slug: '',
      bannerImage: this.randomImage('photo'),
      sending: false,
    };
  },
}
</script>

