<template>
  <div class="row" v-if="activeTab === 'build'">
    <div class="col" style="max-height: 80vh; overflow: scroll">
      <draggable :list="structure.rows">
        <form-card v-for="row, index in structure.rows" :key="index"
                   :title="`Row ${index+1}${row.columns.length ? ': '+row.columns.map(o => o.name || o.label || o.type).join(', ') : ''}`"
                   :no-layout="true"
                   save-button="Clone"
                   @submit="cloneRow(index)"
                   @delete="structure.rows.splice(index, 1)"
                   inner-style="border-left: 10px solid whitesmoke; cursor: move;">

          <div class="md-layout md-gutter">
            <div class="md-layout-item">

              <md-field>
                <label>Only show if</label>
                <md-select v-model="row.conditionalField">
                  <md-option value="">
                    [N/A &mdash; show always]
                  </md-option>
                  <md-option v-for="field, index in allFields" :key="index" :value="field.name">
                    {{ field.label || field.name || 'A ' + field.type + ' field' }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">          
              <md-field v-if="row.conditionalField">
                <label>Only show if {{ row.conditionalField }} field is&hellip;</label>
                <md-select v-model="row.conditionalType">
                  <md-option value="[filled]">
                    Filled in
                  </md-option>
                  <md-option value="[blank]">
                    Left blank
                  </md-option>
                  <md-option v-for="option, index in allFieldsByName[row.conditionalField].options || []"
                             :key="index" :value="option.value">
                    {{ option.label || option.value }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          
          <draggable :list="row.columns" group="fields">
            <form-card v-for="column, colIndex in row.columns" :key="colIndex"
                       button-alignment="right"
                       inner-style="border-left: 10px solid whitesmoke; cursor: move;">
              <template v-if="editingField.row === index && editingField.col === colIndex">
                <md-field v-if="column.type !== 'submit'" :class="/\W/.test(column.name || '') ? 'md-invalid' : ''">
                  <label>Field name</label>
                  <md-input v-model="column.name" />
                  <span class="md-error">Field name must contain only letters, numbers, and underscores.</span>
                </md-field>
                <md-field v-if="column.type !== 'submit' && column.type !== 'hidden'">
                  <label>Label</label>
                  <md-input v-model="column.label" />
                </md-field>
                <md-field>
                  <label>Type</label>
                  <md-select v-model="column.type">
                    <md-option v-for="label, option in fieldTypes" :key="option" :value="option">
                      {{ label }}
                    </md-option>
                  </md-select>
                </md-field>

                <md-field v-if="column.type === 'radio' || column.type === 'checkbox' || column.type === 'hidden'">
                  <label>Field value</label>
                  <md-input v-model="column.value" />
                </md-field>
                
                <template v-if="column.type === 'select'">
                  <md-subheading>Options</md-subheading>
                  <draggable :list="column.options">
                    <form-card v-for="option, optIndex in column.options" :key="optIndex"
                               :no-layout="true"
                               inner-style="border-left: 10px solid whitesmoke; cursor: move;"                               
                               @delete="column.options.splice(optIndex, 1)">
                      <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                          <md-field>
                            <label>Value</label>
                            <md-input v-model="option.value" />
                          </md-field>
                        </div>
                        <div class="md-layout-item">
                          <md-field>
                            <label>Label</label>
                            <md-input v-model="option.label" />
                          </md-field>
                        </div>
                      </div>
                    </form-card>
                  </draggable>

                  <form-card @submit="column.options.push(addingOption); addingOption = { value: '', label: '' }"
                             :no-layout="true">
                    <div class="md-layout md-gutter">
                      <div class="md-layout-item">
                        <md-field>
                          <label>Value</label>
                          <md-input v-model="addingOption.value" />
                        </md-field>
                      </div>
                      <div class="md-layout-item">                      
                        <md-field>
                          <label>Label</label>
                          <md-input v-model="addingOption.label" />
                        </md-field>
                      </div>
                    </div>
                  </form-card>
                </template>
                
                <md-field v-if="column.type !== 'radio' && column.type !== 'checkbox' && column.type !== 'hidden'">
                  <label>Placeholder text</label>
                  <md-input v-model="column.placeholder" />
                </md-field>

                <md-field>
                  <label>Width</label>
                  <md-select v-model="column.bsColClass">
                    <md-option v-for="label, option in bsColClasses" :key="option" :value="option">
                      {{ label }}
                    </md-option>
                  </md-select>
                </md-field>                
              </template>
              <md-subheader v-else>
                {{ column.label || column.name || 'A ' + column.type + ' field' }}
              </md-subheader>

              <template v-slot:actions>
                <md-button class="md-primary"
                           v-if="editingField.row === index && editingField.col === colIndex"
                           @click="editingField = { row: null, col: null }">Done Editing</md-button>
                <md-button class="md-primary"
                           v-else
                           @click="editingField = { row: index, col: colIndex }">Edit</md-button>
              </template>
              
            </form-card>
          </draggable>
        </form-card>
      </draggable>

      <form-card title="+ Add Row" @submit="addRow"
                 :submit-enabled="!/\W/.test(addingColumn.name || '') && (addingColumn.name || addingColumn.type === 'submit')">
        <md-field :class="/\W/.test(addingColumn.name || '') ? 'md-invalid' : ''">
          <label>Field name</label>
          <md-input v-model="addingColumn.name" />
          <span class="md-error">Field name must contain only letters, numbers, and underscores.</span>          
        </md-field>
        <md-field>
          <label>Label</label>
          <md-input v-model="addingColumn.label" />
        </md-field>
        <md-field>
          <label>Type</label>
          <md-select v-model="addingColumn.type">
            <md-option v-for="label, option in fieldTypes" :key="option" :value="option">
              {{ label }}
            </md-option>
          </md-select>
        </md-field>
        <md-field v-if="addingColumn.type === 'radio' || addingColumn.type === 'checkbox'">
          <label>Field value</label>
          <md-input v-model="column.value" />
        </md-field>
        
        <md-field>
          <label>Placeholder text</label>
          <md-input v-model="addingColumn.placeholder" />
        </md-field>        
      </form-card>
    </div>
    <div class="col-4">
      <div class="preview py-5 sticky-top">
        <form-card inner-style="background: whitesmoke">
          <component style="margin: auto" :is="compiled" />
        </form-card>
        <pre v-if="sampleSubmittedData" v-html="sampleSubmittedData"></pre>
      </div>
    </div>    
  </div>

  <div v-else-if="activeTab === 'success'">
    <form-card @submit="$emit('save-form', rendered)">
      <md-field class="md-has-value md-borderless">
        <label>
          After form is submitted&hellip;
        </label>
        <md-radio v-model="structure.useRedirect" value="message">Display a message</md-radio>
        <md-radio v-model="structure.useRedirect" value="page">Go to another page</md-radio>
        <md-radio v-model="structure.useRedirect" value="url">Go to a URL</md-radio>
      </md-field>

      <template v-if="structure.useRedirect === 'page'">
        <md-field v-show="!selectingPage">
          <label>Page</label>
          <md-input :placeholder="successRedirectPage" :value="successRedirectPage"
                    readonly @click="selectingPage = true"></md-input>
        </md-field>
        <md-table v-model="pages" v-show="selectingPage">
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell class="go-cell" width="10">
              <md-button class="primary-button" @click.prevent="selectPage(item)">Select</md-button>
            </md-table-cell>
            <md-table-cell md-label="Title">
              <span><a style="text-decoration: underline; color: inherit"
                       href="#" @click.prevent="selectPage(item)">{{ computedPage(item.key).title }}</a></span>
            </md-table-cell>
            <md-table-cell md-label="Path">
              <span>{{ item.key }}</span>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </template>
      
      <md-field v-else-if="structure.useRedirect === 'url'" key="url">
        <label>URL</label>
        <md-input v-model="structure.successRedirect" />
      </md-field>
      <md-field v-else key="message">
        <label>Message</label>
        <md-textarea v-model="structure.successMessage" />
        <span class="md-helper-text" v-pre>
          HTML, with Handlebars template syntax.
          Access submitted data as <code>fields</code>, e.g. <code>Thanks, {{ fields.firstname }}, we'll be in touch</code>.
        </span>
      </md-field>
    </form-card>
  </div>

  <div v-else-if="activeTab === 'alerts'">
    <form-card>
      <md-field class="md-has-value md-borderless">
        <label>
          After form is submitted&hellip;
        </label>
        <md-radio v-model="structure.alerts" value="">Send my default email alerts</md-radio>
        <md-radio v-model="structure.alerts" value="custom">Send custom email alerts</md-radio>
        <md-radio v-model="structure.alerts" value="none">Don't send any email alerts</md-radio>
      </md-field>
    </form-card>

    <form-confirmation-manager v-if="structure.alerts !== 'none'" active-tab="default" />
  </div>
</template>

<script>
import Vue from 'vue';
import $ from "cash-dom";
import draggable from 'vuedraggable'

import FormConfirmationManager from './FormConfirmationManager';

import { computedPage } from '../page-utils.js';

const outerHtml = $dom => [...$dom].map(o => o.outerHTML || '').join('\n').trim();

const bsColClasses = {
  "": "Auto",
  "col-2": "1/6",
  "col-3": "25%",
  "col-4": "1/3",
  "col-5": "5/12",
  "col-6": "50%",
  "col-7": "7/12",
  "col-8": "2/3",
  "col-9": "75%",
  "col-10": "5/6",
  "col-11": "11/12",
  "col-12": "100%",
};

const fieldTypes = {
  "text": "Text",
  "tel": "Phone number",
  "email": "Email address",
  "textarea": "Long text",
  "select": "Dropdown",
  "checkbox": "Checkbox",
  "radio": "Radio choice",
  //"hidden": "Hidden field",
  "submit": "Submit button"
};

export default {
  props: ['template', 'formName', 'activeTab'],

  components: {
    draggable,
    FormConfirmationManager,
  },
  
  methods: {

    cloneRow (index) {
      let row = this.structure.rows[index];
      this.structure.rows.splice(index, 0, JSON.parse(JSON.stringify(row)));
    },
    
    selectPage (page) {
      this.selectingPage = false;
      this.successRedirectPage = page.key;
    },
    
    computedPage (o) {
      if (!o) return { title: '' };
      return computedPage(o, this.$store.state);
    },
    
    addRow () {

      let col = this.addingColumn;
      if (!col.name && col.type === 'submit') {
        col.name = 'submit';
      }
      col.label = col.label || col.name;
      col.placeholder = col.placeholder || col.label;
      col.type = col.type || 'text';
      
      let row = {
        "classes": ['row'],
        "conditionalField": null,
        "conditionalType": null,
        "columns": [
          col,
        ],
      };
      this.structure.rows.push(row);
      this.addingColumn = {
        classes: ['col', 'mb-3'],
        name: '',
        label: '',
        type: '',
        placeholder: '',
        options: [],
      };
    },
    
    computeStructure () {
      let dom = $(this.code);

      const successRedirect = dom.attr('success-redirect'),
            successMessage = dom.attr('success-message'),
            extra = dom.attr(':extra'),
            extraFields = dom.attr(':extra-fields'),
            useRedirect = !successRedirect ? 'message' : successRedirect.indexOf('://') !== -1 || successRedirect.indexOf('{{') !== -1 ? 'url' : 'page',
            alerts = dom.attr('alerts') || '';
      
      let rows = [];
      dom.find(".row").each(function() {
        let row = {
          "classes": [...this.classList],
          "conditionalField": null,
          "conditionalType": null,
          "columns": [],
        };

        let condition = $(this).attr('v-if') || '';
        if (condition) {
          row.conditionalField = condition.trim().split(" ")[0].split("fields.")[1];
        }
        if (condition.startsWith('!')) {
          row.conditionalType = '[blank]';
        } else if (condition.split('===').length > 1) {
          row.conditionalType = condition.split('===')[1].replace(/"/g, '');
        } else {
          row.conditionalType = '[filled]';
        }
        $(this).find(".col").each(function() {

          let field = $(this).find("input, select, textarea");

          let col = {
            "classes": [...this.classList],
            "name": (field.attr(':id') || '').replace(/`/g, '').split('--')[1],
            "value": field.attr("value"),
            "type": field.attr('type'),
            "placeholder": field.attr('placeholder'),
            "label": $(this).find("label").text().trim(),
            "options": [],
            "bsColClass": [...this.classList].find(o => o.startsWith("col-")) || "",
          }
          row.columns.push(col);
        });
        rows.push(row);
      });

      return { rows, useRedirect, successRedirect, successMessage, extra, extraFields, alerts };
    },
  },

  watch: {
    'structure.rows': {
      deep: true,
      handler () {
        if (this.ready) {
          this.$emit('form-changed', this.rendered);
        }
      },
    },
    rendered () {
      this.sampleSubmittedData = null;
    },
  },
  
  computed: {

    successRedirectPage: {
      get () {
        if (!this.structure.successRedirect) {
          return null;
        }
        const page = this.pages.filter(o => o.key === this.structure.successRedirect.replace(/^\//, '').trim())[0];
        if (!page) {
          return null;
        }
        const computed = this.computedPage(page.key);
        return `${computed.title}: /${computed.key}`;
      },
      set (val) {
        this.structure.successRedirect = `/${val}`;
        this.structure.useRedirect = 'page';
      },
    },
    
    pages () {
      let pages = [];
      Object.keys(this.$store.state.repo.pages)
        .forEach(key => {
          const keyForHead = key.split('/').pop(); // only use the last path component in head obj
          const head = this.$store.state.repo.config.head[keyForHead] || {},
                meta = head.meta || [];
          
          let metaObj = {};
          meta.forEach(o => {
            metaObj[o.property || o.name] = o.content || o.value;
          });
          
          pages.push({
            key,
            metaObj,
            head,
          });
        });
      return pages;
    },
    
    allFieldsByName () {
      let fields = {};
      this.allFields.forEach(o => {
        fields[o.name] = o;
      });
      return fields;
    },
    
    allFields () {
      let fields = [];
      this.structure.rows.forEach(row => {
        fields = [...fields, ...row.columns.filter(o => o.name)];
      });
      return fields;
    },
    
    compiled () {
      const cmp = Vue.compile(this.rendered);

      let that = this;
      const componentSpec = {
        render: cmp.render,
        staticRenderFns: cmp.staticRenderFns,
        watch: {
          fields: {
            deep: true,
            handler () {
              that.sampleSubmittedData = null;
            },
          },
        },
        methods: {
          submitForm () {
            that.sampleSubmittedData = this.fields;
          },
        },
        data () {
          return {
            fields: {},
            submit: null,
            uuid: 'sample-form',
          };
        },
      };
      return componentSpec;
    },
    
    rendered () {
      let code = $(`<p-form form="${this.formName}" v-slot:form="{ submitForm, fields }">`);

      if (this.structure.successRedirect) {
        code.attr('success-redirect', this.structure.successRedirect.trim());
      } else if (this.structure.successMessage) {
        code.attr('success-message', this.structure.successMessage.trim());
      }

      if (this.structure.extra) {
        code.attr(':extra', this.structure.extra);
      }
      if (this.structure.extraFields) {
        code.attr(':extra-fields', this.structure.extraFields);
      }
      
      this.structure.rows.forEach(row => {
        let el = $('<div class="row">');
        row.classes.forEach(cls => { el.addClass(cls); });

        let condition = '';
        if (row.conditionalField) {
          let object = '';
          if (row.conditionalType === '[blank]') {
            condition = '!';
          } else if (row.conditionalType === '[filled]' || !row.conditionalType) {
            condition = '';
          } else {
            object = ` === "${row.conditionalType}"`;
          }
          condition = `${condition}fields.${row.conditionalField}${object}`;
        }
        condition && el.attr('v-if', condition);

        if (!row.columns.filter(o => o.type !== 'hidden').length) {
          el.addClass('d-none');
        }
        
        row.columns.forEach(col => {
          let inner = $('<div class="col">');
          col.classes.forEach(cls => { inner.addClass(cls); });
          if (col.bsColClass) {
            inner.addClass(col.bsColClass);
          }

          if (col.type === 'hidden') {
            inner.addClass('d-none');
          }
          
          if (col.type === 'submit') {
            let input = $(`<input type="submit" value="${col.placeholder || col.name || 'Submit'}" @click.prevent="submitForm">`);
            input.appendTo(inner);
            el.append(inner);
            return;
          }

          let innerClass = 'form-floating',
              inputClass = 'form-control';
          if (col.type === 'checkbox' || col.type === 'radio') {
            innerClass = 'form-check';
            inputClass = 'form-check-input';
          }
          let innerEl = $(`<div class="${innerClass}">`);

          let input = $('<input>');
          if (col.type === 'textarea') {
            input = $('<textarea>');
            input.attr('rows', 5).css('min-height', input.css('height')).css('height', 'auto');
          } else if (col.type === 'select') {
            input = $('<select>');

            (col.options || []).map(({ value, label }) => $('<option>').val(value).text(label)).forEach(opt => {
              input.append(opt);
            });
          }
          input
              .addClass(inputClass)
              .attr('placeholder', col.placeholder)
              .attr('type', col.type)
              .attr(':id', "`${uuid}--" + col.name + "`")
            .attr("v-model", `fields.${col.name}`);

          if (col.value) {
            input.attr("value", col.value);
          }
          
          innerEl.append(input);

          let label = $('<label>');
          label
              .attr(':for', "`${uuid}--" + col.name + "`")
              .text(col.label);
          innerEl.append(label);
          inner.append(innerEl);

          el.append(inner);
        });
        code.append(el);
      });
      return outerHtml(code);
    },
  },
  
  data () {
    //const template = this.$store.state.repo.components['signup-form'].template;

    return {
      ready: false,
      selectingPage: false,
      fieldTypes,
      bsColClasses,
      sampleSubmittedData: null,
      editingField: {
        row: null,
        col: null,
      },
      addingColumn: {
        classes: ['col', 'mb-3'],
        name: '',
        label: '',
        type: '',
        placeholder: '',
        options: [],
      },
      addingOption: {
        value: '',
        label: '',
      },
      code: this.template.trim(),
      structure: {
        rows: [], 
        successMessage: '', 
        successRedirect: '', 
        useRedirect: false,
        extra: null,
        extraFields: null,
        alerts: '',
      },
    };
  },

  mounted () {
    this.ready = false;
    this.structure = this.computeStructure();
    this.$nextTick(() => {
      this.ready = true;
    });
  },
}
</script>

<style scoped>
>>> label {
  color: black;
}
.md-field >>> .md-radio label {
  padding-left: 4px;
  font-size: 16px;
}

.md-table .md-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: #eee;
  margin: 0;
}
.md-table .md-button.primary-button {
  background: purple;
  color: white;
  font-weight: bold;
}

.md-table >>> .md-table-cell-container {
  display: flex;
  align-items: center;
}
.md-table >>> .md-table-head-label {
  color: black;
}
.md-table .go-cell >>> .md-table-cell-container {
  padding: 6px 0;
}

</style>
