import Corpus from './exquisite-corpse.js';
import $ from 'cash-dom';
const shortuuid = require('short-uuid');

import { writeStyleString, parseStyles } from './css-utils.js';
import { getContrast, hexToRgba, rgbToHex, complement } from './color-utils.js';

function parseRgbString(rgb) {
  const v = rgb.substring(4, rgb.length-1)
                    .replace(/ /g, '')
                    .split(',');
  return { r: parseInt(v[0]), g: parseInt(v[1]), b: parseInt(v[2]) };
}

function cycleCorpusOptions(blockName, argsFn) {
  const blockSpec = Corpus.blocks[blockName],
        blockType = Corpus.types[blockSpec.type],
        blockTypeArgs = blockType.args;

  let myArgs = {};
  let inner = argsFn();

  /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
  while (true) {
    Object.keys(blockTypeArgs).forEach(key => {
      if (blockTypeArgs[key].type !== 'select') {
        return;
      }
      if (blockSpec.relevantArgs && blockSpec.relevantArgs.indexOf(key) === -1) {
        return;
      }
      let options = blockTypeArgs[key].options;
      myArgs[key] = options[Math.floor(Math.random() * options.length)].value;
    });    
    
    let isValid = true;
    (blockType.disallowed_combinations || []).forEach(combo => {
      const matches = Object.keys(myArgs).filter(key => myArgs[key] === combo[key]);
      if (matches.length === Object.keys(combo).length) {
        isValid = false;
      }
    });

    let merged = {...inner, ...myArgs};
    if (Object.values(blockTypeArgs).filter(o => o.type === 'select').length > 0
        &&
        JSON.stringify(merged) === JSON.stringify(inner)) {
      isValid = false; // same as last cycle, try again
    }
    
    if (isValid) {
      console.log(myArgs);
      return () => {
        let inner = argsFn();
        return {...inner, ...myArgs};
      };
    }
  }

}

function cycleCorpusBlock(blockName, blockTypes) {
  const blockSpec = Corpus.blocks[blockName];

  if (!blockTypes) {
    blockTypes = [blockSpec.type];
  }

  const typedBlocks = Object.values(Corpus.blocks).filter(o => blockTypes.indexOf(o.type) !== -1),
        indexOf = typedBlocks.findIndex(o => o.key === blockName);
  let nextBlock = (indexOf + 1 >= typedBlocks.length) ? typedBlocks[0] : typedBlocks[indexOf + 1];
  return nextBlock;
}

function renderCorpus(blockType, args = {}, html, preserveUuid) {
  const block = Corpus.blocks[blockType],
        type = Corpus.types[block.type],
        defaultArgs = type.args;

  if (html) {
    const extractedArgs = block.extractArgs(html);
    console.log(extractedArgs);
    args = {
      ...extractedArgs,
      ...args,
    };
  }
  Object.entries(defaultArgs)
        .forEach(o => {
          if (typeof args[o[0]] !== 'undefined') return;
          args[o[0]] = o[1].default;
        });
  let newHtml = block.render(args),
      dom = $(newHtml);

  dom.attr('data-block-type', blockType);
  dom.attr('data-block-args', JSON.stringify(args));
  
  dom = $('<div>').append(dom);
  dom.find('[uuid]').each(function(i) {
    if (i === 0) {
      $(this).attr('uuid', preserveUuid || shortuuid.generate());
    } else {
      $(this).attr('uuid', shortuuid.generate());
    }
  });
  return dom.html().trim();
}

export {
  parseStyles,
  writeStyleString,
  getContrast,
  rgbToHex,
  hexToRgba,
  complement,
  parseRgbString,

  cycleCorpusBlock,
  cycleCorpusOptions,
  renderCorpus,
}

