<template>
<div class="nuxt-content">
  <component :is="output"></component>
</div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'nuxt-content',
  props: ['document'],
  computed: {
    html () {
      return '<div>'+this.document.document+'</div>';
    },
    output () {
      return Vue.compile(this.html);
    },
  },
}
</script>
