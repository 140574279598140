import { MediaUnit } from './core.js';
import { getContrast } from '../color-utils.js';

export const why_running_photo_shadow_box = new MediaUnit('why_running_photo_shadow_box', 'Photo Shadow Box', 'why_running', {
  styles: ({ metadata, primaryColor, styles, media_side = 'left' }) => ({
    '--media-unit--media__aspect-ratio': `${metadata.height / metadata.width * 100}%`,
    '--image-width': `${metadata.width}`,
    '--image-height': `${metadata.height}`,        
    '--media-unit--media__width': 'min(var(--image-width) * 1px, 35%)',
    '--media-unit--content__padding': '0',
    '--media-unit--media__clip-path': 'none',
    '--image-object-position': 'left',
    'margin-top': '0',
    'margin-bottom': '0',
    'padding-left': '1rem',
    'padding-right': '1rem',
    'padding-top': '1rem',
    'padding-bottom': '1rem',    
    'flex-direction': media_side === 'left' ? 'row-reverse' : 'row',
    'max-width': '1200px',
    'background-color': primaryColor,
    '--background-color': primaryColor,
    'color': getContrast(primaryColor),
    ...styles,
  }),

  mediaStyles: () => ({
    'box-shadow': '5px 5px 5px 0 black',
    'z-index': '4',
  }),

  headingStyles: ({ headingStyles }) => ({
    'text-align': 'left',
    ...headingStyles,
  }),

  contentStyles: ({ contentStyles }) => ({
    'align-self': 'center',
    'display': 'flex',
    'align-items': 'flex-start',
    'justify-content': 'center',
    'padding-left': '2rem',
    'padding-top': '2rem',
    'padding-bottom': '2rem',
    'padding-right': '2rem',
    'margin-left': '0',
    'margin-right': '0',
    'text-align': 'left',
    'background-color': 'transparent',
    ...contentStyles,
  }),
});
  
export const why_running = new MediaUnit('why_running', "Why I'm Running", 'why_running', {
  styles: () => ({
    '--media-unit--media__width': '0%',
    'margin-top': '1rem',
    'margin-bottom': '1rem',
    'color': 'black',
    'background-color': 'transparent',
  }),

  mediaStyles: () => ({
    'display': 'none',
  }),

  contentStyles: () => ({
    'max-width': '1000px',
    'margin-left': 'auto',
    'margin-right': 'auto',
    'text-align': 'center',
    'display': 'block',
    'background-color': 'transparent',    
  }),
});

export const why_running_2 = new MediaUnit('why_running_2', "Why I'm Running - Heavy Box", 'why_running', {
  styles: () => ({
    '--media-unit--media__width': '0%',
    'margin-top': '1rem',
    'margin-bottom': '1rem',
    'background-color': 'transparent',
    'color': 'black',
  }),

  mediaStyles: () => ({
    'display': 'none',
  }),

  contentStyles: () => ({
    'max-width': '1400px',
    'margin-left': 'auto',
    'margin-right': 'auto',
    'text-align': 'center',
    'display': 'block',
    'border': '5px solid black',
    'padding': '2rem 4rem 3rem',
    'background-color': 'transparent',    
  }),  
});
