export const platform_three_columns_colors_inside = {
  block: 'platform_three_columns_colors_inside',
  args: {
    tilePadding: '5',
  },
};

export const platform_three_columns_colors = {
  block: 'platform_three_columns_colors',
  args: {
    tilePadding: '8',
  },
};

export const platform_three_columns_colors_elevated = {
  block: 'platform_three_columns_colors',
  args: {
    tilePadding: '8',
    styles: {
      'max-width': '1400px',
      'overflow': 'visible',
      'box-shadow': '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    },
  },
};

export const platform_three_columns_colors_separated = {
  block: 'platform_three_columns_colors_buttons',
  args: {
    tilePadding: '8',
    tilePaddingX: '4',
    styles: {
      'max-width': '1400px',
      'overflow': 'visible',
    },
  },
};


