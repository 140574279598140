<template>
<i :class="`ri-${n}`"></i>
</template>

<script>
import 'remixicon/fonts/remixicon.css';

export default {
  name: 'remix-icon',
  props: [
    'n',
  ],
}
</script>
