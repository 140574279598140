import { MediaUnit, imageMetadata } from './core.js';

export const endorsements_three_columns_images_inside = new MediaUnit(
  'endorsements_three_columns_images_inside', 'endorsements_three_columns_images_inside', 'endorsements_columns', {

    styles: () => ({
      '--image-width': `0`,
      '--image-height': `0`,
      '--media-unit--media__width': '0%',
      '--theme--font__size': '1.1rem',
      '--theme--font__line-height': '1.1',
      '--theme--font__size__heading': '1.4rem',
      '--mobile--content-wrapper__padding-bottom': '1rem',
      '--mobile--content-wrapper__margin-top': '1rem',
      '--mobile--content-wrapper__margin-bottom': '1rem',            
      'max-width': '1400px',
    }),
    mediaStyles: () => ({
      'flex': '0',
      'display': 'none',
    }),
    contentStyles: ({ perRow }) => {
      let flexWrap = perRow ? {'flex-wrap': 'wrap'} : '';

      return {
        'flex-basis': '100%',
        'margin-left': 'auto',
        'margin-right': 'auto',
        ...flexWrap,
      };
    },
    
    template: ({ endorsements, styles, mediaStyles, contentStyles, perRow, noFlexGrow }) => {
      let extraCss = [];
      if (perRow) {
        extraCss.push(`flex-basis: ${90 / parseInt(perRow)}%`);
      } else {
        extraCss.push(`flex-basis: 100%`);
      }
      if (noFlexGrow) {
        extraCss.push('flex-grow: 0');
      }
      extraCss = extraCss.join(';');
      const wrapped = (endorsements || []).map(({ image, heading, content }) => {
        const imageType = imageMetadata(image).piesExtra?.type || '';
        let transform = 'w_500,ar_0.75,c_fill,g_auto';
        if (imageType.indexOf('logo') !== -1) {
          transform = 'w_500,ar_1,c_pad,b_transparent';
        }
        if (imageType.indexOf('icon') !== -1) {
          transform = 'w_500,ar_1,c_pad,b_transparent';
        }
        console.log(imageType, transform);
        return `<div class="media-unit--content-wrapper" style="margin: 1rem; padding: 1rem; ${extraCss}">
      ${image ? `<img src="https://res.cloudinary.com/pies/image/upload/${transform}/f_webp/${imageMetadata(image).public_id}" style="margin-bottom: 1rem;">` : ''}
      ${heading ? `<h2 style="margin-bottom: 0; margin-top: auto; text-align: center; width: 100%;">${heading}</h2 style="margin-bottom: 0; margin-top: auto; text-align: center; width: 100%;">` : ''}
      ${content || ''}
    </div>`;
      }).join('\n');
      
      return `<media-unit uuid="" 
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" content-class="has-wrapped-content direction-row alignment-stretch-centered">
  <template v-slot:default>
    ${wrapped}
  </template>
</media-unit>`;

    },
});
