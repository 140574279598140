<template>
<div id="app">

  <portal-target name="modal-container" multiple>
  </portal-target>

  <div v-if="!waitingForAuth">
    <start-fresh v-if="initializing" />

    <div v-else>
      <router-view>
        <template v-slot:cake-nav>
          <div style="position:fixed;top:0;left:0;right:0;height:100px;background:purple;z-index:999">
            Cake!
          </div>
        </template>
      </router-view>
    </div>
  </div>

  <loader v-if="isLoading" />
  
</div>

</template>

<script>
import StartFresh from './components/StartFresh.vue';
import Loader from './components/Loader.vue';

export default {
  name: 'App',
  components: {
    StartFresh,
    Loader,
  },
  computed: {
    isLoading () {
      return this.$store.state.appIsLoading;
    },
    initializing () {
      return this.$store.state.appIsInitializing;
    },
    initialData () {
      return {};
    }
  },
  data () {
    return {
      waitingForAuth: true,
    };
  },
  async mounted () {
    this.$store.commit('setLoading', true);
    await this.$store.dispatch('checkAuth');
    this.waitingForAuth = false;
    this.$store.commit('setLoading', false);
  },
}
</script>

<style>
.md-theme-default a.wp-block-button__link:not(.md-button) {
  color: var(--button-text-color);
}
.md-theme-default a.wp-block-button__link:not(.md-button):hover {
  color: var(--button-text-color__hover);
  text-decoration: none;
}

 .md-tabs {
  margin-bottom: 1rem;
}
.md-toolbar .md-tabs {
  width: 100%;
  margin-bottom: 0;
}
.md-toolbar .md-tabs >>> .md-button {
  margin-top: .5rem;
  padding-top: .5rem;
  margin-bottom: .5rem;
  min-height: 52px;
}

.md-list-item.md-active .md-list-item-text,
.md-list-item.md-active i {
  color: var(--md-theme-default-primary-on-background, #448aff);
}

.dialog-content .md-drawer {
  width: auto;
  min-width: 250px;
  font-size: 2rem;
}
.dialog-content .md-tabs.md-theme-default .md-tabs-indicator {
  display: none;
}
.md-switch.md-switch-left-right .md-switch-container {
  background-color: #cfa9c8 !important;
  --md-theme-default-accent-on-: #cfa9c8 !important;
  --md-theme-default-switchvariant: #800080 !important;
  --md-theme-default-accent: #800080 !important;
}
.md-switch.md-switch-left-right .md-ripple {
  display: none;
}
.md-borderless:after {
  display: none;
}

.swal2-shown .swal2-container {
  z-index: 9999;
}
.swal2-container .swal2-popup {
  border-radius: 0;
  padding-bottom: 0;
}
.swal2-container .swal2-actions {
  justify-content: space-between;
  width: 100%;
}

.justify-self-end {
  margin-left: auto !important;
  margin-right: 0 !important;
}
</style>
