<template>
<dialog-layout>
  <button class="btn" @click="$emit('close')">Close</button>
  <button class="btn" @click="save">Save</button>
  
  <textarea style="width:100%;min-height:800px;"
            v-model="realHtml"></textarea>
</dialog-layout>
</template>

<script>
export default {
  name: 'page-code-editor',
  methods: {
    async save () {
      this.$store.commit('setLoading', true);      
      await this.$store.dispatch('immediatelyWritePageContent', {
        key: this.$store.state.activePageKey,
        html: this.realHtml,
      });
      this.$store.commit('setLoading', false);
    },
  },
  data () {
    return {
      realHtml: this.$store.getters.activePage.html,
    };
  },
}
</script>
