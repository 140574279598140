import { MediaUnit } from './core.js';
import { getContrast, complement } from '../color-utils.js';


export const join_donate_columns = new MediaUnit(
  'join_donate_columns', 'join_donate_columns', 'join_donate', {

    styles: ({ primaryColor, buttonWidth }) => ({
      '--image-width': `0`,
      '--image-height': `0`,
      '--media-unit--media__width': '0%',
      'background': primaryColor,
      'color': getContrast(primaryColor),
      '--theme--font__size': '1.1rem',
      '--theme--font__line-height': '1.1',
      '--theme--font__size__heading': '1.4rem',
      '--button-width': buttonWidth ? `${buttonWidth}px` : null,
      '--mobile--content-side__padding-left': 0,
      '--mobile--content-side__padding-right': 0,
      '--mobile--content-side__padding-top': 0,
      '--mobile--content-side__padding-bottom': 0,
      '--mobile--content-wrapper__padding-left': '3rem',
      '--mobile--content-wrapper__padding-right': '3rem',
      '--mobile--content-wrapper__padding-top': '3rem',
      '--mobile--content-wrapper__padding-bottom': '3rem',
      '--mobile--content-wrapper__margin-top': '.25rem',
      '--mobile--content-wrapper__margin-bottom': '.25rem',      
    }),
    mediaStyles: () => ({
      'flex': '0',
      'display': 'none',
    }),
    contentStyles: () => ({
      'flex-basis': '100%',
      'padding-top': '0',
      'padding-bottom': '0',
      'padding-left': '0',
      'padding-right': '0',
    }),
    
    template: ({ styles, mediaStyles, contentStyles, donateBaseHref, amounts, heading, primaryColor, secondaryColor }) => {
      const links = JSON.stringify(amounts
        .split(',')
        .map(amount => ({href:`${donateBaseHref}${amount}`, text: `$${amount}`}))
      );
      
      return `
<media-unit uuid="" 
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" content-class="has-wrapped-content direction-row alignment-null">
  <template v-slot:default>
    <div class="media-unit--content-wrapper" style="padding-top: 3rem; padding-bottom: 3rem; padding-left: 3rem; padding-right: 3rem;">
      <h2>Join</h2>
      <signup-form style="margin-left: auto; margin-right: auto" uuid=""></signup-form>
    </div>
    <div class="media-unit--content-wrapper" style="padding-top: 3rem; padding-bottom: 3rem; padding-left: 3rem; padding-right: 3rem; background-color: ${secondaryColor || complement(primaryColor)}; color: ${getContrast(secondaryColor || complement(primaryColor))};">
      <h2>${heading}</h2>
      <p-buttons style="max-width: 450px; margin-left: auto; margin-right: auto" :links='${links}'></p-buttons>
    </div>
  </template>
</media-unit>`;
    },
});

export const donate_only_bar = new MediaUnit(
  'donate_only_bar', 'donate_only_bar', 'join_donate', {

    styles: ({ primaryColor, buttonWidth, styles }) => ({
      '--image-width': `0`,
      '--image-height': `0`,
      '--media-unit--media__width': '0%',
      'background': primaryColor,
      'color': getContrast(primaryColor),
      '--button-width': buttonWidth ? `${buttonWidth}px` : null,
      '--button-margin-left': 'auto',
      '--button-margin-right': '0',
      '--button-wrap': 'nowrap',
      '--mobile--content-side__padding-left': 0,
      '--mobile--content-side__padding-right': 0,
      '--mobile--content-side__padding-top': 0,
      '--mobile--content-side__padding-bottom': 0,
      '--mobile--content-wrapper__padding-left': '2rem',
      '--mobile--content-wrapper__padding-right': '2rem',
      '--mobile--content-wrapper__padding-top': '1rem',
      '--mobile--content-wrapper__padding-bottom': '1rem',
      '--mobile--content-wrapper__margin-top': '.25rem',
      '--mobile--content-wrapper__margin-bottom': '.25rem',
      'padding-top': '2rem',
      'padding-bottom': '2rem',
      'padding-left': '4rem',
      'padding-right': '4rem',      
      ...styles,
    }),
    mediaStyles: () => ({
      'flex': '0',
      'display': 'none',
    }),
    contentStyles: () => ({
      'flex-basis': '100%',
      'padding-top': 0,
      'padding-bottom': 0,
      'padding-left': 0,
      'padding-right': 0,
    }),
    
    template: ({ styles, mediaStyles, contentStyles, donateBaseHref, amounts, heading, content, interactiveComponent, firstSideFlex = '1' }) => {
      const links = JSON.stringify(amounts
        .split(',')
        .map(amount => ({href:`${donateBaseHref}${amount}`, text: `$${amount}`}))
      );
      interactiveComponent = interactiveComponent || `<p-buttons :links='${links}'></p-buttons>`;
      
      return `
<media-unit uuid="" 
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" content-class="has-wrapped-content direction-row alignment-stretch-apart-centered">
  <template v-slot:default>
    <div class="media-unit--content-wrapper" style="padding-top: 1rem; padding-bottom: 1rem; padding-left: 2rem; padding-right: 2rem; flex: ${firstSideFlex}; text-align: left;">
      <h2 style="text-align: left">${heading}</h2>
      ${content}
    </div>
    <div class="media-unit--content-wrapper" style="padding-top: 1rem; padding-bottom: 1rem; padding-left: 2rem; padding-right: 2rem;">
      ${interactiveComponent}
    </div>
  </template>
</media-unit>`;
    },
});

export const donate_bar_no_text = new MediaUnit(
  'donate_bar_no_text', 'donate_bar_no_text', 'join_donate', {

    styles: ({ primaryColor, buttonWidth }) => ({
      '--image-width': `0`,
      '--image-height': `0`,
      '--media-unit--media__width': '0%',
      'background': primaryColor,
      'color': getContrast(primaryColor),
      '--theme--font__size': '1.1rem',
      '--theme--font__line-height': '1.1',
      '--theme--font__size__heading': '1.4rem',
      '--button-width': buttonWidth ? `${buttonWidth}px` : null,
      '--button-margin-left': '1rem',
      '--button-margin-right': '1rem',
      '--button-wrap': 'nowrap',
    }),
    mediaStyles: () => ({
      'flex': '0',
      'display': 'none',
    }),
    contentStyles: () => ({
      'flex-basis': '100%',
      'padding-top': 0,
      'padding-bottom': 0,
      'padding-left': 0,
      'padding-right': 0,
    }),
    
    template: ({ styles, mediaStyles, contentStyles, donateBaseHref, amounts }) => {
      const links = JSON.stringify(amounts
        .split(',')
        .map(amount => ({href:`${donateBaseHref}${amount}`, text: `$${amount}`}))
      );

      return `
<media-unit uuid="" 
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}" content-class="has-wrapped-content direction-row alignment-stretch-centered">
  <template v-slot:default>
    <div class="media-unit--content-wrapper" style="padding-top: 1rem; padding-bottom: 1rem; padding-left: 2rem; padding-right: 2rem;">
      <p-buttons :links='${links}'></p-buttons>
    </div>
  </template>
</media-unit>`;
    },
});
