<template>
<md-button class="md-icon-button"
           @click="pinned = !pinned"
           @mouseover="$emit('unmask')"
           @mouseout="!pinned && $emit('remask')">
    <md-icon>
      <ri n="eye-line" />
    </md-icon>
  </md-button>
</template>

<style scoped>
.md-icon-button {
  position: absolute; top: 0; right: 50px; transform: scale(1.5);
}
.md-icon-button i {
  color: purple;
}
</style>

<script>
export default {
  data () {
    return {
      pinned: false,
    }
  },
}
</script>

