<template>
<md-tabs md-alignment="fixed" class="controls md-transparent"
         :md-active-tab="activeTab"
         @md-changed="$emit('tab-changed', $event)">
        
  <template slot="md-tab" slot-scope="{ tab }">
    <div class="mb-1">
      <ri v-if="tab.data.icon" class="ri-xl mb-2 d-block" :n="tab.data.icon" />
      <rocket-ship size="25" class="mb-1 d-block" v-else
                   :class="activeTab === tab.id ? 'active-tab-icon' : ''" />
      <b>{{ tab.label }}</b>
    </div>
  </template>
        
  <md-tab v-for="tab in tabs"
          :key="tab.id"
          @mouseover="$emit('tab-mouseover', tab)"
          @mouseout="$emit('tab-mouseout', tab)"
          :id="tab.id" :md-label="tab.label"
          :md-template-data="{ icon: tab.icon }"></md-tab>
</md-tabs>
</template>

<script>
export default {
  props: ['tabs', 'activeTab'],
}
</script>

<style>
.md-tabs .md-tabs-navigation {
  overflow-x: scroll;
}
</style>
