<template>
<md-button @click="onclick">
  <mini-loader h="12" w="12" color="#333" v-if="status === 'loading'"></mini-loader>
  <span v-else>
    <slot></slot>
  </span>
</md-button>
</template>

<script>
import MiniLoader from './MiniLoader';

export default {
  name: 'status-button',
  components: { MiniLoader },

  data () {
    return {
      status: null,
    };
  },
  
  methods: {
    async onclick () {
      if (this.status) return;
      if (!this.$listeners.action) return;
      this.status = 'loading';
      await this.$listeners.action();
      this.status = null;
    },
  }
}
</script>
