import $ from "cash-dom";
import { debounce } from 'lodash';
import { get, post } from 'axios';
import axios from 'axios';
import Vue from 'vue'
import Vuex from 'vuex'

import { parseStyles } from './css-utils.js';
import { computedPage } from './page-utils.js';

const debouncedWriteLayoutConfig = debounce((config) => {
  post(`/api/config?path=layout`, {
    content: config,
  });
}, 500);

const debouncedWriteActivePageContent = debounce((html, activePageKey) => {
  post(`/api/pages?page=${activePageKey}`, {
        content: html,
  });
}, 5000);

const debouncedUpdateActivePageContent = debounce((state, html, activePageKey) => {
  state.repo.pages[activePageKey].template = html;
}, 500);

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {

    appIsInitializing: true,
    appIsLoading: false,

    ssoEndpoint: null,
    allowLogin: true,
    
    activePageKey: null,

    activeComponent: null,
    
    editingComponent: null,

    viewingContentCollectionEntry: null,

    login: {
      user: {},
      campaign: {},
      website: {},
    },

    history: [],
    
    repo: {
      config: {
        feeds: {},
        fonts: [],
        head: {},
        layout: {
          donate: {},
          header: [],
          headerStyle: '',
          headerType: 'right-aligned-header',
          style: '',
          logo: '',
          disclaimer: '',
          social: [],
        },
        site: {},
      },
      contentPages: {},
      contentTypes: [],
      images: {},
      snippets: {},
      pages: {},
      components: {},
      redirects: {},
    },
    
    contentCollections: {},

  },

  getters: {

    userIsPCCCStaff: (state) => {
      return state.login?.user?.username === 'pccc_staff';
    },
    
    feeds: (state) => {
      let configs = state.repo?.config?.feeds || {};
      let feeds = {};
      Object.keys(configs).forEach(key => {
        const config = configs[key];
        feeds[key] = state.repo.contentSpreadsheets[config.dir];
      });
      return feeds;
    },
    
    styles: (state) => {
      let style = state.repo?.config?.layout?.style;
      if (!style) return {};
      return parseStyles(style);
    },

    headerStyles: (state) => {
      let style = state.repo?.config?.layout?.headerStyle;
      if (!style) return {};
      return parseStyles(style);
    },

    footerStyles: (state) => {
      let style = state.repo?.config?.layout?.footerStyle;
      if (!style) return {};
      return parseStyles(style);
    },
    
    activeComponent: (state, getters) => {
      let computed = {
        styles: {},
        contentStyles: {},
        mediaStyles: {},
        backgrounds: [],
        images: [],
        contentTemplate: '',
        contentIsWrapped: false,
        contentIsFed: null,
        contentWrapperStyles: [],
        contentTemplates: [],

        blockType: '',
        blockArgs: {},
        blockOverrides: {},
        blockDesignOverrides: {},
      };

      if (!state.activeComponent) {
        return;
      }

      const props = state.activeComponent.props || {};
      if (props.styles) {
        computed.styles = parseStyles(props.styles);
      }
      if (props['content-styles']) {
        computed.contentStyles = parseStyles(props['content-styles']);
      }
      if (props['media-styles']) {
        computed.mediaStyles = parseStyles(props['media-styles']);
      }
      computed.contentClass = [];
      if (props['content-class']) {
        computed.contentClass = props['content-class'].split(/ +/);
      }

      if (props['data-block-type']) {
        computed.blockType = props['data-block-type'];
      }
      if (props['data-block-args']) {
        try {
          computed.blockArgs = JSON.parse(props['data-block-args']);
        } catch (err) {
          computed.blockArgs = {};
        }
      } 
      if (props['data-block-overrides']) {
        try {
          computed.blockOverrides = JSON.parse(props['data-block-overrides']);
        } catch (err) {
          computed.blockOverrides = {};
        }
      } 
      if (props['data-block-design-overrides']) {
        try {
          computed.blockDesignOverrides = JSON.parse(props['data-block-design-overrides']);
        } catch (err) {
          computed.blockDesignOverrides = {};
        }
      } 

      const dom = $(getters.activePage.html).find(`[uuid='${state.activeComponent.uuid}']`);

      const contentTemplate = (
        dom.children("template").filter((i, o) => o.hasAttribute('v-slot:default'))[0]?.innerHTML
        ||
        dom.children("template")[0]?.innerHTML
        ||
        dom.html()
      );

      const contentWrappersOnly = function() {
        return this.tagName && this.tagName.toUpperCase() === 'DIV'
            && this.classList && this.classList.contains('media-unit--content-wrapper');
      };
      const filteredContentTemplate = $(contentTemplate).filter(contentWrappersOnly);
      const noTextNodes = $(contentTemplate).filter(function() { return this.nodeName !== '#text'; });

      if (filteredContentTemplate.length === 1) {
        const vFor = (filteredContentTemplate.attr('v-for') || '').match(/^entry in feeds\['(.*)'\]$/);
        if (vFor) {
          let feedId = vFor[1];
          computed.contentIsFed = {
            uuid: feedId,
            config: state.repo.config.feeds[feedId],
            columns: Object.keys(getters.feeds[feedId][0]),
          };
        }
      }
      
      if (noTextNodes.length === filteredContentTemplate.length) {
        computed.contentIsWrapped = true;
        computed.contentWrapperStyles = filteredContentTemplate.map(function() {
          return parseStyles($(this).attr('style') || '');
        });
        computed.contentTemplates = filteredContentTemplate.map(function() {
          return this.innerHTML;
        });
      } else {
        computed.contentTemplate = contentTemplate;
      }

      const backgrounds = dom.find("template").filter((i, o) => o.hasAttribute('v-slot:backgrounds')).html();
      computed.backgrounds = $("<div>").html(backgrounds).find("background").map(function() {
        let attrs = {};
        [...this.attributes].forEach(attr => {
          attrs[attr.name] = attr.value;
        });
        return attrs;
      });

      if (state.activeComponent.props?.image) {
        computed.images = state.activeComponent.props.image
                               .split(', ')
                               .map(src => src.split('/').slice(-2).join(':'));
      }
            
      return computed;
    },
    
    activePage: (state) => {
      return computedPage(state.activePageKey, state);
    },
    
  },
  
  mutations: {
    doneInitializing (state) {
      state.appIsInitializing = false;
    },
    setLoading (state, loading) {
      state.appIsLoading = loading;
    },

    setActivePageKey (state, key) {
      state.activePageKey = key;
      state.history = [
        computedPage(key, state).html,
      ];
    },
    
    setEditingComponent (state, component ) {
      state.activeComponent = component;
    },

    putLayoutConfig(state, config) {
      state.repo.config.layout = {
        ...state.repo.config.layout,
        ...config,
      };
    },

    putComponent (state, { file, config }) {
      state.repo.components = {
        ...state.repo.components,
        [file.key]: file,
      };

      state.repo.config.components = {
        ...state.repo.config.components,
        [file.key]: config,
      };

      delete Vue.options.components[file.key];

      let component = Vue.compile(file.template);
      component = {
        ...component,
        ...config,
      };
      Vue.component(file.key, component);
    },

    putRedirects (state, redirects) {
      state.repo.redirects = redirects;
    },
    
    putPage (state, { page, config }) {
      state.repo.pages = {
        ...state.repo.pages,
        ...{ [page.key]: page},
      };

      const keyForHead = page.key.split('/').pop();      
      state.repo.config.head = {
        ...state.repo.config.head,
        [keyForHead]: config.head,
      };
    },

    putConfig(state, config) {
      state.repo.config = {
        ...state.repo.config,
        ...config,
      };
    },
    
    updateActivePageContent (state, html) {
      debouncedUpdateActivePageContent(state, html, state.activePageKey);
      debouncedWriteActivePageContent(html, state.activePageKey);
      state.history.push(html);
      while (state.history.length > 100) {
        state.history.shift();
      }
    },

    undo (state) {
      if (state.history.length < 2) {
        return;
      }
      state.history.pop();
      const html = state.history[state.history.length - 1];
      state.repo.pages[state.activePageKey].template = html;
      debouncedWriteActivePageContent(html, state.activePageKey);      
    },

    dropPage (state, { key }) {
      let pages = {...state.repo.pages};
      delete pages[key];
      state.repo.pages = pages;

      let head = {...state.repo.config.head};
      const keyForHead = key.split('/').pop();
      delete head[keyForHead];
      state.repo.config.head = head;

      if (key === state.activePageKey) {
        state.activePageKey = Object.keys(state.repo.pages)[0];
      }      
    },
    
    dropImage (state, { key }) {
      let images = {...state.repo.images};
      delete images[key];
      state.repo.images = images;
    },
    
    dropSnippet (state, { key }) {
      let snippets = {...state.repo.snippets};
      delete snippets[key];
      state.repo.snippets = snippets;
    },
    
    putImage (state, { key, info }) {
      state.repo.images = {
        ...state.repo.images,
        [key]: info,
      };
    },

    putSnippet (state, { key, value }) {
      state.repo.snippets = {
        ...state.repo.snippets,
        [key]: value,
      };
    },

    setRepoContents (state, repo) {
      state.repo = repo;

      Object.keys(repo.components).forEach(key => {
        const { template } = repo.components[key],
              config = repo.config.components[key];

        let component = Vue.compile(template);

        component = {
          ...component,
          ...config,
        };

        if (component.stubs) {
          component.data = () => JSON.parse(JSON.stringify(component.stubs));
        }
        
        Vue.component(key, component);
      });
    },

    putContentSpreadsheet (state, { content_type, rows }) {
      state.repo.contentSpreadsheets = {
        ...state.repo.contentSpreadsheets,
        [content_type]: rows,
      };
    },
    
    setContentCollections (state, collections) {
      state.contentCollections = collections;
    },
    setContentCollectionEntry (state, entry) {
      entry = {...entry};
      entry.computed = {};
      entry.computed.entry = {
        document: entry.content,
        ...entry.metadata,
      };
      if (entry.page?.template) {
        entry.computed.wrapped = entry.page.template;
      } else {
        entry.computed.wrapped = entry.content;
      }
      state.viewingContentCollectionEntry = entry;
    },
    setLogin (state, login) {
      state.login = login;
    },
    setSsoEndpoint (state, endpoint) {
      state.ssoEndpoint = endpoint;
      if (endpoint.match('staging')) {
        state.allowLogin = false;  
      }
    },
  },
  actions: {
    async initializeAnonymous ({ dispatch }) {
      await post(`/api/gitlab`);
      await dispatch('checkAuth');
    },
    async initializeLoggedIn ({ dispatch }) {
      await post(`/api/gitlab`);
      await dispatch('checkAuth');
    },
    async fetchContentCollections ({ commit }) {
      const { types } = (await get(`/api/content-pages`)).data;

      let resps = await Promise.all(
        types.map(type => get(`/api/content-pages?content_type=${type}`))
      );

      let collections = {};
      resps.forEach(resp => {
        collections[ resp.data.type ] = resp.data.tree;
      });

      commit('setContentCollections', collections);
    },
    async fetchContentCollectionEntry ({ commit }, { content_type, page }) {
      let resp = await get(`/api/content-pages?content_type=${content_type}&page=${page}`);
      commit('setContentCollectionEntry', resp.data);
    },
    async addContentCollectionEntry ({ dispatch }, { content_type, page }) {
      await post(`/api/content-pages?content_type=${content_type}&page=${page}`);
      await dispatch('fetchContentCollections');
      await dispatch('fetchContentCollectionEntry', { content_type, page });
    },

    async putContentSpreadsheet({ commit }, { content_type, rows }) {
      await post(`/api/spreadsheets?content_type=${content_type}`, rows);
      commit('putContentSpreadsheet', { content_type, rows });
    },

    async removeGlobalSnippet ({ commit }, { key }) {
      await axios.delete(`/api/snippets?key=${key}`);
      commit('dropSnippet', { key });
    },

    async updateGlobalSnippet ({ commit }, { key, value }) {
      await post(`/api/snippets?key=${key}`, value);
      commit('putSnippet', { key, value });
    },
    
    async fetchPage ({ commit }, { key, stillLoading }) {
      commit('setLoading', true);

      const data = (await get(`/api/pages?page=${key}`)).data;

      commit('putPage', data);
      commit('setActivePageKey', data.page.key);

      if (!stillLoading) {
        commit('setLoading', false);
      }
    },

    async updateConfigs({ commit }, configs) {
      const data = (await post(`/api/config`, { configs })).data;

      commit('putConfig', data);
    },

    async checkAuth ({ commit, dispatch, state }) {
      const data = (await get('/api/auth')).data;

      if (data.sso) {
        commit('setSsoEndpoint', data.sso);
      }
      
      if (data.authenticated) {
        commit('setLogin', data.jwt);
      }

      if (state.login.website?.uuid) {
        await dispatch('fetchRepoContents');
      }

      if (Object.values(state.repo.pages).length) {
        commit('doneInitializing');
      }
    },

    async putComponent ({ commit, state }, component) {
      if (!component.name) {
        component.name = component.key;
      }
      const data = (await post(`/api/components?name=${component.name}`, component)).data;

      commit('putComponent', data);

      // force a refresh of the preview
      const key = state.activePageKey;
      commit('setActivePageKey', null);
      commit('setActivePageKey', key);
    },
    
    async fetchRepoContents ({ commit }) {
      const data = (await get(`/api/repo`)).data;

      //const deploys = (await get(`/api/deploys`)).data;
      //console.log('deploys:', deploys);
      
      commit('setRepoContents', data);
    },
        
    async writeLayoutConfig ({ commit }, { config, immediately }) {
      if (immediately) {
        await post(`/api/config?path=layout`, {
          content: config,
        });
        commit('putLayoutConfig', config);
      } else {
        commit('putLayoutConfig', config);
        debouncedWriteLayoutConfig(config);
      }
    },

    async writeRedirects ({ commit }, redirects) {
      await post(`/api/redirects`, { redirects });
      commit('putRedirects', redirects);
    },

    async deletePage ({ commit }, { key }) {
      await axios.delete(`/api/pages?page=${key}`);
      commit('dropPage', { key });
    },
    
    async immediatelyWritePageContent ({ commit, state }, { key, title, html = '', style }) {
      const data = (await post(`/api/pages?page=${key}`, {
        title,
        content: html,
        style,
      })).data;

      state.history.push(html);
      while (state.history.length > 100) {
        state.history.shift();
      }      

      commit('putPage', data);
    },

    async updatePageHead ({ commit }, { key, head }) {
      const data = (await post(`/api/pages?page=${key}`, { head })).data;
      commit('putPage', data);
    },
    
    async setEditingComponent ({ commit }, component) {
      commit('setEditingComponent', component);
    },

    async dropImageMetadata ({ commit }, info) {
      const key = info.public_id.replace('/', ':');
      await axios.delete(`/api/images?path=${key}`);

      commit('dropImage', { key });
    },
    
    async registerImageMetadata ({ commit }, { info }) {
      const key = info.public_id.replace('/', ':');
      await post(`/api/images?path=${key}`, {
        content: info,
      });

      commit('putImage', { key, info });
    },
  },
});

export default {
  store
};
