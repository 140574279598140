<template>
<dialog-layout>
  <dialog-close-button @close="$emit('close')" />  

  <template v-if="!preselected">
    Insert a component
    <select v-model="selected">
      <option value="">Select a component from your library</option>
      <option v-for="component in library" :key="component.name" :value="component.name">
        {{component.name}}
      </option>
    </select>
  </template>

  <form-card v-if="selected" @submit="$emit('close', { name: selected, props: propValues })">
    <md-field v-for="prop in selectedComponentProps" :key="prop">
      <label>{{ upperCase(prop.replace(/-/g, ' ').replace(/_/g, ' ')) }}</label>
      <md-input v-model="propValues[prop]" />
    </md-field>
  </form-card>
</dialog-layout>
</template>

<script>
export default {
  name: 'dialog-add-user-defined-component',
  props: ['type', 'preselected'],
  methods: {
    upperCase: string => string.charAt(0).toUpperCase() + string.slice(1),
  },
  computed: {
    selectedComponentProps () {
      return (this.selectedComponent.props || []).filter(o => o !== 'uuid');
    },
    selectedComponent () {
      if (!this.selected) return null;
      return this.library.filter(o => o.name === this.selected)[0];
    },
    components () {
      return this.$store.state.repo.components;
    },
    library () {
      return Object.keys(this.components)
        .map(key => ({
          key,
          ...this.$store.state.repo.config.components[key] || {},
        }))
        .filter(({ type }) => type === (this.type || 'user-defined'));
    },
  },
  data () {
    return {
      selected: this.preselected || null,
      propValues: {},
    };
  },
}
</script>
