<template>
<div>
  <md-table v-model="components" @md-selected="editComponent">
    <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="single">
      <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
      <md-table-cell md-label="Type">{{ item.type }}</md-table-cell>
    </md-table-row>
  </md-table>
  
  <div class="text-center">
    <button @click="startAddEntry('form')" class="btn">New form</button>
    <button @click="startAddEntry('user-defined')" class="btn">New custom component</button>    
  </div>
  
  <div v-if="activeComponent" class="py-4 px-5">
    <input class="form-control" v-model="activeComponent.name" />
    <textarea class="form-control" v-model="activeComponent.template"></textarea>

    <template v-if="activeComponent.type === 'user-defined'">
      <div v-for="prop, index in activeComponent.props" :key="index" class="row">
        <div class="col">
          <input class="form-control" v-model="activeComponent.props[index]" />
        </div>
        <div class="col">
          <input class="form-control" v-model="previewedComponentProps[prop]" />
        </div>
      </div>
    </template>
    
    <div class="text-center">
      <button @click="activeComponent.props.push(`variable${activeComponent.props.length}`)"
              class="btn"><ri n="add-line" /></button>
    </div>
  
    
    <div class="text-center my-4">
      <button @click="compilePreview" class="btn"><ri n="eye-line" /></button>
      <button @click="save" class="btn"><ri n="save-line" /></button>
    </div>

    <div class="preview" v-if="previewedComponent">
      <component :is="previewedComponent" v-bind="previewedComponentProps"></component>
    </div>
  </div>
  
</div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'CollectionManager',

  data () {
    return {
      activeComponent: null,
      previewedComponentProps: {},
      previewedComponent: null,
    };
  },
  
  methods: {
    defaultTemplate (type) {
      if (type === 'user-defined') {
        return '<div>\nYour custom reusable content here!\n</div>';
      }
      if (type === 'form') {
        return `<div></div>`;
      }
    },
    defaultProps (type) {
      if (type !== 'form') {
        return {};
      }
      if (type === 'form') {
        return {
          uuid: null,
        };
      }
    },
    editComponent ({ name }) {
      this.activeComponent = this.components.filter(o => o.name === name)[0];
      this.previewedComponent = null;
      this.previewedComponentProps = this.defaultProps(this.activeComponent.type);
    },

    async save () {
      await this.$store.dispatch('putComponent', this.activeComponent);
    },
    
    compilePreview () {
      let template = this.activeComponent.template;
      if (this.activeComponent.type === 'form') {
        template = `
<p-form uuid="sample-uuid">
  <template v-slot:form="{ fields, uuid, submit, submitForm }">${template}</template>
</p-form>
`;
      }
      const cmp = Vue.compile(template);
      const componentSpec = {
        ...cmp,
        ...this.activeComponent,
      };
     this.previewedComponent = componentSpec;
    },
    
    startAddEntry (type) {
      let defaultProps = this.defaultProps(type);
      this.activeComponent = {
        template: this.defaultTemplate(type),
        props: Object.keys(defaultProps),
        type,
        name: `custom-component-${this.components.length}`,
      };
      this.previewedComponentProps = defaultProps;
      this.previewedComponent = null;
    },
  },
  
  computed: {
    components () {
      let components = [];
      Object.keys(this.$store.state.repo.components).forEach(key => {
        let config = this.$store.state.repo.config.components[key];
        let component = {
          template: this.$store.state.repo.components[key].template,
          ...config,
        };
        components.push(component);
      });
      return components;
    },
  },

  async mounted () {
    await Promise.all([
      this.$store.dispatch('fetchRepoContents'),
    ]);
  },
}
</script>

