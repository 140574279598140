export const image_gallery = {
  block: 'image_columns',
};

export const image_gallery_spaced = {
  block: 'image_columns',
  args: {
    styles: {
      'max-width': '1400px',
      'margin-top': '2rem',
      'margin-bottom': '2rem',      
    },
    spaceBetween: 10,
  },
};

