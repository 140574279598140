<template>
<div class="user-content"
     @contextmenu.prevent="showBlockEditOptions"
     @dblclick.prevent="showBlockEditOptions"
     @mouseover="indicateElementEditable($event.target)"
     >

  <div ref="style-container"></div>
  
  <component class="user-content-compiled"
             ref="compiled" :is="output">
  </component>
  
  <div :style="activeElementPosition" class="edit-element" v-if="shifting">
    <a href="#" @click.prevent="editElement">
      <ri n="edit-box-fill" />
    </a>
    <a href="#" @click.prevent="editElement2">
      <ri n="edit-box-line" />
    </a>
    <a href="#" @click.prevent="editImage" v-if="shifting.type === 'image'">
      <ri n="image-edit-line" />
    </a>
  </div>

  <html-element-live-preview-editor
    v-if="shifting && shifting.liveEdits"
    :el="shifting.el"
    @discard="shifting = null"
    />
  
  <div :style="activeBlockPosition" class='block-middle-controls' v-if="hoveredOver" style="z-index: 999">
    
    <md-speed-dial md-event="click" v-if="speedDialState === null && !$store.state.appIsLoading"
                   ref="block-controls"
                   class="md-center" md-direction="bottom">
      <md-speed-dial-target ref="block-controls-toggle" class="md-primary" style="display:none">
      </md-speed-dial-target>
      <slot name="block-edit-speed-dial" v-bind:block="hoveredOver">
        <md-speed-dial-content>
        <md-button class="md-icon-button" @click="speedDialState = 'mediaWidth'">
          <ri n="arrow-left-right-line" />
          Layout
        </md-button>
        <!--<md-button class="md-icon-button" @click="editMediaUnit('tab-design', 'image')">
          <ri n="image-line" />
          Images
        </md-button>-->
        <md-button class="md-icon-button"
                   @click="editStructuredMediaUnit"
                   @disabledclick="speedDialState = 'structuredContent'">
          <ri n="pencil-line" />
          Content
        </md-button>
        <md-button class="md-icon-button" @click="editBlockLevelStyles">
          <ri n="brush-line" />
          Design
        </md-button>
        <md-button v-if="$store.state.history.length > 1" class="md-icon-button" @click="undo">
          <ri n="arrow-go-back-line" />
          Undo
        </md-button>        
      </md-speed-dial-content>
      <md-speed-dial-content class="md-speed-dial-right">
        <md-button class="md-icon-button" @click="deleteBlock">
          <ri n="delete-bin-2-line" />
          Delete
        </md-button>
        <md-button class="md-icon-button" @click="cloneBlock">
          <ri n="file-copy-line" />
          Clone
        </md-button>
        <md-button class="md-icon-button" @click="callOnAdd">
          <ri n="file-add-line" />
          Add New 
        </md-button>
        <md-button class="md-icon-button" @click="speedDialState = 'moveBlock'">
          <ri n="drag-move-2-line" />
          Relocate
        </md-button>
      </md-speed-dial-content>
      </slot>
    </md-speed-dial>
    
    <div class="md-fab-stack" v-if="speedDialState === 'moveBlock'">
      <md-button class="md-fab" style="background: purple; color: white; font-size: 2rem;" @click="moveBlockUp">
        <ri n="arrow-up-line" />
      </md-button>
      <md-divider />
      <md-button class="md-fab" style="background: purple; color: white; font-size: 2rem;" @click="moveBlockDown">
        <ri n="arrow-down-line" />
      </md-button>
    </div>
    
    <media-unit-live-preview-editor
      v-if="speedDialState === 'mediaWidth'"
      :uuid="$store.state.activeComponent.uuid"
      :key="$store.state.activeComponent.uuid"
      @save="saveLiveStyleEdits"
      @discard="cancelLiveStyleEdits"
      />

    <content-block-form
      v-if="speedDialState === 'structuredContent'"
      :block-type="$store.getters.activeComponent.blockType"
      :block-args="$store.getters.activeComponent.blockArgs"
      :uuid="$store.state.activeComponent.uuid"
      @save="saveContentBlockForm"
      @discard="cancelLiveStyleEdits"
      />


  </div>
  <div :style="activeBlockPosition" class='toggle-next-block' v-if="hoveredOver && !speedDialState"
       style="display:none">
    <a href="#" @click.prevent="cycleBlock">
      <ri n="arrow-go-forward-line" />
    </a>
    <a href="#" @click.prevent="undoCyclingBlock" v-if="originalComponentForUndoingCycle">
      <ri n="recycle-line" />
    </a>

    <a href="#" @click.prevent="deleteBlock">
      <ri n="delete-bin-2-line" />
    </a>
  </div>
</div>
</template>

<script>
var css = require('css');
const shortuuid = require('short-uuid');
import Vue from 'vue';
import $ from "cash-dom";
import anime from 'animejs/lib/anime.es.js';

import NuxtContent from './NuxtContent.vue';

import DialogTiptap from './DialogTiptap.vue';
import HtmlElementLivePreviewEditor from './HtmlElementLivePreviewEditor.vue';
import DialogImageInput from './dialogs/DialogImageInput.vue';
import MediaUnitEditor from './dialogs/MediaUnitEditor.vue';
import BlockLevelStyleEditor from './dialogs/BlockLevelStyleEditor.vue';
import DialogMediaUnitManipulatorWithContentKnobs from './DialogMediaUnitManipulatorWithContentKnobs.vue';
import DialogMediaUnitManipulatorWithBackgroundKnobs from './DialogMediaUnitManipulatorWithBackgroundKnobs.vue';
import DialogMediaUnitManipulator from './DialogMediaUnitManipulator.vue';
import MediaUnitLivePreviewEditor from './MediaUnitLivePreviewEditor.vue';
import ContentBlockForm from './ContentBlockForm.vue';
import DialogAddContentBlock from './DialogAddContentBlock.vue';

import BoxLayout from './dialogs/BoxLayout.vue';
import { openDialog } from '../modal-dialog.js';

import { parseCssUnitValue, parseStyles, writeStyleString } from '../css-utils.js';
import { cycleCorpusBlock, renderCorpus } from '../utils.js';

const outerHtml = $dom => [...$dom].map(o => o.outerHTML || '').join('\n').trim();

export default {
  name: 'UserContent',
  props: ['css', 'html', 'onAdd', 'entry', 'useLiveHoverEdits', 'useBlockEditOptions'],
  
  mounted () {
    this.$refs['style-container'].innerHTML = '';
    
    if (this.css) {
      let style = document.createElement('style');
      style.innerText = this.scopedCSS;
      this.$refs['style-container'].append(style);
    }
    this.initWidgets();
  }, 
  
  methods: {

    undo () {
      this.$store.commit('undo');
    },    
    
    initWidgets () {
      if (typeof window.FB !== 'undefined') {
        window.FB.XFBML.parse()
      }
      if (typeof window.twttr !== 'undefined') {
        window.twttr.widgets.load();
      }
    },
    
    log: o => console.log(o),
    
    changeImageUntetheredness (on) {
      if (on) {
        $(`[id="${this.$store.state.activeComponent.uuid}"]`).addClass('media-untethered');
      } else {
        $(`[id="${this.$store.state.activeComponent.uuid}"]`).removeClass('media-untethered');
      }
    },
    
    changeWidthCappedness (val) {
      this.updateLiveStyle({
        'margin-left': 'auto',
        'margin-right': 'auto',
        'max-width': val,
      });
    },
    
    changeHeightFixedness (on) {
      if (on) {
        const fallbackHeight =  document.getElementById(this.$store.state.activeComponent.uuid).offsetHeight;
        this.activeBlockHeightAmount = fallbackHeight;
        this.updateLiveStyle({'height': `${fallbackHeight}px`});
        this.$refs['height-amount'].value = fallbackHeight;
        this.$refs['height-amount'].style.display = '';
        this.$refs['image-tetheredness'].style.display = '';
        this.$refs['image-tetheredness'].checked = true;        
        this.changeImageUntetheredness(true);
      } else {
        this.activeBlockHeightAmount = null; 
        this.updateLiveStyle({'height': null});
        this.$refs['height-amount'].style.display = 'none';
        this.$refs['image-tetheredness'].style.display = 'none';
        this.$refs['image-tetheredness'].checked = false;
        this.changeImageUntetheredness(false);
      }
    },
    
    updateLiveStyle (changes) {
      Object.keys(changes).forEach(key => {
        $(`[id="${this.$store.state.activeComponent.uuid}"]`).css(key, changes[key]);
      });
    },
    
    async saveLiveStyleEdits ({ uuid, style, mediaStyle, contentStyle, classList,
                                backgroundImageStyle, contentWrapperStyles }) {
      let styles = {
        ...this.$store.getters.activeComponent.styles,
        ...style,
      };
      let mediaStyles = {
        ...this.$store.getters.activeComponent.mediaStyles,
        ...mediaStyle,
      };
      let contentStyles = {
        ...this.$store.getters.activeComponent.contentStyles,
        ...contentStyle,
      };
      
      let dom = $(this.html);
      let current = dom.find(`[uuid="${uuid}"]`);
      
      if (backgroundImageStyle) {
        let backgroundSlot = [...current.find('template')]
            .filter(o => o.hasAttribute('v-slot:backgrounds'))[0];
        if (backgroundSlot) {
          let matchingTemplate = $('<div>'+backgroundSlot.innerHTML+'</div>');
          matchingTemplate.find("[image]").first().attr(backgroundImageStyle);
          current.find("template").filter(function() { return this.hasAttribute('v-slot:backgrounds'); }).html(matchingTemplate.html());
        }
      }
      
      if (contentWrapperStyles && contentWrapperStyles.length) {
        let contentSlot = [...current.find('template')]
            .filter(o => o.hasAttribute('v-slot:default'))[0];
        let matchingTemplate = $('<div>'+contentSlot.innerHTML+'</div>');
        matchingTemplate.find('.media-unit--content-wrapper').each(function(i) {
          let style = contentWrapperStyles[i];
          $(this).css(style);
        });
        current.find("template").filter(function() { return this.hasAttribute('v-slot:default'); }).html(matchingTemplate.html())
      }
      
      current
        .attr('styles', writeStyleString(styles, false, true))
        .attr('media-styles', writeStyleString(mediaStyles, false, true))
        .attr('content-styles', writeStyleString(contentStyles, false, true))
        .attr('class', classList.join(' '))
        .attr('data-block-overrides', JSON.stringify({
          styles: style,
          mediaStyles: mediaStyle,
          contentStyles: contentStyle,
          classList,
          backgroundImageStyle,
          contentWrapperStyles,
        }));
      this.$store.commit('setLoading', true);
      await this.$store.dispatch('immediatelyWritePageContent', {
        key: this.$store.state.activePageKey,
        html: outerHtml(dom)
      });
      this.$store.commit('setLoading', false);
      this.hoveredOver = null;
      this.speedDialState = null;      
    },
    
    async saveContentBlockForm ({ uuid, code }) {
      let dom = $(this.html);
      let current = dom.find(`[uuid="${uuid}"]`);
      
      current.replaceWith(code);
      this.$store.commit('setLoading', true);
      await this.$store.dispatch('immediatelyWritePageContent', {
        key: this.$store.state.activePageKey,
        html: outerHtml(dom)
      });
      this.$store.commit('setLoading', false);
      this.hoveredOver = null;
      this.speedDialState = null;      
    },
    
    cancelLiveStyleEdits ({ uuid }) {
      let dom = $(this.html);
      let current = dom.find(`[uuid="${uuid}"]`);
      
      const feeds = this.feeds;
      const content = this.content;
      
      let replacementHtml = $((new Vue({
        render: Vue.compile(outerHtml(current)).render,
        data () {
          return {
            feeds,
            content,
          };
        },        
      })).$mount().$el.outerHTML);
      
      $(`[id="${uuid}"]`).replaceWith(replacementHtml);
      this.hoveredOver = null;
      this.speedDialState = null;
    },
    
    async undoCyclingBlock () {
      if (!this.hoveredOver || !this.originalComponentForUndoingCycle) return;
      
      const uuid = this.hoveredOver.uuid;
      this.hoveredOver = null;
      this.speedDialState = null;
      
      let dom = $(this.html);
      let current = dom.find(`[uuid="${uuid}"]`);
      
      const replacementUuid = $(this.originalComponentForUndoingCycle).attr('uuid');
      
      current.replaceWith(this.originalComponentForUndoingCycle);
      current.remove();
      this.$store.commit('updateActivePageContent', outerHtml(dom));
      this.setEditingBlock(dom.find(`[uuid="${replacementUuid}"]`)[0]);
      
      this.originalComponentForUndoingCycle = null;
    },
    
    async cycleBlock () {
      
      if (!this.hoveredOver) return;
      
      const uuid = this.hoveredOver.uuid;
      this.hoveredOver = null;
      this.speedDialState = null;      
      
      let dom = $(this.html);
      let current = dom.find(`[uuid="${uuid}"]`);
      
      if (!this.originalComponentForUndoingCycle) {
        this.originalComponentForUndoingCycle = outerHtml(current);
      }
      
      const blockType = current.attr('data-block-type');
      await this.finishCyclingBlock(cycleCorpusBlock(blockType).key, {});
    },
    
    async finishCyclingBlock (blockType, args, overrides = {}, designOverrides = {}, preserveUuid) {
      let dom = $(this.html);
      let current = dom.find(`[uuid="${this.hoveredOver.uuid}"]`);
      
      let replacement = $(renderCorpus(blockType, args, outerHtml(current), preserveUuid).trim());
      
      const feeds = this.feeds;
      const content = this.content;
      
      if (overrides.styles || designOverrides.styles) {
        replacement.attr('styles', writeStyleString({
          ...parseStyles(replacement.attr('styles') || {}),
          ...overrides.styles || {},
          ...designOverrides.styles || {},
        }, false, true));
      }
      if (overrides.mediaStyles || designOverrides.mediaStyles) {
        replacement.attr('media-styles', writeStyleString({
          ...parseStyles(replacement.attr('media-styles') || {}),
          ...overrides.mediaStyles || {},
          ...designOverrides.mediaStyles || {},
        }, false, true));
      }
      if (overrides.contentStyles || designOverrides.contentStyles) {
        replacement.attr('content-styles', writeStyleString({
          ...parseStyles(replacement.attr('content-styles') || {}),
          ...overrides.contentStyles || {},
          ...designOverrides.contentStyles
        }, false, true));
      }      
      if (overrides.backgroundImageStyle) {
        let backgroundSlot = [...replacement.find('template')]
            .filter(o => o.hasAttribute('v-slot:backgrounds'))[0];
        if (backgroundSlot) {
          let matchingTemplate = $('<div>'+backgroundSlot.innerHTML+'</div>');
          matchingTemplate.find("[image]").first().attr(overrides.backgroundImageStyle);
          replacement.find("template").filter(function() { return this.hasAttribute('v-slot:backgrounds'); }).html(matchingTemplate.html())
        }
      }
      if (overrides.contentWrapperStyles) {
        let contentSlot = [...replacement.find('template')]
            .filter(o => o.hasAttribute('v-slot:default'))[0];
        let matchingTemplate = $('<div>'+contentSlot.innerHTML+'</div>');
        matchingTemplate.find('.media-unit--content-wrapper').each(function(i) {
          let style = overrides.contentWrapperStyles[i];
          $(this).css(style);
        });
        replacement.find("template").filter(function() { return this.hasAttribute('v-slot:default'); }).html(matchingTemplate.html());
      }
      if (overrides.classList) {
        replacement.attr('class', overrides.classList.join(' '))
      }
      if (overrides) {
        replacement.attr('data-block-overrides', JSON.stringify(overrides));
      }
      if (designOverrides) {
        replacement.attr('data-block-design-overrides', JSON.stringify(designOverrides));
      }
      
      let replacementHtml = $((new Vue({
        render: Vue.compile(outerHtml(replacement)).render,
        data () {
          return {
            feeds,
            content,
          };
        },
      })).$mount().$el.outerHTML);
      
      let leavingHtml = $(`[data-block-type][id="${this.hoveredOver.uuid}`);
      replacementHtml.css('position', 'absolute')
        .css('top', leavingHtml.position().top)
        .css('left', leavingHtml.position().left)
        .css('width', leavingHtml.outerWidth())
        .css('margin-left', '100vw')
        .css('filter', 'blur(3px) grayscale(.7)')
        .insertAfter(leavingHtml);
      
      await Promise.all([
        anime({
          targets: `[data-block-type][id="${leavingHtml.attr('id')}"]`,
          translateX: '-100vw',
          duration: 500,
          easing: 'easeOutExpo',
        }).finished,
        anime({
          targets: `[data-block-type][id="${replacementHtml.attr('id')}"]`,
          marginLeft: 0,
          duration: 250,
          easing: 'linear',
        }).finished,
      ]);
      
      current.replaceWith(replacement);
      current.remove();
      
      const replacementUuid = replacementHtml.attr('id');
      
      const component = $(`[id="${replacementUuid}"]`),
            offset = component.offset(),
            height = component.outerHeight(),
            width = component.outerWidth();
      
      this.$store.commit('updateActivePageContent', outerHtml(dom));
      
      this.$nextTick(() => {
        this.hoveredOver = {
          uuid: replacementUuid,
          offset,
          height,
          width,
        };
        this.speedDialState = null;        
        this.setEditingBlock(dom.find(`[uuid="${replacementUuid}"]`)[0]);
      });
    },
    
    async editImage () {
      if (!this.shifting) return;
      
      let editingElement = this.shifting;
      this.shifting = null;
      
      const value = await openDialog(DialogImageInput, {
        value: editingElement.el.src,
      });
      editingElement.el.src = value;
      //@@TODO
    },
    
    async editElement () {
      if (!this.shifting) return;
      
      this.shifting.pinned = true;
      this.shifting.liveEdits = true;
      if (this.shifting.el) return;
      
      let editingElement = this.shifting;
      this.shifting = null;
      
      const component = $(editingElement.el).closest('[data-block-type]'),
            matchingElements = component.find('div:not(.media-side)').find(editingElement.el.tagName),
            index = matchingElements.index(editingElement.el);
      
      let dom = $(this.html);
      let targetEl = [...dom.find(`[uuid="${component.attr('id')}"] template`)]
          .filter(o => o.hasAttribute('v-slot:default'))[0] || dom.find(`[uuid=${component.attr('id')}]`)[0];
      let matchingTemplate = $('<div>'+targetEl.innerHTML+'</div>');
      
      let matchingTemplateElements = matchingTemplate.find(editingElement.el.tagName);
      
      let boxLayout = parseStyles($(matchingTemplateElements[index]).attr('style'), true);
      
      const newLayout = {
        ...boxLayout,
        ...(await openDialog(BoxLayout, {
          value: boxLayout,
        }))
      };
      
      $(matchingTemplateElements[index]).attr('style', writeStyleString(newLayout, true, true));
      targetEl.innerHTML = matchingTemplate[0].innerHTML;
      
      $(editingElement.el).css('filter', 'blur(3px)');
      this.$store.commit('updateActivePageContent', outerHtml(dom));
    },
    
    async editElement2 () {
      if (!this.shifting) return;
      
      let editingElement = this.shifting;
      this.shifting = null;
      
      if (editingElement.el.tagName.toUpperCase() === 'LI' || $(editingElement.el).closest('li:not(.wp-block-latest-posts--tile)').length) {
        editingElement.el = $(editingElement.el).closest('ul:not(.wp-block-latest-posts), ol')[0];
      }
      
      if ($(editingElement.el).closest('.form-wrapper').length) {
        return;
      }
      
      let component = $(editingElement.el).closest('[data-block-type]'),
          matchingElementsSelector = 'div:not(.media-side)';
      if (component.hasClass('tile-row')) {
        component = $(editingElement.el).closest('.wp-block-latest-posts--tile');
        matchingElementsSelector = '.wp-block-latest-posts__post-excerpt';
      }
      
      const matchingElements = component.find(matchingElementsSelector).find(editingElement.el.tagName),
            index = matchingElements.index(editingElement.el);
      
      let dom = $(this.html);
      let targetEl = [...dom.find(`[uuid="${component.attr('id')}"] template`)]
          .filter(o => o.hasAttribute('v-slot:default'))[0] || dom.find(`[uuid=${component.attr('id')}]`)[0];
      let matchingTemplate = $('<div>'+targetEl.innerHTML+'</div>');
      
      let matchingTemplateElements = matchingTemplate.find(editingElement.el.tagName);
      
      let thisMatchingTemplateElement = matchingTemplateElements[index];
      const resp = await openDialog(DialogTiptap, {
        value: thisMatchingTemplateElement.outerHTML,
      });
      
      $(matchingTemplateElements[index]).replaceWith(resp);
      targetEl.innerHTML = matchingTemplate[0].innerHTML;
      
      $(editingElement.el).css('filter', 'blur(3px)');
      this.$store.commit('updateActivePageContent', outerHtml(dom));
    },
    
    async cloneBlock () {
      if (!this.hoveredOver) return;
      let dom = $(this.html);
      
      let current = dom.find(`[uuid="${this.hoveredOver.uuid}"]`);
      
      let replacement = $(outerHtml(current));
      replacement.attr('uuid', shortuuid.generate()).find("[uuid]").each(function() {
        $(this).attr('uuid', shortuuid.generate());
      });
      
      const feeds = this.feeds;
      const content = this.content;
      
      let replacementHtml = $((new Vue({
        render: Vue.compile(outerHtml(replacement)).render,
        data () {
          return {
            feeds,
            content,
          };
        },
      })).$mount().$el.outerHTML);
      
      $(replacementHtml).css('transform', 'scale(0)')
        .css('opacity', 0)
        .insertAfter(`[id="${this.hoveredOver.uuid}"]`);
      
      await Promise.all([
        anime({
          targets: `[id="${replacementHtml.attr('id')}"]`,
          //rotate: '-720deg',
          scale: 1.2,
          opacity: .8,
          duration: 200,
          easing: 'easeOutExpo',
        }).finished,
      ]);
      await Promise.all([
        anime({
          targets: `[id="${replacementHtml.attr('id')}"]`,
          //rotate: '-720deg',
          scale: 1,
          opacity: 1,
          duration: 100,
          easing: 'easeOutExpo',
        }).finished,
      ]);      
      
      replacement.insertAfter(current);
      
      this.$store.commit('updateActivePageContent', outerHtml(dom));
      
      this.hoveredOver = null;
      this.speedDialState = null;      
    },
    
    async moveBlockUp () {
      if (!this.hoveredOver) return;
      let dom = $(this.html);
      
      let current = dom.find(`[uuid="${this.hoveredOver.uuid}"]`),
          previous = current.prev(`[uuid]`);
      
      if (!previous.length) return;
      
      let leavingHtml = $(`[id="${this.hoveredOver.uuid}"]`).css('filter', 'blur(3px)'),
          replacementHtml = $(`[id="${previous.attr('uuid')}"]`).css('filter', 'blur(3px)');
      
      await Promise.all([
        anime({
          targets: `[id="${leavingHtml.attr('id')}"]`,
          translateY: '-100%',
          duration: 300,
          easing: 'easeOutExpo',
        }).finished,
        anime({
          targets: `[id="${replacementHtml.attr('id')}"]`,
          translateY: '100%',
          duration: 300,
          easing: 'easeOutExpo',
        }).finished,
      ]);
      
      current.insertBefore(previous);
      
      this.$store.commit('updateActivePageContent', outerHtml(dom));
      
      this.hoveredOver = null;
      this.speedDialState = null;      
    },
    
    async moveBlockDown () {
      if (!this.hoveredOver) return;
      let dom = $(this.html);
      
      let current = dom.find(`[uuid="${this.hoveredOver.uuid}"]`),
          next = current.next(`[uuid]`);
      
      if (!next.length) return;
      
      let leavingHtml = $(`[id="${this.hoveredOver.uuid}"]`).css('filter', 'blur(3px)'),
          replacementHtml = $(`[id="${next.attr('uuid')}"]`).css('filter', 'blur(3px)');
      
      await Promise.all([
        anime({
          targets: `[id="${leavingHtml.attr('id')}"]`,
          translateY: '100%',
          duration: 300,
          easing: 'easeOutExpo',
        }).finished,
        anime({
          targets: `[id="${replacementHtml.attr('id')}"]`,
          translateY: '-100%',
          duration: 300,
          easing: 'easeOutExpo',
        }).finished,
      ]);
      
      current.insertAfter(next);
      
      this.$store.commit('updateActivePageContent', outerHtml(dom));
      
      this.hoveredOver = null;
      this.speedDialState = null;      
    },
    
    async editBlockProps () {
      if (!this.hoveredOver) return;
      
      let ModalComponent = DialogMediaUnitManipulator;
      if (this.$store.state.activeComponent.props['data-block-type'] == 'hero_ayanna') {
        ModalComponent = DialogMediaUnitManipulatorWithContentKnobs;
      }
      if (this.$store.state.activeComponent.props['data-block-type'] == 'hero_sammi' ||
          this.$store.state.activeComponent.props['data-block-type'] == 'hero_sammi_reversed') {
        ModalComponent = DialogMediaUnitManipulatorWithBackgroundKnobs;
      }
      const { props } = await openDialog(ModalComponent, {
        component: this.$store.getters.activeComponent,
        uuid: this.$store.state.activeComponent.uuid,
      });
      
      let dom = $(this.html);
      dom.find(`[uuid="${this.$store.state.activeComponent.uuid}"]`).attr(props);
      this.$store.commit('updateActivePageContent', outerHtml(dom));
      
      this.hoveredOver = null;
      this.speedDialState = null;      
    },
    
    async editStructuredMediaUnit () {
      
      if (this.$store.state.activeComponent.props['data-block-advanced-mode']) {
        await this.editMediaUnit('tab-content', 'tiptap');
        return;
      } else {
        const result = await openDialog(DialogAddContentBlock, {
          editing: true,
          startOnForm: 'content',
        });
        if (!result) {
          return;
        }
        if (result.action === 'destructure') {
          await this.editMediaUnit('tab-content', 'tiptap');
          return;
        }
        
        const { block, value, overrides, designOverrides } = result;
        await this.finishCyclingBlock(block, value, overrides, designOverrides, this.$store.state.activeComponent.uuid);
      }
    },
    
    async editBlockLevelStyles () {
      const uuid = this.hoveredOver.uuid;
      this.hoveredOver = null;
      
      await openDialog(BlockLevelStyleEditor);
      
      const component = $(`[id="${uuid}"]`),
            offset = component.offset(),
            height = component.outerHeight(),
            width = component.outerWidth();
      
      let dom = $(this.html);
      let current = dom.find(`[uuid="${uuid}"]`);
      this.setEditingBlock(current[0]);
      
      this.hoveredOver = {
        uuid,
        offset,
        height,
        width,
      };
      this.speedDialState = null;
    },
    
    async editMediaUnit (initialTab, initialSubtab, onlyShowTab) {
      const uuid = this.hoveredOver.uuid;
      this.hoveredOver = null;
      
      await openDialog(MediaUnitEditor, { initialTab, initialSubtab, onlyShowTab });
      
      const component = $(`[id="${uuid}"]`),
            offset = component.offset(),
            height = component.outerHeight(),
            width = component.outerWidth();
      
      let dom = $(this.html);
      let current = dom.find(`[uuid="${uuid}"]`);
      this.setEditingBlock(current[0]);
      
      this.hoveredOver = {
        uuid,
        offset,
        height,
        width,
      };
      this.speedDialState = null;      
    },
    
    async deleteBlock () {
      if (!this.hoveredOver) return;
      let dom = $(this.html);
      
      let current = dom.find(`[uuid="${this.hoveredOver.uuid}"]`);
      
      let leavingHtml = $(`[id="${current.attr('uuid')}"]`);//.css('filter', 'blur(3px)');
      
      await Promise.all([
        anime({
          targets: `[id="${leavingHtml.attr('id')}"]`,
          rotate: '720deg',
          scale: 0,
          opacity: 0,
          duration: 1000,
          easing: 'easeOutExpo',
        }).finished,
      ]);
      
      current.remove();
      
      this.$store.commit('updateActivePageContent', outerHtml(dom));
      
      this.hoveredOver = null;
      this.speedDialState = null;      
    },
    
    callOnAdd () {
      this.onAdd && this.onAdd(null, this.hoveredOver?.uuid);
    },
    
    pinElementEditable (event) {
      console.log(event.target, 'is the editable');
    },
    
    checkIfElementIsEditable (el) {
      const isInUserContent = el.closest('.user-content-compiled').length;
      if (!isInUserContent) {
        return;
      }
      
      const unhoverable = [
        '.media-unit--overlay',
        '.wp-block-button__link',
        '.wp-block-button',
        '.tile-row', '.tile-row > ul', '.tile-row > ul > li',
        '.wp-block-latest-posts__post-excerpt',
        '.wp-block-latest-posts__featured-image',
        '.wp-block-latest-posts__featured-image > a',
        '.wp-block-latest-posts__post-meta',
      ];
      if (unhoverable.filter(selector => $(selector).index(el) !== -1).length) {
        return;
      }
      
      const ancestorChecks = ['ul:not(.wp-block-latest-posts), ol', '.form-wrapper'];
      ancestorChecks.forEach(selector => {
        let found = el.closest(selector);
        if (found.length) {
          el = found.eq(0);
        }
      });
      return el;
    },
    
    indicateElementEditable (el, options = {}) {
      if (!this.useLiveHoverEdits) return;
      if (this.shifting?.pinned) return;
      if (el.classList.contains('.edit-element')) {
        return;
      }
      if ($(el).closest('.edit-element').length) {
        return;
      }
      $('.hovering').removeClass('hovering');
      $('.siblings-hovering').removeClass('siblings-hovering');
      el = $(el);
      
      el = this.checkIfElementIsEditable(el);
      if (!el) return;
      
      const component = el.closest('[id][data-block-type]'),
            matchingElements = component.find(el[0].tagName),
            index = matchingElements.index(el);
      
      if (index === -1) {
        return;
      }
      component.find('*').addClass('siblings-hovering');
      el.addClass('hovering').removeClass('siblings-hovering');
      el.find('*').removeClass('siblings-hovering');
      component.find('*').has(el[0]).removeClass('siblings-hovering');
      
      let type = 'html';
      if (el[0].tagName.toUpperCase() === 'IMG') {
        type = 'image';
      }
      
      let parent = this.checkIfElementIsEditable(el.parent());
      
      this.shifting = {
        el: el[0],
        type,
        offset: el.offset(),
        height: el.outerHeight(),
        width: el.outerWidth(),
        parent: parent.length ? parent[0] : null,
        pinned: options.pinned || false,
        liveEdits: false,
      };
    },
    editListener (e) {
      const isInUserContent = $(e.target).closest('.user-content-compiled').length;
      if (!isInUserContent) return;
      $(e.target).addClass('hovering').prop('draggable', true);
      this.maybeHovering = true;
    },
    mouseoutListener (e) {
      const isInUserContent = $(e.target).closest('.user-content-compiled').length;
      if (!isInUserContent) return;
      $(e.target).removeClass('hovering').removeProp('draggable');
    },
    
    setEditingBlock (el) {
      let props = {};
      [...el.attributes].forEach(({ name, value }) => {
        props[name] = value || "";
      });
      this.$store.commit('setEditingComponent', {
        name: el.tagName,
        props,
        uuid: props.uuid,
        content: '',
      });
    },
    
    async showBlockEditOptions (e) {
      if (!this.useBlockEditOptions) return;
      const isInUserContent = $(e.target).closest('.user-content-compiled').length;
      if (!isInUserContent) {

        const xMax = 16,
              rMax = -2;

        const uuid = this.hoveredOver.uuid;
        anime({
          targets: `[id="${uuid}"]`,
          easing: 'easeInOutSine',
          duration: 550,
          translateX: [
            {
              value: xMax * -1,
            },
            {
              value: xMax,
            },
            {
              value: xMax/-2,
            },
            {
              value: xMax/2,
            },
            {
              value: 0
            }
          ],
          rotate: [
            {
              value: rMax * -1,
            },
            {
              value: rMax,
            },
            {
              value: rMax/-2,
            },
            {
              value: rMax/2,
            },
            {
              value: 0
            }
          ],
          complete: () => {
            // clean up after ourselves -- if transform rule stays set it will screw up background-attachment
            $(`[id="${uuid}"]`).css('transform', null);
          },
        });
        
        this.$refs['block-controls-toggle'] && this.$refs['block-controls-toggle'].$el.click();
        this.hoveredOver = null;
        this.speedDialState = null;
        return;
      }
      const component = $(e.target).closest('[id][data-block-type]'),
            uuid = component.attr('id'),
            offset = component.offset(),
            height = component.outerHeight(),
            width = component.outerWidth();
      if (!component.length) {
        return;
      }
      
      if (uuid === this.hoveredOver?.uuid) {
        this.hoveredOver = null;
        this.speedDialState = null;
        return;
      }
      
      let dom = $(this.html);
      let current = dom.find(`[uuid="${uuid}"]`);
      this.setEditingBlock(current[0]);

      anime({            
        targets: `[id="${uuid}"]`,
        easing: 'easeInOutSine',
        duration: 550,
        scale: [
          { value: .95 },
          { value: 1.05 },
          { value: .97 },
          { value: 1.03 },
          { value: .99 },
          { value: 1 },
        ],
        complete: () => {
          // clean up after ourselves -- if transform rule stays set it will screw up background-attachment
          $(`[id="${uuid}"]`).css('transform', null);
        },
      });

      this.hoveredOver = {
        uuid,
        offset,
        height,
        width,
      };

      this.$refs['block-controls-toggle'] && this.$refs['block-controls-toggle'].$el.click();
      this.speedDialState = null;

      this.$nextTick(() => {
        if (this.$refs['block-controls'].$data.MdSpeedDial.active) {
          this.$refs['block-controls-toggle'].$el.click();
          this.$nextTick(() => {
            this.$refs['block-controls-toggle'].$el.click();
          });
        } else {
          this.$refs['block-controls-toggle'].$el.click();
        }
      });
    },
  },
  components: {
    MediaUnitLivePreviewEditor,
    ContentBlockForm,
    HtmlElementLivePreviewEditor,
  },
  data () {
    let content = {};
    if (this.entry) {
      content = {...this.entry.metadata};
      content.document = this.entry.content;
    }
    
    return {
      content,
      hoveredOver: null,
      originalComponentForUndoingCycle: null,
      maybeHovering: false,
      shiftX: null,
      shiftY: null,
      shifting: null,
      speedDialState: null,
    };
  },
  watch: {

    activePageKey () {
      this.hoveredOver = null;
      this.speedDialState = null;      
    },
    
    scopedCSS () {
      this.$refs['style-container'].innerHTML = '';

      if (this.css) {
        let style = document.createElement('style');
        style.innerText = this.scopedCSS;
        this.$refs['style-container'].append(style);
      }
    },
    
    entry () {
      this.content = this.entry;
    },
    async html () {
      this.initWidgets();
    },
  },
  computed: {

    activePageKey () {
      return this.$store.state.activePageKey;
    },
    
    feeds () {
      return this.$store.getters.feeds;
    },
    
    scopedCSS () {
      if (!this.css) return '';
      let ast = css.parse(this.css);
      const scope = '.user-content-compiled';
      for (let rule of ast.stylesheet.rules) {
        if (rule.type == 'rule') {
          rule.selectors = rule.selectors.map(selector => `${scope} ${selector}`);
        }
      }
      return css.stringify(ast);
    },
    
    activeBlockType () {
      if (!this.$store.state.activeComponent) return;
      return this.$store.state.activeComponent.name.toUpperCase();
    },
    
    activeBlockHeightIsLocked: {
      get () {
        const block = this.$store.getters.activeComponent;
        if (!block) return;
        
        if (block.styles['height']) {
          return true;
        } else {
          return false;
        }
      },
      
      set (val) {
        let el = $(`[id="${this.$store.state.activeComponent.uuid}"]`);
        let dom = $(this.html);
        let current = dom.find(`[uuid="${this.$store.state.activeComponent.uuid}"]`),
            styles = parseStyles(current.attr('styles'));
        
        if (val) {
          const height = el.outerHeight(),
                _1vh = window.innerHeight / 100,
                heightInVh = height / _1vh;
          
          el.css('height', `${heightInVh}vh`);
          styles['height'] = `${heightInVh}vh`;
        } else {
          el.css('height', '');
          delete styles['height'];
        }
        
        current.attr('styles', writeStyleString(styles));
        this.$store.commit('updateActivePageContent', outerHtml(dom));
      },
    },
    
    activeBlockImageObjectPosition: {
      get () {
        const block = this.$store.getters.activeComponent;
        if (!block) return;
        
        const position = block.styles['--image-object-position'] || 'left';
        return position;
      },
      
      set (val) {
        let el = $(`[id="${this.$store.state.activeComponent.uuid}"]`);
        let dom = $(this.html);
        let current = dom.find(`[uuid="${this.$store.state.activeComponent.uuid}"]`),
            styles = parseStyles(current.attr('styles'));
        
        el.css('--image-object-position', val);
        styles['--image-object-position'] = val;
        
        current.attr('styles', writeStyleString(styles));
        this.$store.commit('updateActivePageContent', outerHtml(dom));
      },
    },

    activeBlockMarginTopAmount: {
      get () {
        const block = this.$store.getters.activeComponent;
        if (!block) return;
        
        let val = block.styles['margin-top'];
        if (!val) return parseCssUnitValue('0rem').amount;

        return parseCssUnitValue(val).amount;
      },
      
      set (val) {
        $(`[id="${this.$store.state.activeComponent.uuid}"]`).css('margin-top', `${val}${this.activeBlockMarginTopUnit}`);
      },
    },
    activeBlockMarginTopUnit: {
      get () {
        const block = this.$store.getters.activeComponent;
        if (!block) return;
        
        let val = block.styles['margin-top'];
        if (!val) return parseCssUnitValue('0rem').unit;

        return parseCssUnitValue(val).unit || 'px';
      },
      
      set (val) {
        $(`[id="${this.$store.state.activeComponent.uuid}"]`).css('margin-top', `${this.activeBlockMarginTopAmount}${val}`);
      },
    },
    activeBlockMarginBottomAmount: {
      get () {
        const block = this.$store.getters.activeComponent;
        if (!block) return;
        
        let val = block.styles['margin-bottom'];
        if (!val) return parseCssUnitValue('0rem').amount;

        return parseCssUnitValue(val).amount;
      },
      
      set (val) {
        $(`[id="${this.$store.state.activeComponent.uuid}"]`).css('margin-bottom', `${val}${this.activeBlockMarginBottomUnit}`);
      },
    },
    activeBlockMarginBottomUnit: {
      get () {
        const block = this.$store.getters.activeComponent;
        if (!block) return;
        
        let val = block.styles['margin-bottom'];
        if (!val) return parseCssUnitValue('0rem').unit;

        return parseCssUnitValue(val).unit || 'px';
      },
      
      set (val) {
        $(`[id="${this.$store.state.activeComponent.uuid}"]`).css('margin-bottom', `${this.activeBlockMarginBottomAmount}${val}`);
      },
    },

    activeBlockHeightAmount: {
      get () {
        const block = this.$store.getters.activeComponent;
        if (!block) return;
        
        let height = block.styles['height'];
        if (!height) {
          return;
        }

        return parseCssUnitValue(height).amount;
      },
      
      set (val) {
        if (!val) {
          this.updateLiveStyle({'height': null});
        } else {
          this.updateLiveStyle({'height': `${val}${this.activeBlockHeightUnit}`});
        }
      },
    },

    activeBlockHeightUnit: {
      get () {
        const block = this.$store.getters.activeComponent;
        if (!block) return;
        
        let height = block.styles['height'];
        if (!height) return 'px';

        return parseCssUnitValue(height).unit || 'px';
      },
    },
    
    activeBlockMediaWidthAmount: {
      get () {
        const block = this.$store.getters.activeComponent;
        if (!block) return;
        
        let width = block.styles['--media-unit--media__width'];
        if (!width) return;

        return parseCssUnitValue(width).amount;
      },
      
      set (val) {
        $(`[id="${this.$store.state.activeComponent.uuid}"]`).css('--media-unit--media__width', `${val}${this.activeBlockMediaWidthUnit}`);
        
        let dom = $(this.html);
        let current = dom.find(`[uuid="${this.$store.state.activeComponent.uuid}"]`),
            styles = parseStyles(current.attr('styles'));
        
        styles['--media-unit--media__width'] = `${val}${this.activeBlockMediaWidthUnit}`;
        current.attr('styles', writeStyleString(styles));
        //this.$store.commit('updateActivePageContent', outerHtml(dom));
      },
    },

    activeBlockMediaWidthUnit: {
      get () {
        const block = this.$store.getters.activeComponent;
        if (!block) return;
        
        let width = block.styles['--media-unit--media__width'];
        if (!width) return;

        return parseCssUnitValue(width).unit || 'px';
      },
      
      set (val) {
        $(`[id="${this.$store.state.activeComponent.uuid}"]`).css('--media-unit--media__width', `${this.activeBlockMediaWidthAmount}${val}`);
      },
    },
    
    activeBlockPosition () {
      if (!this.hoveredOver) return;
      let style = {
        '--active-block-top': `${this.hoveredOver.offset.top}px`,
        '--active-block-height': `${this.hoveredOver.height}px`,
        '--active-block-left': `0`,
        '--active-block-width': `calc(100vw + .5em)`,
      };
      if (this.speedDialState) {
        style['background'] = 'transparent';
      }
      return style;
    },
    activeElementPosition () {
      if (!this.shifting) return;
      return {
        '--active-element-top': `${this.shifting.offset.top}px`,
        '--active-element-height': `${this.shifting.height}px`,
        '--active-element-left': `${this.shifting.offset.left}px`,
        '--active-element-width': `${this.shifting.width}px`,
      };
    },
    output () {
      const cmp = Vue.compile(this.html);

      const feeds = this.feeds;
      const content = this.content;
      const initWidgets = this.initWidgets;

      const that = this;
      
      const componentSpec = {
        render: cmp.render,
        staticRenderFns: cmp.staticRenderFns,
        components: { NuxtContent, },
        methods: {
          stopLinks (e) {
            that.$emit('click');
            e.preventDefault();
            e.stopPropagation();
            if (that.shifting) {
              that.shifting.pinned = !that.shifting.pinned;
            }
          },
        },
        data () {
          return {
            feeds,
            content,
          };
        },
        mounted () {
          this.$el.addEventListener('click', this.stopLinks, true);
          initWidgets();
        },
      };

      return componentSpec;
    },
  },

};
</script>

<style scoped>
.md-fab-stack {
  display:flex;
  flex-direction:column;
  justify-content:center;
}
.md-fab-stack .md-button >>> .md-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.md-speed-dial.md-with-hover .md-speed-dial-content {
  height: 0;
  transition: height .9s ease;
}
.md-speed-dial-content.md-speed-dial-right {
  right: 0;
  left: auto;
  flex-direction: column-reverse;
}
.md-speed-dial-content.md-speed-dial-right .md-button {
  border-radius: 80px 0 0 80px;
}
.md-speed-dial-content.md-speed-dial-right .md-button:first-child {
  margin-bottom: 0 !important;
}
.md-speed-dial-content.md-speed-dial-right .md-button:last-child {
  margin-bottom: 6px !important;
}
.md-speed-dial-content.md-speed-dial-right >>> .md-button-content {
  flex-direction: row-reverse;
  width: 100%;
  text-align: right;
  padding-right: 0;
  padding-left: .5em;
}
.md-speed-dial .md-speed-dial-content.md-speed-dial-right .md-button [class^="ri-"],
.md-speed-dial .md-speed-dial-content.md-speed-dial-right .md-button [class*=" ri-"] {
  margin-left: .5em;
  margin-right: 0;
}

.md-speed-dial-content.md-speed-dial-right .md-button >>> .md-button-content {

}
.md-speed-dial-content.md-speed-dial-right .md-button >>> .md-button-content {

}
.md-speed-dial.md-with-hover:hover .md-speed-dial-content {
  height: 5em;
}
.md-speed-dial.md-with-hover:hover .md-speed-dial-target {
  opacity: 0;
  height: 0;
}
.md-speed-dial.md-active .md-speed-dial-content .md-button[md-button-index="6"],
.md-speed-dial.md-with-hover:hover .md-speed-dial-content .md-button[md-button-index="6"] {
  transition-delay: .6s;
}
.md-speed-dial.md-active .md-speed-dial-content .md-button[md-button-index="7"],
.md-speed-dial.md-with-hover:hover .md-speed-dial-content .md-button[md-button-index="7"] {
  transition-delay: .7s;
}
.md-speed-dial.md-active .md-speed-dial-content .md-button[md-button-index="8"],
.md-speed-dial.md-with-hover:hover .md-speed-dial-content .md-button[md-button-index="8"] {
  transition-delay: .8s;
}
.md-speed-dial.md-active .md-speed-dial-content .md-button[md-button-index="9"],
.md-speed-dial.md-with-hover:hover .md-speed-dial-content .md-button[md-button-index="9"] {
  transition-delay: .9s;
}
.md-speed-dial {
  align-items: center;
}
.md-speed-dial-content {
  //flex-direction: row;
  //flex-wrap: wrap;
  //max-width: 5em;
  //align-items: center;

  justify-content: space-evenly;

    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    justify-content: center;
z-index: 999;
}
.md-speed-dial-content .md-button {
  margin-right: .5em;
  border-radius: 0 80px 80px 0;
width: auto;
align-self: stretch;
}
.md-speed-dial .md-speed-dial-content .md-button >>> .md-button-content {
  display: flex;
  align-items: center;
  color: white;
  padding-right: .5em;
}
.md-speed-dial .md-speed-dial-content .md-button >>> .md-ripple {
  justify-content: flex-start;
}
.md-speed-dial .md-speed-dial-content .md-button [class^="ri-"],
.md-speed-dial .md-speed-dial-content .md-button [class*=" ri-"] {
  margin-right: .5em;
}

.md-speed-dial .md-primary {
  background: purple !important;
  opacity: .3;  
}
.md-speed-dial:hover .md-primary {
  opacity: 1;
}
.md-speed-dial [class^="ri-"],
.md-speed-dial [class*=" ri-"] {
  color: white;
  font-size: 2.5em;
}
.md-speed-dial .md-speed-dial-content .md-button {
  background: purple !important;
}
.md-speed-dial .md-speed-dial-content [class^="ri-"],
.md-speed-dial .md-speed-dial-content [class*=" ri-"] {
  color: white;
  font-size: 1.5em;
}
.edit-element {
  position: absolute;
  top: var(--active-element-top);
  height: var(--active-element-height);
  left: var(--active-element-left);
  width: var(--active-element-width);
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-element a {
  top: 0;
  right: 0;
  font-size: 1.5rem;
  z-index: 99999;
  text-decoration: none;
  color: black;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
}

.block-middle-controls {
  position: absolute;
  background: rgb(128 0 128 / 13%);
  top: var(--active-block-top);
  left: var(--active-block-left);
  height: var(--active-block-height);
  width: var(--active-block-width);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  flex-direction: column;
}

.toggle-next-block {
  position: absolute;
  background: #eee;
  top: var(--active-block-top);
  height: var(--active-block-height);
  right: 0;
  width: 50px;
  color: black;
  padding: 1rem 2rem;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  flex-direction: column;
}
.toggle-next-block a {
  color: inherit;
  text-decoration: none;
  transition: all .1s ease;
}
.toggle-next-block a:hover {
  color: blue;
  transform: scale(1.2);
}
svg.svg-inline--fa {
  transition: transform 0.5s;
}
svg.svg-inline--fa:hover {
  transform: scale(1.2);
}

.user-content >>> [data-block-type].floating {
  top: -100vh !important;
  opacity: 0.2 !important;
}
.user-content >>> [data-block-type].sinking {
  top: 100vh !important;
  opacity: 0.2 !important;
}
.user-content >>> [data-block-type].leaving {
  left: -100vw !important;
  opacity: 0.2 !important;
}
.user-content >>> [data-block-type].deleting {
  transform: scale(0.1) !important;
  opacity: 0.2 !important;
}
.user-content >>> [data-block-type].arriving {
  right: -100vw !important;
}

.user-content >>> .hovering {
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 38%) !important;
}
.user-content >>> .siblings-hovering {
  filter: grayscale(1) blur(3px);
}
</style>
