import { TileRow } from './core.js';

export const issue_tiles = new TileRow('issue_tiles', 'Issue Tiles', 'generic_short_tiles', {
  styles: () => ({
    'background': '#dbf1ff',
    'margin-bottom': '-1rem',
    'padding-bottom': '2.5rem',
  }),  
  containerStyles: () => ({
    '--columns': '4',
  }),
  justify: () => 'start',
  order: () => 'title,image,excerpt,link',
});

export const issue_tiles2 = new TileRow('issue_tiles2', 'Issue Tiles 2', 'generic_short_tiles', {
  styles: () => ({
    'background': '#ffaa33',
    'margin-bottom': '-1rem',
    'padding-bottom': '2.5rem',
  }),
  containerStyles: () => ({
    '--columns': '3',
  }),
  tileStyles: () => ({
    'position': 'relative',
  }),
  tileTitleStyles: () => ({
    'position': 'absolute',
    'top': '4rem',
    'left': '100%',
    'transform': 'translateX(-100%)',
    'width': '100%',
  }),  
  justify: () => 'center',
  order: () => 'title,image,excerpt,link',
});

export const issue_tiles_icons = new TileRow('issue_tiles_icons', 'Issue Tiles Icons', 'generic_short_tiles', {
  styles: () => ({
    'background': '#ffffff',
    'margin-bottom': '-1rem',
    'padding-bottom': '2.5rem',
    'padding-top': '2.5rem',    
    'padding-left': '5vw',
    'padding-right': '5vw',
  }),
  tileStyles: () => ({
    'background': '#fafafa',
    'border-radius': '25px',
    'margin-left': '.5rem',
    'margin-right': '.5rem',
    'padding-left': '1.25rem',
    'padding-right': '1.25rem',
    'padding-top': '1.25rem',
  }),
  containerStyles: () => ({
    'justify-content': 'center',
    '--columns': '4',
  }),
  tileTitleStyles: () => ({
  }),  
  justify: () => 'center',
  order: () => 'image,title,excerpt,link',
});

export const issue_tiles3 = new TileRow('issue_tiles3', 'Issue Tiles 3', 'generic_short_tiles', {
  styles: () => ({
    'background': '#aaffaa',
    'margin-bottom': '-1rem',
    'padding-bottom': '2.5rem',
  }),
  containerStyles: () => ({
    '--columns': '1',
    'max-width': '1200px',
    'margin-left': 'auto',
    'margin-right': 'auto',
  }),
  tileStyles: () => ({
    'margin-left': '5rem',
    'margin-right': '5rem',    
  }),
  justify: () => 'center',
  order: () => 'image,title,excerpt,link',
});
