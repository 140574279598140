export const two_columns_50 = {
  block: 'two_columns',
};

export const two_columns_centered_50 = {
  block: 'two_columns',
  args: {
    contentClass: 'has-wrapped-content direction-row alignment-stretch-centered',
  },
};

export const two_columns_75 = {
  block: 'two_columns',
  args: {
    firstColumnWidth: 75,
  },
};

export const two_columns_60 = {
  block: 'two_columns',
  args: {
    firstColumnWidth: 60,
  },
};

