<template>
  <div>
    <div class="loader-underlay"></div>
    <div class="loader">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="224px" height="224px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(50 50)">
          <g transform="scale(0.82)">
            <g transform="translate(-50 -50)">
              <g transform="rotate(276.897 50 50)">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0416666666666667s"></animateTransform>
                <path fill-opacity="1" fill="#1a94c1" d="M50 50L50 0A50 50 0 0 1 100 50Z"></path>
              </g>
              <g transform="rotate(297.672 50 50)">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.3888888888888888s"></animateTransform>
                <path fill-opacity="1" fill="#19943a" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(90 50 50)"></path>
              </g>
              <g transform="rotate(318.443 50 50)">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="2.0833333333333335s"></animateTransform>
                <path fill-opacity="1" fill="#9c238d" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(180 50 50)"></path>
              </g>
              <g transform="rotate(339.222 50 50)">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="4.166666666666667s"></animateTransform>
                <path fill-opacity="1" fill="#dc1611" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(270 50 50)"></path>
              </g>
            </g>
          </g>
        </g>
        <!-- [ldio] generated by https://loading.io/ -->
      </svg>
    </div>
  </div>
</template>

<script>
  export default {
      name: 'loader'
  }
</script>

<style scoped>
.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-underlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(1px);
}
</style>
