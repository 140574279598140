<template>
<dialog-layout style="min-height: min(80vh, 800px)">

  <dialog-close-button @close="$emit('close')" />
  
  <md-app style="min-height: min(80vh, 800px)">
    <md-app-toolbar>
      <app-tabs
        :tabs="tabs"
        @tab-changed="activeTab = $event; if ($event === 'tab-css') { editingGlobal = false; } else { editingGlobal = true; }">
      </app-tabs>
    </md-app-toolbar>

    <md-app-drawer md-permanent="clipped">
      
      <md-list>

        <md-list-item>
          <md-switch class="md-switch-left-right" v-model="editingGlobal" @change="editingMeta = {}; editingSnippet = {};">
            <span v-if="editingGlobal">Sitewide</span>
            <span v-else>Page-level</span>
          </md-switch>
        </md-list-item>

        <template v-if="subtabs[activeTab]">
          <md-list-item v-for="tab in subtabs[activeTab][editingGlobal ? 'global' : 'page']"
                        :md-expand="tab.subtabs"
                        :key="tab.name">
            <md-icon><ri :n="tab.icon" /></md-icon>
            <span class="md-list-item-text">{{ tab.label }}</span>
            
            <md-list v-if="tab.subtabs" slot="md-expand">
              <md-list-item v-for="subtab, index in tab.subtabs" :key="subtab.key"
                            class="md-inset"
                            @click="setEditing(activeTab, subtab.name, subtab, index)">
                <span class="md-list-item-text">{{ subtab.label }}</span>
              </md-list-item>
            </md-list>
          </md-list-item>
        </template>
        
      </md-list>

    </md-app-drawer>
    
    <md-app-content style="background:whitesmoke">
      <template v-if="activeTab === 'tab-snippets' && !editingGlobal">
        <md-empty-state style="max-width: 700px"
          md-label="Per-page code snippets not available"
          md-description="We don't currently support per-page snippets. Instead, you can use CSS hooks and conditional Javascript based on the currently viewed page.">
          <md-button href="https://progressive-change-campaign-committee.helpscoutdocs.com/article/4-snippets" target="_blank" class="md-raised">Learn more</md-button>
        </md-empty-state>
      </template>

      <template v-if="activeTab === 'tab-css'">
        <md-empty-state v-if="editingGlobal"
                        style="max-width: 700px"
                        md-label="Global CSS styles not available"
          md-description="We don't currently support global custom CSS styles. Instead, you can use snippets to add an external stylesheet or inline <style> tag.">
          <md-button href="https://progressive-change-campaign-committee.helpscoutdocs.com/article/4-snippets" target="_blank" class="md-raised">Learn more</md-button>
        </md-empty-state>
        
        <form-card v-else @submit="saveCss" :sending="sending" title="Custom CSS">
          <md-field>
            <label>CSS Code</label>
            <md-textarea class="code-textarea" v-model="realCss" md-autogrow></md-textarea>
          </md-field>

          <template v-slot:actions>
            <md-button @click.prevent.stop="realCss = ' ';saveCss()"
                       class="md-accent" :disabled="sending">Delete</md-button>
          </template>
        </form-card>
      </template>
      
      <template v-if="activeTab === 'tab-snippets' && editingGlobal">
        
        <form-card v-if="editingSnippet.tab" @submit="editSnippet" :sending="sending"
                   :title="editingSnippet.tab.label"
                   :help="editingSnippet.tab.help">
          <md-field>
            <label>Name</label>
            <md-input v-model="editingSnippet.key"></md-input>
          </md-field>
          
          <md-field :md-counter="editingSnippet.tab && editingSnippet.tab.counter ? true : false">
            <label>Code in HTML HEAD (just before <code>&lt;/head></code>)</label>
            <md-textarea class="code-textarea" v-model="editingSnippet.value.head" md-autogrow></md-textarea>
          </md-field>
          
          <md-field :md-counter="editingSnippet.tab && editingSnippet.tab.counter ? true : false">
            <label>Code in HTML BODY, early (just after <code>&lt;body></code>)</label>
            <md-textarea  class="code-textarea" v-model="editingSnippet.value.body" md-autogrow></md-textarea>
          </md-field>
          
          <md-field :md-counter="editingSnippet.tab && editingSnippet.tab.counter ? true : false">
            <label>Code in HTML BODY, late (just before <code>&lt;/body></code>)</label>
            <md-textarea class="code-textarea" v-model="editingSnippet.value.foot" md-autogrow></md-textarea>
          </md-field>
          
          <template v-slot:actions>
            <md-button @click.prevent.stop="removeSnippet"
                       class="md-accent" :disabled="sending">Delete</md-button>
          </template>
        </form-card>

        <md-empty-state v-else style="max-width: 700px"
          md-label="Choose a snippet on the right to get started"
          md-description="With snippets, you can add Javascript code and libraries, external stylesheets, and other arbitrary HTML code to your site.">
          <md-button href="https://progressive-change-campaign-committee.helpscoutdocs.com/article/4-snippets" target="_blank" class="md-raised">Learn more</md-button>
        </md-empty-state>
        
      </template>
      
      <template v-if="activeTab === 'tab-meta'">        

        <template v-if="editingMeta.tab">
          <form-card :sending="sending"
                     @submit="editMeta"
                     @delete="removeMeta"
                     :title="editingMeta.tab.label"
                     :help="editingMeta.tab.help">
            <md-field v-show="!editingMeta.key.endsWith('_tag')">
              <label>Name</label>
              <md-input v-model="editingMeta.key"></md-input>
            </md-field>
            
            <md-field :md-counter="editingMeta.tab && editingMeta.tab.counter ? true : false">
              <label>Value</label>
              <md-textarea :md-counter="editingMeta.tab && editingMeta.tab.counter"
                           :key="`${editingGlobal ? 'global' : 'page'}-${editingMeta.key}`"
                           v-model="editingMeta.value" md-autogrow></md-textarea>
            </md-field>
          </form-card>
          
          <md-card class="md-layout-item md-size-100" style="margin-top: 5rem">
            <md-card-content>
              <pre v-text="editingMetaTagPreview" style="margin-bottom:0">
              </pre>
            </md-card-content>
          </md-card>
        </template>
      
        <md-empty-state v-else style="max-width: 700px"
                        md-label="Choose a meta tag on the right to get started"
                        :md-description="`Add custom meta tags to ${editingGlobal ? 'all pages on your website' : this.currentPageName} here. These can control how your ${editingGlobal ? 'website' : 'page'} behaves on search engines, social media platforms, and more.`">
          <md-subheader v-if="editingGlobal">
            <pro-tip>
              You can set default values here, and then override them on specific pages
              using a page-level meta tag with the same name.
              <br><br>
              This can be helpful if (for example) you want a standard title, description, and image to
              appear with all Facebook shares until you specify otherwise.
            </pro-tip>
          </md-subheader>
        </md-empty-state>
       
      </template>
    </md-app-content>
  </md-app>
</dialog-layout>
</template>

<script>
export default {
  name: 'PageMetaEditor',
  
  watch: {
    pageToPreview () {
      this.exquisiteCorpse = this.exquisiteCorpseContent(this.pageToPreview.corpse);
    },
  },
  
  data () {
    
    let { meta, metaObj, customMeta } = this.recomputeMeta();
    
    let customGlobalMeta = [];
    for (let entry of this.$store.state.repo.config.layout.meta || []) {
      if ([
        'og:site_name', 'twitter:site',
        'fb:app_id', 'google-site-verification'
      ].indexOf(entry.hid) == -1) {
        customGlobalMeta.push({
          name: entry.hid,
          label: entry.hid,
        });
      }
    }
    
    let snippets = Object.keys(this.$store.state.repo.snippets);
    let customGlobalSnippets = snippets.filter(name => {
      return ['google-analytics', 'facebook-pixel', 'google-tag-manager'].indexOf(name) === -1;
    }).map(o => ({
      name: o, label: o,
    }));
    
    return {

      tabs: [
        { id: 'tab-meta', label: 'Meta Tags', icon: 'price-tag-line' },
        { id: 'tab-snippets', label: 'Code Snippets', icon: 'code-s-slash-line' },
        { id: 'tab-css', label: 'CSS Styles', icon: 'braces-line' },        
      ],
      
      meta,
      metaObj,

      realCss: this.$store.getters.activePage.style,
      
      sending: false,
      
      editingGlobal: true,
      
      redirectStatus: {},
      addingStatus: null,
      
      exquisiteCorpse: '',
      selectedType: null,
      pageToPreview: null,
      
      newPageHeading: null,
      newPageImage: null,
      
      newRedirect: {
        permanent: false,
        source: '',
        destination: '',
      },
      
      subtabs: {
        'tab-snippets': {
          "global": [
            { name: 'analytics', icon: 'line-chart-line', label: "Analytics", subtabs: [
              { name: 'google-analytics', label: "Google Analytics"},
              { name: 'facebook-pixel', label: "Facebook Pixel"},
              { name: 'google-tag-manager', label: "Google Tag Manager"},
            ]}, 
            { name: 'custom', label: 'Custom', icon: 'add-circle-line', subtabs: [
              ...customGlobalSnippets,
              { name: 'add_new', icon: 'link', label: '+ Add Custom' },
            ]},
          ],
          "page": [
          ],
        },
        
        'tab-meta': {
          "global": [
            { name: 'title', icon: 'star-line', label: 'Title & Favicon', subtabs: [
              { name: 'title_tag', label: 'Title'},
              { name: 'favicon_tag', label: 'Favicon'},
            ]},
            { name: 'attribution', icon: 'earth-line', label: 'Attribution', subtabs: [
              { name: 'og:site_name', label: "Site Name"},
              { name: 'twitter:site', label: "Twitter Handle"},
            ]},
            { name: 'og', label: 'Facebook', icon: 'facebook-circle-line', 'subtabs': [
              { name: 'og:title', icon: 'heading', label: 'Title' },
              { name: 'og:description', icon: 'pencil-ruler-line', label: 'Description' },
              { name: 'og:image', icon: 'link', label: 'Image' },
            ]},
            { name: 'twitter', label: 'Twitter', icon: 'twitter-line', subtabs: [
              { name: 'twitter:title', icon: 'heading', label: 'Title' },          
              { name: 'twitter:description', icon: 'pencil-ruler-line', label: 'Description' },
              { name: 'twitter:image', icon: 'link', label: 'Image' },
            ]},
            { name: 'analytics', icon: 'line-chart-line', label: "Analytics", subtabs: [
              { name: 'fb:app_id', label: "Facebook App ID"},
              { name: 'google-site-verification', label: "Google Site Verification"},
            ]},
            { name: 'custom', label: 'Custom', icon: 'add-circle-line', subtabs: [
              ...customGlobalMeta,
              { name: 'add_new', icon: 'link', label: '+ Add Custom' },
            ]},
          ],
          "page": [
            { name: 'title', icon: 'star-line', label: 'Title', subtabs: [
              { name: 'title_tag', label: 'Title'},
              //{ name: 'favicon_tag', label: 'Favicon'},
            ]},
            { name: 'seo', label: 'Search Engines', icon: 'search-eye-line', 'subtabs': [
              { name: 'title', icon: 'paragraph', label: 'Title' },
              {
                name: 'description', icon: 'pencil-ruler-line', label: 'Description',
                help: 'Meta description is used in search result previews. Keep it short! 160 characters or less.',
                counter: 155,
              },
              { name: 'keywords', icon: 'pencil-ruler-line', label: 'Keywords',
                help: 'Keywords are considered a bad practice that can hurt your SEO rankings. Use with caution &mdash; best to leave this blank.' },
            ]},
            { name: 'og', label: 'Facebook', icon: 'facebook-circle-line', 'subtabs': [
              { name: 'og:title', icon: 'heading', label: 'Title' },
              { name: 'og:description', icon: 'pencil-ruler-line', label: 'Description' },
              { name: 'og:image', icon: 'link', label: 'Image' },
            ]},
            { name: 'twitter', label: 'Twitter', icon: 'twitter-line', subtabs: [
              { name: 'twitter:title', icon: 'heading', label: 'Title' },          
              { name: 'twitter:description', icon: 'pencil-ruler-line', label: 'Description' },
              { name: 'twitter:image', icon: 'link', label: 'Image' },
            ]},
            { name: 'custom', label: 'Custom', icon: 'add-circle-line', subtabs: [
              ...customMeta,
              { name: 'add_new', icon: 'link', label: '+ Add Custom' },
            ]},
          ],
        },
      },
      
      activeTab: '',
      editingMeta: {},
      
      editingSnippet: {},
      
      newMetaKey: '',
    };
  },
  
  props: [
    'showMeta',
    'selectable',
    'only',
  ],
  
  methods: {

    async saveCss () {
      this.sending = true;
      this.$store.commit('setLoading', true);      
      await this.$store.dispatch('immediatelyWritePageContent', {
        key: this.$store.state.activePageKey,
        html: this.$store.getters.activePage.html,
        style: this.realCss,
      });
      this.sending = false;
      this.$store.commit('setLoading', false);
    },
    
    recomputeMeta () {
      const keyForHead = this.$store.state.activePageKey.split('/').pop(); // only use the last path component in head obj
      const head = this.$store.state.repo.config.head[keyForHead] || {},
            meta = head.meta || [];
      
      let metaObj = {};
      meta.forEach(o => {
        metaObj[o.property || o.name] = o.content || o.value;
      });
      
      let customMeta = [];
      for (let entry of meta) {
        if (['title', 'description', 'og:title', 'og:description',
             'og:image', 'twitter:title', 'twitter:description', 'twitter:image',
            ].indexOf(entry.hid) === -1) {
          customMeta.push({
            name: entry.hid,
            icon: 'link',
            label: entry.hid,
          });
        }
      }
      
      return { meta, metaObj, customMeta };
    },
    
    async setEditing (type, key, tab) {
      if (type === 'tab-meta') {
        await this.setEditingMeta(key, tab);
      }
      if (type === 'tab-snippets') {
        await this.setEditingSnippet(key, tab);
      }
      
    },
    
    async setEditingMeta (key, tab) {
      
      if (key === 'add_new') {
        key = null;
      }

      let meta = this.metaObj;
      if (this.editingGlobal) {
        meta = {};
        (this.$store.state.repo.config.layout.meta || []).forEach(o => {
          meta[o.property || o.name] = o.content || o.value;
        });
      }
      
      if (this.editingGlobal && key.endsWith('_tag')) {
        let realKey = key.replace(/_tag$/, '');
        this.editingMeta = {
          key,
          tab,
          value: this.$store.state.repo.config.layout[realKey],
        };
      } else if (key.endsWith('_tag')) {
        const keyForHead = this.$store.state.activePageKey.split('/').pop(); // only use the last path component in head obj
        const head = this.$store.state.repo.config.head[keyForHead] || {};
        let realKey = key.replace(/_tag$/, '');        
        this.editingMeta = {
          key,
          tab,
          value: head[realKey]
        };
      } else {
        this.editingMeta = {
          key,
          tab,
          value: meta[key],
        };
      }
    },
    
    async setEditingSnippet (key, tab) {
      
      if (key === 'add_new') {
        key = null;
      }
      this.editingSnippet = {
        key,
        tab,
        value: this.$store.state.repo.snippets[key] || {
          head: '',
          body: '',
          foot: '',
        },
      };
    },

    async removeGlobalMeta () {
      const { key } = this.editingMeta;

      let config = this.$store.state.repo.config.layout,
          meta = config.meta || [];

      if (key.endsWith('_tag')) {
        config = {...config};
        let realKey = key.replace(/_tag$/, '');
        if (config[realKey]) {
          delete config[realKey];
        }
      } else {
        let newMeta = meta.filter(m => m.name !== key && m.property !== key);
        config = {...config, meta: newMeta};
      }

      this.sending = true;
      await this.$store.dispatch('updateConfigs', { layout: config });

      let customGlobalMeta = [];
      for (let entry of this.$store.state.repo.config.layout.meta || []) {
        if ([
          'og:site_name', 'twitter:site',
          'fb:app_id', 'google-site-verification'
        ].indexOf(entry.hid) == -1) {
          customGlobalMeta.push({
            name: entry.hid,
            label: entry.hid,
          });
        }
      }
      let tab = this.subtabs['tab-meta'].global.find(o => o.name === 'custom');
      tab.subtabs = [...customGlobalMeta, tab.subtabs[tab.subtabs.length - 1] ];
      
      this.sending = false;
      this.editingMeta = {};
    },
    
    async removeMeta () {
      if (this.editingGlobal) {
        await this.removeGlobalMeta();
        return;
      }
      const keyForHead = this.$store.state.activePageKey.split('/').pop(); // only use the last path component in head obj
      const head = this.$store.state.repo.config.head[keyForHead] || {};

      let { key } = this.editingMeta,
          newHead = {...head};

      
      if (key.endsWith('_tag')) {
        let realKey = key.replace(/_tag$/, '');        
        delete newHead[realKey];
      } else {
        const newMeta = this.meta.filter(meta => (meta.name !== key && meta.property !== key));
        newHead.meta = newMeta;
      }

      this.sending = true;
      this.$store.commit('setLoading', true);
      await this.$store.dispatch('updatePageHead', {
        key: this.$store.state.activePageKey,
        head: newHead,
      });
      this.$store.commit('setLoading', false);
      
      this.sending = false;
      
      let { meta, metaObj, customMeta } = this.recomputeMeta();
      this.meta = meta;
      this.metaObj = metaObj;
      let tab = this.subtabs['tab-meta'].page.find(o => o.name === 'custom');
      tab.subtabs = [...customMeta, tab.subtabs[tab.subtabs.length - 1] ];
      
      this.editingMeta = {};
    },

    async editGlobalMeta () {
      const { key, value } = this.editingMeta;

      let config = this.$store.state.repo.config.layout,
          meta = config.meta || [];

      if (key.endsWith('_tag')) {
        let realKey = key.replace(/_tag$/, '');
        config = {...config};
        config[realKey] = value;
      } else {
        let newMeta = meta.filter(m => m.name !== key && m.property !== key);
        newMeta.push({
          hid: key,
          name: key,
          property: key,
          value: value,
          content: value,
        });
        config = {...config, meta: newMeta};
      }

      this.sending = true;
      await this.$store.dispatch('updateConfigs', { layout: config });

      let customGlobalMeta = [];
      for (let entry of this.$store.state.repo.config.layout.meta || []) {
        if ([
          'og:site_name', 'twitter:site',
          'fb:app_id', 'google-site-verification'
        ].indexOf(entry.hid) == -1) {
          customGlobalMeta.push({
            name: entry.hid,
            label: entry.hid,
          });
        }
      }
      let tab = this.subtabs['tab-meta'].global.find(o => o.name === 'custom');
      tab.subtabs = [...customGlobalMeta, tab.subtabs[tab.subtabs.length - 1] ];
      
      this.sending = false;
    },
    
    async editMeta () {
      if (this.editingGlobal) {
        await this.editGlobalMeta();
        return;
      }
      
      const { key, value } = this.editingMeta;

      const keyForHead = this.$store.state.activePageKey.split('/').pop(); // only use the last path component in head obj
      const head = this.$store.state.repo.config.head[keyForHead] || {};

      let newHead = {
        ...head,
        meta: this.meta,
      };
      
      if (key.endsWith('_tag')) {
        let realKey = key.replace(/_tag$/, '');
        newHead[realKey] = value;
      } else {
        let newMeta = this.meta.filter(meta => (meta.name !== key && meta.property !== key));
        newMeta.push({
          hid: key,
          name: key,
          property: key,
          value: value,
          content: value,
        });
      
        newHead.meta = newMeta;
      }
        
      this.sending = true;
        this.$store.commit('setLoading', true);
      await this.$store.dispatch('updatePageHead', {
        key: this.$store.state.activePageKey,
        head: newHead,
      });
      this.$store.commit('setLoading', false);
      
      this.sending = false;
      
      let { meta, metaObj, customMeta } = this.recomputeMeta();
      this.meta = meta;
      this.metaObj = metaObj;
      let tab = this.subtabs['tab-meta'].page.find(o => o.name === 'custom');
      tab.subtabs = [...customMeta, tab.subtabs[tab.subtabs.length - 1] ];
    },

    async removeSnippet () {
      const { key } = this.editingSnippet;
      
      this.sending = true;
      this.$store.commit('setLoading', true);
      await this.$store.dispatch('removeGlobalSnippet', {
        key,
      });
      this.$store.commit('setLoading', false);
      
      this.sending = false;
      
      let snippets = Object.keys(this.$store.state.repo.snippets);
      let customGlobalSnippets = snippets.filter(name => {
        return ['google-analytics', 'facebook-pixel', 'google-tag-manager'].indexOf(name) === -1;
      }).map(o => ({
        name: o, label: o,
      }));
      let tab = this.subtabs['tab-snippets'].global.find(o => o.name === 'custom');
      tab.subtabs = [...customGlobalSnippets, tab.subtabs[tab.subtabs.length - 1] ];
      
      this.editingSnippet = {};
    },
        
    async editSnippet () {
      if (!this.editingSnippet.key) {
        let idx = Object.keys(this.$store.state.repo.snippets).length + 1;
        while (Object.keys(this.$store.state.repo.snippets).indexOf(`custom-snippet-${idx}`) !== -1) {
          ++idx;
        }
        this.editingSnippet.key = `custom-snippet-${idx}`;
      }
      
      const { key, value } = this.editingSnippet;
      this.sending = true;
      
      this.$store.commit('setLoading', true);
      await this.$store.dispatch('updateGlobalSnippet', {
        key, value
      });
      this.$store.commit('setLoading', false);
      
      this.sending = false;
      
      let snippets = Object.keys(this.$store.state.repo.snippets);
      let customGlobalSnippets = snippets.filter(name => {
        return ['google-analytics', 'facebook-pixel', 'google-tag-manager'].indexOf(name) === -1;
      }).map(o => ({
        name: o, label: o,
      }));
      let tab = this.subtabs['tab-snippets'].global.find(o => o.name === 'custom');
      tab.subtabs = [...customGlobalSnippets, tab.subtabs[tab.subtabs.length - 1] ];
    },
  },
  
  computed: {

    currentPageName () {
      if (this.$store.state.activePageKey === 'index') {
        return 'your homepage';
      }
      return `your "${this.$store.state.activePageKey}" page`;
    },
    
    editingMetaTagPreview () {
      if (!this.editingMeta.key) return;
      if (this.editingMeta.key === 'title_tag' && !this.editingGlobal) {
        return `<title>${this.editingMeta.value || ''}${this.$store.state.repo.config.layout.title ? ` - ${this.$store.state.repo.config.layout.title}` : ''}</title>`;
      }
      if (this.editingMeta.key === 'title_tag') {
        return `<title>${this.editingMeta.value || ''}</title>`;
      }
      if (this.editingMeta.key === 'favicon_tag') {
        return `<link rel="icon" href="${this.editingMeta.value || ''}">`;
      }
      return `<meta name="${this.editingMeta.key}" property="${this.editingMeta.key}" value="${this.editingMeta.value || ''}" content="${this.editingMeta.value || ''}">`;
    },
    
  },
}
</script>

<style scoped>
.code-textarea {
  background: whitesmoke;
  padding: 9px;
  font-family: Courier, monospace;
}

.md-drawer {
  width: auto;
  min-width: 250px;
  font-size: 2rem;
}
.md-table .md-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: #eee;
  margin: 0;
}
.md-table .md-button.primary-button {
  background: purple;
  color: white;
  font-weight: bold;
}

.md-table >>> .md-table-cell-container {
  display: flex;
  align-items: center;
}
.md-table >>> .md-table-head-label {
  color: black;
}
.md-table .go-cell >>> .md-table-cell-container {
  padding: 6px 0;
}

.md-button.go-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: purple;
  color: white;
  margin: 0;
}
.md-table div {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
}
.md-table .meta {
  position: relative;
}
.md-table .meta img {
  max-width: 100%;
  max-height: 100%;
}
.md-table .meta span {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
}
.md-table .meta span:hover {
  background: lightyellow;
}
.editing-meta .md-table-cell-container {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.editing-meta .md-table-cell-container textarea {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.editing-meta-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editing-meta-buttons .btn {
    font-size: 0.9rem;
    padding: 0.1rem .5rem;
    min-width: 0;
}
</style>
