import Vue from 'vue';
import storeObj from './store.js';
import DialogWrapper from './components/DialogWrapper.vue';

import VueRouter from 'vue-router';

export function openDialog (dialogComponent, props, routes) {
  return new Promise((resolve) => {

    let router = routes ? new VueRouter({
      routes,
      mode: 'abstract',
    }) : undefined;
    
    const Wrapper = Vue.extend(DialogWrapper)
    
    const dialog = new Wrapper({
      propsData: {
        dialogComponent,
        props,
      },
      router,
      store: storeObj.store,
    }).$mount()
    document.body.appendChild(dialog.$el);
    dialog.$el.focus();

    dialog.$on('close', function (value) {
      dialog.$destroy();
      dialog.$el.remove();
      resolve(value)
    })
  })
}
