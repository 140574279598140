import complementaryColors from 'complementary-colors'

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgba(hex, alpha, { returnObj } = {}) {

  if (!hex) return hex;
  if (typeof hex.startsWith === 'function' && hex.startsWith('rgb')) {
    hex = parseRgbColor(hex);
    hex = rgbToHex(hex[0], hex[1], hex[2]);
  }

  let orig = hex;
  
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });
  
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
  
  alpha = typeof alpha === 'undefined' ? 1 : alpha;

  if (rgb === null) {
    return orig;
  }

  if (returnObj) {
    return rgb;
  }
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;    
}

function parseRgbColor(rgb) {
  rgb = rgb.replace(/[^\d,]/g, '').split(',').map(o => parseInt(o));
  return rgb;
}

const getContrast = function (hexcolor) {

  if (!hexcolor) return 'black';
  if (hexcolor.startsWith('rgb')) {
    hexcolor = parseRgbColor(hexcolor);
    hexcolor = rgbToHex(hexcolor[0], hexcolor[1], hexcolor[2]);
  }
  
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1);
  }
  
  // Convert to RGB value
  var r = parseInt(hexcolor.substr(0,2),16);
  var g = parseInt(hexcolor.substr(2,2),16);
  var b = parseInt(hexcolor.substr(4,2),16);
  
  // Get YIQ ratio
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  
  // Check contrast
  return (yiq >= 128) ? 'black' : 'white';
  
};

const complement = (color, index = 2, scheme = 'square') => {
  let c = new complementaryColors(color);
  c = c[scheme]()[index];
  return rgbToHex(c.r, c.g, c.b);
};

const getPalette = (style) => {
  const colorKeys = [
    'color', 'background-color', 'background',
    'border-color', 'border-top-color', 'border-right-color',
    'border-left-color', 'border-bottom-color',
  ];

  let filtered = {};
  colorKeys.forEach(key => {
    filtered[key] = style[key];
  });
  
  return filtered;
};

const colorRef = (primaryColor, store) => {
  const propertyNames = ['--theme--color__heavy', '--theme--color__secondary', '--button-color__hover'];
  if (propertyNames.indexOf(primaryColor) !== -1) {
    primaryColor = store.getters.styles[primaryColor];
  }
  let color = getContrast(primaryColor);
  let rgb = null;
  propertyNames.forEach(colorName => {
    let color = store.getters.styles[colorName];
    if (hexToRgba(color) === hexToRgba(primaryColor)) {
      rgb = hexToRgba(primaryColor, 1, { returnObj: true });
      primaryColor = `var(${colorName})`;
    }
  });
  rgb = rgb || {};
  return { primary: primaryColor, contrast: color, rgb };
};

export {
  getContrast,
  parseRgbColor,
  hexToRgba,
  rgbToHex,
  complement,
  getPalette,
  colorRef,
}
