export default {
  generic_short_tiles: {
    label: "Generic Short Tiles",
    args: {},
  },

  join_donate: {
    label: "Join/donate",
    args: {
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },      
      secondaryColor: {
        type: 'color',
        label: 'Contrast Color',
      },
      buttonWidth: {
        type: 'number',
        default: '',
      },
      donateBaseHref: {
        type: 'text',
        default: 'https://secure.actblue.com/donate/example?refcode=website&amount=',
      },
      amounts: {
        type: 'text',
        default: '3,27,50,100,250,500',
      },
      heading: {
        type: 'text',
        default: "Donate",
      },      
      content: {
        type: 'html',
        default: '<p>Can you chip in $3 to support our people-powered campaign?</p>',
      },
    },
  },
  
  platform_one_image: {
    label: "Platform one image",
    args: {
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },      
      image: {
        type: 'image',
        subtype: 'photo',        
        default: '',
      },
      heading: {
        type: 'text',
        default: "My Priorities",
      },      
      content: {
        type: 'html',
        default: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
      },
    },
  },

  platform_columns: {
    label: "Platform columns",
    args: {
      padImages: {
        type: 'text',
      },
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },
      perRow: {
        type: 'number',
        label: 'Entries Per Row',
      },
      noFlexGrow: {
        type: 'number',
        label: 'Keep Sizes Equal',
      },            
      issues: {
        type: 'object_list',
        default: 3,        
        object_label: 'Topic',
        list_entry_view: ({ heading, content, image }) => heading || content || `<img style="max-height:100px" src="${image}">` || null,
        fields: {
          image: {
            type: 'image',
            subtype: 'photo',
            default: '',
          },
          heading: {
            type: 'text',
            default: 'Topic',
          },
          content: {
            type: 'html',
            default: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>',
          },
          primaryColor: {
            type: 'color',
          },
          link: {
            type: 'text',
          },
          linkText: {
            type: 'text',
            default: 'Read More',
          },
        },
      },
    },
  },

  platform_columns_icons: {
    label: "Platform columns - icons",
    args: {
      padImages: {
        type: 'text',
      },
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },
      perRow: {
        type: 'number',
        label: 'Entries Per Row',
      },
      noFlexGrow: {
        type: 'number',
        label: 'Keep Sizes Equal',
      },            
      issues: {
        type: 'object_list',
        default: 3,        
        object_label: 'Topic',
        list_entry_view: ({ heading, content, image }) => heading || content || `<img style="max-height:100px" src="${image}">` || null,
        fields: {
          image: {
            type: 'image',
            subtype: 'icon',
            default: '',
          },
          heading: {
            type: 'text',
            default: 'Topic',
          },
          content: {
            type: 'html',
            default: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>',
          },
          primaryColor: {
            type: 'color',
          },
          link: {
            type: 'text',
          },
          linkText: {
            type: 'text',
            default: 'Read More',
          },
        },
      },
    },
  },

  platform_columns_text: {
    label: "Platform columns - text only",
    args: {
      padImages: {
        type: 'text',
      },
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },
      perRow: {
        type: 'number',
        label: 'Entries Per Row',
      },
      noFlexGrow: {
        type: 'number',
        label: 'Keep Sizes Equal',
      },            
      issues: {
        type: 'object_list',
        default: 3,        
        object_label: 'Topic',
        list_entry_view: ({ heading, content, image }) => heading || content || `<img style="max-height:100px" src="${image}">` || null,
        fields: {
          image: {
            type: 'image',
            subtype: 'photo',
            default: '',
            fill_blank_image: false,        
          },
          heading: {
            type: 'text',
            default: 'Topic',
          },
          content: {
            type: 'html',
            default: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>',
          },
          primaryColor: {
            type: 'color',
          },
          link: {
            type: 'text',
          },
          linkText: {
            type: 'text',
            default: 'Read More',
          },
        },
      },
    },
  },

  endorsements_one: {
    label: "Endorsement - single",
    args: {
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },      
      image: {
        type: 'image',
        subtype: 'endorser-photo',
        default: '',
      },
      heading: {
        type: 'text',
        default: 'Endorser Name and Title',
      },
      content: {
        type: 'html',
        default: '<p>“A short quote from the endorser, if available.”',
      },
    },
  },

  endorsements_text: {
    label: "Endorsement - single",
    args: {
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },      
      image: {
        type: 'image',
        subtype: 'endorser-photo',
        default: '',
        fill_blank_images: false,
      },
      heading: {
        type: 'text',
        default: 'Endorsed By',
      },
      content: {
        type: 'html',
        default: `<ul style="list-style-type:none">
<li>State Senator Name</li>
<li>State Representative Name</li>
<li>Mayor Name</li>
<li>Town Councillor Name</li>
<li>Local Union Chapter</li>
<li>Small Business Owner Name</li>
<li>Respected Local Activist Name</li>
<li>PTA President Name</li>
`,
      },
    },
  },    
      
  endorsements_columns: {
    label: "Endorsements - columns",
    args: {
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },      
      endorsements: {
        type: 'object_list',
        default: 4,
        object_label: "Endorsement",
        list_entry_view: ({ heading, content, image }) => heading || content || `<img style="max-height:100px" src="${image}">` || null,
        fields: {
          image: {
            type: 'image',
            subtype: 'endorser-logo',
            default: '',
          },
          heading: {
            type: 'text',
            default: '',
          },
          content: {
            type: 'html',
            default: '',
          },
        },
      },
      perRow: {
        type: 'number',
        label: 'Entries Per Row',
      },
      noFlexGrow: {
        type: 'number',
        label: 'Keep Sizes Equal',
      },      
    },
  },

  endorsements_columns_people: {
    label: "Endorsements - columns, people",
    args: {
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },      
      endorsements: {
        type: 'object_list',
        default: 4,
        object_label: "Endorsement",
        list_entry_view: ({ heading, content, image }) => heading || content || `<img style="max-height:100px" src="${image}">` || null,
        fields: {
          image: {
            type: 'image',
            subtype: 'endorser-photo',
            default: '',
          },
          heading: {
            type: 'text',
            default: 'Name',
          },
          content: {
            type: 'html',
            default: '<p>Title</p>',
          },
        },
      },
      perRow: {
        type: 'number',
        label: 'Entries Per Row',
      },
      noFlexGrow: {
        type: 'number',
        label: 'Keep Sizes Equal',
      },      
    },
  },

  image_gallery: {
    label: "Image Gallery",
    args: {
      images: {
        type: 'object_list',
        default: 4,
        list_entry_view: ({ heading, content, image }) => heading || content || `<img style="max-height:100px" src="${image}">` || null,     
        fields: {
          image: {
            type: 'image',
            subtype: 'photo',
            default: '',
          },
        },
      },
      perRow: {
        type: 'number',
        label: 'Entries Per Row',
        default: '4',
      },
      noFlexGrow: {
        type: 'number',
        label: 'Keep Sizes Equal',
      },      
      aspectRatio: {
        type: 'text',
        default: '16:9',
      },
      clickBehavior: {
        type: 'select',
        default: '',
        options: [
          {label: "Not clickable", value: ""},
          {label: "Click to download", value: "download"},          
        ],        
      },
    },
  },

  two_columns: {
    label: "Two Content Columns",
    args: {
      heading: {
        type: 'text',
        default: "Lorem ipsum dolor sit amet."
      },
      content: {
        type: 'html',
        default: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
      },
      secondaryContent: {
        type: 'html',
        default: `<signup-form style="margin-left: auto; margin-right: auto" uuid=""></signup-form>`,
      },
      image: {
        type: 'image',
        subtype: 'photo',
      },      
    },
  },

  multi_columns: {
    label: "Multiple Content Columns",
    args: {
      gutterWidth: {
        type: 'number',
        default: '15',
      },
      paddingX: {
        type: 'number',
        default: '1',
      },
      paddingY: {
        type: 'number',
        default: '1',
      },
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },       
      columns: {
        type: 'object_list',
        default: 3,
        fields: {
          content: {
            type: 'html',
            default: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
          },
          backgroundColor: {
            type: 'color',
            default: 'rgba(255, 255, 255, 0)',
          },
          width: {
            type: 'number',
            default: '50',
          },
        },
      },
    },
  },
    
  hero: {
    grouping: "hero",
    label: "Hero Unit",
    disallowed_combinations: [
      {min_height: "100vh", shape: "rounded"},
      {capped_width: "", elevation: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'},
      {capped_width: "", elevation: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'},
      {capped_width: "", elevation: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)'},
      {capped_width: "", elevation: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)'},
      {capped_width: "", elevation: 'black 5px 5px 5px 0px'},
      {min_height: "100vh", capped_width: "1200"},
      {min_height: "100vh", capped_width: "1400"},      
    ],
    args: {
      image: {
        type: 'image',
        subtype: 'photo',
        default: '',
      },
      secondary_image: {
        type: 'image',
        subtype: 'logo',
        default: '',
        fill_blank_image: false,
      },
      secondaryImageMaxWidth: {
        type: 'text',
      },
      secondaryImageMarginBottom: {
        type: 'number',
      },
      foreground_image: {
        type: 'image',
        subtype: 'photo',
        default: '',
        fill_blank_image: false,        
      },
      media_side: {
        type: 'select',
        default: 'left',
        options: [
          {label: "Image on left", value: "left"},
          {label: "Image on right", value: "right"},          
        ],
      },
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },
      gradientDepth: {
        type: 'number',
        default: '0.4',
      },
      aspectRatio: {
        type: 'text',
        default: '24:9',
      },      
      elevation: {
        type: 'select',
        default: '',
        options: [
          {label: "None", value: ''},
          {label: "Shallow", value: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'},
          {label: "Medium", value: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'},
          {label: "Raised", value: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)'},
          {label: "Extra raised", value: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)'},
          {label: 'From bottom right', value: 'black 5px 5px 5px 0px'},
        ],
      },
      capped_width: {
        type: 'select',
        default: '',
        options: [
          {label: "Full width", value: ""},
          {label: "Capped width - wide", value: "1600"},
          {label: "Capped width - medium", value: "1400"},
          {label: "Capped width - narrow", value: "1200"},                    
        ],
      },
      min_height: {
        type: 'select',
        default: '',
        options: [
          {label: "Auto", value: ""},
          {label: "Tall", value: "75vh"},
          {label: "Full screen", value: "100vh"},
        ],
      },
      media_width: {
        type: 'select',
        default: '',
        options: [
          {label: "55", value: "55"},
          {label: "35", value: "35"},
          {label: "75", value: "75"},
        ],
      },
      shape: {
        type: 'select',
        options: [
          {label: 'Diagonal', value: 'diagonal'},
          {label: 'Arrow', value: 'arrow'},
          {label: 'Striped', value: 'striped'},
          {label: 'Rounded', value: 'rounded'},
          {label: 'No shape', value: 'none'},
        ],
      },
      heading: {
        type: 'text',
        default: "Lorem ipsum dolor sit amet."
      },
      content: {
        type: 'html',
        default: `<signup-form uuid=""></signup-form>`,
      },
    },
  },

  hero_background_image: {
    grouping: "hero_background_image",
    label: "Hero Unit",
    disallowed_combinations: [
      {min_height: "100vh", shape: "rounded"},
      {capped_width: "", elevation: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'},
      {capped_width: "", elevation: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'},
      {capped_width: "", elevation: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)'},
      {capped_width: "", elevation: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)'},
      {capped_width: "", elevation: 'black 5px 5px 5px 0px'},
      {min_height: "100vh", capped_width: "1200"},
      {min_height: "100vh", capped_width: "1400"},      
    ],
    args: {
      image: {
        type: 'image',
        subtype: 'photo',
        default: '',
      },
      secondary_image: {
        type: 'image',
        subtype: 'logo',
        default: '',
      },
      secondaryImageMaxWidth: {
        type: 'text',
      },
      secondaryImageMarginBottom: {
        type: 'number',
      },
      foreground_image: {
        type: 'image',
        subtype: 'photo',
        default: '',
        fill_blank_image: false,        
      },
      media_side: {
        type: 'select',
        default: 'left',
        options: [
          {label: "Image on left", value: "left"},
          {label: "Image on right", value: "right"},          
        ],
      },
      background_image: {
        type: 'image',
        subtype: 'background',
        default: '',
      },
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },
      gradientDepth: {
        type: 'number',
        default: '0.4',
      },
      aspectRatio: {
        type: 'text',
        default: '24:9',
      },      
      elevation: {
        type: 'select',
        default: '',
        options: [
          {label: "None", value: ''},
          {label: "Shallow", value: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'},
          {label: "Medium", value: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'},
          {label: "Raised", value: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)'},
          {label: "Extra raised", value: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)'},
          {label: 'From bottom right', value: 'black 5px 5px 5px 0px'},
        ],
      },
      capped_width: {
        type: 'select',
        default: '',
        options: [
          {label: "Full width", value: ""},
          {label: "Capped width - wide", value: "1600"},
          {label: "Capped width - medium", value: "1400"},
          {label: "Capped width - narrow", value: "1200"},                    
        ],
      },
      min_height: {
        type: 'select',
        default: '',
        options: [
          {label: "Auto", value: ""},
          {label: "Tall", value: "75vh"},
          {label: "Full screen", value: "100vh"},
        ],
      },
      media_width: {
        type: 'select',
        default: '',
        options: [
          {label: "55", value: "55"},
          {label: "35", value: "35"},
          {label: "75", value: "75"},
        ],
      },
      shape: {
        type: 'select',
        options: [
          {label: 'Diagonal', value: 'diagonal'},
          {label: 'Arrow', value: 'arrow'},
          {label: 'Striped', value: 'striped'},
          {label: 'Rounded', value: 'rounded'},
          {label: 'No shape', value: 'none'},
        ],
      },
      heading: {
        type: 'text',
        default: "Why I'm Running",
      },
      content: {
        type: 'html',
        default: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p-buttons :links="[{ 'href': 'https://example.com', 'text': 'Join' }, { 'href': 'https://example.com', 'text': 'Donate' }]"></p-buttons>`,
      },
    },
  },
  
  header: {
    grouping: "header",
    label: "Header",
    args: {
      image: {
        type: 'image',
        subtype: 'photo',
        default: '',
      },
      heading: {
        type: 'text',
        default: "My Page or Section Title"
      },
      content: {
        type: 'html',
        default: '',
      },
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },      
      imageSize: {
        type: 'select',
        default: 'cover',
        label: 'Background image fit',
        options: [
          {label: "Cover", value: "cover"},
          {label: "Contain", value: "contain"},          
        ],
      },      
      imagePosition: {
        type: 'select',
        default: 'center',
        label: 'Background image position',
        options: [
          {label: "Center", value: "center"},
          {label: "Left", value: "left"},
          {label: "Right", value: "right"},
          {label: "Top", value: "top"},
          {label: "Bottom", value: "bottom"},
        ],
      },      
      contentHeight: {
        type: 'select',
        default: '10',
        label: 'Dimensions',
        options: [
          {label: "Wide", value: "3"},
          {label: "Medium", value: "6"},
          {label: "Tall", value: "10"},
          {label: "Extra Tall", value: "20"},
        ],
      },
      contentMaxWidth: {
        type: 'select',
        default: '',
        label: 'Content width',
        options: [
          {label: "Full width", value: ""},
          {label: "Narrow", value: "800px"},
        ],
      },
    },
  },
  long_text: {
    label: "Long Text Block",
    grouping: 'long_text',
    args: {
      image: {
        type: 'image',
        subtype: 'photo',
      },
      heading: {
        type: 'text',
        default: "Let's do this together."
      },
      content: {
        type: 'html',
        default: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`
      },
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },            
    },
  },
  why_running: {
    label: "Short Text Block (Why I'm Running, etc)",
    args: {
      image: {
        type: 'image',
        subtype: 'photo',
      },
      heading: {
        type: 'text',
        default: "Short Text Blurb",
      },
      content: {
        type: 'html',
        default: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>',
      },
      primaryColor: {
        type: 'color',
        label: 'Background Color',
      },
    },
  },
};
