import $ from 'cash-dom';
import storeObj from '../store.js';
import { writeStyleString } from '../css-utils.js';

const writeStyles = o => writeStyleString(o, false, true);

const imageKey = src => {
  console.log(src);
  return src ? src.split('/').slice(-2).join(':') : null;
}

export const imageMetadata = src => {

  if (!src) return {};
  let info = {... (storeObj.store.state.repo.images[imageKey(src)] || {}) };

  const components = src.split('/').map(o => o.split(',')),
        dimensions = components.filter(o => o[0] === '$v_1')[0];

  const aspectRatio = info.height / info.width;

  if (dimensions) {
    let adjustedWidth = dimensions.map(o => o.match(/^w_(?<width>\d+)/)?.groups?.width).filter(o => o)[0],
        adjustedHeight = dimensions.map(o => o.match(/^h_(?<height>\d+)/)?.groups?.height).filter(o => o)[0];
    
    if (adjustedWidth && adjustedHeight) {
      info.width = adjustedWidth;
      info.height = adjustedHeight;
    } else if (adjustedWidth) {
      info.height = aspectRatio * adjustedWidth;
      info.width = adjustedWidth;
    } else if (adjustedHeight) {
      info.width = (1 / aspectRatio) * adjustedHeight;
      info.height = adjustedHeight;
    }
  }

  if (typeof info.height === 'undefined') {
    info.height = 0;
  }
  if (typeof info.width === 'undefined') {
    info.width = 0;
  }
  return info;
}

const $contentRoot = dom => {
  let content = [...dom.find('template')].find(o => o.attributes['v-slot:default']);
  if (content) {
    return $("<div>").html(content.innerHTML);
  }
  return dom;
};

export const extractStandardArgs = html => {
  let dom = $(html),
      root = $contentRoot(dom);

  const heading = root.find('h1, h2, h3, h4, h5, h6, h7').first().html();
  root.find('h1, h2, h3, h4, h5, h6, h7').first().remove();

  const image = extractHeroImage(dom, root);

  return {
    heading,
    image,
    content: root.html(),
  };
};

const extractHeroImage = (dom, contentRoot) => {
  let image = dom.attr('image');
  if (image) {
    contentRoot.find(`img[src="${image}"]`).remove();
    return image;
  }

  let backgrounds = [...dom.find('template')].find(o => o.attributes['v-slot:backgrounds']);
  if (backgrounds) {
    backgrounds = $("<div>").html(backgrounds.innerHTML);
    return backgrounds.find("background[image]").attr('image');
  }

  image = contentRoot.find("img").first().attr('src');
  if (image) {
    contentRoot.find("img").first().remove();
    return image;
  }

  return null;
};

export class Block {
  constructor (key, label, type) {
    this.key = key;
    this.label = label;
    this.type = type;
  }

  extractArgs (html) {
    return extractStandardArgs(html);
  }

  inspectImage (image) {
    const metadata = imageMetadata(image);

    const colors = metadata.colors || [['#8B0000'], ['#66023C']],
          primaryColor = colors.map(o => o[0]).filter(o => o !== '#FFFFFF' && o !== '#000000')[0];

    return {
      metadata,
      primaryColor,
    }
  }

  render () {
    return '<div></div>';
  }
}

export class TileRow extends Block {
  constructor (key, label, type, { styles, containerStyles, tileStyles, tileTitleStyles, justify, order }) {
    super(key, label, type);
    this.styles = styles;
    this.containerStyles = containerStyles;
    this.tileStyles = tileStyles;
    this.tileTitleStyles = tileTitleStyles;
    this.justify = justify;
    this.order = order;
  }
  
  render () {
    let args = {};
    let styles = writeStyles(this.styles ? this.styles(args) : {}),
        containerStyles = writeStyles(this.containerStyles ? this.containerStyles(args) : {}),
        tileStyles = writeStyles(this.tileStyles ? this.tileStyles(args) : {}),
        tileTitleStyles = writeStyles(this.tileTitleStyles ? this.tileTitleStyles(args) : {});
    
    return `<tile-row uuid="" style="${styles}" justify="${this.justify(args)}" order="${this.order(args)}" container-styles="${containerStyles}">

        <tile uuid="" title="Our Environment" href="/issues" style="${tileStyles}" title-styles="${tileTitleStyles}"
              image="https://res.cloudinary.com/pies/image/upload/test-widget-upload/nj8oebjei48dyavyoh6s.png">
          <p>Our environmental commons, watersheds, and ecosystems are all connected and directly impacted by the impacts of climate change.</p>
        </tile>

        <tile uuid="" title="Quality Schools" href="/issues" style="${tileStyles}" title-styles="${tileTitleStyles}"
              image="https://res.cloudinary.com/pies/image/upload/test-widget-upload/pdr1favpdneoxetaxoq1">
          <p>
            Kids do best when educators have the resources that they need, and when the educators and students are leading discussions about how education happens.
          </p>
        </tile>

        <tile uuid="" title="Fair Wages" href="/issues" style="${tileStyles}" title-styles="${tileTitleStyles}"
              image="https://res.cloudinary.com/pies/image/upload/test-widget-upload/zg3eaujmaalhtxoymbm2">
          <p>Raising the minimum wage won’t just increase the money in someone’s pocket, it will rebuild America’s middle class.</p>
        </tile>
        <tile uuid="" title="Our Environment" href="/issues" style="${tileStyles}" title-styles="${tileTitleStyles}"
              image="https://res.cloudinary.com/pies/image/upload/test-widget-upload/nj8oebjei48dyavyoh6s.png">
          <p>Our environmental commons, watersheds, and ecosystems are all connected and directly impacted by the impacts of climate change.</p>
        </tile>

        <tile uuid="" title="Quality Schools" href="/issues" style="${tileStyles}" title-styles="${tileTitleStyles}"
              image="https://res.cloudinary.com/pies/image/upload/test-widget-upload/pdr1favpdneoxetaxoq1">
          <p>
            Kids do best when educators have the resources that they need, and when the educators and students are leading discussions about how education happens.
          </p>
        </tile>

        <tile uuid="" title="Fair Wages" href="/issues" style="${tileStyles}" title-styles="${tileTitleStyles}"
              image="https://res.cloudinary.com/pies/image/upload/test-widget-upload/zg3eaujmaalhtxoymbm2">
          <p>Raising the minimum wage won’t just increase the money in someone’s pocket, it will rebuild America’s middle class.</p>
        </tile>
    </tile-row>
    `;
  }
}

export class MediaUnit extends Block {
  constructor (key, label, type, { styles, contentStyles, mediaStyles, template, contentClass, headingStyles }) {
    super(key, label, type);
    this.styles = styles;
    this.contentStyles = contentStyles;
    this.mediaStyles = mediaStyles;
    this.template = template;
    this.contentClass = contentClass;
    this.headingStyles = headingStyles;
  }
  
  render ({ image, heading, content, primaryColor, ...extra }) {

    const { metadata, realPrimaryColor } = this.inspectImage(image),
          sitePrimaryColor = storeObj.store.getters.styles['--theme--color__heavy'];

    let args = {
      image, heading, content, metadata,
      primaryColor: primaryColor || realPrimaryColor || sitePrimaryColor || '#ffffff',
    },
        styles = this.styles ? this.styles({...args, ...extra}) : {},
        mediaStyles = this.mediaStyles ? this.mediaStyles({...args, ...extra}) : {},
        contentStyles = this.contentStyles ? this.contentStyles({...args, ...extra}) : {},
        headingStyles = this.headingStyles ? this.headingStyles({...args, ...extra}) : {};

    if (extra.styles) {
      styles = {...styles, ...extra.styles};
      delete extra.styles;
    }
    if (extra.mediaStyles) {
      mediaStyles = {...mediaStyles, ...extra.mediaStyles};
      delete extra.mediaStyles;
    }
    if (extra.contentStyles) {
      contentStyles = {...contentStyles, ...extra.contentStyles};
      delete extra.contentStyles;
    }
    if (extra.headingStyles) {
      headingStyles = {...headingStyles, ...extra.headingStyles};
      delete extra.headingStyles;
    }
    let contentClass = extra.contentClass || this.contentClass;
    
    styles = writeStyles(styles);
    mediaStyles = writeStyles(mediaStyles);
    contentStyles = writeStyles(contentStyles);
    headingStyles = writeStyles(headingStyles);
    
    primaryColor = args.primaryColor;
    args.styles = styles;
    args.mediaStyles = mediaStyles;
    args.contentStyles = contentStyles;
    args.headingStyles = headingStyles;

    return (this.template ?
            this.template({...args, ...extra}) : `
<media-unit uuid="" ${image ? 'image="' + image + '"' : ''}
    content-class="${contentClass}"
    styles="${styles}" content-styles="${contentStyles}" media-styles="${mediaStyles}">
  <template v-slot:default>
    ${heading ? `<h1 style="${headingStyles}">${heading}</h1>` : ''}
    ${content}
  </template>
</media-unit>`);
  }
}

