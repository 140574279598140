<template>
<dialog-layout ref="layout" class="media-unit-editor"
               :previewable="true"
               @unmask="$emit('unmask')"
               @remask="$emit('remask')"
               :key="uuid" :width=".96" :height=".9"
               >
  
  <dialog-close-button @close="maybeClose" />
  
  <md-app>
    <md-app-toolbar
      :data-md-toolbar-collapsed="!dirty">

      <div class="md-toolbar-row" v-if="dirty">
        <div class="md-toolbar-section-start">
          <md-button class="md-accent" @click="discardChanges">
            Revert Changes
          </md-button>
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-primary" @click="saveComponent">
            {{ saving ? '&hellip;' : 'Save Changes' }}
          </md-button>
        </div>
      </div>
      
    </md-app-toolbar>
    
    <md-app-drawer v-show="subtabs[activeTab]" md-permanent="clipped">
      
      <md-list>
        <template v-for="tab in subtabs[activeTab]">
          <md-list-item v-if="tab.expands" md-expand :key="tab.name"> 
            <md-icon><ri :n="tab.icon" /></md-icon>
            <span class="md-list-item-text">{{ tab.label }}</span>
            
            <md-list slot="md-expand">
              <md-list-item v-for="subtab, index in tab.expands" class="md-inset" :key="index"
                            @click="activeSubtab = `${tab.name}-${subtab.name}`">
                <md-icon><ri :n="subtab.icon" /></md-icon>
                <span class="md-list-item-text">{{ subtab.label }}</span>
              </md-list-item>
            </md-list>
          </md-list-item>
          <template v-if="!tab.expands">
            <md-list-item v-if="tab.name !== 'wrapperStyles' || contentIsWrapped"
                          :key="tab.name" @click="activeSubtab = tab.name">
              <md-icon><ri :n="tab.icon" /></md-icon>
              <span class="md-list-item-text">{{ tab.label }}</span>
            </md-list-item>
          </template>
        </template>
      </md-list>
    </md-app-drawer>
    
    <md-app-content v-if="activeTab === 'tab-typography'"
                    :style="`--preview-background: ${styles['background-color'] || styles['background'] || '#eee'}`"
                    >
      <div v-if="activeSubtab === 'text'" key="content-typography-text'">
        <div>
          <div>
            <label>
              Text font:
              <font-family-picker v-model="textFont" />
            </label>
          </div>
          
          <div>
            <css-value-input v-model="styles['--theme--font__size']"
                             :inherited="$store.getters.styles['--theme--font__size']"
                             :no-unit="true" default-unit="rem">
              Text size
            </css-value-input>
          </div>
          
          <div>
            <css-value-input v-model="styles['--theme--font__line-height']"
                             :inherited="$store.getters.styles['--theme--font__line-height']"
                             :no-unit="true" default-unit="">
              Line height
            </css-value-input>
          </div>
          
          <div>
            <css-value-input v-model="styles['--theme--font__letter-spacing']"
                             :inherited="$store.getters.styles['--theme--font__letter-spacing']"
                             :no-unit="true" default-unit="em">
              Letter spacing
            </css-value-input>
          </div>
        </div>
      </div>
      <div v-if="activeSubtab === 'headings'" key="content-typography-headings'">
        <div>
          
          <div>
            <label>
              Heading font:
              <font-family-picker v-model="accentFont" />
            </label>
          </div>
          <div>
            <css-value-input v-model="styles['--theme--font__size__heading']"
                             :no-unit="true" default-unit="rem"
                             :inherited="[$store.getters.styles['--theme--font__size__heading'], styles['----theme--font__size'], $store.getters.styles['--theme--font__size'] ]">
              Base text size
            </css-value-input>
          </div>
          <div>
            <css-value-input v-model="styles['--theme--font__line-height__heading']"
                             :no-unit="true" default-unit=""
                             :inherited="[ $store.getters.styles['--theme--font__line-height__heading'], styles['--theme--font__line-height'], $store.getters.styles['--theme--font__line-height'] ]">
              Line height
            </css-value-input>
          </div>
          <div>
            <css-value-input v-model="styles['--theme--font__letter-spacing__heading']"
                             :no-unit="true" default-unit="em"
                             :inherited="[ $store.getters.styles['--theme--font__letter-spacing__heading'], styles['--theme--font__letter-spacing'], $store.getters.styles['--theme--font__letter-spacing'] ]">
              Letter spacing
            </css-value-input>
          </div>
        </div>
      </div>
      <div class="row" v-if="activeSubtab === 'links'" key="content-typography-links'">
        <div class="col-12 col-lg-7">
          <label>
            Link color:
            <color-picker
              key="--link-color"
              v-model="styles['--link-color']"
              :inherited="$store.getters.styles['--link-color']"
              fallback="#000" />
          </label>
          
          <label>
            Hover color:
            <color-picker
              key="--link-color__hover"
              v-model="styles['--link-color__hover']"
              :inherited="$store.getters.styles['--link-color__hover']"
              fallback="#ccc" />
          </label>
          
          <div class="md-horizontal-radio-field">
            <label>
              Underline hovered-over links?
            </label>
            <md-field class="md-has-value md-borderless">
              <md-radio v-model="styles['--link-text-decoration']" value="underline">
                Yes, underline
              </md-radio>
              <md-radio v-model="styles['--link-text-decoration']" value="none">
                No, do not underline
              </md-radio>
            </md-field>
          </div>
          <div class="md-horizontal-radio-field">
            <label>
              Underline hovered-over links?
            </label>
            <md-field class="md-has-value md-borderless">
              <md-radio v-model="styles['--link-text-decoration__hover']" value="underline">
                Yes, underline
              </md-radio>
              <md-radio v-model="styles['--link-text-decoration__hover']" value="none">
                No, do not underline
              </md-radio>
            </md-field>
          </div>
        </div>
        <div class="col">
          <iframe :key="writeStyles(styles, { withFonts: true })"
                  style="width: 100%; min-height: 200px; border: none; margin: 0 auto; visibility: hidden"
                  class="p-5" @load="$event.target.style['visibility'] = 'visible'"
                  :srcdoc="iframeWrapper(typographySamples(activeSubtab))"></iframe>
        </div>
      </div>
      
      <div class="row" v-if="activeSubtab === 'buttons'" key="content-typography-buttons'">
        <div class="col-12 col-lg-7">
          <div>
            <label>
              Button colors
              <div>
                <color-picker
                  expand-to="bottom-left"
                  key="button-color" v-model="styles['--button-color']"
                  :fallback="$store.getters.styles['--button-color']" />
                &nbsp;
                <color-picker
                  expand-to="bottom-left"
                  key="button-text-color" v-model="styles['--button-text-color']"
                  :fallback="$store.getters.styles['--button-text-color']" />
                &nbsp;
                <color-picker
                  expand-to="bottom-left"
                  key="button-border-color" v-model="styles['--button-border-color']"
                  :fallback="$store.getters.styles['--button-border-color']" />
              </div>
            </label>
          </div>
          <div>
            <label>
              Hovered-over button colors
              <div>
                <color-picker
                  expand-to="bottom-left"
                  key="button-color__hover" v-model="styles['--button-color__hover']"
                  :fallback="$store.getters.styles['--button-color__hover']" />
                &nbsp;
                <color-picker
                  expand-to="bottom-left"
                  key="--button-text-color__hover" v-model="styles['--button-text-color__hover']"
                  :fallback="$store.getters.styles['--button-text-color__hover']" />
                &nbsp;
                <color-picker
                  expand-to="bottom-left"
                  key="button-border-color" v-model="styles['--button-border-color__hover']"
                  :fallback="$store.getters.styles['--button-border-color__hover']" />
              </div>
            </label>
          </div>          
          <div>
            <label>
              Button font:
              <font-family-picker v-model="buttonFont" />
            </label>
          </div>
          <div>
            <css-value-input v-model="styles['--theme--font__size__button']"
                             :no-unit="true" default-unit="rem"
                             :inherited="[ $store.getters.styles['--theme--font__size__button'], styles['theme--font__size'], $store.getters.styles['--theme--font__size'] ]">
              Button base text size
            </css-value-input>
          </div>
          <div>
            <css-value-input v-model="styles['--theme--font__line-height__button']"
                             :no-unit="true" default-unit=""                             
                             :inherited="[ $store.getters.styles['--theme--font__line-height__button'], styles['theme--font__line-height'], $store.getters.styles['--theme--font__line-height'] ]">
              Button line height
            </css-value-input>
          </div>
          <div>
            <css-value-input v-model="styles['--theme--font__letter-spacing__button']"
                             :no-unit="true" default-unit="em"
                             :inherited="[ $store.getters.styles['--theme--font__letter-spacing__button'], styles['--theme--font__letter-spacing'], $store.getters.styles['--theme--font__letter-spacing'] ]">
              Button letter spacing
            </css-value-input>
          </div>
          
          <div>
            <css-value-input v-model="styles['--button-border-width']"
                             :no-unit="true" default-unit="px"
                             :inherited="[ $store.getters.styles['--button-border-width'], '3px' ]">
              Border size
            </css-value-input>
          </div>
          
          <div>
            <css-value-input :no-unit="true" v-model="styles['--button-radius']" default-unit="px"
                             :inherited="$store.getters.styles['--button-radius']">
              Button radius
            </css-value-input>
          </div>
          
          <div class="md-horizontal-radio-field">
            <label>
              Button shadow:
            </label>
            <md-field class="md-has-value md-borderless">
              <md-radio v-model="styles['--button-box-shadow']"
                        value="none">None</md-radio>
              <md-radio v-model="styles['--button-box-shadow']"
                        value="0 .125rem .25rem rgba(0,0,0,.075)">Small</md-radio>
              <md-radio v-model="styles['--button-box-shadow']"
                        value="0 0.5rem 1rem rgb(0 0 0 / 15%)">Medium</md-radio>
              <md-radio v-model="styles['--button-box-shadow']"
                        value="0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)">Large</md-radio>
              <md-radio v-model="styles['--button-box-shadow']"
                        value="black 5px 5px 5px 0px">Right</md-radio>
            </md-field>
          </div>
          
        </div>
        <div class="col">
          <iframe :key="writeStyles(styles, { withFonts: true })"
                  style="width: 100%; min-height: 300px; border: none; margin: 0 auto; visibility: hidden"
                  class="p-5" @load="$event.target.style['visibility'] = 'visible'"
                  :srcdoc="iframeWrapper(typographySamples(activeSubtab))"></iframe>
        </div>
        
      </div>

      <div class="row" v-if="activeSubtab === 'form-fields'" key="content-typography-form-fields'">
        <div class="col-12 col-lg-7">
          <div>
            <label>
              Field colors
              <div>
                <color-picker
                  expand-to="bottom-left"
                  key="form-field-color" v-model="styles['--form-field-color']"
                  :fallback="$store.getters.styles['--form-field-color']" />
                &nbsp;
                <color-picker
                  expand-to="bottom-left"
                  key="form-field-text-color" v-model="styles['--form-field-text-color']"
                  :fallback="$store.getters.styles['--form-field-text-color']" />
                &nbsp;
                <color-picker
                  expand-to="bottom-left"
                  key="form-field-border-color" v-model="styles['--form-field-border-color']"
                  :fallback="$store.getters.styles['--form-field-border-color']" />
              </div>
            </label>
          </div>
          <div>
            <css-value-input v-model="styles['--form-field-border-width']"
                             :no-unit="true" default-unit="px"
                             :inherited="[ $store.getters.styles['--form-field-border-width'], '3px' ]">
              Border size
            </css-value-input>
          </div>
          
          <div>
            <css-value-input :no-unit="true" v-model="styles['--form-field-radius']" default-unit="px"
                             :inherited="$store.getters.styles['--form-field-radius']">
              Form-Field radius
            </css-value-input>
          </div>
          
          <div class="md-horizontal-radio-field">
            <label>
              Form-Field shadow:
            </label>
            <md-field class="md-has-value md-borderless">
              <md-radio v-model="styles['--form-field-box-shadow']"
                        value="none">None</md-radio>
              <md-radio v-model="styles['--form-field-box-shadow']"
                        value="0 .125rem .25rem rgba(0,0,0,.075)">Small</md-radio>
              <md-radio v-model="styles['--form-field-box-shadow']"
                        value="0 0.5rem 1rem rgb(0 0 0 / 15%)">Medium</md-radio>
              <md-radio v-model="styles['--form-field-box-shadow']"
                        value="0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)">Large</md-radio>
              <md-radio v-model="styles['--form-field-box-shadow']"
                        value="black 5px 5px 5px 0px">Right</md-radio>
            </md-field>
          </div>
          
        </div>
        <div class="col">
          <iframe :key="writeStyles(styles, { withFonts: true })"
                  style="width: 100%; min-height: 300px; border: none; margin: 0 auto; visibility: hidden"
                  class="p-5" @load="$event.target.style['visibility'] = 'visible'"
                  :srcdoc="iframeWrapper(typographySamples(activeSubtab))"></iframe>
        </div>
        
      </div>
      <iframe :key="writeStyles(styles, { withFonts: true })"
              style="width: 100%; min-height: 800px; border: none; margin: 0 auto; visibility: hidden"
              class="p-5" @load="$event.target.style['visibility'] = 'visible'"
              :srcdoc="iframeSrc"></iframe>
      
    </md-app-content>
      
  </md-app>
  
</dialog-layout>
</template>

<script>
import { writeStyleString } from '../../css-utils.js';

import CssValueInput from '../inputs/CssValueInput.vue';
import ColorPicker from '../ColorPicker.vue';
import FontFamilyPicker from '../FontFamilyPicker.vue';
import Vue from 'vue';

import $ from 'cash-dom';

const outerHtml = $dom => [...$dom].map(o => o.outerHTML || '').join('\n').trim();
const writeStyles = styles => writeStyleString(styles, true, true);

const parseFont = (family) => {
  if (!family) {
    return family;
  }
  return family.replace(/"/g, '');
};

export default {
  name: 'MediaUnitEditor',
  
  props: ['initialTab', 'initialSubtab'],
  
  components: {
    CssValueInput,
    ColorPicker,
    FontFamilyPicker,
  },
  
  methods: {
    
    async maybeClose () {
      if (this.dirty) {
        const result = await this.$swal.fire({
          title: "You have unsaved changes.",
          text: "Before you go on, do you want to save your changes, revert them, or cancel & stay on this page?",
          icon: 'warning',
          showConfirmButton: true,
          showDenyButton: true,
          showCancelButton: true,
          buttonsStyling: false,
          reverseButtons: true,
          confirmButtonText: 'Save',
          denyButtonText: 'Revert',
          cancelButtonText: 'Stay',
          customClass: {
            confirmButton: 'md-button md-primary md-theme-default',
            denyButton: 'md-button md-accent md-theme-default',
            cancelButton: 'md-button md-theme-default',
          },
        });
        if (result.isDismissed) {
          return;
        }
        if (result.isDenied) {
          await this.discardChanges();
        }
        if (result.isConfirmed) {
          await this.saveComponent();
        }
      }
      this.$emit('close');
    },

    writeStyles (styles, opts) {
      if (opts?.withFonts) {
        styles = {...styles};
        
        const globalAccentFont = parseFont(this.$store.getters.styles['--theme--font__heading']) || '',
              globalTextFont = parseFont(this.$store.getters.styles['--theme--font__text']) || '',
              globalButtonFont = parseFont(this.$store.getters.styles['--theme--font__button']) || '';
        if (globalAccentFont !== (this.accentFont || '')) {
          styles['--theme--font__heading'] = this.accentFont ? `"${this.accentFont}"` : '';
        }
        if (globalButtonFont !== (this.buttonFont || '')) {
          styles['--theme--font__button'] = this.buttonFont ? `"${this.buttonFont}"` : '';
        }
        if (globalTextFont !== (this.textFont || '')) {
          styles['--theme--font__text'] = this.textFont ? `"${this.textFont}"` : '';
        }
      }
      return writeStyles(styles);
    },
    
    typographySamples (type) {
      let content = '';
      if (type === 'buttons') {
        content = `<div class='wp-block-buttons' style="--button-width: 400px"><div class='wp-block-button'><a href="#" class='wp-block-button__link'>An example button</a></div></div>`;
      }
      if (type === 'form-fields') {
        content = `<div class="form-wrapper" uuid="0" style="margin-right: auto; margin-left: auto;"><form method="POST"><div class="row mb-3"><div class="col"><div class="form-floating"><input id="0--email" type="email" placeholder="email@domain.com" class="form-control"> <label for="0--email">An example form field</label></div></div></div></form></div>`;
      }
      if (type === 'links') {
        content = `<div><a href='#'>An example hyperlink</a></div>`;
      }
      if (type === 'text') {
        content = `<p style="text-align:left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p style="text-align:left">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>`;
      }
      if (type === 'headings') {
        content = `<div><h1>Heading 1</h1><h2>Heading 2</h2><h3>Heading 3</h3><h4>Heading 4</h4><h5>Heading 5</h5><h6>Heading 6</h6></div>`;
      }
      return `<div class='media-unit' style='${this.writeStyles(this.styles, { withFonts: true })};padding:0 3rem 5rem 3rem'><div><div class="media-unit--content-wrapper">${content}</div></div></div>`;
    },
    
    iframeWrapper (content) {
      let allFonts = [
        this.accentFont,
        this.buttonFont,
        this.textFont,
      ].filter(o => o)
          .map(o => ({ family: o }));
      allFonts = [...allFonts, ...this.$store.state.repo.config.fonts];
      const fonts = allFonts
            .filter(o => o && o.family)
            .map(({ family }) => `https://fonts.googleapis.com/css2?family=${family}:wght@400;700&display=swap`)
            .map(href => `<link rel='stylesheet' type='text/css' href="${href}" />`)
            .join('\n');
      
      return `
<html><head>
  ${fonts}
  <my-script type="text/javascript">
    window.onload = function() {    
      if (parent) {        
        var oHead = document.getElementsByTagName("head")[0];        
        var arrStyleSheets = parent.document.getElementsByTagName("style");        
        for (var i = 0; i < arrStyleSheets.length; i++)            
          oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
        var arrStyleSheets = parent.document.getElementsByTagName("link");        
        for (var i = 0; i < arrStyleSheets.length; i++) {           
          if (arrStyleSheets[i].rel !== 'stylesheet' && arrStyleSheets[i].type !== 'text/css') continue;
          if (arrStyleSheets[i].href.endsWith('.css')) {
            oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
          }
        }
      }
    }
  </my-script>
</head>
<body>
  <div class="md-theme-default" style='${this.$store.state.repo.config.layout.style}'>
${content}
</div>
</body></html>`.replace(/my-script/g, "script");
      
    },
    
    maskHovered () {
      this.$refs.layout.maskHovered();
    },
    
    mount () {
      this.mounted = false;

      this.blockAdvancedMode = this.component.state.props['data-block-advanced-mode'] || false;

      this.accentFont = parseFont(this.component.getters.styles['--theme--font__heading']) || this.accentFont;
      this.textFont = parseFont(this.component.getters.styles['--theme--font__text']) || this.textFont;
      this.buttonFont = parseFont(this.component.getters.styles['--theme--font__button']) || this.buttonFont;
      
      this.styles = {...this.component.getters.styles};
      this.accentFont = parseFont(this.component.getters.styles['--theme--font__heading']) || this.accentFont;
      this.textFont = parseFont(this.component.getters.styles['--theme--font__text']) || this.textFont;
      this.buttonFont = parseFont(this.component.getters.styles['--theme--font__button']) || this.buttonFont;
      
      this.$nextTick(() => {
        this.mounted = true;
        this.compiledPreview = outerHtml($(`[id="${this.uuid}"]`));
      });
    },
    
    discardChanges () {
      if (!this.uuid) return;
      this.mount();
      
      let pageHtml = this.$store.getters.activePage.html,
          dom = $(pageHtml),
          component = dom.find(`[uuid="${this.uuid}"]`),
          code = outerHtml(component);
      
      const feeds = this.$store.getters.feeds;
      
      let replacementHtml = $((new Vue({
        render: Vue.compile(code).render,
        data () {
          return {
            feeds,
          };
        },
      })).$mount().$el.outerHTML);
      
      $(`[id="${this.uuid}"]`).replaceWith(replacementHtml);
      this.dirty = false;      
    },
    
    generateComponentCode () {
      if (!this.uuid) return;
      let pageHtml = this.$store.getters.activePage.html,
          dom = $(pageHtml),
          component = dom.find(`[uuid="${this.uuid}"]`);
      
      let styles = {...this.styles};
      
      const globalAccentFont = parseFont(this.$store.getters.styles['--theme--font__heading']) || '',
            globalTextFont = parseFont(this.$store.getters.styles['--theme--font__text']) || '',
            globalButtonFont = parseFont(this.$store.getters.styles['--theme--font__button']) || '';
      if (globalAccentFont !== (this.accentFont || '')) {
        styles['--theme--font__heading'] = this.accentFont ? `"${this.accentFont}"` : '';
      }
      if (globalButtonFont !== (this.buttonFont || '')) {
        styles['--theme--font__button'] = this.buttonFont ? `"${this.buttonFont}"` : '';
      }
      if (globalTextFont !== (this.textFont || '')) {
        styles['--theme--font__text'] = this.textFont ? `"${this.textFont}"` : '';
      }

      component
        .attr('styles', writeStyles(styles));

      let overrides = {
        ...this.$store.getters.activeComponent.blockDesignOverrides,
      };
      overrides.styles = overrides.styles || {};
      
      'theme--font__text theme--font__heading theme--font__button theme--font__size theme--font__line-height theme--font__letter-spacing theme--font__size__heading theme--font__line-height__heading theme--font__letter-spacing__heading link-color link-color__hover link-text-decoration link-text-decoration__hover button-color button-text-color button-border-color button-color__hover button-text-color__hover button-border-color__hover theme--font__size__button theme--font__line-height__button theme--font__letter-spacing__button button-border-width button-radius button-box-shadow form-field-color form-field-text-color form-field-border-color form-field-border-width form-field-radius form-field-box-shadow'.split(' ').map(key => `--${key}`).forEach(key => {
        const val = styles[key];
        if (!val) {
          delete overrides.styles[key];
        } else {
          overrides.styles[key] = val;
        }
      });
      component.attr('data-block-design-overrides', JSON.stringify(overrides));
      
      if (this.blockAdvancedMode) {
        component.attr('data-block-advanced-mode', this.blockAdvancedMode);
      }

      return outerHtml(component);
    },

    compilePreview () {
      if (!this.mounted) return;
      let code = this.generateComponentCode();
      const feeds = this.$store.getters.feeds;
      
      let replacementHtml = $((new Vue({
        render: Vue.compile(code).render,
        data () {
          return {
            feeds,
          };
        },
      })).$mount().$el.outerHTML);

      $(`[id="${this.uuid}"]`).replaceWith(replacementHtml);
      this.compiledPreview = outerHtml(replacementHtml);
      
      this.dirty = true;
    },
    
    async saveComponent () {
      if (this.saving) return;
      
      let code = this.generateComponentCode();

      let pageHtml = this.$store.getters.activePage.html,
          dom = $(pageHtml);
      dom.find(`[uuid="${this.uuid}"]`).replaceWith(code);

      this.saving = true;
      await this.$store.dispatch('immediatelyWritePageContent', {
        key: this.$store.state.activePageKey,
        html: outerHtml(dom)
      });

      let registeredFonts = [...this.$store.state.repo.config.fonts],
          fontsDirty = false;
      
      if (registeredFonts.map(o => parseFont(o.family)).indexOf(this.accentFont) === -1) {
        registeredFonts.push({ source: 'google', family: this.accentFont });
        fontsDirty = true;
      }
      if (registeredFonts.map(o => parseFont(o.family)).indexOf(this.textFont) === -1) {
        registeredFonts.push({ source: 'google', family: this.textFont });
        fontsDirty = true;
      }
      if (registeredFonts.map(o => parseFont(o.family)).indexOf(this.buttonFont) === -1) {
        registeredFonts.push({ source: 'google', family: this.buttonFont });
        fontsDirty = true;
      }
      if (fontsDirty) {
        await this.$store.dispatch('updateConfigs', { fonts: registeredFonts });
      }
      
      this.saving = false;
      this.dirty = false;
    },
  },

  watch: {

    styles: {
      deep: true,
      handler () {
        this.compilePreview();
      },
    },
    accentFont () {
      this.compilePreview();
    },
    buttonFont () {
      this.compilePreview();
    },
    textFont () {
      this.compilePreview();
    },
  },
  
  computed: {

    iframeSrc () {
      return this.iframeWrapper(this.compiledPreview);
    },

    uuid () {
      return this.component.state.uuid;
    },
    
    component () {
      return {
        state: this.$store.state.activeComponent,
        getters: this.$store.getters.activeComponent,
      };
    },
  },

  data () {
    return {

      blockAdvancedMode: false,
      
      subtabs: {
        'tab-typography': [
          { name: 'text', icon: 'paragraph', label: 'Text' },
          { name: 'headings', icon: 'heading', label: 'Headings' },
          { name: 'buttons', icon: 'drag-drop-line', label: 'Buttons' },
          { name: 'form-fields', icon: 'input-cursor-move', label: 'Form Fields' },          
          { name: 'links', icon: 'link', label: 'Hyperlinks' },
        ],
      },
      
      compiledPreview: '',
      
      mounted: false,
      dirty: false,
      saving: false,
      
      activeTab: 'tab-typography',
      activeSubtab: 'text',

      styles: {},
      accentFont: '',
      buttonFont: '',
      textFont: '',
    }
  },

  mounted () {
    this.mount();
  },
}
</script>

<style scoped>
.md-horizontal-radio-field > label {
  margin-bottom: -1rem;
}
.md-horizontal-radio-field .md-field {
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
}
.md-horizontal-radio-field .md-field >>> .md-radio label.md-radio-label {
  padding-left: 3px;
}
.md-app-toolbar[data-md-toolbar-collapsed] {
  min-height: 15px;
}
label {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
label input.form-control {
  max-width: 5rem;
  padding: .75rem .25rem;
  text-align: right;
}
label input.form-control::-webkit-outer-spin-button, 
label input.form-control::-webkit-inner-spin-button {
  margin-left: .25rem;
}


.wrapper-previews {
  display: flex;
  flex-wrap: wrap;
}
.wrapper-preview {
  background: var(--preview-background, #eee);
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 48%);
  flex: 0 0 30%;
  align-self: stretch;
  margin: 1rem;
  padding: 1rem;
  min-height: 10rem;
  filter: brightness(0.8) opacity(0.8);
}
.wrapper-preview.active {
  filter: none;
}
.wrapper-preview-flex {
  display: flex;
}
.wrapper-preview > * {
  margin: .5em;
  border: 1px solid #ccc;
  padding: .5em;
  flex: 1;
  background: white;
}
.wrapper-preview-flex.direction-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.wrapper-preview-flex.direction-column > * {  
  width: 50%;
}
.wrapper-preview-flex.display-first-child-fill {
  flex-wrap: wrap;
}
.wrapper-preview-flex.display-first-child-fill > :first-child {
  flex: 100%;
}
.wrapper-preview-flex.align-stretch > * {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.wrapper-preview-flex.align-flex-center > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.wrapper-preview-flex.align-center {
  align-self: stretch;
  justify-content: center;
  align-items: center;
}
.wrapper-preview-flex.align-center > * {
  align-self: center;
}
.wrapper-preview-flex.flex-items > * {
  display: flex;
}
.wrapper-preview p {
  font-size: .4rem;
  line-height: 1;
}
.wrapper-preview img {
  font-size: .4rem;
  margin-bottom: 1.5em;
  }
.wrapper-preview p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.media-unit-editor .md-tabs {
  margin-bottom: 1rem;
}
.media-unit-editor .md-toolbar .md-tabs {
  width: 100%;
  margin-bottom: 0;
}
.media-unit-editor .md-drawer {
  width: auto;
  padding-right: 2rem;
  font-size: 2rem;
}

.media-unit-editor .controls {
  font-family: "Roboto", sans-serif;    
}
.background-overlay-editor {
  background: #eee;
  margin: 1rem auto;
  padding: 1rem;
}
.media-unit-editor .md-tabs {
  margin-bottom: 1rem;
}
.media-unit-editor .btn-row {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-unit-editor .md-content {
}
</style>
