<template>
<dialog-layout>
  <dialog-close-button @close="$emit('close')" />
  
  <md-app style="min-height: min(80vh, 800px)">
    <md-app-toolbar :class="dirty ? 'md-large md-dense' : ''">
      <div class="md-toolbar-row">
        <app-tabs :tabs="tabs" @tab-changed="activeTab = $event; activeSubtab = '';" />
      </div>
    </md-app-toolbar>
    
    <md-app-content v-if="activeTab === 'publish'">
      <form-card button-alignment="right">
        <div v-if="Object.keys(lastDeploy).length">
          <a target="_blank" :href="`https://${lastDeploy.url}`">
            Last deployed on {{ new Date(lastDeploy.createdAt).toLocaleDateString()}}
          </a>
          to <b>{{ lastDeploy.target || 'a preview url' }}</b>
          <br>
          Status: {{ lastDeploy.readyState }}
        </div>
      
        <template #actions v-if="deployable">
          <md-button @click="deployToPreview"
                     class="md-secondary me-5">
            Publish draft preview
          </md-button>
          <md-button @click="deployToProduction"
                     class="md-primary">
            Publish live
          </md-button>
        </template>
      </form-card>
    </md-app-content>
    
    <md-app-content v-if="activeTab === 'domain'">
      <form-card title="Add a domain"
                 :help="domains.length === 0 ? 'A domain name is your address on the internet -- like AliciaHorne.com, AliciaForCalifornia.com, or VoteAlicia.com. If you do not yet have a domain name, we recommend purchasing one through Namecheap, Google Domains, or Cloudflare. Once you have a domain name purchased, enter it here to connect it to your website!' : 'Want to add additional domains? Go ahead!'"
                 @submit="addDomain">
        <md-field>
          <label>
            Domain Name
          </label>
          <md-input v-model="addingDomain" />
        </md-field>
      </form-card>
      <md-table v-if="domains && domains.length">
        <md-table-row>
          <md-table-head>Domain</md-table-head>
          <md-table-head>Primary?</md-table-head>
          <md-table-head>DNS</md-table-head>
          <md-table-head></md-table-head>
          <md-table-head></md-table-head>
        </md-table-row>        
        <md-table-row v-for="item, index in domains" :key="index">
          <md-table-cell>
            {{ item.name }}
          </md-table-cell>
          <md-table-cell>
            {{ item.redirect ? `Redirects to ${item.redirect}` : 'Yes, primary' }}
          </md-table-cell>
          <md-table-cell>
            <div v-if="currentDns[item.name]">
              <div class="md-table-head-label" style="text-align: left; padding: 0; text-transform: uppercase; margin: 0 0 -.5em">Currently:</div>
              <div v-for="record, index in currentDns[item.name]" :key="index">
                {{ record.host }} {{ record.type }} {{ record.value }}
              </div>
            </div>
            <div v-if="dnsStatus[item.name] !== 'DNS Ready!' && intendedDns(item.name)">
              <div class="md-table-head-label" style="text-align: left; padding: 0; text-transform: uppercase; margin: 0 0 -.5em" :style="currentDns[item.name] ? 'margin-top: 1em' : ''">Expected:</div>
              <div>
                {{ intendedDns(item.name).host }} {{ intendedDns(item.name).type }} {{ intendedDns(item.name).value }}
              </div>
            </div>
          </md-table-cell>
          <md-table-cell>
            <status-button @action="checkDns(item.name)">
              {{ dnsStatus[item.name] || 'Check DNS' }}
            </status-button>
          </md-table-cell>
          <md-table-cell>
            <status-button v-if="canDelete(item)" @action="deleteDomain(item.name)">
              Delete
            </status-button>
            <VueCustomTooltip v-else
                              label="To delete this domain, first delete any domains that are redirecting to it."
                              position="is-left">
              <status-button>
                <del>Delete</del>
              </status-button>
            </VueCustomTooltip>
          </md-table-cell>
        </md-table-row>
      </md-table>
      
    </md-app-content>
    
    <md-app-content v-if="activeTab === 'history'">
      <md-table v-model="deploys">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Published On" md-sort-by="id" md-numeric>{{ (new Date(item.createdAt)).toLocaleDateString() }}</md-table-cell>
          <md-table-cell md-label="Type" md-sort-by="name">{{ item.target || 'preview' }}</md-table-cell>
          <md-table-cell md-label="Link" md-sort-by="email"><a target="_blank" :href="`https://${item.url}`">link</a></md-table-cell>
        </md-table-row>
      </md-table>
    </md-app-content>
  </md-app>
  
</dialog-layout>
</template>

<script>
import { get, post } from 'axios';

export default {
  
  watch: {
    activeTab (val) {
      if (val === 'domain') {
        this.fetchDomains();
      }
      if (val === 'history') {
        this.fetchHistory();
      }
    },
  },
  
  methods: {
    canDelete (domain) {
      // A domain can be deleted if it's not a primary domain, OR if no other domains point to it
      if (domain.redirect) {
        return true;
      }
      if (this.domains.filter(o => o.redirect === domain.name).length) {
        return false;
      }
      return true;
    },
    intendedDns (domain) {
      const isRootDomain = [...domain].filter(c => c === '.').length === 1;

      if (isRootDomain) {
        return { type: 'A', host: '@', value: '76.76.21.21' };
      } else {
        return { type: 'CNAME', host: 'www', value: 'cname.vercel-dns.com' };
      }
    },
    
    async checkDns (domain) {
      const { records } = (await get(`https://dns-lookup-eta.vercel.app/api/dns-lookup?domain=${domain}`)).data;
      if (!records) {
        this.$set(this.dnsStatus, domain, 'DNS Missing');
        this.$set(this.currentDns, domain, []);
      } else {
        const recordsForDisplay = records.map(o => ({
          ...o,
          host: [...o.name].filter(c => c === '.').length === 1 ? '@' : o.name.split('.')[0],
        }));
        this.$set(this.currentDns, domain, recordsForDisplay);
        const isRootDomain = [...domain].filter(c => c === '.').length === 1;
        if (isRootDomain) {
          const validRecords = records.filter(o => o.type === 'A' && o.value === '76.76.21.21');
          if (!validRecords.length) {
            this.$set(this.dnsStatus, domain, 'DNS Invalid');
          } else if (validRecords.length !== records.length) {
            this.$set(this.dnsStatus, domain, 'Extra DNS Records Found');
          } else {
            this.$set(this.dnsStatus, domain, 'DNS Ready!');
            delete this.currentDns[domain];
          }
        } else {
          const validRecords = records.filter(o => o.type === 'CNAME' && o.value === 'cname.vercel-dns.com');
          if (!validRecords.length) {
            this.dnsStatus[domain] = 'DNS Invalid';
          } else if (validRecords.length !== records.length) {
            this.$set(this.dnsStatus, domain, 'Extra DNS Records Found');
          } else {
            this.$set(this.dnsStatus, domain, 'DNS Ready!');
            delete this.currentDns[domain];
          }
        }
      }
      console.log(records, this.dnsStatus);
    },
    
    async fetchDomains () {
      const resp = await get(`/api/domains`);
      this.domains = (resp.data.domains || [])
        .filter(d => d.name.indexOf('vercel') === -1);
      
      this.domains.forEach(({ name }) => {
        this.checkDns(name);
      });
    },

    async fetchHistory () {
      const resp = await get(`/api/deploys?history=1`);
      this.deploys = resp.data;
    },

    async deleteDomain (domain) {
      await post(`/api/domains`, { domain, delete: true });
      await this.fetchDomains();
    },
    
    async addDomain () {
      let domain = this.addingDomain;

      domain = domain.toLowerCase();
      if (domain.indexOf('://') !== -1) {
        domain = domain.split('://')[1];
      }
      if (domain.indexOf('www.') !== -1) {
        domain = domain.split('www.')[1];
      }

      const isRootDomain = [...domain].filter(c => c === '.').length === 1;
      const isValidDomain = [...domain].filter(c => c === '.').length > 0;

      if (!isValidDomain) {
        this.$swal.fire({
          title: 'Hang on a second!',
          text: `Please double-check the domain name you've entered. It looks like you may have left off the suffix (e.g. ".com", ".org", ".us", etc)`,
            icon: 'warning',
            customClass: {
              confirmButton: 'md-button md-primary md-theme-default',
            },
          buttonsStyling: false,
        });
      }

      let redirect = null;
      await this.fetchDomains();
      if (this.domains.length && this.domains.filter(o => o.redirect === null).length) {
        redirect = this.domains.filter(o => o.redirect === null)[0].name;
      }

      if (isRootDomain) {
        if (this.domains.map(o => o.name).indexOf(`www.${domain}`) === -1) {
          // we'll add www too
          await post(`/api/domains`, { domain: `www.${domain}`, redirect });
        }
        if (!redirect) {
          // and then we'll redirect the root to www if there isn't already another domain set
          redirect = `www.${domain}`;
        }
      }
      await post(`/api/domains`, { domain, redirect });
      this.fetchDomains();
    },
    
    async deployToPreview () {
      await post(`/api/deploys`);
      this.nextToLastDeploy = this.lastDeploy;
      this.lastDeploy = {};
      this.pollForDeploy();
    },

    async deployToProduction () {
      await post(`/api/deploys?target=production`);
      this.nextToLastDeploy = this.lastDeploy;
      this.lastDeploy = {};
      this.pollForDeploy();
    },
    
    async pollForDeploy () {
      if (Object.keys(this.lastDeploy).length && !this.building) {
        return;
      }
      await this.checkDeploy();
      window.setTimeout(this.pollForDeploy, 2000);
    },
    
    async checkDeploy () {
      if (this.sending) return;
      this.sending = true;
      const resp = await get(`/api/deploys`);

      if (this.nextToLastDeploy && resp.data.length && resp.data[0].id === this.nextToLastDeploy.id) {
        this.sending = false;
        // We are expecting another deploy to pop up after this one
        return;
      }
      this.lastDeploy = resp.data.length ? resp.data[0] : {};
      this.sending = false;
    },
  },

  computed: {
    building () {
      return this.lastDeploy.readyState && this.lastDeploy.readyState !== 'READY';
    },
    
    deployable () {
      return Object.keys(this.lastDeploy).length === 0 ||
        this.lastDeploy.readyState === 'READY';
    }
  },

  unmounted () {
    this.interval && window.clearInterval(this.interval);
  },
  
  async mounted () {
    await this.checkDeploy();
    this.pollForDeploy();    
  },
  
  data () {
    return {
      tabs: [
        { id: 'publish', label: "Publish", iconComponent: 'rocket-ship' },
        { id: 'domain', label: "Domain Name", icon: 'home-wifi-line' },
        { id: 'history', label: "History", icon: 'history-line' },
      ],
      addingDomain: '',
      activeTab: 'publish',
      dnsStatus: {},
      currentDns: {},
      domains: [],
      dirty: false,
      lastDeploy: {},
      nextToLastDeploy: null,
      deploys: false,
      sending: false,
      interval: null,
    };
  },
}
</script>

<style scoped>
.md-table .md-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: #eee;
  margin: 0;
}
.md-button.go-button {
  height: auto;
  max-height: 2rem;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: purple;
  color: white;
  margin: 0;
}
.md-button.go-button.secondary-go-button {
  border: 3px solid purple;
  background: white;
  color: purple;
}
.btn {
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 700;
  background-color: rgb(26, 147, 193);
  border: none;
  color: white !important;
  text-decoration: none !important;
  letter-spacing: 0px;
  font-size: 1.1rem;
  padding: .25rem .5rem;
}
.btn:hover {
  background-color: rgb(26, 147, 193);
  filter: brightness(1.2);
  color: white;
}
.btn-primary, 
.btn-primary:hover {
  background-color: rgb(154, 40, 139);
}
</style>
