<template>
<div>
  <form-card v-for="rule, index in viewingConfirmations" :key="index"
             @submit="editOrSave(index)"
             :sending="sending"
             :title="`Message #${index+1}: ${rule.subject || '[No Subject]'}`"
             :help="editingIndex === index ? '' : `To: ${rule.to || '[No Recipient]'}`"
             :save-button="editingIndex === index ? 'Save' : 'Edit'">
    <template v-if="editingIndex === index">
      <md-field v-for="key in fieldOrder" :key="key" :class="errors[index][key] ? 'md-invalid' : ''">
        <label :for="key">{{key}}</label>
        <md-input v-if="key !== 'html' && key !== 'text'"
                  type="text" :id="key" v-model="rule[key]" :disabled="sending" />
        <md-textarea v-else
                     type="text" :id="key" v-model="rule[key]" :disabled="sending"></md-textarea>
        <span v-if="errors[index][key]" class="md-error">{{ errors[index][key] }}</span>
      </md-field>
    </template>
  </form-card>

  <form-card @submit="add" :sending="sending" title="Add new message">
    <md-field v-for="key in fieldOrder" :key="key" :class="newErrors[key] ? 'md-invalid' : ''">
      <label :for="key">{{key}}</label>
      <md-input v-if="key !== 'html' && key !== 'text'"
                type="text" :id="key" v-model="newConfirmation[key]" :disabled="sending" />
      <md-textarea v-else
                   type="text" :id="key" v-model="newConfirmation[key]" :disabled="sending"></md-textarea>
      <span v-if="newErrors[key]" class="md-error">{{ newErrors[key] }}</span>
    </md-field>
  </form-card>
</div>
</template>

<script>
import { get, post } from 'axios';

export default {
  name: 'form-confirmation-manager',

  props: ['activeTab'],
          
  watch: {
    activeTab (val) {
      if (val === 'new') {
        let index = Object.keys(this.confirmations).length;
        let newKey = `New alert set ${index+1}`;
        this.confirmations[newKey] = {
          confirmations: [],
        };
        this.activeTab = newKey;
        this.activeSubtab = 'new';
      }
    },
  },
  
  methods: {
    async fetch () {
      this.fetching = true;
      this.confirmations = (await get('/api/form-confirmations')).data;
      this.fetching = false;

      this.viewingConfirmations = this.confirmations[this.activeTab].confirmations || [];
      this.errors = this.viewingConfirmations.map(() => ({}));
    },

    async editOrSave (index) {
      if (this.editingIndex === index) {
        return await this.save(index);
      } else {
        this.editingIndex = index;
      }
    },
    
    async save (index) {
      this.sending = true;
      this.errors[index] = {};

      const editingConfirmation = this.viewingConfirmations[index];
      this.requiredFields.forEach(field => {
        if (!editingConfirmation[field]) {
          this.errors[index][field] = `Field ${field} is required.`;
        }
      });
      if (Object.keys(this.errors[index]).length) {
        this.sending = false;
        return;
      }

      this.confirmations[this.activeTab].confirmations[index] = editingConfirmation;

      await post(`/api/form-confirmations?key=${this.activeTab}`, this.confirmations[this.activeTab].confirmations);
      await this.fetch();
      this.sending = false;
    },

    async add () {
      this.sending = true;
      this.newErrors = {};

      const editingConfirmation = this.newConfirmation;
      this.requiredFields.forEach(field => {
        if (!editingConfirmation[field]) {
          this.newErrors[field] = `Field ${field} is required.`;
        }
      });
      if (Object.keys(this.newErrors).length) {
        this.sending = false;
        return;
      }
      this.confirmations[this.activeTab].confirmations.push(editingConfirmation);

      await post(`/api/form-confirmations?key=${this.activeTab}`, this.confirmations[this.activeTab].confirmations);
      await this.fetch();
      this.sending = false;
    },
  },

  mounted () {
    this.fetch();
  },
  
  data () {
    return {
      fetching: false,
      sending: false,
      confirmations: {},
      activeSubtab: null,

      viewingConfirmations: [],
      errors: [],

      newConfirmation: {},
      newErrors: {},

      editingIndex: null,

      fieldOrder: ['from', 'to', 'replyTo', 'subject', 'html', 'text'],
      requiredFields: ['to', 'subject', 'html', 'text'], 
    };
  },
}
</script>
