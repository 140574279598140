import { MediaUnit } from './core.js';
import { getContrast, hexToRgba } from '../color-utils.js';

export const header_squares = new MediaUnit('header_squares', 'Square Blocks', 'header', {
  styles: ({ metadata, primaryColor, contentHeight }) => ({
    '--image-width': `${metadata.width}`,
    '--image-height': `${metadata.height}`,        
    '--media-unit--media__width': contentHeight === '3' ? '25%' : '50%', 
    '--image-object-position': 'top',
    'background': primaryColor,
    'color': getContrast(primaryColor),
    '--theme--font__size__heading': '3rem',
  }),
  contentStyles: ({ contentHeight }) => ({
    'padding-top': `${contentHeight || 10}rem`,
    'padding-bottom': `${contentHeight || 10}rem`,
    'padding-left': '3rem',
    'padding-right': '3rem',
  }),

  template: ({ image, heading, content, styles, mediaStyles, contentStyles, headingStyles }) => {
    return `
<media-unit uuid="" content-class="has-wrapped-content direction-row alignment-stretch-centered"
            image="${image}"
            styles="${styles}" media-styles="${mediaStyles}" content-styles="${contentStyles}">
  <template v-slot:default>
    <div class="media-unit--content-wrapper">
      ${heading ? `<h3 style="${headingStyles}">${heading}</h3>` : ''}
      ${content}
    </div>
  </template>
</media-unit>`;
  },
});

export const banner_header = new MediaUnit('banner_header', 'Banner Image', 'header', {
  styles: ({ primaryColor = '#f9f9e9', media_side = 'left' }) => ({
    '--image-width': `0`,
    '--image-height': `0`,
    'background': `${primaryColor || '#f9f9e9'}`,
    '--background-color': `${primaryColor || '#f9f9e9'}`,

    '--media-unit--media__width': '0%',
    '--image-object-position': 'right', 
    '--theme--font__size__heading': '3rem',
    'flex-direction': media_side === 'left' ? '' : 'row-reverse',
    'color': getContrast(primaryColor || '#f9f9e9'),    
  }),

  contentStyles: ({ contentHeight, contentMaxWidth, contentStyles = {} }) => ({
    'padding-top': `${contentHeight || 10}rem`,
    'padding-bottom': `${contentHeight || 10}rem`,
    'padding-left': '3rem',
    'padding-right': '3rem',
    'max-width': contentMaxWidth,
    ...contentStyles,
  }),

  mediaStyles: () => ({
    'flex': '0',
    'display': 'none',
  }),

  template: ({ image, heading, content, styles, mediaStyles, contentStyles, headingStyles, imageSize, imagePosition, imageAttachment, media_side, primaryColor, backgroundImageOpacity = '.2' }) => {
    let gradient = '';
    if (imageSize === 'contain') {
      gradient = `<background opacity="1" gradient="linear" to="${media_side === 'left' ? 'right' : 'left'}" start="${hexToRgba(primaryColor, 1)}" start-at="35%" end="${hexToRgba(primaryColor, 0)}" end-at="85%"></background>`;
    }
    return `
<media-unit uuid="" content-class="has-wrapped-content direction-row alignment-centered"
            styles="${styles}" media-styles="${mediaStyles}" content-styles="${contentStyles}">
  <template v-slot:backgrounds>
    <background uuid="" opacity="${backgroundImageOpacity}" image="${image}" position="${imagePosition || 'center'}" attachment="${imageAttachment || ''}" size="${imageSize || 'cover'}" repeat="no-repeat"></background>
    ${gradient}
  </template>
  <template v-slot:default>
    <div class="media-unit--content-wrapper" style="margin-top: 1rem; margin-bottom: 1rem; margin-left: 1rem; margin-right: 1rem;">    
      ${heading ? `<h1 style="${headingStyles}">${heading}</h1>` : ''}
      ${content}
    </div>
  </template>
</media-unit>`;
  },
});
