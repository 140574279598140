<template>
  <md-field class="md-has-value md-borderless">
    <label v-html="label"></label>
    <md-switch class="md-switch-left-right" v-model="value">
      {{ value ? ifTrue : ifFalse }}
    </md-switch>
  </md-field>
</template>

<script>
export default {
  props: ['label', 'ifTrue', 'ifFalse', 'value'],

  watch: {
    value (val) {
      this.$emit('input', val);
    },
  },
}
</script>

<style scoped>
.md-switch.md-switch-left-right .md-switch-container {
  background-color: #cfa9c8 !important;
  --md-theme-default-accent-on-: #cfa9c8 !important;
  --md-theme-default-switchvariant: #800080 !important;
  --md-theme-default-accent: #800080 !important;
}
.md-switch.md-switch-left-right .md-ripple {
  display: none;
}
.md-borderless:after {
  display: none;
}
</style>
