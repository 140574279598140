<template>
<div>

  <image-input v-for="src, index in images"
               :key="index"
               :value="src"
               @input="receiveInput(index, $event)"
               @deleteImage="deleteImage(index, $event)"
               :allow-delete="true"
               >
  </image-input>

  <button @click="addImage" class="btn"><ri n="add-line" /></button>
</div>
</template>

<script>
import ImageInput from './ImageInput.vue';

export default {
  name: 'MultiImageInput',
  components: {
    ImageInput,
  },
  props: {
    image: { type: String },
  },
  methods: {
    addImage () {
      let images = [...this.images];
      images.push('https://placekitten.com/400/400');
      this.currentImage = images.join(', ');
    },
    deleteImage (index) {
      let images = [...this.images];
      images.splice(index, 1);
      this.currentImage = images.join(', ');
    },
    receiveInput (index, $event) {
      let images = [...this.images];
      images[index] = $event;
      this.currentImage = images.join(', ');
    },
  },
  watch: {
    currentImage () {
      this.$emit('input', this.currentImage);
    },
  },
  computed: {
    images () {
      if (!this.currentImage) {
        return [];
      }
      return this.currentImage.split(', ').map(o => o.trim()).filter(o => o);
    },
  },
  data () {
    return {
      currentImage: this.image || '',
    };
  },
}
</script>
