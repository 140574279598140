<template>
<component :is="maybeLabel">
  <span v-if="hasLabel">
    <slot name="default" v-bind="{ unit, changeUnit, value: realValue }"></slot>
    <span v-if="hasLabel">
      (<VueCustomTooltip position="is-right" label="This is the definition of the unit in question"><span @sclick="changeUnit('px')">{{ unit || 'unitless' }}</span></VueCustomTooltip>):
    </span>
  </span>
  <div class="row">
    <div v-if="customize" :class="!noUnit && 'col-9'">
      <box-layout-input @unit="unit = $event"
                        ref="input"
                        v-model="realValue" :no-unit="noUnit" :default-unit="defaultUnit" />
    </div>
    <div v-if="!noUnit" class="col">
      <button v-if="realValue" @click="realValue = null; customize = false;" class="btn">Clear</button>
      <button v-if="!customize" @click="customize = true; realValue = firstValidInherited;" class="btn">Customize</button>
    </div>
  </div>
</component>
</template>

<style scoped>
label {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
label input.form-control {
  max-width: 5rem;
  padding: .75rem .25rem;
  text-align: right;
}
label input.form-control::-webkit-outer-spin-button, 
label input.form-control::-webkit-inner-spin-button {
  margin-left: .25rem;
} 
.row {
  flex-wrap: nowrap;
}

.row .col, .row .col-9 {
  justify-content: initial !important;  
}
</style>

<script>
import BoxLayoutInput from './BoxLayoutInput.vue';

export default {
  name: 'css-value-input',
  components: { BoxLayoutInput },
  props: [
    'value',
    'inherited',
    'noUnit',
    'defaultUnit',
  ],
  computed: {
    hasLabel () {
      return this.$scopedSlots['default'] ? true : false;
    },
    maybeLabel () {
      if (this.hasLabel) return 'label';
      return 'div';
    },
  },
  methods: {
    changeUnit (to) {
      this.$refs['input'].parsed.unit = to;
    },
  },
  watch: {
    realValue () {
      this.$emit('input', this.realValue);
    },
  },
  data () {
    let firstValidInherited = this.inherited;
    if (typeof this.inherited === 'object' && typeof this.inherited.filter === 'function') {
      firstValidInherited = this.inherited.filter(o => o)[0];
    }
    return {
      firstValidInherited,
      realValue: this.value || firstValidInherited,
      customize: this.value || this.noUnit ? true : false,
      unit: null,
    };
  },
}
</script>
