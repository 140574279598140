<template>
<dialog-layout>
  <md-button style="position: absolute; top: 0; right: 0; transform: scale(1.5)"
             class="md-icon-button md-accent" @click="$emit('close')">
    <md-icon>
      <ri n="close-circle-line" />
    </md-icon>
  </md-button>

  <draggable :list="buttons">
    <form-card v-for="button, index in buttons" :key="index"
               @delete="buttons.splice(index, 1)"
               :no-layout="true">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-40">
          <md-field>
            <label>Text</label>
            <md-input v-model="button.text" />
          </md-field>
        </div>
        <div class="md-layout-item md-size-40">
          <md-field>
            <label>Link URL</label>
            <md-input v-model="button.href" />
          </md-field>
        </div>
        <div class="md-layout-item md-size-20" style="align-items: center; display: flex;">
          <md-button class="go-button" @click="addLink(button)">Choose page</md-button>
        </div>
      </div>
    </form-card>
  </draggable>
    
  <form-card @submit="saveLink" save-button="Add" :no-layout="true" title="Add a button">
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-40">
        <md-field>
          <label>Text</label>
          <md-input v-model="adding.text" />
        </md-field>
      </div>
      <div class="md-layout-item md-size-40">
        <md-field>
          <label>Link URL</label>
          <md-input v-model="adding.href" />
        </md-field>
      </div>
      <div class="md-layout-item md-size-20" style="align-items: center; display: flex;">
        <md-button class="go-button" @click="addLink(adding)">Choose page</md-button>
      </div>
    </div>
  </form-card>

  <md-field>
    <label>Set a fixed width for all buttons?</label>
    <md-input v-model="width" />
  </md-field>
  <md-field class="md-has-value md-borderless">
    <label>Button colors</label>
    <color-picker expand-to="top-right" v-model="buttonColor" />
    <color-picker expand-to="top-right" v-model="buttonTextColor" />
    <color-picker expand-to="top-right" v-model="buttonColorHover" />
    <color-picker expand-to="top-right" v-model="buttonTextColorHover" />    
  </md-field>
  

  <md-button class="md-primary" @click="$emit('close', { links: buttons, width, buttonColor, buttonTextColor, buttonColorHover, buttonTextColorHover })">Save</md-button>
</dialog-layout>
</template>

<script>
import draggable from 'vuedraggable'
import DialogAddLink from './DialogAddLink.vue';
import { openDialog } from '../modal-dialog.js';
import ColorPicker from './ColorPicker.vue';

export default {
  props: ['links', 'initial'],

  components: {
    draggable,
    ColorPicker,
  },
  
  methods: {
    saveLink (){
      this.buttons.push(this.adding);
      this.adding = {
        text: '',
        href: '',
      };
    },
    
    async addLink (what) {
      const link = await openDialog(DialogAddLink);
      if (link.to) {
        what.href = link.to === 'index' ? '/' : `/${link.to}`;
      } else {
        what.href = link.href;
      }
    },
  },
  
  data () {
    let buttons = [];
    (this.links || []).forEach(link => {
      buttons.push({
        ...link,
      });
    });

    return {
      buttons,
      width: this.initial.size || null,
      buttonColor: this.initial['button-color'] || null,
      buttonColorHover: this.initial['button-color__hover'] || null,
      buttonTextColor: this.initial['button-text-color'] || null,
      buttonTextColorHover: this.initial['button-text-color__hover'] || null,
      adding: {
        text: '',
        href: '',
      },
    };
  },
};
</script>

<style scoped>
.md-button.go-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: purple;
  color: white;
  margin: 0;
}
</style>  
