import App from './App.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
Vue.component('vue-dropzone', vue2Dropzone);

import 'vue-material-slider/dist/vue-material-slider.css';
import VueMaterialSlider from 'vue-material-slider';
Vue.use(VueMaterialSlider);

import 'bootstrap';
import PortalVue from 'portal-vue'
import FontPicker from 'font-picker-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faPinterest, faYoutube, faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import RemixIcon from './components/RemixIcon.vue'

import './plugins/sweetAlert';

import Tiptap from './components/Tiptap.vue'
import UserContent from './components/UserContent.vue';
import CloudinaryImageEditor from './components/CloudinaryImageEditor.vue';
import NuxtLink from './components/NuxtLink.vue';

Vue.component('nuxt-link', NuxtLink);

library.add(faPlus);
library.add(faFacebook, faTwitter, faInstagram, faLinkedin, faPinterest, faYoutube)

Vue.config.productionTip = false

Vue.use(PortalVue)
Vue.use(FontPicker)
Vue.component('font-picker', FontPicker);

Vue.use(VueRouter);

Vue.component('cloudinary-image-editor', CloudinaryImageEditor);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('ri', RemixIcon);
Vue.component('tiptap', Tiptap)
Vue.component('user-content', UserContent);

import Pies from 'vue-pies-website-components/plugin';
Vue.use(Pies);

import ImageGalleryColumns from './components/userspace/ImageGalleryColumns.vue';
Vue.component('image-gallery-columns', ImageGalleryColumns);

import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

Vue.use(VueMaterial);

import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
Vue.component('vue-draggable-resizable', VueDraggableResizable)

import 'vue-pies-website-components/sass/style.scss';

import { vfmPlugin } from 'vue-final-modal'

Vue.use(vfmPlugin)

import store from './store.js';

import { directive as onClickOutside } from 'vue-on-click-outside' 
Vue.directive('on-click-outside', onClickOutside)

import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
Vue.use(VueCustomTooltip)

import ContentCollectionManager from './components/ContentCollectionManager';
import ComponentManager from './components/ComponentManager';

import FormConfirmationManager from './components/FormConfirmationManager';

import ImageLibrary from './components/ImageLibrary';
import HelloWorld from './components/HelloWorld';
import DialogLayout from './components/DialogLayout';
import ImageInput from './components/ImageInput';

import { Sketch } from 'vue-color'

import ProTip from './components/design/ProTip';
import FormCard from './components/design/FormCard';
import DialogCloseButton from './components/design/DialogCloseButton';
import DialogPreviewButton from './components/design/DialogPreviewButton';
import AppTabs from './components/design/AppTabs';
import Snowflake from './components/design/Snowflake';
import RocketShip from './components/design/RocketShip';
import MdSwitchLeftRight from './components/design/MdSwitchLeftRight';

import FormBuilder from './components/FormBuilder';

Vue.component('pro-tip', ProTip);
Vue.component('snow-flake', Snowflake);
Vue.component('rocket-ship', RocketShip);

Vue.component('md-switch-left-right', MdSwitchLeftRight);
Vue.component('form-card', FormCard);
Vue.component('app-tabs', AppTabs);
Vue.component('dialog-close-button', DialogCloseButton);
Vue.component('dialog-preview-button', DialogPreviewButton);
Vue.component('sketch-picker', Sketch);
Vue.component('dialog-layout', DialogLayout);
Vue.component('image-input', ImageInput);

import StatusButton from './components/StatusButton';
Vue.component('status-button', StatusButton);

import FormSubmissions from './components/FormSubmissions';

import IconBrowser from './components/IconBrowser';

const routes = [
  { path: '/collections', component: ContentCollectionManager, },
  { path: '/icons', component: IconBrowser, },  
  { path: '/submissions', component: FormSubmissions, },  
  { path: '/library', component: ComponentManager, },
  { path: '/images', component: ImageLibrary, props: { isRoot: true }, },
  { path: '/alerts', component: FormConfirmationManager, },
  { path: '/forms', component: FormBuilder },
  { path: '/', component: HelloWorld, },
];

const router = new VueRouter({
  routes,
});

new Vue({
  render: h => h(App),
  store: store.store,
  router,
}).$mount('#app')
