<template>
<transition name="bounce">
  <md-card class="md-card-transparent-unless-hovered" v-if="isMounted">
    <md-card-actions style="justify-content: center;">
      <md-button @click="$emit('save', { uuid, type: blockType, args: value, code, rendered })">
        Save
      </md-button>
      <md-button @click="$emit('discard', { uuid })">
        Cancel
      </md-button>
    </md-card-actions>
    <md-card-content>
      <div v-for="{ type, subtype, options }, key in form" :key="key"
           :style="type === 'html' ? 'flex:100%;max-height:30vh;overflow:scroll': ''">
        <label>
          {{key}}
          <input v-if="type === 'text'" type="text" v-model="value[key]">
          <input v-else-if="type === 'number'" type="number" v-model="value[key]">
          <tiptap v-else-if="type === 'html'" v-model="value[key]"></tiptap>
          <image-input v-else-if="type === 'image'" v-model="value[key]"></image-input>

          <md-field v-else-if="type === 'select'">
          
            <label :for="`field-${key}`">
              {{key}}
            </label>

            <md-select v-model="value[key]" :name="`field-${key}`" :id="`field-${key}`">
              <md-option v-for="{ label, value } in options" :key="label" :value="value">
                {{ label }}
              </md-option>
            </md-select>
              
          </md-field>
            
        </label>
      </div>
    </md-card-content>
  </md-card>
</transition>
</template>

<script>
import $ from 'cash-dom';
import Corpus from '../exquisite-corpse.js';
import { renderCorpus } from '../utils.js';
import Vue from 'vue';

export default {
  name: 'content-block-form',
  props: [
    'blockType',
    'blockArgs',
    'uuid',
  ],
  methods: {
    iframeWrapper (content) {
      const fonts = this.$store.state.repo.config.fonts
            .filter(o => o && o.family)
            .map(({ family }) => `https://fonts.googleapis.com/css2?family=${family}:wght@400;700&display=swap`)
            .map(href => `<link rel='stylesheet' type='text/css' href="${href}" />`)
            .join('\n');

      return `
<html><head>
  ${fonts}
  <my-script type="text/javascript">
    window.onload = function() {    
      if (parent) {        
        var oHead = document.getElementsByTagName("head")[0];        
        var arrStyleSheets = parent.document.getElementsByTagName("style");        
        for (var i = 0; i < arrStyleSheets.length; i++)            
          oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
        var arrStyleSheets = parent.document.getElementsByTagName("link");        
        for (var i = 0; i < arrStyleSheets.length; i++) {           
          if (arrStyleSheets[i].rel !== 'stylesheet' && arrStyleSheets[i].type !== 'text/css') continue;
          if (arrStyleSheets[i].href.endsWith('.css')) {
            oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
          }
        }
      }
    }
  </my-script>
</head>
<body>
  <div style='${this.$store.state.repo.config.layout.style}'>${content}</div>
</body></html>`.replace(/my-script/g, "script");

    },
  },

  computed: {

    iframeSrc () {
      return this.iframeWrapper(this.rendered);
    },
    
    corpusTypes () {
      return Corpus.types;
    },

    corpusBlocks () {
      return Corpus.blocks;
    },
    
    form () {
      if (!this.blockType) return {};

      const type = this.corpusBlocks[this.blockType]?.type;
      if (!type) return {};
      
      return this.corpusTypes[type]?.args || {};
    },
  },

  watch: {
    value: {
      deep: true,
      handler (val) {

        const code = renderCorpus(this.blockType, val, null, this.uuid);

        const rendered = (new Vue({
          render: Vue.compile(code).render,
        })).$mount().$el.outerHTML;

        this.code = code;
        this.rendered = rendered;

        $(`[id="${this.uuid}"]`).replaceWith(rendered);
      },
    },
  },

  mounted () {
    this.value = {...this.blockArgs} || {};

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  data () {
    return {
      isMounted: false,
      value: {},
      code: '',
      rendered: '',
    };
  },
}
</script>

<style>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(.9);
    background-color: rgba(255,255,255,1);
  }
  50% {
    transform: scale(1.1);
    background-color: rgba(255,255,255,.6);
  }
  100% {
    transform: scale(1);
    background-color: rgba(255,255,255,.1);    
  }
}
</style>
<style scoped>
body .md-card.md-card-transparent-unless-hovered {
  background-color: rgba(255,255,255,0.1);
  transition: background-color .3s ease, max-height .3s ease;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  z-index: 99999;
  max-height: 150px;
}
body .md-card.md-card-transparent-unless-hovered:hover {
  background-color: rgba(255,255,255,1);
  max-height: 80vh;
}
body .md-card.md-card-transparent-unless-hovered .md-card-actions {
  opacity: 0;
  max-height: 0;
  transition: all .3s ease;
}
body .md-card.md-card-transparent-unless-hovered:not(:hover) * {
  opacity: 0.1;
}
body .md-card.md-card-transparent-unless-hovered:hover .md-card-actions {
  opacity: 1;
  max-height: 100px;
  transition: opacity 1.2s ease-in, max-height .3s ease-in;
}
body .md-card.md-card-transparent-unless-hovered .md-card-actions .md-button {
  padding-top: .5rem;
  height: auto;
}
body .md-card.md-card-transparent-unless-hovered .md-card-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-left: -.5rem;
  margin-right: -.5rem;  
}


body .md-card.md-card-transparent-unless-hovered .md-card-content > * {
  flex: 1;
  margin-left: 1rem;
  margin-right: 1rem;  
}

</style>
