<template>
<dialog-layout style="height: min(80vh, 800px)">

  <dialog-close-button @close="$emit('close')" />
  
  <md-app style="height: min(80vh, 800px)">
    <md-app-toolbar :class="dirty ? 'md-large md-dense' : ''">
      <app-tabs :tabs="tabs" @tab-changed="i18nPage = $event; editing = null"></app-tabs>
    </md-app-toolbar>
    
    <md-app-drawer md-permanent="clipped">
      <md-list style="overflow:scroll;max-height:min(75vh,700px)">
        <md-list-item>
          <md-field>
            <label>Search</label>
            <md-input type="text" v-model="searching" />
          </md-field>
        </md-list-item>
        <md-list-item v-for="string, index in i18n" :key="string" @click="editing = string">
          <span class="md-list-item-text" v-html="i18nTruncated[index]"></span>
        </md-list-item>
      </md-list>
    </md-app-drawer>
    <md-app-content style="background:whitesmoke">
      <div v-if="editing">
        <form-card @submit="save">
          <template v-slot:header>
            <div v-html="editing">
            </div>
          </template>
          <tiptap :key="editing" v-model="l10n[editing]" />
        </form-card>
      </div>
    </md-app-content>
  </md-app>
</dialog-layout>
</template>

<script>
import Tiptap from '../TiptapOneLine.vue';
import $ from "cash-dom";
import { get, post } from 'axios';

const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export default {
  components: {
    Tiptap,
  },
  computed: {
    tabs () {
      return [
        { id: 'everything!', label: 'Everything!', icon: 'translate-2' },
        ...Object.keys(this.$store.state.repo.config.i18n).map(o => {
          let icon = 'file-line';
          if (o.startsWith('./components') && o.indexOf('-form') !== -1) {
            icon = 'input-cursor-move';
          } else if (o.startsWith('./components')) {
            icon = 'magic-line';
          }
          return {
            id: o,
            label: o.split('/').pop().split('.')[0],
            icon,
          };
        }),
      ];
    },
    i18n () {
      if (!this.i18nPage) {
        return [];
      }
      else if (this.i18nPage === 'everything!') {
        return Object.keys(this.l10n).filter(onlyUnique)
          .filter(o => !this.searching.trim() || o.indexOf(this.searching.trim()) !== -1 || (this.l10n[o]||'').indexOf(this.searching.trim()) !== -1);
      } else {
        return this.$store.state.repo.config.i18n[this.i18nPage].filter(onlyUnique)
          .filter(o => !this.searching.trim() || o.indexOf(this.searching.trim()) !== -1 || (this.l10n[o]||'').indexOf(this.searching.trim()) !== -1);
      }
    },
    i18nTruncated () {
      return this.i18n.map(o => {
        o = $('<div>').html(o).text();
        if (o.length < 20) {
          return o;
        } else {
          return o.substr(0, 20) + '&hellip;';
        }
      });
    },
  },
  methods: {
    async save () {
      await post(`/api/l10n?path=${this.lang}`, this.l10n);
    },
  },
  watch: {
    editing (val) {
      if (!this.l10n[val]) {
        this.l10n[val] = val;
      }
    },
  },
  async mounted () {
    const resp = await get(`/api/l10n?path=${this.lang}`);
    this.l10n = resp.data.config;
  },
  data () {
    return {
      searching: '',
      lang: 'es',
      l10n: {},
      i18nPage: `./pages/${this.$store.state.activePageKey}.vue`,
      editing: null,
      dirty: false,
    };
  },
}
</script>

<style scoped>
.md-app .md-toolbar .md-tabs {
  padding-left: 0 !important;
}
.md-tabs.md-theme-default >>> .md-tabs-navigation {
  display: block !important;
  overflow-x: auto !important;
  white-space: nowrap !important;
}
</style>
