<template>
<dialog-layout ref="layout" class="media-unit-editor" :previewable="true"
               @unmask="$emit('unmask')"
               @remask="$emit('remask')" >
  
  
  <dialog-close-button @close="maybeClose" />
  
  <md-app>
    <md-app-toolbar :data-md-toolbar-collapsed="!dirty">
      
      <div class="md-toolbar-row" v-if="dirty">
        <div class="md-toolbar-section-start">
          <md-button class="md-accent" @click="discardChanges">
            Revert Changes
          </md-button>
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-primary" @click="saveChanges">
            Save Changes
          </md-button>
        </div>
        
      </div>
      
    </md-app-toolbar>
    
    <md-app-drawer md-permanent="clipped">
      
      <md-list>
        <md-list-item v-for="tab in subtabs[activeTab]" :key="tab.name"
                      @click="activeSubtab = tab.name">
          <md-icon><ri :n="tab.icon" /></md-icon>
          <span class="md-list-item-text">{{ tab.label }}</span>
        </md-list-item>
      </md-list>
    </md-app-drawer>
    
    <md-app-content v-if="mounted && activeTab === 'tab-mobile'">
      <div class="row">
        <div class="col">
          <div>
            <label>
              Text size: <css-value-input v-model="styles['--theme--mobile--font__size']" />
            </label>
          </div>
          <div>
            <label>
              Line height: <css-value-input v-model="styles['--theme--mobile--font__line-height']" />
            </label>
          </div>
          <div>
            <label>
              Letter spacing: <css-value-input v-model="styles['--theme--mobile--font__letter-spacing']" />
            </label>
          </div>
          
          <div>
            <label>
              Heading base text size:
              <css-value-input v-model="styles['--theme--mobile--font__size__heading']" :inherited="styles['--theme--mobile--font__size']" />
            </label>
          </div>
          <div>
            <label>
              Heading Line height:
              <css-value-input v-model="styles['--theme--mobile--font__line-height__heading']" :inherited="styles['--theme--mobile--font__line-height']" />
            </label>
          </div>
          <div>
            <label>
              Heading Letter spacing:
              <css-value-input v-model="styles['--theme--mobile--font__letter-spacing__heading']" :inherited="styles['--theme--mobile--font__letter-spacing']" />
            </label>
          </div>
          
          <div>
            <label>
              Button base text size:
              <css-value-input v-model="styles['--theme--mobile--font__size__button']" :inherited="styles['--theme--mobile--font__size']" />
            </label>
          </div>
          <div>
            <label>
              Button Line height:
              <css-value-input v-model="styles['--theme--mobile--font__line-height__button']" :inherited="styles['--theme--mobile--font__line-height']" />
            </label>
          </div>
          <div>
            <label>
              Button Letter spacing:
              <css-value-input v-model="styles['--theme--mobile--font__letter-spacing__button']" :inherited="styles['--theme--mobile--font__letter-spacing']" />
            </label>
          </div>
          
        </div>
        <div class="col-4 text-center">
          <iframe style="min-height: 800px; border: 1px solid black; margin: 0 auto;"
                  class="shadow-lg" width="400" :srcdoc="iframeSrc"></iframe>
        </div>      
      </div>
    </md-app-content>
    
    <md-app-content v-if="mounted && activeTab === 'tab-typography'">
      <div v-if="activeSubtab === 'text'" key="typography-text">
        <div>
          <label>
            Text font: <font-family-picker v-model="textFont" />
          </label>
        </div>
        <div>
          <css-value-input v-model="styles['--theme--font__size']" default-unit="rem" :no-unit="true">
            Text size
          </css-value-input>
        </div>
        <div>
          <css-value-input v-model="styles['--theme--font__line-height']" :no-unit="true" default-unit="">
            Line height
          </css-value-input>
        </div>
        <div>
          <css-value-input v-model="styles['--theme--font__letter-spacing']" :no-unit="true" default-unit="em">
            Letter spacing
          </css-value-input>
        </div>
      </div>
      <div v-if="activeSubtab === 'headings'" key="typography-headings">
        <div>
          <label>
            Heading font: <font-family-picker v-model="accentFont" />
          </label>
        </div>
        <div>
          <css-value-input v-model="styles['--theme--font__size__heading']" :inherited="styles['--theme--font__size']" :no-unit="true" default-unit="rem">
            Heading base text size
          </css-value-input>
        </div>
        <div>
          <css-value-input v-model="styles['--theme--font__line-height__heading']" :inherited="styles['--theme--font__line-height']" :no-unit="true" default-unit="">
            Heading line height
          </css-value-input>
        </div>
        <div>
          <css-value-input v-model="styles['--theme--font__letter-spacing__heading']" :inherited="styles['--theme--font__letter-spacing']" :no-unit="true" default-unit="em">
            Heading Letter spacing
          </css-value-input>
        </div>
      </div>
      <div v-if="activeSubtab === 'buttons'" key="typography-buttons">
        <div>
          <label>
            Button colors
            <div>
              <color-picker
                expand-to="bottom-left"
                key="button-color" v-model="styles['--button-color']" fallback="rgba(0,0,0,0)" />
              &nbsp;
              <color-picker
                expand-to="bottom-left"
                key="button-text-color" v-model="styles['--button-text-color']" fallback="rgba(0,0,0,0)" />
              &nbsp;
              <color-picker
                expand-to="bottom-left"
                key="button-border-color" v-model="styles['--button-border-color']" fallback="rgba(0,0,0,0)" />
            </div>
          </label>
        </div>
        <div>
          <label>
            Hovered-over button colors
            <div>
              <color-picker
                expand-to="bottom-left"
                key="button-color__hover" v-model="styles['--button-color__hover']" fallback="rgba(0,0,0,0)" />
              &nbsp;
              <color-picker
                expand-to="bottom-left"
                key="--button-text-color__hover" v-model="styles['--button-text-color__hover']" fallback="rgba(0,0,0,0)" />
              &nbsp;
              <color-picker
                expand-to="bottom-left"
                key="button-border-color" v-model="styles['--button-border-color__hover']" fallback="rgba(0,0,0,0)" />
            </div>
          </label>
        </div>
        <div>
          <label>
            Button font: <font-family-picker v-model="buttonFont" />
          </label>
        </div>
        
        <div>
          <css-value-input v-model="styles['--theme--font__size__button']"
                           :inherited="[ styles['--theme--font__size__heading'], styles['--theme--font__size'] ]"
                           :no-unit="true" default-unit="rem">
            Button base text size
          </css-value-input>
        </div>
        <div>
          <css-value-input v-model="styles['--theme--font__line-height__button']"
                           :inherited="styles['--theme--font__line-height']"
                           :no-unit="true" default-unit="">
            Button line height
          </css-value-input>
        </div>
        <div>
          <css-value-input v-model="styles['--theme--font__letter-spacing__button']"
                           :inherited="styles['--theme--font__letter-spacing']"
                           :no-unit="true" default-unit="em">
            Button letter spacing
          </css-value-input>
        </div>
        
        <div>
          <css-value-input v-model="styles['--button-border-width']"
                           inherited="3px"
                           :no-unit="true" default-unit="px">
            Border size
          </css-value-input>
        </div>
        
        <div>
          <css-value-input :no-unit="true" v-model="styles['--button-radius']" default-unit="px">
            Button radius
          </css-value-input>
        </div>
        
        <div class="md-horizontal-radio-field">
          <label>
            Button shadow:
          </label>
          <md-field class="md-has-value md-borderless">
            <md-radio v-model="styles['--button-box-shadow']"
                      value="none">None</md-radio>
            <md-radio v-model="styles['--button-box-shadow']"
                      value="0 .125rem .25rem rgba(0,0,0,.075)">Small</md-radio>
            <md-radio v-model="styles['--button-box-shadow']"
                      value="0 0.5rem 1rem rgb(0 0 0 / 15%)">Medium</md-radio>
            <md-radio v-model="styles['--button-box-shadow']"
                      value="0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)">Large</md-radio>
            <md-radio v-model="styles['--button-box-shadow']"
                      value="black 5px 5px 5px 0px">Right</md-radio>
          </md-field>
        </div>
      </div>
      
      <div v-if="activeSubtab === 'layout'" key="typography-layout">
        <div class="md-horizontal-radio-field">
          <label>
            Width
          </label>
          <md-field class="md-has-value md-borderless">
            <md-radio v-model="styles['max-width']" value="2000px">
              Full width
            </md-radio>
            <md-radio v-model="styles['max-width']" value="1600px">
              Wide
            </md-radio>
            <md-radio v-model="styles['max-width']" value="1400px">
              Medium
            </md-radio>
            <md-radio v-model="styles['max-width']" value="1200px">
              Narrow
            </md-radio>
          </md-field>
        </div>
        
        <div class="md-horizontal-radio-field">
          <label>
            Shadow
          </label>
          <md-field class="md-has-value md-borderless">
            <md-radio v-model="styles['--media-unit--box-shadow']"
                      v-for="{ value, label } in elevations" :key="value" :value="value">{{ label }}</md-radio>
          </md-field>
        </div>

        <div>
          <label>
            Background
            <div>
              <color-picker
                expand-to="bottom-left"
                key="background-color" v-model="styles['background-color']" fallback="rgba(0,0,0,0)" />
            </div>
          </label>
        </div>

        <div>
          <label>
            Border
          </label>
          <css-value-input v-model="styles['border-width']"
                           inherited="3px"
                           :no-unit="true" default-unit="px">
            Size
          </css-value-input>
          <label>
            Color
            <div>
              <color-picker
                expand-to="bottom-left"
                key="border-color" v-model="styles['border-color']" fallback="rgba(0,0,0,0)" />
            </div>
          </label>
        </div>
        
      </div>

      <div class="row" v-if="activeSubtab === 'form-fields'" key="typography-form-fields'">
        <div class="col-12 col-lg-7">
          <div>
            <label>
              Field colors
              <div>
                <color-picker
                  expand-to="bottom-left"
                  key="form-field-color" v-model="styles['--form-field-color']"
                  />
                &nbsp;
                <color-picker
                  expand-to="bottom-left"
                  key="form-field-text-color" v-model="styles['--form-field-text-color']"
                  />
                &nbsp;
                <color-picker
                  expand-to="bottom-left"
                  key="form-field-border-color" v-model="styles['--form-field-border-color']"
                  />
              </div>
            </label>
          </div>
          <div>
            <css-value-input v-model="styles['--form-field-border-width']"
                             :no-unit="true" default-unit="px"
                             >
              Border size
            </css-value-input>
          </div>
          
          <div>
            <css-value-input :no-unit="true" v-model="styles['--form-field-radius']" default-unit="px"
                             >
              Form-Field radius
            </css-value-input>
          </div>
          
          <div class="md-horizontal-radio-field">
            <label>
              Form-Field shadow:
            </label>
            <md-field class="md-has-value md-borderless">
              <md-radio v-model="styles['--form-field-box-shadow']"
                        value="none">None</md-radio>
              <md-radio v-model="styles['--form-field-box-shadow']"
                        value="0 .125rem .25rem rgba(0,0,0,.075)">Small</md-radio>
              <md-radio v-model="styles['--form-field-box-shadow']"
                        value="0 0.5rem 1rem rgb(0 0 0 / 15%)">Medium</md-radio>
              <md-radio v-model="styles['--form-field-box-shadow']"
                        value="0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)">Large</md-radio>
              <md-radio v-model="styles['--form-field-box-shadow']"
                        value="black 5px 5px 5px 0px">Right</md-radio>
            </md-field>
          </div>
          
        </div>
      </div>
      
      <div v-if="activeSubtab === 'theme'" key="typography-theme">
        <div>
          <label>
            Primary Theme Color
            <color-picker
              expand-to="bottom-left"
              key="--theme--color__heavy" v-model="styles['--theme--color__heavy']" fallback="rgba(0,0,0,0)" />
          </label>
        </div>
        <div>
          <label>
            Secondary Theme Color
            <color-picker
              expand-to="bottom-left"
              key="--theme--color__secondary" v-model="styles['--theme--color__secondary']" fallback="rgba(0,0,0,0)" />
          </label>
        </div>
        <div>
          <label>
            Additional Theme Color
            <color-picker
              expand-to="bottom-left"
              key="--theme--color__accent" v-model="styles['--theme--color__accent']" fallback="rgba(0,0,0,0)" />
          </label>
        </div>
        
        <div>
          <label>
            Default Text Color
            <color-picker
              expand-to="bottom-left"
              key="--theme--color__text" v-model="styles['--theme--color__text']" fallback="#000000" />
          </label>
        </div>

      </div>
      
      <div v-if="activeSubtab === 'links'" key="typography-links">
        <div>
          <label>
            Link color
            <color-picker
              expand-to="bottom-left"
              key="--link-color" v-model="styles['--link-color']" fallback="rgba(0,0,0,0)" />
          </label>
        </div>
        <div>
          <label>
            Hovered-over link color
            <color-picker
              expand-to="bottom-left"
              key="--link-color__hover" v-model="styles['--link-color__hover']" fallback="rgba(0,0,0,0)" />
          </label>
        </div>
        <div class="md-horizontal-radio-field">
          <label>
            Underline links?
          </label>
          <md-field class="md-has-value md-borderless">
            <md-radio v-model="styles['--link-text-decoration']" value="underline">
              Yes, underline
            </md-radio>
            <md-radio v-model="styles['--link-text-decoration']" value="none">
              No, do not underline
            </md-radio>
          </md-field>
        </div>
        <div class="md-horizontal-radio-field">
          <label>
            Underline hovered-over links?
          </label>
          <md-field class="md-has-value md-borderless">
            <md-radio v-model="styles['--link-text-decoration__hover']" value="underline">
              Yes, underline
            </md-radio>
            <md-radio v-model="styles['--link-text-decoration__hover']" value="none">
              No, do not underline
            </md-radio>
          </md-field>
        </div>
      </div>
      <iframe :key="writeStyles(styles)"
              style="width: 100%; min-height: 400px; border: none; margin: 0 auto; visibility: hidden"
              class="p-5" @load="$event.target.style['visibility'] = 'visible'"
              :srcdoc="iframeWrapper(typographySamples(activeSubtab))"></iframe>
    </md-app-content>
  </md-app>
</dialog-layout>
</template>

<script>
import Vue from 'vue';
import { writeStyleString } from '../../css-utils.js';

import CssValueInput from '../inputs/CssValueInput.vue';
import ColorPicker from '../ColorPicker.vue';
import FontFamilyPicker from '../FontFamilyPicker.vue';

import $ from 'cash-dom';

const writeStyles = styles => writeStyleString(styles, true, true);

const parseFont = (family) => {
  if (!family) {
    return family;
  }
  return family.replace(/"/g, '');
};

export default {
  name: 'GlobalStyleEditor',

  computed: {
    
    elevations () {
      return  [
        {label: "None", value: ''},
        {label: "Bootstrap - Small", value: '0 .125rem .25rem rgba(0,0,0,.075)'},
        {label: "Bootstrap - Medium", value: '0 0.5rem 1rem rgb(0 0 0 / 15%)'},
        {label: "Bootstrap - Large", value: '0 1rem 3rem rgb(0 0 0 / 18%)'},
        {label: "Shallow", value: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'},
        {label: "Medium", value: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'},
        {label: "Raised", value: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)'},
        {label: "Extra raised", value: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)'},
        {label: 'From bottom right', value: 'black 5px 5px 5px 0px'},
      ];
    },
    
    iframeSrc () {
      const fonts = this.fonts
            .map(({ family }) => `https://fonts.googleapis.com/css2?family=${family}:wght@400;700&display=swap`)
            .map(href => `<link rel='stylesheet' type='text/css' href="${href}" />`)
            .join('\n');

      return `<html><head>${fonts}
  <my-script type="text/javascript">
    window.onload = function() {    
      if (parent) {        
        var oHead = document.getElementsByTagName("head")[0];        
        var arrStyleSheets = parent.document.getElementsByTagName("style");        
        for (var i = 0; i < arrStyleSheets.length; i++)            
          oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
        var arrStyleSheets = parent.document.getElementsByTagName("link");        
        for (var i = 0; i < arrStyleSheets.length; i++) {           
          if (arrStyleSheets[i].rel !== 'stylesheet' && arrStyleSheets[i].type !== 'text/css') continue;
          if (arrStyleSheets[i].href.endsWith('.css')) {
            oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
          }
        }
      }
    }
  </my-script>
</head><body><div style='${writeStyleString(this.styles, false, true)}'>${this.compiledPreview}</div></body></html>`.replace(/my-script/g, "script");
    },
  },
  
  components: {
    CssValueInput,
    ColorPicker,
    FontFamilyPicker,
  },
  
  methods: {

    async maybeClose () {
      if (this.dirty) {
        const result = await this.$swal.fire({
          title: "You have unsaved changes.",
          text: "Before you go on, do you want to save your changes, revert them, or cancel & stay on this page?",
          icon: 'warning',
          showConfirmButton: true,
          showDenyButton: true,
          showCancelButton: true,
          buttonsStyling: false,
          reverseButtons: true,
          confirmButtonText: 'Save',
          denyButtonText: 'Revert',
          cancelButtonText: 'Stay',
          customClass: {
            confirmButton: 'md-button md-primary md-theme-default',
            denyButton: 'md-button md-accent md-theme-default',
            cancelButton: 'md-button md-theme-default',
          },
        });
        if (result.isDismissed) {
          return;
        }
        if (result.isDenied) {
          await this.discardChanges();
        }
        if (result.isConfirmed) {
          await this.saveChanges();
        }
      }
      this.$emit('close');
    },

    writeStyles: o => writeStyles(o),

    typographySamples (type) {
      let content = '';
      if (type === 'buttons') {
        content = `<div class='wp-block-buttons' style="--button-width: 400px"><div class='wp-block-button'><a href="#" class='wp-block-button__link'>An example button</a></div></div>`;
      }
      if (type === 'form-fields') {
        content = `<div class="form-wrapper" uuid="0" style="margin-right: auto; margin-left: auto;"><form method="POST"><div class="row mb-3"><div class="col"><div class="form-floating"><input id="0--email" type="email" placeholder="email@domain.com" class="form-control"> <label for="0--email">An example form field</label></div></div></div></form></div>`;
      }
      if (type === 'links') {
        content = `<div><a href='#'>An example hyperlink</a></div>`;
      }
      if (type === 'text') {
        content = `<p style="text-align:left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p style="text-align:left">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>`;
      }
      if (type === 'headings') {
        content = `<div><h1>Heading 1</h1><h2>Heading 2</h2><h3>Heading 3</h3><h4>Heading 4</h4><h5>Heading 5</h5><h6>Heading 6</h6></div>`;
      }
      return `<div class='media-unit' style='${writeStyles(this.styles)};padding:0 3rem 5rem 3rem'><div><div class="media-unit--content-wrapper">${content}</div></div></div>`;
    },
    
    iframeWrapper (content) {
      let allFonts = this.fonts;
      const fonts = allFonts
            .filter(o => o && o.family)
            .map(({ family }) => `https://fonts.googleapis.com/css2?family=${family}:wght@400;700&display=swap`)
            .map(href => `<link rel='stylesheet' type='text/css' href="${href}" />`)
            .join('\n');

      return `
<html><head>
  ${fonts}
  <my-script type="text/javascript">
    window.onload = function() {    
      if (parent) {        
        var oHead = document.getElementsByTagName("head")[0];        
        var arrStyleSheets = parent.document.getElementsByTagName("style");        
        for (var i = 0; i < arrStyleSheets.length; i++)            
          oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
        var arrStyleSheets = parent.document.getElementsByTagName("link");        
        for (var i = 0; i < arrStyleSheets.length; i++) {           
          if (arrStyleSheets[i].rel !== 'stylesheet' && arrStyleSheets[i].type !== 'text/css') continue;
          if (arrStyleSheets[i].href.endsWith('.css')) {
            oHead.appendChild(arrStyleSheets[i].cloneNode(true));    
          }
        }
      }
    }
  </my-script>
</head>
<body>
  <div class="md-theme-default" style='${this.$store.state.repo.config.layout.style}'>${content}</div>
</body></html>`.replace(/my-script/g, "script");

    },
          
    mount () {
      this.styles = { ...this.$store.getters.styles };

      this.accentFont = parseFont(this.$store.getters.styles['--theme--font__heading']);
      this.buttonFont = parseFont(this.$store.getters.styles['--theme--font__button']);
      this.textFont = parseFont(this.$store.getters.styles['--theme--font__text']);
      
      this.mounted = true;

      this.compiledPreview = (new Vue({
        render: Vue.compile(this.$store.getters.activePage.html).render,
      })).$mount().$el.outerHTML;
    },
    
    excerpt (html) {
      return $(html).eq(0).text();
    },
    
    
    maskHovered () {
      this.$refs.layout.maskHovered();
    },
    
    async discardChanges () {
      $('#style-holder').attr('style', writeStyleString(this.$store.getters.styles, false, true));
      this.mounted = false;
      await this.$nextTick();
      this.mount();
      await this.$nextTick();
      this.dirty = false;
    },

    async saveChanges () {
      const style = writeStyleString(this.styles, false, true),
            config = {...this.$store.state.repo.config.layout, style};
      this.saving = true;

      await this.$store.dispatch('updateConfigs', {
        fonts: this.fonts,
        layout: config,
      });

      this.saving = false;
      this.dirty = false;
    },
    
    compilePreview () {
      if (!this.mounted) return;
      
      this.styles['--theme--font__text'] = this.textFont ? `"${this.textFont}"` : null;
      this.styles['--theme--font__heading'] = this.accentFont ? `"${this.accentFont}"` : null;
      this.styles['--theme--font__button'] = this.buttonFont ? `"${this.buttonFont}"` : null;
      
      $('#style-holder').attr('style', writeStyleString(this.styles, false, true));
      this.dirty = true;
    },
  },

  watch: {

    activeTab (val) {
      if (this.subtabs[val]) {
        this.activeSubtab = this.subtabs[val][0].name;
      }
    },
    
    styles: {
      deep: true,
      handler () {
        if (!this.mounted) return;
        
        if (this.styles['border-width']) {
          if (this.styles['border-style'] !== 'solid') {
            this.styles['border-style'] = 'solid';
          }
        }
        if (this.styles['max-width']) {
          if (this.styles['margin-left'] !== 'auto') {
            this.styles['margin-left'] = 'auto';
          }
          if (this.styles['margin-right'] !== 'auto') {
            this.styles['margin-right'] = 'auto';
          }
        }
        this.compilePreview();
        this.dirty = true;
      },
    },
    boxLayout: {
      deep: true,
      handler () {
        if (!this.mounted) return;        
        this.compilePreview();
      },
    },

    accentFont (val) {
      if (val) {
        let fonts = this.fonts.filter(o => o.family);
        if (fonts.filter(o => o.family === val).length === 0) {
          fonts.push({ source: 'google', family: val });
        }
        this.fonts = fonts;
      }
      if (!this.mounted) return;
      this.compilePreview();
    },
    buttonFont (val) {
      if (val) {
        let fonts = [...this.fonts];
        if (fonts.filter(o => o.family === val).length === 0) {
          fonts.push({ source: 'google', family: val });
        }
        this.fonts = fonts;
      }
      if (!this.mounted) return;
      this.compilePreview();
    },
    textFont (val) {
      if (val) {
        let fonts = [...this.fonts];
        if (fonts.filter(o => o.family === val).length === 0) {
          fonts.push({ source: 'google', family: val });
        }
        this.fonts = fonts;
      }
      if (!this.mounted) return;
      this.compilePreview();
    },
    
  },
  
  data () {
    return {

      compiledPreview: '',

      styles: {},
      mounted: false,
      dirty: false,
      saving: false,
      
      activeTab: 'tab-typography',
      activeSubtab: '',

      subtabs: {
        'tab-typography': [
          { name: 'theme', icon: 'palette-line', label: 'Colors' },
          { name: 'layout', icon: 'layout-5-line', label: 'Layout' },
          { name: 'text', icon: 'paragraph', label: 'Text' },
          { name: 'headings', icon: 'heading', label: 'Headings' },
          { name: 'buttons', icon: 'drag-drop-line', label: 'Buttons' },
          { name: 'form-fields', icon: 'input-cursor-move', label: 'Form Fields' },
          { name: 'links', icon: 'link', label: 'Hyperlinks' },
        ],
      },

      mediaWidth: '',

      fonts: [...this.$store.state.repo.config.fonts],
      accentFont: '',
      buttonFont: '',
      textFont: '',

      boxLayout: {},
    }
  },
  mounted () {
    this.mount();
    this.$nextTick(() => {
      this.dirty = false;
    });
  },
}
</script>

<style scoped>
.md-app-toolbar[data-md-toolbar-collapsed] {
  min-height: 15px;
}
label {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
label input.form-control {
  max-width: 5rem;
  padding: .75rem .25rem;
  text-align: right;
}
label input.form-control::-webkit-outer-spin-button, 
label input.form-control::-webkit-inner-spin-button {
  margin-left: .25rem;
}

.md-horizontal-radio-field > label {
  margin-bottom: -1rem;
}
.md-horizontal-radio-field .md-field {
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
}
.md-horizontal-radio-field .md-field >>> .md-radio label.md-radio-label {
  padding-left: 3px;
}
.media-unit-editor .controls {
  font-family: "Roboto", sans-serif;    
}
.background-overlay-editor {
  background: #eee;
  margin: 1rem auto;
  padding: 1rem;
}
.media-unit-editor .md-tabs {
  margin-bottom: 1rem;
}
.media-unit-editor .btn-row {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-unit-editor .md-content {
}
.media-unit-editor .md-toolbar .md-tabs {
  width: 100%;
  margin-bottom: 0;
}
.media-unit-editor .md-drawer {
  width: auto;
  padding-right: 2rem;
  font-size: 2rem;
}
.media-unit-editor .md-toolbar .md-tabs >>> .md-button {
  margin-top: .5rem;
  padding-top: .5rem;
  margin-bottom: .5rem;
  min-height: 52px;
}
</style>
