<template>
<dialog-layout style="min-height: min(80vh, 800px)" :width=".96" class="form-manager-app">
  <dialog-close-button @close="$emit('close')" />

  <md-app style="min-height: min(80vh, 800px)">
    <md-app-toolbar :class="toolbarHasActionRow ? 'md-large md-dense' : ''">
      
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start" style="position:absolute">
          <md-button class="md-icon-button" @click="drawerOpen = true">
            <md-icon>
              <ri n="menu-fold-line" />
            </md-icon>
          </md-button>

          <md-button v-if="activeForm" :disabled="true">
            Editing: {{ activeForm.replace(/-/g, ' ') }}
          </md-button>
        </div>
        
        <app-tabs v-if="activeForm" :tabs="appTabs" :active-tab="activeTab" @tab-changed="maybeChangeTab" />
      </div>
      <div class="md-toolbar-row" v-if="toolbarHasActionRow">
        <div class="md-toolbar-section-start">
          <md-button class="md-accent" @click="discardFormChanges">
            Revert Changes
          </md-button>
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-primary" @click="saveFormChanges()">
            Save Changes
          </md-button>
        </div>
      
      </div>
    </md-app-toolbar>

    <md-app-drawer :md-active.sync="drawerOpen">
      <md-list>
        <md-list-item v-for="form, index in forms" :key="index" @click="activeForm = form.name; drawerOpen = false;">
          <span class="md-list-item-text">{{ form.name }}</span>
          <md-button @click.prevent="cloneForm(form)" class="go-button">Clone</md-button>
        </md-list-item>
        <md-list-item @click="addNewForm">
          <span class="md-list-item-text">+ Add New</span>
        </md-list-item>
      </md-list>
    </md-app-drawer>

    <md-app-content style="background:whitesmoke">
      <form-builder v-if="activeForm"
                    :form-name="activeForm"
                    :template="formCode"
                    :key="activeForm"
                    :active-tab="activeTab"
                    @form-changed="formStructureChanged"
                    @save-form="saveFormChanges" />
    </md-app-content>
  </md-app>
</dialog-layout>
</template>

<script>
import FormBuilder from './FormBuilder';

export default {
  components: {
    FormBuilder,
  },

  methods: {
    async cloneForm (form) {
      const result = await this.$swal.fire({
        title: "Name your new form",
        input: "text",
        buttonsStyling: false,
        reverseButtons: true,        
        confirmButtonText: 'Add',
        customClass: {
          confirmButton: 'md-button md-primary md-theme-default justify-self-end',
        },
      });

      const formName = result.value;

      if (!formName) {
        return await this.cloneForm(form);
      }

      let template = this.$store.state.repo.components[form.name].template;
      template = template.replace(form.name, formName);
      
      const newComponent = {
        template,
        props: ['uuid'],
        type: 'form',
        name: formName,
      };
      
      await this.$store.dispatch('putComponent', newComponent);
      this.activeForm = formName;
      this.drawerOpen = false;
      
    },
    
    async addNewForm () {
      const result = await this.$swal.fire({
        title: "Name your new form",
        input: "text",
        buttonsStyling: false,
        reverseButtons: true,        
        confirmButtonText: 'Add',
        customClass: {
          confirmButton: 'md-button md-primary md-theme-default justify-self-end',
        },
      });

      const formName = result.value;

      if (!formName) {
        return await this.addNewForm();
      }
      
      const newComponent = {
        template: '<p-form form="${formName}" v-slot:form="{ submitForm, fields }"></p-form>',
        props: ['uuid'],
        type: 'form',
        name: formName,
      };
      
      await this.$store.dispatch('putComponent', newComponent);
      this.activeForm = formName;
      this.drawerOpen = false;
    },
    async saveFormChanges (code) {
      let activeComponent = {...this.$store.state.repo.components[this.activeForm]};
      activeComponent.template = code || this.dirtyFormCode || this.formCode;
      await this.$store.dispatch('putComponent', activeComponent);
      this.dirtyFormCode = null;
    },
    async discardFormChanges () {
      this.dirtyFormCode = null;
      const form = this.activeForm;
      this.activeForm = null;
      await this.$nextTick();
      this.activeForm = form;
    },
    async maybeChangeTab (to) {
      if (this.activeTab === 'build' && to !== 'build' && this.formHasUnsavedChanges) {
        const result = await this.$swal.fire({
          title: "Your form has unsaved changes.",
          text: "Before you go on, do you want to save your changes, revert them, or cancel & stay on this page?",
          icon: 'warning',
          showConfirmButton: true,
          showDenyButton: true,
          showCancelButton: true,
          buttonsStyling: false,
          reverseButtons: true,
          confirmButtonText: 'Save',
          denyButtonText: 'Revert',
          cancelButtonText: 'Stay',
          customClass: {
            confirmButton: 'md-button md-primary md-theme-default',
            denyButton: 'md-button md-accent md-theme-default',
            cancelButton: 'md-button md-theme-default',
          },
        });
        if (result.isDismissed) {
          return;
        }
        if (result.isDenied) {
          await this.discardFormChanges();
        }
        if (result.isConfirmed) {
          await this.saveFormChanges();
        }
      }
      this.activeTab = to;
    },

    formStructureChanged (code) {
      this.dirtyFormCode = code;
    },
  },

  computed: {
    formHasUnsavedChanges () {
      return this.dirtyFormCode && this.dirtyFormCode !== this.formCode;
    },
    toolbarHasActionRow () {
      return this.activeForm && this.activeTab === 'build' && this.formHasUnsavedChanges;
    },
    formCode () {
      return this.$store.state.repo.components[this.activeForm].template;
    },
    forms () {
      return Object.values(this.$store.state.repo.config.components).filter(o => o.type === 'form');
    },
  },
  data () {
    return {
      dirtyFormCode: null,
      drawerOpen: true,
      activeForm: null,
      activeTab: 'build',
      appTabs: [
        { id: 'build', icon: 'tools-line', label: 'Build' },
        { id: 'success', icon: 'guide-line', label: 'Success Behavior' },
        //{ id: 'alerts', icon: 'mail-send-line', label: 'Alerts' },
      ],
    };
  }
}
</script>

<style>
.form-manager-app .md-button.go-button {
  height: auto;
  padding: 0.35em 0.1em;
  width: auto;
  min-width: 0;
  border-radius: 7px;
  background: purple;
  color: white;
  margin: 0;
  display: none;
}
.form-manager-app .md-list-item:hover .md-button.go-button {
  display: inline;
}
</style>
