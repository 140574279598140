import { MediaUnit } from './core.js';
import { getContrast } from '../color-utils.js';

export const meet_alicia = new MediaUnit('meet_alicia', 'Meet Alicia', 'long_text', {
  styles: ({ primaryColor }) => ({
    '--media-unit--media__width': '0%',
    'margin-top': '4rem',
    'margin-bottom': '4rem',
    'color': getContrast(primaryColor),
    'background-color': primaryColor,
  }),

  mediaStyles: () => ({
    'display': 'none',
  }),

  contentStyles: () => ({
    'text-align': 'left',
  }),

  template: ({ image, heading, content, styles, contentStyles, mediaStyles, metadata, media_side = 'left' }) => {
    let imageEl = '';
    if (image) {
      if (media_side === 'right') {
        imageEl = `<img src="${image}" style="float: left; margin-right: 3rem; margin-bottom: 3rem; width: 100%; max-width: ${Math.min(600, metadata.width || 600)}px" />`;
      } else {
        imageEl = `<img src="${image}" style="float: right; margin-left: 5rem; margin-bottom: 3rem; width: 100%; max-width: ${Math.min(600, metadata.width || 600)}px" />`;
      }
    }
    let headingEl = heading ? `<h1 style="text-align: left">${heading}</h1>` : '';

    return `
<media-unit uuid="" image="${image}"
            styles="${styles}"
            content-styles="${contentStyles}"
            content-class="has-wrapped-content"
            media-styles="${mediaStyles}">
  <template v-slot:default>
    <div class="media-unit--content-wrapper">
      ${headingEl}
      ${imageEl}
      ${content}
    </div>
  </template>
</media-unit>`;
  },
});

export const stacked_centered = new MediaUnit('stacked_centered', 'Stacked and Centered', 'long_text', {
  styles: ({ primaryColor }) => ({
    '--media-unit--media__width': '0%',
    'margin-top': '4rem',
    'margin-bottom': '4rem',
    'color': getContrast(primaryColor),
    'background-color': primaryColor,
  }),

  mediaStyles: () => ({
    'display': 'none',
  }),

  contentStyles: () => ({
    'text-align': 'justify',
    'max-width': '1000px',
    'margin-left': 'auto',
    'margin-right': 'auto',    
  }),

  template: ({ image, heading, content, styles, contentStyles, mediaStyles, metadata, headingStyles }) => {
    let imageEl = image ? `<img src="${image}" style="margin-bottom: 2rem; width: 100%; max-width: ${Math.min(600, metadata.width || 600)}px" />` : '';
    let headingEl = heading ? `<h1 style="${headingStyles}">${heading}</h1>` : '';

    return `
<media-unit uuid="" image="${image}"
            styles="${styles}"
            content-styles="${contentStyles}"
            media-styles="${mediaStyles}">
  <template v-slot:default>
      ${headingEl}
      ${imageEl}
      ${content}
    </div>
</media-unit>`;
  },
});
